import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddFacility from './AddFacility'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function FacilityList() {
    const [facilityData, setFacilityData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedFacilityId, setSelectedFacilityId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchFacilityData();
    }, []);

    const fetchFacilityData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilityData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (id) => {
        // setSelectedFacilityId(id);
        // setShowModal(true);
        navigate(`/admin/configurations/facility/add-facility?facilityId=${id}`);
    };

    const handleDelete = async (facilityId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete area: ${facilityId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/facility/delete/${facilityId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchFacilityData();
            } catch (error) {
                console.error('Failed to delete area:', error);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedFacilityId(null);
        fetchFacilityData(); // Refresh data after adding or editing
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = facilityData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Facility ID': item.facilityId,
            'Facility Name': item.facilityName,
            'Area ID': item.areaId,
            'Facility Category': item.facilityCategory,
            'Flaring UoM': item.flaringUOM,
            'Zone or Area': item.zoneOrArea,
            'Leakage Calc Switch': item.leakageCalcSwitch,
            'No of Shifts': formatWithIndianThousandsSeparatorForExportData(item.noOfShifts),
            'Shift Start Time': formatWithIndianThousandsSeparatorForExportData(item.shiftStartTime),
            'DAHS Server Name': item.dahsServerName,
            'Flare Scheduler': item.flareScheduler,
            'EMS Scheduler': item.emsScheduler,
            'PI URL': item.piURL,
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, facilityData]);

    const columns = [
        { name: 'Facility ID', selector: row => row.facilityId, sortable: true },
        { name: 'Facility Name', selector: row => row.facilityName, sortable: true },
        { name: 'Area ID', selector: row => row.areaId, sortable: true },
        { name: 'Facility Category', selector: row => row.facilityCategory, sortable: true },
        { name: 'Flaring UoM', selector: row => row.flaringUOM, sortable: true },
        { name: 'Zone or Area', selector: row => row.zoneOrArea, sortable: true },
        {
            name: 'Leakage Calc Switch',
            selector: row => row.leakageCalcSwitch,
            sortable: true
        },
        {
            name: 'No of Shifts',
            selector: row => row.noOfShifts,
            cell: row => formatWithIndianThousandsSeparator(row.noOfShifts),
            sortable: true
        },
        {
            name: 'Shift Start Time',
            selector: row => row.shiftStartTime,
            cell: row => formatWithIndianThousandsSeparator(row.shiftStartTime),
            sortable: true
        },
        { name: 'DAHS Server Name', selector: row => row.dahsServerName, sortable: true },
        { name: 'Flare Scheduler', selector: row => row.flareScheduler, sortable: true },
        { name: 'EMS Scheduler', selector: row => row.emsScheduler, sortable: true },
        {name : 'PI URL', selector: row => row.piURL, sortable: true,width: '250px'},
        { name: 'Status', selector: row => row.status, sortable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.facilityId)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.facilityId)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Facility_${new Date().toLocaleDateString()}`;

    const title = "Facility Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Facility</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Organizational Structure</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Facility</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    {/* <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>Add Facility</button> */}
                                    <Link to="/admin/configurations/facility/add-facility" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedFacilityId ? 'Edit Facility' : 'Add Facility'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddFacility areaId={selectedFacilityId} closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    );
}
