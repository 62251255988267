import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TagsMasterService } from './services/TagsMasterService';

const AddTagsMasterVTwo = () => {
  const navigate = useNavigate();
  const [formGroup, setFormGroup] = useState({
    deviceId: '',
    description: '',
    readingType: '',
    compositionType: '',
    composition: '',
    compositionProperty: '',
    tagAvailability: 'Y',
    tagName: '',
    tagMin: '',
    tagMax: '',
    tagDefault: '',
    engUnit: '',
    valveOpenState: '',
    scheduleHours: '',
    status: 'A'
  });
  const [isEdit, setIsEdit] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [headerList, setHeaderList] = useState([]);
  const [readingTypeList, setReadingTypeList] = useState([]);
  const [compositionTypes, setCompositionTypes] = useState([]);
  const [gasCompositionList, setGasCompositionList] = useState([]);
  const [liquidCompositionList, setLiquidCompositionList] = useState([]);
  const [compositionPropertyList, setCompositionPropertyList] = useState([]);
  const [engUnitList, setEngUnitList] = useState([]);
  const [ynList] = useState(['Y', 'N']);
  const [statusOptions] = useState(['A', 'D']);
  const [selectedTagItem, setSelectedTagItem] = useState(null);
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [appSession, setAppSession] = useState(null);

  useEffect(() => {
    getFormPrerequisites();
    buildTagMasterForm();
  }, []);

  const buildTagMasterForm = () => {
    setFormGroup({
      deviceId: '',
      description: '',
      readingType: '',
      compositionType: '',
      composition: '',
      compositionProperty: '',
      tagAvailability: 'Y',
      tagName: '',
      tagMin: '',
      tagMax: '',
      tagDefault: '',
      engUnit: '',
      valveOpenState: '',
      scheduleHours: '',
      status: 'A'
    });
  };

  const getFormPrerequisites = async () => {
    try {
      const [
        devices,
        headers,
        appSessionData,
        codeMasters,
        uoms,
        compositions,
        liquidCompositions
      ] = await Promise.all([
        TagsMasterService.getAllDevices(),
        TagsMasterService.getAllHeaders(),
        TagsMasterService.getAppSession(),
        TagsMasterService.getAllCodeMaster(),
        TagsMasterService.getAllUOMs(),
        TagsMasterService.getAllCompositions(),
        TagsMasterService.getAllLiquidCompositions()
      ]);

      setDeviceList(devices);
      setHeaderList(headers);
      setAppSession(appSessionData);
      setCompositionTypes(codeMasters.filter(t => t.type === 'CompositionType'));
      setCompositionPropertyList(codeMasters.filter(t => t.type === 'LIMSproperty'));
      setEngUnitList(uoms);
      setGasCompositionList(compositions);
      setLiquidCompositionList(liquidCompositions);

      if (selectedTagItem) {
        applyTagType(selectedTagItem.deviceId || selectedTagItem.headerId);
      }
    } catch (error) {
      console.error('Failed to load initial data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.deviceId) newErrors.deviceId = 'Source ID is required.';
    if (!formGroup.readingType) newErrors.readingType = 'Reading Type is required.';
    if (!formGroup.compositionType) newErrors.compositionType = 'Composition type is required.';
    if (!formGroup.composition) newErrors.composition = 'Composition is required.';
    if (!formGroup.compositionProperty) newErrors.compositionProperty = 'Composition Property is required.';
    if (!formGroup.tagName) newErrors.tagName = 'Tag name is required.';
    if (!formGroup.tagDefault) newErrors.tagDefault = 'Tag default is required.';
    if (!formGroup.engUnit) newErrors.engUnit = 'Eng unit is required.';
    if (!formGroup.status) newErrors.status = 'Status is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await TagsMasterService.saveTagsMaster(formGroup, isEdit);
      toast.success(`Tags Master ${isEdit ? 'updated' : 'added'} successfully.`);
      navigate('/admin/configurations/master/list-tags-master');
    } catch (error) {
      console.error('Failed to save Tags Master:', error);
      toast.error('Error saving Tags Master');
    }
  };

  const applyTagType = (selectedId) => {
    const headerFound = headerList.find(x => x.headerId === selectedId);
    if (headerFound) {
      setIsDeviceSelected(false);
      setSelectedTagItem(headerFound);
      filterReadingTypesForHeader();
    } else {
      const device = deviceList.find(x => x.deviceId === selectedId);
      setIsDeviceSelected(true);
      setSelectedTagItem(device);
      filterReadingTypesForDevice(device);
    }
  };

  const filterReadingTypesForDevice = (selectedItem) => {
    setReadingTypeList(
      appSession.searchFilterItems.filter(t =>
        t.searchField === 'DataCollectionMode' &&
        t.searchData === selectedItem.dataCollectionMode &&
        t.displayField === 'readingType')
    );
  };

  const filterReadingTypesForHeader = () => {
    setReadingTypeList(
      appSession.searchFilterItems.filter(t =>
        t.searchField === 'DataCollectionMode' &&
        t.searchData === 'HEADERID' &&
        t.displayField === 'readingType')
    );
  };

  return (
    <div>
      <div className="breadcrumb mt-2">
        <h1>{isEdit ? 'Edit Tag Master' : 'Add Tag Master'}</h1>
        <ul>
          <li>Master Data Management</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="deviceId">Source/Header ID</label>
                <select
                  className="form-control"
                  id="deviceId"
                  name="deviceId"
                  value={formGroup.deviceId}
                  onChange={(e) => {
                    handleChange(e);
                    applyTagType(e.target.value);
                  }}
                >
                  <option value="">Select Source/Header ID</option>
                  {deviceList.map(device => (
                    <option key={device.deviceId} value={device.deviceId}>Source - {device.deviceId}</option>
                  ))}
                  {headerList.map(header => (
                    <option key={header.headerId} value={header.headerId}>Header - {header.headerId}</option>
                  ))}
                </select>
                {errors.deviceId && <div className="invalid-feedback d-block">{errors.deviceId}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  value={formGroup.description}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="readingType">Reading Type</label>
                <select
                  className="form-control"
                  id="readingType"
                  name="readingType"
                  value={formGroup.readingType}
                  onChange={handleChange}
                >
                  <option value="">Select Reading Type</option>
                  {readingTypeList.map(type => (
                    <option key={type.displayData} value={type.displayData}>{type.displayData}</option>
                  ))}
                </select>
                {errors.readingType && <div className="invalid-feedback d-block">{errors.readingType}</div>}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="compositionType">Composition type</label>
                <select
                  className="form-control"
                  id="compositionType"
                  name="compositionType"
                  value={formGroup.compositionType}
                  onChange={handleChange}
                >
                  <option value="">Select Composition Type</option>
                  {compositionTypes.map(type => (
                    <option key={type.code} value={type.code}>{type.description}</option>
                  ))}
                </select>
                {errors.compositionType && <div className="invalid-feedback d-block">{errors.compositionType}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="composition">Composition</label>
                <select
                  className="form-control"
                  id="composition"
                  name="composition"
                  value={formGroup.composition}
                  onChange={handleChange}
                >
                  <option value="">Select LIMS Composition</option>
                  <optgroup label="Gas Compositions">
                    {gasCompositionList.map(composition => (
                      <option key={composition.componentId} value={composition.componentId}>
                        {composition.componentId} - {composition.iupacName}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Liquid Compositions">
                    {liquidCompositionList.map(composition => (
                      <option key={composition.compositionId} value={composition.compositionId}>
                        {composition.compositionId} - {composition.fuel}
                      </option>
                    ))}
                  </optgroup>
                </select>
                {errors.composition && <div className="invalid-feedback d-block">{errors.composition}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="compositionProperty">Composition Property</label>
                <select
                  className="form-control"
                  id="compositionProperty"
                  name="compositionProperty"
                  value={formGroup.compositionProperty}
                  onChange={handleChange}
                >
                  <option value="">Select LIMS Property</option>
                  {compositionPropertyList.map(prop => (
                    <option key={prop.code} value={prop.code}>{prop.description}</option>
                  ))}
                </select>
                {errors.compositionProperty && <div className="invalid-feedback d-block">{errors.compositionProperty}</div>}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="tagAvailability">Tag Availability</label>
                <select
                  className="form-control"
                  id="tagAvailability"
                  name="tagAvailability"
                  value={formGroup.tagAvailability}
                  onChange={handleChange}
                >
                  {ynList.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="tagName">Tag Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="tagName"
                  name="tagName"
                  value={formGroup.tagName}
                  onChange={handleChange}
                />
                {errors.tagName && <div className="invalid-feedback d-block">{errors.tagName}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="tagMin">Tag Minimum</label>
                <input
                  type="number"
                  className="form-control"
                  id="tagMin"
                  name="tagMin"
                  value={formGroup.tagMin}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="tagMax">Tag Maximum</label>
                <input
                  type="number"
                  className="form-control"
                  id="tagMax"
                  name="tagMax"
                  value={formGroup.tagMax}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="tagDefault">Tag Default Value</label>
                <input
                  type="number"
                  className="form-control"
                  id="tagDefault"
                  name="tagDefault"
                  value={formGroup.tagDefault}
                  onChange={handleChange}
                />
                {errors.tagDefault && <div className="invalid-feedback d-block">{errors.tagDefault}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="engUnit">Eng Units</label>
                <select
                  className="form-control"
                  id="engUnit"
                  name="engUnit"
                  value={formGroup.engUnit}
                  onChange={handleChange}
                >
                  <option value="">Select Eng unit</option>
                  {engUnitList.map(unit => (
                    <option key={unit.uomId} value={unit.uomId}>{unit.description}</option>
                  ))}
                </select>
                {errors.engUnit && <div className="invalid-feedback d-block">{errors.engUnit}</div>}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="valveOpenState">Valve Open State (Digital Tags)</label>
                <input
                  type="text"
                  className="form-control"
                  id="valveOpenState"
                  name="valveOpenState"
                  value={formGroup.valveOpenState}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="scheduleHours">Schedule Hours</label>
                <input
                  type="number"
                  className="form-control"
                  id="scheduleHours"
                  name="scheduleHours"
                  value={formGroup.scheduleHours}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  value={formGroup.status}
                  onChange={handleChange}
                >
                  {statusOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
              </div>
            </div>
            <div className="form-group row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/master/list-tags-master')}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddTagsMasterVTwo;

