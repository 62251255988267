import React, { useState, useEffect, useRef } from 'react';
import anychart from 'anychart';
import fmsDashboardService from '../services/fmsDashboardService';
import './FlaringReasons.css';

const FlaringReasonsAnychart = ({ fmsDashboardFilter, selectedDate, realFlaring }) => {
  const chartRef = useRef(null);
  const [showChart, setShowChart] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [flaringReasons, setFlaringReasons] = useState(null);

  useEffect(() => {
    fetchDataAndSetupChart(true);
  }, [selectedDate, fmsDashboardFilter]);

  useEffect(() => {
    if (showChart && flaringReasons) {
      renderSunburstChart(flaringReasons);
    }
  }, [showChart, flaringReasons]);

  const fetchDataAndSetupChart = async (forceUpdate) => {
    if (!selectedDate || !fmsDashboardFilter) {
      return;
    }

    setShowChart(false);

    if (forceUpdate) {
      try {
        const response = await fmsDashboardService.getFlaringReasons(selectedDate, fmsDashboardFilter.type, fmsDashboardFilter.facilityId);
        const flaringReasonsData = response.data;
        if (!flaringReasonsData || (!flaringReasonsData.category && !flaringReasonsData.details)) {
          setShowNoDataText(true);
          return;
        }
        if ((flaringReasonsData.category && flaringReasonsData.category.length !== 0) || (flaringReasonsData.details && flaringReasonsData.details.length !== 0)) {
          setShowNoDataText(false);
          setFlaringReasons(flaringReasonsData);
          setShowChart(true);
        } else {
          setShowNoDataText(true);
        }
      } catch (error) {
        setShowNoDataText(true);
      } finally {
        setShowChart(true);
      }
    } else {
      setShowChart(true);
    }
  };

  const renderSunburstChart = (data) => {
    const chartData = data.category.map(cat => ({
      name: cat.category,
      value: cat.percent,
      children: data.details
        .filter(detail => detail.reasonCategory === cat.category)
        .map(detail => ({
          name: detail.reasonDescription,
          value: detail.percentage
        })),
    }));

    if (chartRef.current) {
      // Clear any previous chart
      chartRef.current.innerHTML = '';
    }

    // Create the sunburst chart
    const chart = anychart.sunburst(chartData, "as-tree");

    // Set container for the chart
    chart.container(chartRef.current);

    chartRef.current.style.width = "300px"; // Adjust as needed
    chartRef.current.style.height = "300px"; // Adjust as needed

    // Set the inner radius to create the inner circle
    chart.innerRadius("50%");

    // Set up colors for the segments
    chart.palette(["#007bff", "#ff7043"]); // Adjust colors if needed

    chart.padding(0, 0, 0, 0); // Remove any unnecessary padding around the chart

    // Configure the labels with larger font size
    chart.labels().useHtml(true);
    chart.labels().fontSize(14); // Set font size for labels
    chart.labels().format("<b>{%name}</b>");

    // Configure the tooltip with larger font size
    chart.tooltip().useHtml(true);
    chart.tooltip().fontSize(14); // Set font size for tooltip
    chart.tooltip().format("Reason: <b>{%name}</b><br>Percentage: <b>{%value}%</b>");

    // Draw the chart
    chart.draw();

    // Add a central label manually if needed
    const centerLabel = chart.label();
    centerLabel
      .text(`${realFlaring}`) // The text you want to display
      .fontColor("#000000") // Set text color to black or any desired color
      .fontSize(11) // Adjust font size as needed
      .fontWeight(800) // Font weight
      .hAlign("center") // Horizontal alignment
      .vAlign("middle") // Vertical alignment
      .anchor("center") // Anchor to center
      .position("center") // Position at the center of the chart
      .useHtml(true); // Enable HTML formatting

    // Handle node clicks to display child details
    chart.listen('pointClick', function (e) {
      const point = e.point;
      if (point) { // Check if the point is valid
        const pointData = point.get('name');
        const pointValue = point.get('value');
        // chart.title(`Selected: ${pointData} (${pointValue}%)`);
      }
    });
  };

  return (
    <div className="flaring-reasons">
      {showChart && !showNoDataText ? (
        <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>
      ) : (
        <div className="no-data-txt">
          <h5 className="mt-5"> No Data Found</h5>
        </div>
      )}
    </div>
  );
};

export default FlaringReasonsAnychart;
