import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import FacilitySelector from '../../common/FacilitySelector';
import 'react-datepicker/dist/react-datepicker.css';
import { formatNumber, formatWithIndianThousandsSeparator ,formatWithIndianThousandsSeparatorForExportData} from '../../common/FormatNumber';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { format } from 'date-fns';

export default function FacilityEmissions() {
    const [facilityData, setFacilityData] = useState([]);
    const [facilityEmissionsData, setFacilityEmissionsData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    const [startDatePickerClass, setStartDatePickerClass] = useState('');
    const [endDatePickerClass, setEndDatePickerClass] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]);



    useEffect(() => {
        fetchFacilityData();
    }, []);

    const fetchFacilityData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            setFacilityData(data.filter(t => t.facilityCategory != "AMMNET"));
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        }
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleFacilityChange = (e) => {
        setSelectedFacilityId(e.target.value);
    };

    const handleFacilityEmissionButtonClick = () => {
        if (startDate && endDate && selectedFacilityId !== '') {
            setIsLoading(true); // Set loading state to true before fetching data

            const apiUrl = `${API_BASE_URL}/reports/EMSFacilityEmissions?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {

                    setFacilityEmissionsData(data);
                    setFilteredData(data)
                    setIsLoading(false); // Set loading state to false after data is fetched
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false); // Set loading state to false if there's an error
                });
        } else {
            console.error('Please select valid options.');
        }
    };



    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        let filtered = facilityEmissionsData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );

        // If filtered data is empty, add a placeholder row to ensure headers are displayed
        if (filtered.length === 0) {
            filtered = [{
                facilityId: '',
                facilityName: '',
                pollutant: '',
                emissions: '',
                uomId: ''
            }];
        }

        setFilteredData(filtered);

         // Create exportFilteredData according to the columns order
            const exportData = filtered.map(item => ({
                'Facility ID': item.facilityId,
                'Facility Name': item.facilityName,
                'Pollutant': item.pollutant,
                'Adj Emission': formatWithIndianThousandsSeparatorForExportData(item.emissions ? item.emissions.toFixed(0) : item.emissions),
                'UoM': item.uomId
            }));
            setExportFilteredData(exportData);
            
    }, [search, facilityEmissionsData]);




    const columns = [
        {
            name: 'Facility ID',
            selector: row => row.facilityId,
            sortable: true
        },
        {
            name: 'Facility Name',
            selector: row => row.facilityName,
            sortable: true
        },
        {
            name: 'Pollutant',
            selector: row => row.pollutant,
            sortable: true
        },
        {
            name: 'Adj Emission',
            selector: row => row.emissions,
            sortable: true,
            cell: row => formatWithIndianThousandsSeparator(row.emissions ? row.emissions.toFixed(0) : row.emissions)
        },
        {
            name: 'UoM',
            selector: row => row.uomId,
            sortable: true
        }

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `EMS_Facility_Emissions${new Date().toLocaleDateString()}`;

    const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
    const handleStartDateBlur = () => setStartDatePickerClass('');
    const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
    const handleEndDateBlur = () => setEndDatePickerClass('');

    const headerData = [
        { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facility' },
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];
    
    const title = "Facility Emission Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Facility Emissions</h1>
                <ul>
                    <li>Reports</li>
                    <li>Facility Emissions</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Select Facility</label>
                        <select
                            name='facility'
                            className='form-control'
                            value={selectedFacilityId}
                            onChange={handleFacilityChange}
                        >
                            <option value={-1}>All Facilities</option>
                            {facilityData.map((facility) => (
                                <option key={facility.facilityId} value={facility.facilityId}>
                                    {facility.facilityName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label>Start Date</label>
                        <div className={startDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleStartDateFocus}
                                onBlur={handleStartDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <label>End Date</label>
                        <div className={endDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleEndDateFocus}
                                onBlur={handleEndDateBlur}
                            />
                        </div>
                    </div>

                    {/* <div className='col-md-3'>
        <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
        </div> */}
                    <div className='col-md-3 generate_report_button'>
                        <button className='btn btn-primary' onClick={handleFacilityEmissionButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>

                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3"> Facility Emissions </h4> */}
                            <div className='row'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col*/}
            </div>
        </div>

    )
}
