import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';

const AddEmsDevice = () => {
  const navigate = useNavigate();
  const { emsDeviceId } = useParams();
  const [formGroup, setFormGroup] = useState({
    deviceId: '',
    materialState: '',
    compType: '',
    componentId: '',
    factorName: '',
    ap42Section: '',
    ssc: '',
    emsMethod: '',
    notes: '',
    isPrimary: 1,
    level1: '',
    level2: '',
    level3: '',
    level4: '',
    neiPollutantCode: '',
    controlId: '',
    refDesc: '',
    measure: '',
    unit: '',
    calcType: '',
    formula: '',
    tooltip: '',
    status: 'A'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [emsDeviceList, setEmsDeviceList] = useState([]);
  const [unregisteredDevices, setUnregisteredDevices] = useState([]);
  const [materialStateList, setMaterialStateList] = useState([]);
  const [emsMethodList, setEmsMethodList] = useState([]);
  const [emsFactorList, setEmsFactorList] = useState([]);
  const [emsPollutantList, setEmsPollutantList] = useState([]);
  const [ipControlList, setIpControlList] = useState([]);
  const [level1Items, setLevel1Items] = useState([]);
  const [level2Items, setLevel2Items] = useState([]);
  const [level3Items, setLevel3Items] = useState([]);
  const [level4Items, setLevel4Items] = useState([]);
  const [selectedEmsFactor, setSelectedEmsFactor] = useState(null);
  const [compositionTypes, setCompositionTypes] = useState([]);
  const [compositionDetails, setCompositionDetails] = useState([]);
  const [statusOptions] = useState(['A', 'D']);
  const [isPrimaryMap] = useState([{ id: 1, Name: 'Yes' }, { id: 0, Name: 'No' }]);
  const [showEmsFactorError, setShowEmsFactorError] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, [emsDeviceId]);

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const [
        emsDevicesResponse,
        unregisteredDevicesResponse,
        codeMastersResponse,
        emsMethodsResponse,
        ipControlListResponse,
        level1ItemsResponse,
        compositionTypesResponse
      ] = await Promise.all([
        fetch(`${API_BASE_URL}/ems-master/ems-device`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/ems-master/ems-device/unregistered`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/ems-master/ems-method`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/ems-master/ip-control/list`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/ems-master/ems-factor/level1`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/composition/distinct/comp-types`, { credentials: 'include' })
      ]);

      if (
        !emsDevicesResponse.ok ||
        !unregisteredDevicesResponse.ok ||
        !codeMastersResponse.ok ||
        !emsMethodsResponse.ok ||
        !ipControlListResponse.ok ||
        !level1ItemsResponse.ok ||
        !compositionTypesResponse.ok
      ) {
        throw new Error('Network response was not ok');
      }

      const [
        emsDevicesData,
        unregisteredDevicesData,
        codeMastersData,
        emsMethodsData,
        ipControlListData,
        level1ItemsData,
        compositionTypesData
      ] = await Promise.all([
        emsDevicesResponse.json(),
        unregisteredDevicesResponse.json(),
        codeMastersResponse.json(),
        emsMethodsResponse.json(),
        ipControlListResponse.json(),
        level1ItemsResponse.json(),
        compositionTypesResponse.json()
      ]);

      setEmsDeviceList(emsDevicesData);
      setUnregisteredDevices(unregisteredDevicesData);
      setMaterialStateList(codeMastersData.filter(item => item.type === CodeMasterType.MaterialState));
      setEmsPollutantList(codeMastersData.filter(item => item.type === CodeMasterType.PollutantID && item.subType === 'EMS'));
      setEmsMethodList(emsMethodsData);
      setIpControlList(ipControlListData);
      setLevel1Items(level1ItemsData);
      setCompositionTypes(compositionTypesData.filter(c => !['GHG', 'AMMNET'].includes(c)));

      if (emsDeviceId) {
        const deviceToEdit = emsDevicesData.find(device => device.id == emsDeviceId);
        if (deviceToEdit) {
          setFormGroup(prevState => ({
            ...prevState,
            deviceId: deviceToEdit.deviceId,
            materialState: deviceToEdit.materialState,
            isPrimary: deviceToEdit.isPrimary,
            notes: deviceToEdit.notes,
            emsMethod: deviceToEdit.method,
            ssc: deviceToEdit.ssc,
            level1: deviceToEdit.level1,
            level2: deviceToEdit.level2,
            level3: deviceToEdit.level3,
            level4: deviceToEdit.level4,
            emsFactor: deviceToEdit.factorId,
            neiPollutantCode: deviceToEdit.neiPollutantCode,
            ap42Section: deviceToEdit.ap42Section,
            refDesc: deviceToEdit.refDesc,
            measure: deviceToEdit.measure,
            unit: deviceToEdit.unit,
            calcType: deviceToEdit.calcType,
            factorName: deviceToEdit.factorName,
            formula: deviceToEdit.formula,
            tooltip: deviceToEdit.tooltip,
            compType: deviceToEdit.compType,
            componentId: deviceToEdit.componentId,
            status: deviceToEdit.status
          }));
        }
      }
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDependentData = async (level, value) => {
    let url = '';
    if (level === 2) {
      url = `${API_BASE_URL}/ems-master/ems-factor/level2/${value}`;
    } else if (level === 3) {
      url = `${API_BASE_URL}/ems-master/ems-factor/level3/${formGroup.level1}?level2=${value}`;
    } else if (level === 4) {
      url = `${API_BASE_URL}/ems-master/ems-factor/level4/${formGroup.level1}?level2=${formGroup.level2}&level3=${value}`;
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (level === 2) {
        setLevel2Items(data);
      } else if (level === 3) {
        setLevel3Items(data);
      } else if (level === 4) {
        setLevel4Items(data);
      }
    } catch (error) {
      console.error(`Failed to fetch level ${level} items:`, error);
    }
  };

  const fetchCompositionDetails = async (compType) => {
    try {
      const response = await fetch(`${API_BASE_URL}/composition/comp-details/${compType}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setCompositionDetails(data);
    } catch (error) {
      console.error('Failed to fetch composition details:', error);
    }
  };

  const fetchMatchingEmsFactorsWithJoin = async (level1, level2, level3, level4) => {
    const url = `${API_BASE_URL}/ems-master/ems-factor/matching-with-join?level1=${level1}&level2=${level2}&level3=${level3}&level4=${level4}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setEmsFactorList(data.map(x => ({
        factorId: x.factorId,
        scc: x.scc,
        ap42Section: x.ap42Section,
        refDesc: x.refDesc,
        measure: x.measure,
        formula: x.formula,
        tooltip: x.tooltip,
        unit: x.unit,
        factor: x.factor,
        calcType: x.calcType,
        controlId: x.controlId
      })));
    } catch (error) {
      console.error('Failed to fetch matching EMS factors:', error);
    }
  };

  useEffect(() => {
    if (formGroup.level1) {
      fetchDependentData(2, formGroup.level1);
    }
  }, [formGroup.level1]);

  useEffect(() => {
    if (formGroup.level2) {
      fetchDependentData(3, formGroup.level2);
    }
  }, [formGroup.level2]);

  useEffect(() => {
    if (formGroup.level3) {
      fetchDependentData(4, formGroup.level3);
    }
  }, [formGroup.level3]);

  useEffect(() => {
    if (formGroup.level4) {
      const { level1, level2, level3 } = formGroup;
      fetchMatchingEmsFactorsWithJoin(level1, level2, level3, formGroup.level4);
    }
  }, [formGroup.level4]);

  useEffect(() => {
    if (formGroup.compType) {
      fetchCompositionDetails(formGroup.compType);
    }
  }, [formGroup.compType]);

  useEffect(() => {
    if (formGroup.neiPollutantCode) {
      const matchedDetail = compositionDetails.find(detail => detail.eqptCode === formGroup.neiPollutantCode);
      if (matchedDetail) {
        setFormGroup(prevState => ({
          ...prevState,
          componentId: matchedDetail.componentId
        }));
      } else {
        setFormGroup(prevState => ({
          ...prevState,
          componentId: ''
        }));
      }
    }
  }, [formGroup.neiPollutantCode, compositionDetails]);

  const validate = () => {
    const newErrors = {};
    if (!formGroup.deviceId || formGroup.deviceId.length > 20) {
      newErrors.deviceId = 'Source ID is required and has a maximum length of 20 characters.';
    }
    if (!formGroup.materialState) {
      newErrors.materialState = 'Material State is required.';
    }
    if (!formGroup.emsMethod) {
      newErrors.emsMethod = 'EMS Method is required.';
    }
    if (!formGroup.level1) {
      newErrors.level1 = 'Level 1 is required.';
    }
    if (!formGroup.level2) {
      newErrors.level2 = 'Level 2 is required.';
    }
    if (!formGroup.level3) {
      newErrors.level3 = 'Level 3 is required.';
    }
    if (!formGroup.level4) {
      newErrors.level4 = 'Level 4 is required.';
    }
    if (!formGroup.compType) {
      newErrors.compType = 'Composition Type is required.';
    }
    if (!formGroup.neiPollutantCode) {
      newErrors.neiPollutantCode = 'NEI Pollutant Code is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    if (!selectedEmsFactor) {
      setShowEmsFactorError(true);
      return;
    }

    const apiEndpoint = isEditMode ? `${API_BASE_URL}/ems-master/ems-device/update` : `${API_BASE_URL}/ems-master/ems-device/save`;
    const method = isEditMode ? 'PUT' : 'POST';

    try {
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formGroup)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`EMS Source ${isEditMode ? 'updated' : 'added'} successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate('/admin/configurations/ems-device-master/list-ems-device');
      }, 1000);
    } catch (error) {
      console.error(`Failed to ${isEditMode ? 'update' : 'add'} EMS Source:`, error);
      toast.error(`Failed to ${isEditMode ? 'update' : 'add'} EMS Source.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleFactorIdChange = (factorId) => {
    const selectedFactor = emsFactorList.find(x => x.factorId === factorId);
    if (selectedFactor) {
      setSelectedEmsFactor(selectedFactor);
      const {
        scc,
        ap42Section,
        refDesc,
        measure,
        formula,
        tooltip,
        unit,
        factor,
        calcType
      } = selectedFactor;

      setFormGroup(prevState => ({
        ...prevState,
        ssc: scc,
        ap42Section,
        refDesc,
        measure,
        formula,
        tooltip,
        unit,
        factorName: factor,
        calcType
      }));
    }
  };

  const getControlDescription = (controlId) => {
    return controlId && ipControlList.find(x => x.controlId.toString() === controlId)?.control;
  };

  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
        <h1>{isEditMode ? 'Edit EMS Source' : 'Add EMS Source'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Source Master-EMS</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              <h4 className="card-title mb-3">{isEditMode ? 'Edit EMS Source' : 'Add EMS Source'}</h4>
              <hr></hr>
              <form onSubmit={handleSubmit} noValidate>
                <h4>Source Details</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Source ID</label>
                      <select
                        name="deviceId"
                        className={`form-control ${errors.deviceId ? 'is-invalid' : ''}`}
                        value={formGroup.deviceId}
                        onChange={handleChange}
                        required
                        disabled={isEditMode}
                      >
                        <option value="">Select Source</option>
                        {isEditMode
                          ? emsDeviceList.map(device => (
                            <option key={device.deviceId} value={device.deviceId}>{device.deviceId}</option>
                          ))
                          : unregisteredDevices.map(device => (
                            <option key={device.deviceId} value={device.deviceId}>{device.deviceId}</option>
                          ))}
                      </select>
                      {errors.deviceId && <div className="invalid-feedback">{errors.deviceId}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Material State</label>
                      <select
                        name="materialState"
                        className={`form-control ${errors.materialState ? 'is-invalid' : ''}`}
                        value={formGroup.materialState}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Material State</option>
                        {materialStateList.map(state => (
                          <option key={state.code} value={state.code}>{state.description}</option>
                        ))}
                      </select>
                      {errors.materialState && <div className="invalid-feedback">{errors.materialState}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>EMS Method</label>
                      <select
                        name="emsMethod"
                        className={`form-control ${errors.emsMethod ? 'is-invalid' : ''}`}
                        value={formGroup.emsMethod}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select EMS Method</option>
                        {emsMethodList.map(method => (
                          <option key={method.emsMethodId} value={method.emsMethodId}>{method.emsMethodId}</option>
                        ))}
                      </select>
                      {errors.emsMethod && <div className="invalid-feedback">{errors.emsMethod}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Notes</label>
                      <input
                        type="text"
                        name="notes"
                        className={`form-control ${errors.notes ? 'is-invalid' : ''}`}
                        value={formGroup.notes}
                        onChange={handleChange}
                      />
                      {errors.notes && <div className="invalid-feedback">{errors.notes}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Is Primary</label>
                      <select
                        name="isPrimary"
                        className={`form-control ${errors.isPrimary ? 'is-invalid' : ''}`}
                        value={formGroup.isPrimary}
                        onChange={handleChange}
                        required
                      >
                        {isPrimaryMap.map(item => (
                          <option key={item.id} value={item.id}>{item.Name}</option>
                        ))}
                      </select>
                      {errors.isPrimary && <div className="invalid-feedback">{errors.isPrimary}</div>}
                    </div>
                  </div>
                </div>
                <h4>US EPA Factors</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Level 1</label>
                      <select
                        name="level1"
                        className={`form-control ${errors.level1 ? 'is-invalid' : ''}`}
                        value={formGroup.level1}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Level 1</option>
                        {level1Items.map(level => (
                          <option key={level} value={level}>{level}</option>
                        ))}
                      </select>
                      {errors.level1 && <div className="invalid-feedback">{errors.level1}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Level 2</label>
                      <select
                        name="level2"
                        className={`form-control ${errors.level2 ? 'is-invalid' : ''}`}
                        value={formGroup.level2}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Level 2</option>
                        {level2Items.map(level => (
                          <option key={level} value={level}>{level}</option>
                        ))}
                      </select>
                      {errors.level2 && <div className="invalid-feedback">{errors.level2}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Level 3</label>
                      <select
                        name="level3"
                        className={`form-control ${errors.level3 ? 'is-invalid' : ''}`}
                        value={formGroup.level3}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Level 3</option>
                        {level3Items.map(level => (
                          <option key={level} value={level}>{level}</option>
                        ))}
                      </select>
                      {errors.level3 && <div className="invalid-feedback">{errors.level3}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Level 4</label>
                      <select
                        name="level4"
                        className={`form-control ${errors.level4 ? 'is-invalid' : ''}`}
                        value={formGroup.level4}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Level 4</option>
                        {level4Items.map(level => (
                          <option key={level} value={level}>{level}</option>
                        ))}
                      </select>
                      {errors.level4 && <div className="invalid-feedback">{errors.level4}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Composition Type</label>
                      <select
                        name="compType"
                        className={`form-control ${errors.compType ? 'is-invalid' : ''}`}
                        value={formGroup.compType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Composition Type</option>
                        {compositionTypes.map(c => (
                          <option key={c} value={c}>{c}</option>
                        ))}
                      </select>
                      {errors.compType && <div className="invalid-feedback">{errors.compType}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>NEI Pollutant Code</label>
                      <select
                        name="neiPollutantCode"
                        className={`form-control ${errors.neiPollutantCode ? 'is-invalid' : ''}`}
                        value={formGroup.neiPollutantCode}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select NEI Pollutant Code</option>
                        {compositionDetails.map(cd => (
                          <option key={cd.eqptCode} value={cd.eqptCode}>{cd.eqptCode}</option>
                        ))}
                      </select>
                      {errors.neiPollutantCode && <div className="invalid-feedback">{errors.neiPollutantCode}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Component ID</label>
                      <input
                        type="text"
                        name="componentId"
                        className={`form-control ${errors.componentId ? 'is-invalid' : ''}`}
                        value={formGroup.componentId}
                        onChange={handleChange}
                        readOnly
                        disabled
                      />
                      {errors.componentId && <div className="invalid-feedback">{errors.componentId}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>EMS Factor</label>
                      <div className="btn-group w-100">
                        <button
                          type="button"
                          className={`btn btn-secondary dropdown-toggle text-left ${showEmsFactorError ? 'is-invalid' : ''}`}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {selectedEmsFactor ? `EMS Factor: ${selectedEmsFactor.factorId}` : 'Select EMS Factor'}
                        </button>
                        <div className="dropdown-menu w-100">
                          <div className="dropdown-row">
                            <div className="dropdown-cell">Factor</div>
                            <div className="dropdown-cell">SCC</div>
                            <div className="dropdown-cell">AP42</div>
                            <div className="dropdown-cell">Measure</div>
                            <div className="dropdown-cell">Unit</div>
                            <div className="dropdown-cell">CType</div>
                            <div className="dropdown-cell">Factor</div>
                            <div className="dropdown-cell">Formula</div>
                            <div className="dropdown-cell">Control</div>
                          </div>
                          {emsFactorList.map(factor => (
                            <div
                              key={factor.factorId}
                              className="dropdown-item"
                              onClick={() => handleFactorIdChange(factor.factorId)}
                            >
                              <div className="dropdown-row">
                                <div className="dropdown-cell">{factor.factorId}</div>
                                <div className="dropdown-cell">{factor.scc || 'NA'}</div>
                                <div className="dropdown-cell">{factor.ap42Section || 'NA'}</div>
                                <div className="dropdown-cell">{factor.measure || 'NA'}</div>
                                <div className="dropdown-cell">{factor.unit || 'NA'}</div>
                                <div className="dropdown-cell">{factor.calcType || 'NA'}</div>
                                <div className="dropdown-cell">{factor.factor || 'NA'}</div>
                                <div className="dropdown-cell">{factor.formula || 'NA'}</div>
                                <div className="dropdown-cell">{getControlDescription(factor.controlId) || 'NA'}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {showEmsFactorError && <div className="invalid-feedback">EMS Factor is required.</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>AP 42 Section</label>
                      <input
                        type="text"
                        name="ap42Section"
                        className={`form-control ${errors.ap42Section ? 'is-invalid' : ''}`}
                        value={formGroup.ap42Section}
                        onChange={handleChange}
                      />
                      {errors.ap42Section && <div className="invalid-feedback">{errors.ap42Section}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>SCC</label>
                      <input
                        type="text"
                        name="ssc"
                        className={`form-control ${errors.ssc ? 'is-invalid' : ''}`}
                        value={formGroup.ssc}
                        onChange={handleChange}
                      />
                      {errors.ssc && <div className="invalid-feedback">{errors.ssc}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Ref Description</label>
                      <input
                        type="text"
                        name="refDesc"
                        className={`form-control ${errors.refDesc ? 'is-invalid' : ''}`}
                        value={formGroup.refDesc}
                        onChange={handleChange}
                      />
                      {errors.refDesc && <div className="invalid-feedback">{errors.refDesc}</div>}
                    </div>
                  </div>
                </div>
                <h4>US EPA Factor Units</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Measure</label>
                      <input
                        type="text"
                        name="measure"
                        className={`form-control ${errors.measure ? 'is-invalid' : ''}`}
                        value={formGroup.measure}
                        onChange={handleChange}
                      />
                      {errors.measure && <div className="invalid-feedback">{errors.measure}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Unit</label>
                      <input
                        type="text"
                        name="unit"
                        className={`form-control ${errors.unit ? 'is-invalid' : ''}`}
                        value={formGroup.unit}
                        onChange={handleChange}
                      />
                      {errors.unit && <div className="invalid-feedback">{errors.unit}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Calc Type</label>
                      <input
                        type="text"
                        name="calcType"
                        className={`form-control ${errors.calcType ? 'is-invalid' : ''}`}
                        value={formGroup.calcType}
                        onChange={handleChange}
                      />
                      {errors.calcType && <div className="invalid-feedback">{errors.calcType}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Factor</label>
                      <input
                        type="text"
                        name="factorName"
                        className={`form-control ${errors.factorName ? 'is-invalid' : ''}`}
                        value={formGroup.factorName}
                        onChange={handleChange}
                      />
                      {errors.factorName && <div className="invalid-feedback">{errors.factorName}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Formula</label>
                      <input
                        type="text"
                        name="formula"
                        className={`form-control ${errors.formula ? 'is-invalid' : ''}`}
                        value={formGroup.formula}
                        onChange={handleChange}
                      />
                      {errors.formula && <div className="invalid-feedback">{errors.formula}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Tooltip</label>
                      <input
                        type="text"
                        name="tooltip"
                        className={`form-control ${errors.tooltip ? 'is-invalid' : ''}`}
                        value={formGroup.tooltip}
                        onChange={handleChange}
                      />
                      {errors.tooltip && <div className="invalid-feedback">{errors.tooltip}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={formGroup.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        {statusOptions.map(status => (
                          <option key={status} value={status}>{status}</option>
                        ))}
                      </select>
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/ems-device-master/list-ems-device')}>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddEmsDevice;



