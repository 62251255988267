import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FacilitySelector from '../../common/FacilitySelector';
import PlantSelector from '../../common/PlantSelector';
import DataCollectionModeSelector from '../../common/DataCollectionModeSelector';
import ExportComponent from '../../common/ExportComponent';
import DeviceSelector from '../../common/DeviceSelector';
import { API_BASE_URL } from '../../../config';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function EmsHealthCheck() {
    const [facilities, setFacilities] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [disableCollectionMode, setDisableCollectionMode] = useState(false);
    const [disableDevice, setDisableDevice] = useState(false);
    const [disablePlant, setDisablePlant] = useState(true);
    const [hourlyEmissionData, setHourlyEmissionData] = useState([]);
    const [transactionErrorsData, setTransactionErrorsData] = useState([]);
    const [associatedTagsData, setAssociatedTagsData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedFacilityId, setSelectedFacilityId] = useState('-1');
    const [selectedModeId, setSelectedModeId] = useState('-1');
    const [selectedDeviceId, setSelectedDeviceId] = useState('-1');
    const [selectedPlantId, setSelectedPlantId] = useState('-1');
    const [search, setSearch] = useState('');
    const [facilityData, setFacilityData] = useState([]);
    const [plantsData, setPlantsData] = useState([]);
    const [dataCollectionModes, setDataCollectionModes] = useState([]);
    const [header, setHeader] = useState([]);
    const [ghgDevice, setGhgDevice] = useState([]);
    const [emsDevice, setEmsDevice] = useState([]);
    const [deviceConfigData, setDeviceConfigData] = useState([]);
    const agGridRef = useRef(null);

    const [deviceConfigColumnDefs, setDeviceConfigColumnDefs] = useState([]);
    const [associatedTagsColumnDefs, setAssociatedTagsColumnDefs] = useState([]);
    const [hourlyEmissionColumnDefs, setHourlyEmissionColumnDefs] = useState([]);
    const [transactionErrorsColumnDefs, setTransactionErrorsColumnDefs] = useState([]);

    useEffect(() => {
        fetchFacilities();
        fetchInitialData();
        initializeColumnDefs();
    }, []);

    const fetchFacilities = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilities(data);
        } catch (error) {
            console.error('Failed to fetch facilities data:', error);
        }
    };

    const handleFacilityChange = (e) => {
        const facId = e.target.value;
        setSelectedFacilityId(facId);
        if (facId === '-1') {
            setDisablePlant(true);
            setSelectedPlantId('-1');
        } else {
            setDisablePlant(false);
        }
    };

    const initializeColumnDefs = () => {
        setDeviceConfigColumnDefs([
            { headerName: 'Column 1', field: 'col1' },
            { headerName: 'Column 2', field: 'col2' },
            { headerName: 'Column 3', field: 'col3' },
            { headerName: 'Column 4', field: 'col4' },
            { headerName: 'Column 5', field: 'col5' },
            { headerName: 'Column 6', field: 'col6' },
            { headerName: 'Column 7', field: 'col7' },
            { headerName: 'Column 8', field: 'col8' },
            { headerName: 'Column 9', field: 'col9' },
            { headerName: 'Column 10', field: 'col10' },
            { headerName: 'Column 11', field: 'col11' },
            { headerName: 'Column 12', field: 'col12' },
        ]);

        setAssociatedTagsColumnDefs([
            { headerName: 'Tag Name', field: 'tagName' },
            { headerName: 'Reading Type', field: 'readingType' },
            { headerName: 'Min', field: 'min' },
            { headerName: 'Max', field: 'max' },
            { headerName: 'Default', field: 'defaultValue' },
            { headerName: 'UoM', field: 'uom' },
            { headerName: 'LIMS Composition', field: 'limsComposition' },
            { headerName: 'LIMS Property', field: 'limsProperty' },
            { headerName: 'MW g/mol', field: 'mw_gmol' },
            { headerName: 'LHV BTU/SCF', field: 'lhv_Btuscf' },
        ]);

        setHourlyEmissionColumnDefs([
            { headerName: 'Tx Date', field: 'txDatetime', valueFormatter: params => new Date(params.value).toLocaleString() },
            { headerName: 'Pollutant', field: 'pollutant' },
            { headerName: 'UoM', field: 'uomId' },
            { headerName: 'Emission', field: 'emission' },
            { headerName: 'Avg', field: 'emissionAverage' },
            { headerName: 'Min', field: 'emissionMinimum' },
            { headerName: 'Max', field: 'emissionMaximum' },
            { headerName: 'Violation Count', field: 'violationCount' },
            { headerName: 'Reasons', field: 'reason' },
        ]);

        setTransactionErrorsColumnDefs([
            { headerName: 'Tx Date', field: 'transactionDateTime', valueFormatter: params => new Date(params.value).toLocaleString() },
            { headerName: 'Source', field: 'tagname' },
            { headerName: 'Error Messages', field: 'errorComments' },
            { headerName: 'Error Code', field: 'codeLineNo' },
        ]);
    };

    const fetchInitialData = async () => {
        try {
            const facilities = await fetch(`${API_BASE_URL}/home/facilities`).then(res => res.json());
            const plants = await fetch(`${API_BASE_URL}/home/plants`).then(res => res.json());
            const devices = await fetch(`${API_BASE_URL}/configuration/data/device/all`).then(res => res.json());
            const dataCollectionModes = await fetch(`${API_BASE_URL}/master/code-master/dataCollectionMode`).then(res => res.json());
            const headers = await fetch(`${API_BASE_URL}/home/headers`).then(res => res.json());
            const ghgDevices = await fetch(`${API_BASE_URL}/device/ghg-device/all`).then(res => res.json());
            const emsDevices = await fetch(`${API_BASE_URL}/ems-master/ems-device`).then(res => res.json());

            setFacilityData(facilities);
            setPlantsData(plants);
            setDeviceData(devices);
            setDataCollectionModes(dataCollectionModes);
            setHeader(headers);
            setGhgDevice(ghgDevices);
            setEmsDevice(emsDevices);
        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    };

    const fetchEmsHealthCheckData = async () => {
        try {
            if (selectedDeviceId !== '-1') {
                const dateStr = selectedDate.toISOString().split('T')[0];
                const [res1, res2, res3] = await Promise.all([
                    fetch(`${API_BASE_URL}/reports/dailytxfordevicewithdays?deviceId=${selectedDeviceId}&fromDate=${dateStr}`),
                    fetch(`${API_BASE_URL}/reports/transactionerrors/all?fromDate=${dateStr}&deviceId=${selectedDeviceId}&updatedBy=IPEMS`),
                    fetch(`${API_BASE_URL}/configuration/data/tagsmaster/all?deviceId=${selectedDeviceId}`)
                ]);
                const data1 = await res1.json();
                const data2 = await res2.json();
                const data3 = await res3.json();
                setHourlyEmissionData(data1);
                setTransactionErrorsData(data2);
                setAssociatedTagsData(data3);
            }
        } catch (error) {
            console.error('Error fetching EMS health check data:', error);
        }
    };

    useEffect(() => {
        if (selectedDeviceId !== '-1') {
            fetchEmsHealthCheckData();
            updateDeviceConfiguration(selectedDeviceId);
        }
    }, [selectedDeviceId, selectedDate]);

    const handleSearch = (event) => setSearch(event.target.value);
    const handleSelectedDateChange = (date) => setSelectedDate(date);

    const onFacilityIdSelected = (facilityId) => {
        setSelectedFacilityId(facilityId);
        setDisablePlant(facilityId === '-1');
        setSelectedPlantId('-1');
    };

    const onPlantSelected = (plantId) => setSelectedPlantId(plantId);
    const onModesSelected = (modeId) => {
        setSelectedModeId(modeId);
        setDisableDevice(modeId === '-1');
    };

    const onDeviceSelected = (deviceId) => {
        setSelectedDeviceId(deviceId);
        updateDeviceConfiguration(deviceId);
    };

    const updateDeviceConfiguration = (deviceId) => {
        const selectedDevice = deviceData.find(device => device.deviceId === deviceId);
        const selectedFacility = facilityData.find(facility => facility.facilityId === selectedDevice.facilityId);
        const selectedGhgDevice = ghgDevice.find(device => device.deviceId === selectedDevice.deviceId);
        const selectedEmsDevice = emsDevice.find(device => device.deviceId === selectedDevice.deviceId);
        console.log('Selected Device:', deviceData);
        if (!selectedDevice) return;

        let GHGData = [
            { col1: 'Source ID', col2: selectedDevice.deviceId, col3: 'EMS Type', col4: selectedDevice.deviceEmissionType, col5: 'Category Level1', col6: '', col7: 'GHG UoM', col8: '' },
            { col1: 'Area', col2: selectedFacility ? selectedFacility.areaId : '', col3: 'Category ID', col4: selectedDevice.categoryId, col5: 'Category Level2', col6: selectedDevice.dataCollectionMode, col7: 'GHG Pollutant', col8: '' },
            { col1: 'Facility', col2: selectedDevice.facilityId, col3: 'GHG Method ID', col4: selectedGhgDevice ? selectedGhgDevice.ghgMethodId : '', col5: 'Category Level3', col6: selectedDevice.categoryId, col7: 'GHG Key', col8: '' },
            { col1: 'Plant', col2: selectedDevice.plantId, col3: 'EF Uncertainty %', col4: selectedGhgDevice ? selectedGhgDevice.efUncertaintyPercentage : '', col5: 'Category Level4', col6: selectedDevice.defaultReasonId, col7: 'GHG Factor', col8: 0 },
            { col1: 'Material State', col2: selectedDevice.materialState, col3: 'AF Uncertainty %', col4: selectedGhgDevice ? selectedGhgDevice.afUncertaintyPercentage : '', col5: 'Measurement Type', col6: selectedDevice.kmMixing, col7: 'IPCC Year', col8: '' },
            { col1: 'Data Collection Mode', col2: selectedDevice.dataCollectionMode, col3: 'Scope', col4: selectedGhgDevice ? selectedGhgDevice.scope : '', col5: 'FUoM', col6: selectedGhgDevice ? selectedGhgDevice.fuom : '', col7: 'IPCC Value', col8: selectedGhgDevice ? selectedGhgDevice.ipccValue : '' },
        ];

        let EMSData = [
            { col1: 'Source ID', col2: selectedDevice.deviceId, col3: 'EMS Type', col4: selectedDevice.deviceEmissionType, col5: 'Unit', col6: selectedEmsDevice ? selectedEmsDevice.unit : '', col7: 'Level1', col8: selectedEmsDevice ? selectedEmsDevice.level1 : '' },
            { col1: 'Area', col2: selectedFacility ? selectedFacility.areaId : '', col3: 'Category ID', col4: selectedDevice.categoryId, col5: 'Calc Type', col6: selectedEmsDevice ? selectedEmsDevice.calcType : '', col7: 'Level2', col8: selectedEmsDevice ? selectedEmsDevice.level2 : '' },
            { col1: 'Facility', col2: selectedDevice.facilityId, col3: 'Method', col4: selectedEmsDevice ? selectedEmsDevice.method : '', col5: 'Factor', col6: selectedEmsDevice ? parseFloat(selectedEmsDevice.factor).toFixed(7) : '', col7: 'Level3', col8: selectedEmsDevice ? selectedEmsDevice.level3 : '' },
            { col1: 'Plant', col2: selectedDevice.plantId, col3: 'Factor ID', col4: selectedEmsDevice ? selectedEmsDevice.factorId : '', col5: 'Formula', col6: selectedEmsDevice ? selectedEmsDevice.formula : '', col7: 'Level4', col8: selectedEmsDevice ? selectedEmsDevice.level4 : '' },
            { col1: 'Material State', col2: selectedDevice.materialState, col3: 'NEI Pollutant', col4: selectedEmsDevice ? selectedEmsDevice.neiPollutantCode : '', col5: 'Tooltip', col6: selectedEmsDevice ? selectedEmsDevice.tooltip : '', col7: '', col8: '' },
            { col1: 'Data Collection Mode', col2: selectedDevice.dataCollectionMode, col3: 'Measure', col4: selectedEmsDevice ? selectedEmsDevice.measure : '', col5: 'Component ID', col6: selectedEmsDevice ? selectedEmsDevice.componentId : '', col7: '', col8: '' },
        ];

        if (selectedDevice.deviceEmissionType === 'GHG') {
            setDeviceConfigData(GHGData);
        } else if (selectedDevice.deviceEmissionType === 'EMS') {
            setDeviceConfigData(EMSData);
        }
    };

    const associateColDef = (state) => {
        if (state === 'GAS') {
            setAssociatedTagsData([
                // Define the columns as per GAS state
            ]);
        } else if (state === 'LIQUID') {
            setAssociatedTagsData([
                // Define the columns as per LIQUID state
            ]);
        }
    };

    return (
        <div className="main-content">
            <div className="breadcrumb mt-3">
                <h1>EMS Health Check</h1>
                <ul>
                    <li>Health Check</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row">
            <div className="col-sm-2 form-group">
                    <label>Select Facility</label>
                    <select
                        className="form-control"
                        value={selectedFacilityId}
                        onChange={handleFacilityChange}
                    >
                        <option value="-1">All Facilities</option>
                        {facilities.map(fac => (
                            <option key={fac.facilityId} value={fac.facilityId}>
                                {fac.facilityName}
                            </option>
                        ))}
                    </select>
                </div>
                <PlantSelector
                    selectedFacilityId={selectedFacilityId}
                    selectedPlantId={selectedPlantId}
                    onChange={onPlantSelected}
                    disabled={disablePlant}
                />
                <DataCollectionModeSelector
                    selectedModeId={selectedModeId}
                    onChange={onModesSelected}
                    disabled={disableCollectionMode}
                />
                <div className="col-sm-2">
                    <label>Select Date</label>
                    <div className={'datepicker-parent'}>
                        <DatePicker
                            className="form-control"
                            selected={selectedDate}
                            onChange={handleSelectedDateChange}
                            dateFormat="yyyy-MM-dd"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                </div>
                <DeviceSelector
                    selectedDeviceId={selectedDeviceId}
                    onChange={onDeviceSelected}
                    disabled={disableDevice}
                />
            </div>
            <div className="row mt-4">
                <div className="col-sm-12">
                    <div className="table-responsive">
                        <h5 className='border text-center py-2'>Source Configurations</h5>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={deviceConfigData}
                                columnDefs={deviceConfigColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true }}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="table-responsive">
                        <h5 className='border text-center py-2'>Associated Tags</h5>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={associatedTagsData}
                                columnDefs={associatedTagsColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true }}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="table-responsive">
                        <h5 className='border text-center py-2'>Hourly Pollutant Emissions</h5>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={hourlyEmissionData}
                                columnDefs={hourlyEmissionColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true }}
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="table-responsive">
                        <h5 className='border text-center py-2'>Source Alerts</h5>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            <AgGridReact
                                rowData={transactionErrorsData}
                                columnDefs={transactionErrorsColumnDefs}
                                defaultColDef={{ resizable: true, sortable: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
