import { API_BASE_URL } from '../../../../../config';


// Helper function to handle fetch responses
const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Unknown error');
  }
  return response.json();
};

export const getAllCodeMasters = async () => {
  const response = await fetch(`${API_BASE_URL}/device/codemasters`);
  if (!response.ok) {
    throw new Error('Failed to fetch code masters');
  }
  return await response.json();
};

export const getPipeFittingByFittingId = async (fittingId) => {
  const response = await fetch(`${API_BASE_URL}/pipe/pipe-fitting/${fittingId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch pipe fitting');
  }
  return await response.json();
};

export const savePipeFitting = async (isEdit, pipeFitting) => {
  const url = `${API_BASE_URL}/pipe/pipe-fitting/${isEdit ? 'update' : 'save'}`;
  const method = isEdit ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(pipeFitting),
  });

  if (!response.ok) {
    throw new Error('Failed to save pipe fitting');
  }

  return response;
};


// Filter fitting types from code masters
export const filterFittingTypesFromCodeMaster = (allCodeMasters) => {
  return allCodeMasters.filter((x) => x.type === 'FittingType');
};

// Fetch all pipe friction factors
export const getAllPipeFrictionFactors = async () => {
  const response = await fetch(`${API_BASE_URL}/pipe-friction-factor`);
  return handleResponse(response);
};

// Save a pipe friction factor
export const savePipeFrictionFactor = async (pipeFrictionFactor) => {
  const response = await fetch(`${API_BASE_URL}/pipe/pipe-friction-factor/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(pipeFrictionFactor),
  });
  return handleResponse(response);
};

// Fetch all coefficients
export const getAllCoefficients = async () => {
  const response = await fetch(`${API_BASE_URL}/coefficient`);
  return handleResponse(response);
};

// Check if a coefficient can be deleted
export const canDeleteCoefficient = async (coefficientId) => {
  const response = await fetch(`${API_BASE_URL}/coefficient/can-delete/${coefficientId}`);
  return handleResponse(response);
};

// Delete a coefficient by ID
export const deleteCoefficient = async (coefficientId) => {
  const response = await fetch(`${API_BASE_URL}/coefficient/delete/${coefficientId}`, {
    method: 'DELETE',
  });
  return handleResponse(response);
};

// Get a coefficient by ID
export const getCoefficientById = async (coefficientId) => {
  const response = await fetch(`${API_BASE_URL}/pipe/coefficient/${coefficientId}`);
  return handleResponse(response);
};

// Save or update a coefficient
export const saveCoefficient = async (coefficient, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/pipe/coefficient/update` : `${API_BASE_URL}/pipe/coefficient/save`;
  const method = isEdit ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(coefficient),
  });

  return handleResponse(response);
};

