import React from 'react'

export default function Footer() {
  return (
    <div className="app-footer text-center">
      <div className="row">
        <div className="col-md-12">
        {/* <img className="logo" src={require('../../assets/images/logo.png')} style={{ width:'20px',height:'auto' }} alt /> */}
          <p>© GreenVue Emission Monitoring System All rights reserved</p>
          <p>
          </p>
        </div>
      </div>
      {/* <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
        <span className="flex-grow-1" />
        <div className="d-flex align-items-center">
          <img className="logo" src={require('../../assets/images/logo.png')} alt />
          <div>
            <p className="m-0">© GreenVue Emission Monitoring System </p>
            <p className="m-0">All rights reserved</p>
          </div>
        </div>
      </div> */}
    </div>
  )
}
