import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UomFormulaService from '../services/UomFormulaService';
import { UomService } from '../services/UomService';
import { TailSpin } from 'react-loader-spinner';

const AddUomConversion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formGroup, setFormGroup] = useState({
    fromUom: '',
    fromUomDescription: '',
    toUom: '',
    toUomDescription: '',
    uomType: '',
    formula: '',
    status: 'A',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lstUomType, setLstUomType] = useState([]);
  const [lstUom, setLstUom] = useState([]);
  const [statusOptions] = useState(['A', 'D']);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
    loadInitialData(id);
  }, [id]);

  const loadInitialData = async (id) => {
    setLoading(true);
    try {
      const [codeMasters, uoms] = await Promise.all([
        UomFormulaService.getAllCodeMasters(),
        UomService.getAllUom(),
      ]);

      setLstUomType(UomFormulaService.filterUomTypesFromCodeMaster(codeMasters));
      setLstUom(uoms);

      if (id) {
        const uomFormula = await UomFormulaService.getUomFormulaById(id);
        setFormGroup(uomFormula);
      }
    } catch (error) {
      console.error('Failed to load initial data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.fromUom) newErrors.fromUom = 'From UOM is required.';
    if (!formGroup.toUom) newErrors.toUom = 'To UOM is required.';
    if (!formGroup.status) newErrors.status = 'Status is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await UomFormulaService.saveUomFormula(formGroup, isEdit);
      toast.success(`UOM Conversion ${isEdit ? 'updated' : 'added'} successfully.`);
      navigate('/admin/configurations/master/list-uom-conversion');
    } catch (error) {
      console.error('Failed to save UOM Conversion:', error);
      toast.error('Error saving UOM Conversion');
    }
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit UoM Conversion' : 'Add UoM Conversion'}</h1>
        <ul>
          <li>System Configuration</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      {loading ? (
        <div className="loader-container">
          <TailSpin height="50" width="50" color="#4A90E2" ariaLabel="loading" />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="fromUom">From UoM</label>
                  <select
                    className="form-control"
                    id="fromUom"
                    name="fromUom"
                    value={formGroup.fromUom}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select From UoM</option>
                    {lstUom.map((uom) => (
                      <option key={uom.uomId} value={uom.uomId}>
                        {uom.description}
                      </option>
                    ))}
                  </select>
                  {errors.fromUom && <div className="invalid-feedback d-block">{errors.fromUom}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="fromUomDescription">From UoM Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fromUomDescription"
                    name="fromUomDescription"
                    value={formGroup.fromUomDescription}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-4">
                  <label htmlFor="toUom">To UOM</label>
                  <select
                    className="form-control"
                    id="toUom"
                    name="toUom"
                    value={formGroup.toUom}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select To UoM</option>
                    {lstUom.map((uom) => (
                      <option key={uom.uomId} value={uom.uomId}>
                        {uom.description}
                      </option>
                    ))}
                  </select>
                  {errors.toUom && <div className="invalid-feedback d-block">{errors.toUom}</div>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="toUomDescription">To UoM Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="toUomDescription"
                    name="toUomDescription"
                    value={formGroup.toUomDescription}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-4">
                  <label htmlFor="formula">Formula</label>
                  <input
                    type="text"
                    className="form-control"
                    id="formula"
                    name="formula"
                    value={formGroup.formula}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-4">
                  <label htmlFor="uomType">UoM Type</label>
                  <select
                    className="form-control"
                    id="uomType"
                    name="uomType"
                    value={formGroup.uomType}
                    onChange={handleChange}
                  >
                    <option value="">Select UoM Type</option>
                    {lstUomType.map((type) => (
                      <option key={type.code} value={type.code}>
                        {type.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="status">Operational Status</label>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    value={formGroup.status}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Operational Status</option>
                    {statusOptions.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                  {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-primary float-right">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary float-right mr-3"
                onClick={() => navigate('/admin/configurations/master/list-uom-conversion')}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddUomConversion;
