import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';

const AddGhgMethod = () => {
  const navigate = useNavigate();
  // const { ghgMethodId } = useParams();
  const [searchParams] = useSearchParams();

  const [formGroup, setFormGroup] = useState({
    ghgMethodId: '',
    approaches: '',
    co2Emissions: '',
    cH4No2CombustionEmissions: '',
    efUncertinityPercent: '',
    afUncertinityPercent: '',
    status: 'A'
  });
  const [statusOptions] = useState(['A', 'D']);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (searchParams.get("ghgMethodId")) {
      setIsEdit(true);
      fetchGhgMethodData(searchParams.get("ghgMethodId"));
    }
  }, [searchParams.get("ghgMethodId")]);

  const fetchGhgMethodData = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-method/${id}`, {
        method: 'GET',
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setFormGroup(data);
    } catch (error) {
      console.error('Failed to fetch GHG method data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.ghgMethodId) {
      newErrors.ghgMethodId = 'GHG Method ID is required.';
    }
    if (formGroup.ghgMethodId.length > 50) {
      newErrors.ghgMethodId = 'GHG Method ID must be less than 50 characters.';
    }
    if (formGroup.approaches.length > 255) {
      newErrors.approaches = 'Approaches must be less than 255 characters.';
    }
    if (formGroup.co2Emissions.length > 255) {
      newErrors.co2Emissions = 'CO2 Emissions must be less than 255 characters.';
    }
    if (formGroup.cH4No2CombustionEmissions.length > 255) {
      newErrors.cH4No2CombustionEmissions = 'CH4N2O Combustion Emissions must be less than 255 characters.';
    }
    if (!formGroup.efUncertinityPercent) {
      newErrors.efUncertinityPercent = 'EF Uncertainty Percentage is required.';
    }
    if (!formGroup.afUncertinityPercent) {
      newErrors.afUncertinityPercent = 'AF Uncertainty Percentage is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const apiEndpoint = isEdit ? `${API_BASE_URL}/ghg-master/ghg-method/update` : `${API_BASE_URL}/ghg-master/ghg-method/save`;
    const method = isEdit ? 'PUT' : 'POST';

    try {
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formGroup)
      });

      // Check if the response is in JSON format
      const responseData = await response.json();

      if (response.ok && responseData.isSuccess) {
        navigate('/admin/configurations/ghg-master/list-ghg-method', {
          state: { message: `GHG Method ${isEdit ? 'updated' : 'added'} successfully!` }
        });
      } else {
        throw new Error(responseData.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error(`Failed to ${isEdit ? 'update' : 'add'} GHG method:`, error);
      toast.error(`Failed to ${isEdit ? 'update' : 'add'} GHG method.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="main-content">
       <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit GHG Method' : 'Add GHG Method'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Source Master-GHG</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">{isEdit ? 'Edit GHG Method' : 'Add GHG Method'}</h4> */}
              <hr></hr>
              <form onSubmit={handleSubmit} noValidate>
                <h4>GHG Method Details</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>GHG Method ID</label>
                      <input
                        type="text"
                        name="ghgMethodId"
                        className={`form-control ${errors.ghgMethodId ? 'is-invalid' : ''}`}
                        value={formGroup.ghgMethodId}
                        onChange={handleChange}
                        required
                        disabled={isEdit}
                      />
                      {errors.ghgMethodId && <div className="invalid-feedback">{errors.ghgMethodId}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Approaches</label>
                      <input
                        type="text"
                        name="approaches"
                        className={`form-control ${errors.approaches ? 'is-invalid' : ''}`}
                        value={formGroup.approaches}
                        onChange={handleChange}
                      />
                      {errors.approaches && <div className="invalid-feedback">{errors.approaches}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>EF Uncertainty Percentage</label>
                      <input
                        type="number"
                        name="efUncertinityPercent"
                        className={`form-control ${errors.efUncertinityPercent ? 'is-invalid' : ''}`}
                        value={formGroup.efUncertinityPercent}
                        onChange={handleChange}
                        required
                      />
                      {errors.efUncertinityPercent && <div className="invalid-feedback">{errors.efUncertinityPercent}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>AF Uncertainty Percentage</label>
                      <input
                        type="number"
                        name="afUncertinityPercent"
                        className={`form-control ${errors.afUncertinityPercent ? 'is-invalid' : ''}`}
                        value={formGroup.afUncertinityPercent}
                        onChange={handleChange}
                        required
                      />
                      {errors.afUncertinityPercent && <div className="invalid-feedback">{errors.afUncertinityPercent}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>CO2 Emissions</label>
                      <input
                        type="text"
                        name="co2Emissions"
                        className={`form-control ${errors.co2Emissions ? 'is-invalid' : ''}`}
                        value={formGroup.co2Emissions}
                        onChange={handleChange}
                      />
                      {errors.co2Emissions && <div className="invalid-feedback">{errors.co2Emissions}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>CH4N2O Combustion Emissions</label>
                      <input
                        type="text"
                        name="cH4No2CombustionEmissions"
                        className={`form-control ${errors.cH4No2CombustionEmissions ? 'is-invalid' : ''}`}
                        value={formGroup.cH4No2CombustionEmissions}
                        onChange={handleChange}
                      />
                      {errors.cH4No2CombustionEmissions && <div className="invalid-feedback">{errors.cH4No2CombustionEmissions}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={formGroup.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        {statusOptions.map(status => (
                          <option key={status} value={status}>{status}</option>
                        ))}
                      </select>
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/ghg-master/list-ghg-method')}>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddGhgMethod;
