import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API_BASE_URL } from '../../../../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddGhgDeviceType = ({ ghgDeviceTypeId, closeModal, showModal }) => {
    const [ghgDeviceType, setGhgDeviceType] = useState({
        ghgDeviceTypeId: '',
        source: '',
        shortName: '',
        status: 'A'
    });
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (ghgDeviceTypeId) {
            fetchGhgDeviceTypeData(ghgDeviceTypeId);
            setIsEdit(true);
        } else {
            resetForm();
        }
    }, [ghgDeviceTypeId]);

    const fetchGhgDeviceTypeData = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/device/ghg-device-type/ghgDeviceTypeId/${id}`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setGhgDeviceType({
                ghgDeviceTypeId: data.ghgDeviceTypeId,
                source: data.source,
                shortName: data.shortName,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch GHG device type data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGhgDeviceType(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEdit ? `${API_BASE_URL}/device/ghg-device-type/update` : `${API_BASE_URL}/device/ghg-device-type/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(ghgDeviceType)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`GHG Device Type ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            closeModal();
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} GHG device type:`, error);
        }
    };

    const resetForm = () => {
        setGhgDeviceType({
            ghgDeviceTypeId: '',
            source: '',
            shortName: '',
            status: 'A'
        });
        setIsEdit(false);
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit GHG Source Type' : 'Add GHG Source Type'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="ghgDeviceTypeId">
                        <Form.Label>GHG Source Type ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="ghgDeviceTypeId"
                            value={ghgDeviceType.ghgDeviceTypeId}
                            onChange={handleChange}
                            readOnly={isEdit}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="source">
                        <Form.Label>Source</Form.Label>
                        <Form.Control
                            type="text"
                            name="source"
                            value={ghgDeviceType.source}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="shortName">
                        <Form.Label>Short Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="shortName"
                            value={ghgDeviceType.shortName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="status"
                            value={ghgDeviceType.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="A">A</option>
                            <option value="D">D</option>
                        </Form.Control>
                    </Form.Group>
                    <div className='mt-2 float-end'>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="ml-2">
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddGhgDeviceType;
