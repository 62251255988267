
export const formatNumber = (number) => {
    return Number(number).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

// indain thousan format code this one 
// export const formatWithIndianThousandsSeparator = (value) => {
//     if (typeof value === 'string') {
//         return value;
//     }

//     if (value != null) {
//         const x = value.toString().split('.');
//         let intPart = x[0];
//         const decPart = x.length > 1 ? '.' + x[1] : '';
//         const lastThree = intPart.substring(intPart.length - 3);
//         const otherNumbers = intPart.substring(0, intPart.length - 3);
//         if (otherNumbers !== '') {
//             intPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThree;
//         } else {
//             intPart = lastThree;
//         }
//         return intPart + decPart;
//     } else {
//         return '';
//     }
// };

// this one is usa milloin format 
export const formatWithIndianThousandsSeparatorForExportData = (value) => {
    // if (typeof value === 'string') {
    //     return value;
    // }
    if (value != null) {
        const x = value.toString().split('.');
        const intPart = x[0];
        const decPart = x.length > 1 ? '.' + x[1] : '';
        // Use regular expression for US formatting (thousands separator)
        const formattedIntPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedIntPart + decPart;
    } else {
        return '';
    }
};




export const formatWithIndianThousandsSeparator = (value) => {
    // if (typeof value === 'string') {
    //     return <div style={{textAlign: 'right',width:'100%' }} >{value}</div>;
    // }
    if (value != null) {
        const x = value.toString().split('.');
        const intPart = x[0];
        const decPart = x.length > 1 ? '.' + x[1] : '';
        // Use regular expression for US formatting (thousands separator)
        const formattedIntPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return <div style={{textAlign: 'right',width:'100%' }}>{formattedIntPart + decPart}</div>;
    } else {
        return '';
    }
};

export const formatNumberDashboard = (value) => {
    if (value != null) {
        const x = value.toString().split('.');
        const intPart = x[0];
        const decPart = x.length > 1 ? '.' + x[1] : '';
        // Use regular expression for US formatting (thousands separator)
        const formattedIntPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedIntPart + decPart;
    } else {
        return '';
    }
};
