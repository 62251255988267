import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API_BASE_URL } from '../../../../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEmsMethod = ({ emsMethodId, closeModal, showModal }) => {
    const initialState = {
        emsMethodId: '',
        approaches: '',
        status: 'A'
    };
    
    const [emsMethod, setEmsMethod] = useState(initialState);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (emsMethodId) {
            fetchEmsMethodData(emsMethodId);
            setIsEdit(true);
        } else {
            setIsEdit(false);
            setEmsMethod(initialState);
        }
    }, [emsMethodId]);

    const fetchEmsMethodData = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/${id}`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setEmsMethod({
                emsMethodId: data.emsMethodId,
                approaches: data.approaches,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch EMS method data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmsMethod(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEdit ? `${API_BASE_URL}/ems-master/ems-method/update` : `${API_BASE_URL}/ems-master/ems-method/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(emsMethod)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`EMS Method ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            resetFormAndCloseModal();
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} EMS method:`, error);
        }
    };

    const resetFormAndCloseModal = () => {
        setEmsMethod(initialState);
        setIsEdit(false);
        closeModal();
    };

    return (
        <Modal show={showModal} onHide={resetFormAndCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit EMS Method' : 'Add EMS Method'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="emsMethodId">
                        <Form.Label>EMS Method ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="emsMethodId"
                            value={emsMethod.emsMethodId}
                            onChange={handleChange}
                            readOnly={isEdit}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="approaches">
                        <Form.Label>Approaches</Form.Label>
                        <Form.Control
                            type="text"
                            name="approaches"
                            value={emsMethod.approaches}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="status"
                            value={emsMethod.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="A">Active</option>
                            <option value="D">Disabled</option>
                        </Form.Control>
                    </Form.Group >
                    <div className='mt-2 float-right'>
                    <Button variant="secondary" onClick={resetFormAndCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="ml-2">
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    </div>
                    
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddEmsMethod;
