import { API_BASE_URL } from '../../../../../config'
import CodeMasterType from '../../../../constants/CodeMasterType';
const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Unknown error');
  }
  return response.json();
};

export const getAllFacilities = async () => {
  const response = await fetch(`${API_BASE_URL}/home/facilities`);
  return handleResponse(response);
};

export const getAllPlants = async () => {
  const response = await fetch(`${API_BASE_URL}/plant/plants`);
  return handleResponse(response);
};

export const getAllUOMs = async () => {
  const response = await fetch(`${API_BASE_URL}/device/uoms`);
  return handleResponse(response);
};

export const getAllCodeMasters = async () => {
  const response = await fetch(`${API_BASE_URL}/device/codemasters`);
  return handleResponse(response);
};

export const getDistinctCompositionTypes = async () => {
  const response = await fetch(`${API_BASE_URL}/composition/distinct/comp-types`);
  return handleResponse(response);
};

export const getCompositionDetailForCompositionType = async (compType) => {
  const response = await fetch(`${API_BASE_URL}/composition/comp-details/${compType}`);
  return handleResponse(response);
};

export const filterPlanTypeFromAllCodeMasters = (codeMasters) => {
  return codeMasters.filter(x => x.type === CodeMasterType.PlanType);
};

export const filterMaterialStateFromAllCodeMasters = (codeMasters) => {
  return codeMasters.filter(x => x.type === CodeMasterType.MaterialState);
};
