// src/contexts/DateContext.js

import React, { createContext, useState } from 'react';

export const DateContext = createContext();

export const DateProvider = ({ children }) => {
  const currentYear = new Date().getFullYear();
  const [selectedDate, setSelectedDate] = useState(new Date(currentYear, 0, 1)); // January is month 0

  return (
    <DateContext.Provider value={{ selectedDate, setSelectedDate }}>
      {children}
    </DateContext.Provider>
  );
};
