// GhgDeviceService.js

import { API_BASE_URL } from '../../../../../config';

const getRequest = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Network response was not ok');
    return await response.json();
  } catch (error) {
    console.error(`Failed to fetch data from ${url}:`, error);
    throw error;
  }
};

const postRequest = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
    if (!response.ok) throw new Error('Network response was not ok');
    return await response.json();
  } catch (error) {
    console.error(`Failed to post data to ${url}:`, error);
    throw error;
  }
};

const putRequest = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
    if (!response.ok) throw new Error('Network response was not ok');
    return await response.json();
  } catch (error) {
    console.error(`Failed to put data to ${url}:`, error);
    throw error;
  }
};

export const fetchGhgDeviceData = (id) => getRequest(`${API_BASE_URL}/device/ghg-device/ghgDeviceId/${id}`);

export const fetchUnregisteredDevices = () => getRequest(`${API_BASE_URL}/ghg-master/ghg-device/unregistered`);

export const fetchCodeMasters = () => getRequest(`${API_BASE_URL}/device/codemasters`);

export const fetchGhgMethods = () => getRequest(`${API_BASE_URL}/ghg-master/ghg-method`);

export const fetchGhgDeviceTypes = () => getRequest(`${API_BASE_URL}/device/ghg-device-type/all`);

export const fetchGwpPotentials = () => getRequest(`${API_BASE_URL}/ghg-master/gwp-potential`);

export const fetchSourceLevel1 = () => getRequest(`${API_BASE_URL}/ghg-master/ghg-source/layer1`);

export const fetchSourceLevel2 = () => getRequest(`${API_BASE_URL}/ghg-master/ghg-source/layer2`);

export const fetchSourceLevel3 = () => getRequest(`${API_BASE_URL}/ghg-master/ghg-source/layer3`);

export const fetchSourceLevel4 = () => getRequest(`${API_BASE_URL}/ghg-master/ghg-source/layer4`);

export const fetchCompositionTypes = () => getRequest(`${API_BASE_URL}/composition/distinct/comp-types`);

export const fetchScope3Categories = () => getRequest(`${API_BASE_URL}/ghg-master/ghg-scope3categories`);

export const fetchFinalGhgFactor = (params) => getRequest(`${API_BASE_URL}/ghg-master/ghg-factor/final-ghg`, { params });

export const saveGhgDevice = (ghgDevice) => postRequest(`${API_BASE_URL}/device/ghg-device/save`, ghgDevice);

export const updateGhgDevice = (ghgDevice) => putRequest(`${API_BASE_URL}/device/ghg-device/update`, ghgDevice);
