import { API_BASE_URL } from '../../../../../config';

export const CodeMasterService = {
  getAllCodeMaster: async () => {
    const response = await fetch(`${API_BASE_URL}/master/code-master/`);
    return response.json();
  },

  saveCodeMaster: async (codeMaster) => {
    const response = await fetch(`${API_BASE_URL}/master/code-master/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(codeMaster),
    });
    return response.json();
  },
};
