import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import ReadingDataTypeEnum from '../../../../constants/ReadingDataTypeEnum';
import CodeMasterType from '../../../../constants/CodeMasterType';

const AddDeviceType = () => {
  const { deviceTypeId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    deviceTypeId: '',
    deviceClass: '',
    description: '',
    dataCollectionMode: '',
    readingType: '',
    readingDataType: '',
    status: 'A'
  });
  const [deviceClasses, setDeviceClasses] = useState([]);
  const [readingTypes, setReadingTypes] = useState([]);
  const [dataCollectionModes, setDataCollectionModes] = useState([]);
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(!!deviceTypeId);
  const statusOptions = ['A', 'D'];
  const lstReadingDataType = ReadingDataTypeEnum;

  useEffect(() => {
    fetchInitialData();
    if (isEdit) {
      fetchDeviceTypeData(deviceTypeId);
    }
  }, [deviceTypeId]);

  const fetchInitialData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const codemasters = await response.json();
      setDeviceClasses(codemasters.filter(item => item.type === CodeMasterType.DeviceClass));
      setDataCollectionModes(codemasters.filter(item => item.type === CodeMasterType.DataCollectionMode));
      setReadingTypes(codemasters.filter(item => item.type === CodeMasterType.ReadingType));
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
    }
  };

  const fetchDeviceTypeData = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/device/type/${id}`, { credentials: 'include' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFormData({
        deviceTypeId: data.deviceTypeId,
        deviceClass: data.deviceClass,
        description: data.description,
        dataCollectionMode: data.dataCollectionMode,
        readingType: data.readingType,
        readingDataType: data.readingDataType,
        status: data.status
      });
    } catch (error) {
      console.error('Failed to fetch Source Type data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.deviceTypeId.trim()) {
      newErrors.deviceTypeId = 'Source Type Id is required and has a maximum length of 10 characters.';
    }

    if (!formData.deviceClass.trim()) {
      newErrors.deviceClass = 'Source Class is required.';
    }

    if (!formData.description.trim()) {
      newErrors.description = 'Description is required.';
    }

    if (!formData.dataCollectionMode.trim()) {
      newErrors.dataCollectionMode = 'Data Collection Mode is required.';
    }

    if (!formData.readingType.trim()) {
      newErrors.readingType = 'Reading Type is required.';
    }

    if (!formData.readingDataType.trim()) {
      newErrors.readingDataType = 'Read Data Type is required.';
    }

    if (!formData.status.trim()) {
      newErrors.status = 'Status is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const apiEndpoint = isEdit ? `${API_BASE_URL}/device/device-type/update` : `${API_BASE_URL}/device/device-type/save`;
    const method = isEdit ? 'PUT' : 'POST';

    try {
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      toast.success(`Source Type ${isEdit ? 'updated' : 'added'} successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate('/admin/configurations/device/list-device-type');
      }, 1000);
    } catch (error) {
      console.error(`Failed to ${isEdit ? 'update' : 'add'} Source Type:`, error);
      toast.error(`Failed to ${isEdit ? 'update' : 'add'} Source Type. ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleCancel = () => {
    navigate('/admin/configurations/device/list-device-type');
  };

  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit Source Type' : 'Add Source Type'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-General</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">{isEdit ? 'Edit Source TYPE' : 'ADD Source TYPE'}</h4> */}
              <hr />
              <form onSubmit={handleSubmit}>
                <h4>Source Type Info</h4>
                <div className='row mt-1'>
                <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="deviceTypeId">Source Type ID</label>
                      <input
                        type="text"
                        name="deviceTypeId"
                        className={`form-control ${errors.deviceTypeId ? 'is-invalid' : ''}`}
                        value={formData.deviceTypeId}
                        onChange={handleChange}
                        maxLength="10"
                        required
                        disabled={isEdit}
                      />
                      {errors.deviceTypeId && <div className="invalid-feedback">{errors.deviceTypeId}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <input
                        type="text"
                        name="description"
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                        value={formData.description}
                        onChange={handleChange}
                        maxLength="50"
                        required
                      />
                      {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="deviceClass">Source Class</label>
                      <select
                        name="deviceClass"
                        className={`form-control ${errors.deviceClass ? 'is-invalid' : ''}`}
                        value={formData.deviceClass}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Source Class</option>
                        {deviceClasses.map(deviceClass => (
                          <option key={deviceClass.code} value={deviceClass.code}>
                            {deviceClass.description}
                          </option>
                        ))}
                      </select>
                      {errors.deviceClass && <div className="invalid-feedback">{errors.deviceClass}</div>}
                    </div>
                  </div>
                </div>
               
                <div className='row mt-2'>
                <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="dataCollectionMode">Data Collection Mode</label>
                      <select
                        name="dataCollectionMode"
                        className={`form-control ${errors.dataCollectionMode ? 'is-invalid' : ''}`}
                        value={formData.dataCollectionMode}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Data Collection Mode</option>
                        {dataCollectionModes.map(dcm => (
                          <option key={dcm.code} value={dcm.code}>
                            {dcm.description}
                          </option>
                        ))}
                      </select>
                      {errors.dataCollectionMode && <div className="invalid-feedback">{errors.dataCollectionMode}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="readingDataType">Read Data Type</label>
                      <select
                        name="readingDataType"
                        className={`form-control ${errors.readingDataType ? 'is-invalid' : ''}`}
                        value={formData.readingDataType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Read Data Type</option>
                        {lstReadingDataType.map(rdt => (
                          <option key={rdt} value={rdt.code}>
                            {rdt.description}
                          </option>
                        ))}
                      </select>
                      {errors.readingDataType && <div className="invalid-feedback">{errors.readingDataType}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="readingType">Reading Type</label>
                      <select
                        name="readingType"
                        className={`form-control ${errors.readingType ? 'is-invalid' : ''}`}
                        value={formData.readingType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Reading Type</option>
                        {readingTypes.map(rt => (
                          <option key={rt.code} value={rt.code}>
                            {rt.description}
                          </option>
                        ))}
                      </select>
                      {errors.readingType && <div className="invalid-feedback">{errors.readingType}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={formData.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        {statusOptions.map(status => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button type="button" className="btn btn-secondary mr-3" onClick={handleCancel}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {isEdit ? 'Update' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddDeviceType;
