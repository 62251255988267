import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API_BASE_URL } from '../../../../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEmsDeviceType = ({ sourceId, closeModal, showModal }) => {
    const [emsDeviceType, setEmsDeviceType] = useState({
        sourceId: '',
        source: '',
        status: 'A'
    });
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (sourceId) {
            fetchEmsDeviceTypeData(sourceId);
            setIsEdit(true);
        }
    }, [sourceId]);

    const fetchEmsDeviceTypeData = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/ems-device-type/${id}`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setEmsDeviceType({
                sourceId: data.sourceId,
                source: data.source,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch EMS device type data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmsDeviceType(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEdit ? `${API_BASE_URL}/ems-master/ems-device-type/update` : `${API_BASE_URL}/ems-master/ems-device-type/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(emsDeviceType)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`EMS Device Type ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            closeModal();
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} EMS device type:`, error);
        }
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit EMS Device Type' : 'Add EMS Device Type'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="sourceId">
                        <Form.Label>Source ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="sourceId"
                            value={emsDeviceType.sourceId}
                            onChange={handleChange}
                            readOnly={isEdit}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="source">
                        <Form.Label>Source</Form.Label>
                        <Form.Control
                            type="text"
                            name="source"
                            value={emsDeviceType.source}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="status"
                            value={emsDeviceType.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="A">A</option>
                            <option value="D">D</option>
                        </Form.Control>
                    </Form.Group>
                   <div className='mt-2 float-right'>
                   <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="ml-2">
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                   </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddEmsDeviceType;
