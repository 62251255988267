import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
    getFacilities,
    getCategories,
    getTypes,
    getCompTypes,
    getPollutants,
    getUoms,
    getActiveProjects,
    getProjectTransactionById,
    saveProjectTransaction
} from '../services/projectTransactionService';

const AddProjectTransaction = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [formGroup, setFormGroup] = useState({
        projectID: '',
        projectName: '',
        facilityID: '',
        projectCategoryID: '',
        projectTypeID: '',
        pollutant: '',
        neiFlag: '',
        txDateTime: new Date(),
        reductionAchived: '',
        projectPercentCompleted: '',
        projectDetails: '',
        reductionExpected: '',
        projectUnits: ''
    });
    const [facilityData, setFacilityData] = useState([]);
    const [pollutantData, setPollutantData] = useState([]);
    const [compTypeData, setCompTypeData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [projectType, setProjectType] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [uomData, setUomData] = useState([]);
    const [errors, setErrors] = useState({});
    const [bsConfig] = useState({
        containerClass: 'theme-default',
        dateInputFormat: 'YYYY-MM-DD',
    });

    useEffect(() => {
        if (id) {
            setIsEdit(true);
            fetchProjectTransaction(id);
        }
        fetchInitialData();
    }, [id]);

    const fetchInitialData = async () => {
        try {
            const [
                facilities,
                categories,
                types,
                compTypes,
                pollutants,
                uoms,
                projects
            ] = await Promise.all([
                getFacilities(),
                getCategories(),
                getTypes(),
                getCompTypes(),
                getPollutants(),
                getUoms(),
                getActiveProjects()
            ]);

            setFacilityData(facilities || []);
            setCategoryData(categories || []);
            setProjectType(types || []);
            setCompTypeData(compTypes || []);
            setPollutantData(pollutants || []);
            setUomData(uoms || []);
            setActiveProjects(projects || []);
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    };

    const fetchProjectTransaction = async (id) => {
        try {
            const data = await getProjectTransactionById(id);
            if (data) {
                setFormGroup({
                    id: data.id,
                    projectID: data.projectID || '',
                    projectName: data.projectName || '',
                    facilityID: data.facilityID || '',
                    projectCategoryID: data.projectCategoryID || '',
                    projectTypeID: data.projectTypeID || '',
                    pollutant: data.pollutant || '',
                    neiFlag: data.neiFlag || '',
                    txDateTime: data.txDateTime ? new Date(data.txDateTime) : new Date(),
                    reductionAchived: data.reductionAchived ? parseFloat(data.reductionAchived).toFixed(2) : '',
                    projectUnits: data.projectUnits || '',
                    projectPercentCompleted: data.projectPercentCompleted || '',
                    projectDetails: data.projectDetails || '',
                    reductionExpected: data.reductionExpected || ''
                });
            }
        } catch (error) {
            console.error('Failed to fetch project transaction:', error);
        }
    };

    const validate = () => {
        const newErrors = {};
        if (!formGroup.projectID) {
            newErrors.projectID = 'Project ID is required.';
        }
        if (!formGroup.facilityID) {
            newErrors.facilityID = 'Facility is required.';
        }
        if (!formGroup.projectCategoryID) {
            newErrors.projectCategoryID = 'Project Category is required.';
        }
        if (!formGroup.projectTypeID) {
            newErrors.projectTypeID = 'Project Type is required.';
        }
        if (!formGroup.pollutant) {
            newErrors.pollutant = 'Pollutant is required.';
        }
        if (!formGroup.neiFlag) {
            newErrors.neiFlag = 'Govt Funded is required.';
        }
        if (!formGroup.txDateTime) {
            newErrors.txDateTime = 'Project Date is required.';
        }
        if (!formGroup.reductionAchived) {
            newErrors.reductionAchived = 'Reduction Achieved is required.';
        }
        if (!formGroup.projectPercentCompleted) {
            newErrors.projectPercentCompleted = 'Project Completed % is required.';
        }
        if (!formGroup.projectDetails) {
            newErrors.projectDetails = 'Project Details are required.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormGroup((prevState) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: null
        }));
    };

    const handleDateChange = (date) => {
        setFormGroup((prevState) => ({
            ...prevState,
            txDateTime: date
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            txDateTime: null
        }));
    };

    const getProjectData = () => {
        const projectId = formGroup.projectID;
        const selectedProject = activeProjects.find(project => project.projectID === projectId);
        if (selectedProject) {
            setFormGroup((prevState) => ({
                ...prevState,
                projectName: selectedProject.projectName || '',
                facilityID: selectedProject.facilityID || '',
                projectCategoryID: selectedProject.projectCategoryID || '',
                projectTypeID: selectedProject.projectTypeID || '',
                pollutant: selectedProject.pollutant || '',
                neiFlag: selectedProject.projectGovtFundedYN || '',
                projectUnits: selectedProject.units || '',
                reductionExpected: selectedProject.reductionExpected || ''
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }

        console.log("Submitting form data:", formGroup); // Add this log

        try {
            const response = await saveProjectTransaction(formGroup, isEdit);
            console.log("Response:", response); // Add this log

            if (response.isSuccess) {
                toast.success(`Project Transaction ${isEdit ? 'updated' : 'added'} successfully!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    navigate('/admin/project/list-project-transaction');
                }, 1000);
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} project transaction:`, error);
            toast.error(`Failed to ${isEdit ? 'update' : 'add'} project transaction.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className="main-content">
             <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit Project Transaction' : 'Add Project Transaction'}</h1>
                <ul>
                    <li>Projects</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">{isEdit ? 'Edit Project Transaction' : 'Add Project Transaction'}</h4> */}
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label>Project ID</label>
                                            <select
                                                name="projectID"
                                                className={`form-control ${errors.projectID ? 'is-invalid' : ''}`}
                                                value={formGroup.projectID}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    getProjectData();
                                                }}
                                                disabled={isEdit}
                                            >
                                                <option value="">Select Project ID</option>
                                                {activeProjects.map((project) => (
                                                    <option key={project.projectID} value={project.projectID}>
                                                        {project.projectID}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.projectID && <div className="invalid-feedback">{errors.projectID}</div>}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Project Name</label>
                                            <input
                                                type="text"
                                                name="projectName"
                                                className="form-control"
                                                value={formGroup.projectName}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label>Facility</label>
                                            <select
                                                name="facilityID"
                                                className={`form-control ${errors.facilityID ? 'is-invalid' : ''}`}
                                                value={formGroup.facilityID}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <option value="">Select Facility</option>
                                                {facilityData.map((facility) => (
                                                    <option key={facility.facilityId} value={facility.facilityId}>
                                                        {facility.facilityName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.facilityID && <div className="invalid-feedback">{errors.facilityID}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Project Category</label>
                                            <select
                                                name="projectCategoryID"
                                                className={`form-control ${errors.projectCategoryID ? 'is-invalid' : ''}`}
                                                value={formGroup.projectCategoryID}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <option value="">Select Project Category</option>
                                                {categoryData.map((category) => (
                                                    <option key={category.projectCategoryId} value={category.projectCategoryId}>
                                                        {category.projectCategory}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.projectCategoryID && <div className="invalid-feedback">{errors.projectCategoryID}</div>}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Project Type</label>
                                            <select
                                                name="projectTypeID"
                                                className={`form-control ${errors.projectTypeID ? 'is-invalid' : ''}`}
                                                value={formGroup.projectTypeID}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <option value="">Select Project Type</option>
                                                {projectType.map((type) => (
                                                    <option key={type.projectTypeID} value={type.projectTypeID}>
                                                        {type.projectType}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.projectTypeID && <div className="invalid-feedback">{errors.projectTypeID}</div>}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Pollutant</label>
                                            <select
                                                name="pollutant"
                                                className={`form-control ${errors.pollutant ? 'is-invalid' : ''}`}
                                                value={formGroup.pollutant}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <option value="">Select Pollutant</option>
                                                {pollutantData.map((pollutant) => (
                                                    <option key={pollutant.pollutantid} value={pollutant.pollutantid}>
                                                        {pollutant.pollutantName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.pollutant && <div className="invalid-feedback">{errors.pollutant}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Govt Funded YN?</label>
                                            <select
                                                name="neiFlag"
                                                className={`form-control ${errors.neiFlag ? 'is-invalid' : ''}`}
                                                value={formGroup.neiFlag}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <option value="">Select Govt Funded</option>
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                            {errors.neiFlag && <div className="invalid-feedback">{errors.neiFlag}</div>}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Reduction Expected</label>
                                            <input
                                                type="number"
                                                name="reductionExpected"
                                                className="form-control"
                                                value={formGroup.reductionExpected}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Units</label>
                                            <input
                                                type="text"
                                                name="projectUnits"
                                                className="form-control"
                                                value={formGroup.projectUnits}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Project Date</label>
                                            <div className="input-group">
                                                <DatePicker
                                                    selected={formGroup.txDateTime}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onChange={handleDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    className={`form-control ${errors.txDateTime ? 'is-invalid' : ''}`}
                                                    disabled={isEdit}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary lnr lnr-calendar-full" onClick={(e) => e.preventDefault()} type="button"></button>
                                                </div>
                                            </div>
                                            {errors.txDateTime && <div className="invalid-feedback">{errors.txDateTime}</div>}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Reduction Achieved</label>
                                            <input
                                                type="number"
                                                name="reductionAchived"
                                                className={`form-control ${errors.reductionAchived ? 'is-invalid' : ''}`}
                                                value={formGroup.reductionAchived}
                                                onChange={handleChange}
                                            />
                                            {errors.reductionAchived && <div className="invalid-feedback">{errors.reductionAchived}</div>}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Project Completed %</label>
                                            <input
                                                type="number"
                                                name="projectPercentCompleted"
                                                className={`form-control ${errors.projectPercentCompleted ? 'is-invalid' : ''}`}
                                                value={formGroup.projectPercentCompleted}
                                                onChange={handleChange}
                                            />
                                            {errors.projectPercentCompleted && <div className="invalid-feedback">{errors.projectPercentCompleted}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Project Details</label>
                                            <input
                                                type="text"
                                                name="projectDetails"
                                                className={`form-control ${errors.projectDetails ? 'is-invalid' : ''}`}
                                                value={formGroup.projectDetails}
                                                onChange={handleChange}
                                            />
                                            {errors.projectDetails && <div className="invalid-feedback">{errors.projectDetails}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary float-right">Save</button>
                                        <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/project/list-project-transaction')}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddProjectTransaction;
