import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllEquipment, getEquipmentById, saveEquipment, deleteEquipment } from '../services/EquipmentService';
import { API_BASE_URL } from '../../../../../config';

const AddEquipment = () => {
  const [formGroup, setFormGroup] = useState({
    equipmentId: '',
    equipmentName: '',
    equipmentType: '',
    plantId: '',
    latitude: 0,
    longitude: 0,
    setPointPressure: 0,
    setPointTemp: 0,
    eqptVolume: 0,
    eqptVolumeUom: '',
    status: '',
    makeModel: '',
    capacity: 0,
    capacityUom: '',
    maxRateHeatInput: 0,
    rateHeatInputUom: '',
    lowNoxBurner: '',
    stackHeight: 0,
    stackHeightUom: '',
    stackDiameter: 0,
    stackDiameterUom: '',
    stackGasExitTemp: 0,
    stackGasExitTempUom: '',
    defaultCGR: 0,
    eqptLength: 0,
    lengthUom: '',
    insideDiameter: 0,
    outsideDiameter: 0,
    operatingMinPressure: 0,
    operatingMaxPressure: 0,
    operatingPressureUom: '',
    operatingMinTemp: 0,
    operatingMaxTemp: 0,
    operatingTempUom: '',
    vesselType: '',
    vesselTypeHead1: '',
    vesselTypeHead2: '',
    vesselWallThickness: 0,
    vesselInsideCrownRadius: 0,
    vesselRadiusAndThicknessUom: ''
  });

  const [statusOptions] = useState(['A', 'D']);
  const [ynOptions] = useState(['Y', 'N']);
  const [allVesselTypes, setAllVesselTypes] = useState([]);
  const [allHeadTypes, setAllHeadTypes] = useState([]);
  const [eqptClassifications, setEqptClassifications] = useState([]);
  const [allPlants, setAllPlants] = useState([]);
  const [uomData, setUomData] = useState([]);
  const [errors, setErrors] = useState({});
  const [disableVesselDetails, setDisableVesselDetails] = useState(true);
  const [disableCombustionDetails, setDisableCombustionDetails] = useState(true);
  const [disablePipesAndVessels, setDisablePipesAndVessels] = useState(true);
  const navigate = useNavigate();
  // const { equipmentId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = !!searchParams.get("equipmentId");

  useEffect(() => {
    fetchInitialData();
    if (isEdit) {
      fetchEquipmentData(searchParams.get("equipmentId"));
    }
  }, [searchParams.get("equipmentId"), isEdit]);

  const fetchInitialData = async () => {
    try {
      const codeMasterResponse = await fetch(`${API_BASE_URL}/master/code-master`);
      const codeMasterData = await codeMasterResponse.json();
      setAllVesselTypes(codeMasterData.filter(item => item.type === 'VesselType'));
      setAllHeadTypes(codeMasterData.filter(item => item.type === 'HeaderType'));

      const eqptClassificationResponse = await fetch(`${API_BASE_URL}/equipment/equipment-classification`);
      const eqptClassificationData = await eqptClassificationResponse.json();
      setEqptClassifications(eqptClassificationData);

      const plantResponse = await fetch(`${API_BASE_URL}/plant/plants`);
      const plantData = await plantResponse.json();
      setAllPlants(plantData);

      const uomResponse = await fetch(`${API_BASE_URL}/master/uom`);
      const uomData = await uomResponse.json();
      setUomData(uomData);

    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const fetchEquipmentData = async (id) => {
    try {
      const data = await getEquipmentById(id);
      setFormGroup(data);
    } catch (error) {
      console.error('Error fetching equipment data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.equipmentId) {
      newErrors.equipmentId = 'Equipment Id is required.';
    }
    if (!formGroup.equipmentName) {
      newErrors.equipmentName = 'Equipment Name is required.';
    }
    if (!formGroup.equipmentType) {
      newErrors.equipmentType = 'Equipment Type is required.';
    }
    if (!formGroup.plantId) {
      newErrors.plantId = 'Plant is required.';
    }
    if (!formGroup.eqptVolumeUom) {
      newErrors.eqptVolumeUom = 'Volume UOM is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await saveEquipment(formGroup, isEdit, searchParams.get('equipmentId'));

      toast.success(`Equipment ${isEdit ? 'updated' : 'added'} successfully.`);
      navigate('/admin/configurations/equipment/list-equipment-data');
    } catch (error) {
      console.error('Failed to save equipment:', error);
      toast.error('Error saving equipment');
    }
  };

  useEffect(() => {
    const equipmentClassification = eqptClassifications.find(t => t.equipmentTypeId === formGroup.equipmentType);
    switch (equipmentClassification?.type) {
      case 'COMBUSTION':
        setDisablePipesAndVessels(true);
        setDisableVesselDetails(true);
        setDisableCombustionDetails(false);
        break;
      case 'ELECEQP':
        setDisableCombustionDetails(true);
        setDisablePipesAndVessels(true);
        setDisableVesselDetails(true);
        break;
      case 'PIPELINE':
        setDisableCombustionDetails(true);
        setDisableVesselDetails(true);
        setDisablePipesAndVessels(false);
        break;
      case 'STACK':
        setDisableCombustionDetails(true);
        setDisablePipesAndVessels(true);
        setDisableVesselDetails(true);
        break;
      case 'TXPORT':
        setDisableCombustionDetails(true);
        setDisablePipesAndVessels(true);
        setDisableVesselDetails(true);
        break;
      case 'VESSEL':
        setDisableCombustionDetails(true);
        setDisablePipesAndVessels(false);
        setDisableVesselDetails(false);
        break;
      default:
        break;
    }
  }, [formGroup.equipmentType, eqptClassifications]);

  return (
    <div>
      {/* <h4>{isEdit ? 'Edit Equipment' : 'Add Equipment'}</h4> */}
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Equipment' : 'Add Equipment'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Equipment</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Equipment Details</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="equipmentId">Equipment ID</label>
                <input type="text" className="form-control" id="equipmentId" name="equipmentId" value={formGroup.equipmentId} onChange={handleChange} readOnly={isEdit} />
                {errors.equipmentId && <div className="invalid-feedback d-block">{errors.equipmentId}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="equipmentName">Equipment Name</label>
                <input type="text" className="form-control" id="equipmentName" name="equipmentName" value={formGroup.equipmentName} onChange={handleChange} />
                {errors.equipmentName && <div className="invalid-feedback d-block">{errors.equipmentName}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="equipmentType">Equipment Type</label>
                <select className="form-control" id="equipmentType" name="equipmentType" value={formGroup.equipmentType} onChange={handleChange}>
                  <option value="">Select Equipment Type</option>
                  {eqptClassifications.map(cl => (
                    <option key={cl.equipmentTypeId} value={cl.equipmentTypeId}>
                      {cl.description}
                    </option>
                  ))}
                </select>
                {errors.equipmentType && <div className="invalid-feedback d-block">{errors.equipmentType}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="plantId">Plant</label>
                <select className="form-control" id="plantId" name="plantId" value={formGroup.plantId} onChange={handleChange}>
                  <option value="">Select plant</option>
                  {allPlants.map(p => (
                    <option key={p.plantId} value={p.plantId}>
                      {p.plantName}
                    </option>
                  ))}
                </select>
                {errors.plantId && <div className="invalid-feedback d-block">{errors.plantId}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="latitude">Latitude</label>
                <input type="number" className="form-control" id="latitude" name="latitude" value={formGroup.latitude} onChange={handleChange} />
                {errors.latitude && <div className="invalid-feedback d-block">{errors.latitude}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="longitude">Longitude</label>
                <input type="number" className="form-control" id="longitude" name="longitude" value={formGroup.longitude} onChange={handleChange} />
                {errors.longitude && <div className="invalid-feedback d-block">{errors.longitude}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="setPointPressure">Set Point Pressure</label>
                <input type="number" className="form-control" id="setPointPressure" name="setPointPressure" value={formGroup.setPointPressure} onChange={handleChange} />
                {errors.setPointPressure && <div className="invalid-feedback d-block">{errors.setPointPressure}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="setPointTemp">Set Point Temperature</label>
                <input type="number" className="form-control" id="setPointTemp" name="setPointTemp" value={formGroup.setPointTemp} onChange={handleChange} />
                {errors.setPointTemp && <div className="invalid-feedback d-block">{errors.setPointTemp}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="eqptVolume">Equipment Volume</label>
                <input type="number" className="form-control" id="eqptVolume" name="eqptVolume" value={formGroup.eqptVolume} onChange={handleChange} />
                {errors.eqptVolume && <div className="invalid-feedback d-block">{errors.eqptVolume}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="eqptVolumeUom">Volume UoM</label>
                <select className="form-control" id="eqptVolumeUom" name="eqptVolumeUom" value={formGroup.eqptVolumeUom} onChange={handleChange}>
                  <option value="">Select Volume UoM</option>
                  {uomData.filter(uom => uom.uomType === 'VOLUME').map(u => (
                    <option key={u.uomId} value={u.uomId}>
                      {u.description}
                    </option>
                  ))}
                </select>
                {errors.eqptVolumeUom && <div className="invalid-feedback d-block">{errors.eqptVolumeUom}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select className="form-control" id="status" name="status" value={formGroup.status} onChange={handleChange}>
                  <option value="">Select status</option>
                  {statusOptions.map(status => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">Vendor Details</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="makeModel">Make or Model</label>
                <input type="text" className="form-control" id="makeModel" name="makeModel" value={formGroup.makeModel} onChange={handleChange} />
                {errors.makeModel && <div className="invalid-feedback d-block">{errors.makeModel}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="capacity">Capacity</label>
                <input type="number" className="form-control" id="capacity" name="capacity" value={formGroup.capacity} onChange={handleChange} />
                {errors.capacity && <div className="invalid-feedback d-block">{errors.capacity}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="capacityUom">Capacity UoM</label>
                <select className="form-control" id="capacityUom" name="capacityUom" value={formGroup.capacityUom} onChange={handleChange}>
                  <option value="">Select Capacity UoM</option>
                  {uomData.filter(uom => uom.uomType === 'VOLFLOW' || uom.uomType === 'MASSFLOW').map(u => (
                    <option key={u.uomId} value={u.uomId}>
                      {u.description}
                    </option>
                  ))}
                </select>
                {errors.capacityUom && <div className="invalid-feedback d-block">{errors.capacityUom}</div>}
              </div>
            </div>
          </div>
        </div>

        {!disableCombustionDetails && (
          <div className="card mt-3">
            <div className="card-header">Combustion Details</div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="maxRateHeatInput">Max Rate Heat Input</label>
                  <input type="number" className="form-control" id="maxRateHeatInput" name="maxRateHeatInput" value={formGroup.maxRateHeatInput} onChange={handleChange} />
                  {errors.maxRateHeatInput && <div className="invalid-feedback d-block">{errors.maxRateHeatInput}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="rateHeatInputUom">Rate heat input units</label>
                  <select className="form-control" id="rateHeatInputUom" name="rateHeatInputUom" value={formGroup.rateHeatInputUom} onChange={handleChange}>
                    <option value="">Select rate heat input units</option>
                    {uomData.filter(uom => uom.uomType === 'HEAT').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.rateHeatInputUom && <div className="invalid-feedback d-block">{errors.rateHeatInputUom}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="lowNoxBurner">Low Nox Burner (Y/N?)</label>
                  <select className="form-control" id="lowNoxBurner" name="lowNoxBurner" value={formGroup.lowNoxBurner} onChange={handleChange}>
                    <option value="">Select Y/N</option>
                    {ynOptions.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {errors.lowNoxBurner && <div className="invalid-feedback d-block">{errors.lowNoxBurner}</div>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="stackHeight">Stack Height</label>
                  <input type="number" className="form-control" id="stackHeight" name="stackHeight" value={formGroup.stackHeight} onChange={handleChange} />
                  {errors.stackHeight && <div className="invalid-feedback d-block">{errors.stackHeight}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="stackHeightUom">Stack Height UOM</label>
                  <select className="form-control" id="stackHeightUom" name="stackHeightUom" value={formGroup.stackHeightUom} onChange={handleChange}>
                    <option value="">Select Stack Height UOM</option>
                    {uomData.filter(uom => uom.uomType === 'LENGTH').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.stackHeightUom && <div className="invalid-feedback d-block">{errors.stackHeightUom}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="stackDiameter">Stack Diameter</label>
                  <input type="number" className="form-control" id="stackDiameter" name="stackDiameter" value={formGroup.stackDiameter} onChange={handleChange} />
                  {errors.stackDiameter && <div className="invalid-feedback d-block">{errors.stackDiameter}</div>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="stackDiameterUom">Stack Diameter UOM</label>
                  <select className="form-control" id="stackDiameterUom" name="stackDiameterUom" value={formGroup.stackDiameterUom} onChange={handleChange}>
                    <option value="">Select Stack Diameter UOM</option>
                    {uomData.filter(uom => uom.uomType === 'LENGTH').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.stackDiameterUom && <div className="invalid-feedback d-block">{errors.stackDiameterUom}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="stackGasExitTemp">Stack Gas Exit Temp</label>
                  <input type="number" className="form-control" id="stackGasExitTemp" name="stackGasExitTemp" value={formGroup.stackGasExitTemp} onChange={handleChange} />
                  {errors.stackGasExitTemp && <div className="invalid-feedback d-block">{errors.stackGasExitTemp}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="stackGasExitTempUom">Stack Gas Exit Temp UOM</label>
                  <select className="form-control" id="stackGasExitTempUom" name="stackGasExitTempUom" value={formGroup.stackGasExitTempUom} onChange={handleChange}>
                    <option value="">Select Stack Gas Exit Temp UOM</option>
                    {uomData.filter(uom => uom.uomType === 'TEMP').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.stackGasExitTempUom && <div className="invalid-feedback d-block">{errors.stackGasExitTempUom}</div>}
                </div>
              </div>
            </div>
          </div>
        )}

        {!disablePipesAndVessels && (
          <div className="card mt-3">
            <div className="card-header">Pipes and Vessels</div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="defaultCGR">Default CGR</label>
                  <input type="number" className="form-control" id="defaultCGR" name="defaultCGR" value={formGroup.defaultCGR} onChange={handleChange} />
                  {errors.defaultCGR && <div className="invalid-feedback d-block">{errors.defaultCGR}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="eqptLength">Length</label>
                  <input type="number" className="form-control" id="eqptLength" name="eqptLength" value={formGroup.eqptLength} onChange={handleChange} />
                  {errors.eqptLength && <div className="invalid-feedback d-block">{errors.eqptLength}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="lengthUom">Length UOM</label>
                  <select className="form-control" id="lengthUom" name="lengthUom" value={formGroup.lengthUom} onChange={handleChange}>
                    <option value="">Select Length UOM</option>
                    {uomData.filter(uom => uom.uomType === 'LENGTH').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.lengthUom && <div className="invalid-feedback d-block">{errors.lengthUom}</div>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="insideDiameter">Inside Diameter</label>
                  <input type="number" className="form-control" id="insideDiameter" name="insideDiameter" value={formGroup.insideDiameter} onChange={handleChange} />
                  {errors.insideDiameter && <div className="invalid-feedback d-block">{errors.insideDiameter}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="outsideDiameter">Outside Diameter</label>
                  <input type="number" className="form-control" id="outsideDiameter" name="outsideDiameter" value={formGroup.outsideDiameter} onChange={handleChange} />
                  {errors.outsideDiameter && <div className="invalid-feedback d-block">{errors.outsideDiameter}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="operatingMinPressure">Operating Min Pressure</label>
                  <input type="number" className="form-control" id="operatingMinPressure" name="operatingMinPressure" value={formGroup.operatingMinPressure} onChange={handleChange} />
                  {errors.operatingMinPressure && <div className="invalid-feedback d-block">{errors.operatingMinPressure}</div>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="operatingMaxPressure">Operating Max Pressure</label>
                  <input type="number" className="form-control" id="operatingMaxPressure" name="operatingMaxPressure" value={formGroup.operatingMaxPressure} onChange={handleChange} />
                  {errors.operatingMaxPressure && <div className="invalid-feedback d-block">{errors.operatingMaxPressure}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="operatingPressureUom">Operating Pressure UOM</label>
                  <select className="form-control" id="operatingPressureUom" name="operatingPressureUom" value={formGroup.operatingPressureUom} onChange={handleChange}>
                    <option value="">Select Operating Pressure UOM</option>
                    {uomData.filter(uom => uom.uomType === 'PRESSURE').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.operatingPressureUom && <div className="invalid-feedback d-block">{errors.operatingPressureUom}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="operatingMinTemp">Operating Min Temp</label>
                  <input type="number" className="form-control" id="operatingMinTemp" name="operatingMinTemp" value={formGroup.operatingMinTemp} onChange={handleChange} />
                  {errors.operatingMinTemp && <div className="invalid-feedback d-block">{errors.operatingMinTemp}</div>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="operatingMaxTemp">Operating Max Temp</label>
                  <input type="number" className="form-control" id="operatingMaxTemp" name="operatingMaxTemp" value={formGroup.operatingMaxTemp} onChange={handleChange} />
                  {errors.operatingMaxTemp && <div className="invalid-feedback d-block">{errors.operatingMaxTemp}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="operatingTempUom">Operating Temp UOM</label>
                  <select className="form-control" id="operatingTempUom" name="operatingTempUom" value={formGroup.operatingTempUom} onChange={handleChange}>
                    <option value="">Select Operating Temp UOM</option>
                    {uomData.filter(uom => uom.uomType === 'TEMP').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.operatingTempUom && <div className="invalid-feedback d-block">{errors.operatingTempUom}</div>}
                </div>
              </div>
            </div>
          </div>
        )}

        {!disableVesselDetails && (
          <div className="card mt-3">
            <div className="card-header">Vessel Details</div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="vesselType">Vessel Type</label>
                  <select className="form-control" id="vesselType" name="vesselType" value={formGroup.vesselType} onChange={handleChange}>
                    <option value="">Select Vessel Type</option>
                    {allVesselTypes.map(v => (
                      <option key={v.code} value={v.code}>
                        {v.description}
                      </option>
                    ))}
                  </select>
                  {errors.vesselType && <div className="invalid-feedback d-block">{errors.vesselType}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="vesselTypeHead1">Vessel Head Type1</label>
                  <select className="form-control" id="vesselTypeHead1" name="vesselTypeHead1" value={formGroup.vesselTypeHead1} onChange={handleChange}>
                    <option value="">Select Vessel Type Head1</option>
                    {allHeadTypes.map(v => (
                      <option key={v.code} value={v.code}>
                        {v.description}
                      </option>
                    ))}
                  </select>
                  {errors.vesselTypeHead1 && <div className="invalid-feedback d-block">{errors.vesselTypeHead1}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="vesselTypeHead2">Vessel Head Type2</label>
                  <select className="form-control" id="vesselTypeHead2" name="vesselTypeHead2" value={formGroup.vesselTypeHead2} onChange={handleChange}>
                    <option value="">Select Vessel Type Head2</option>
                    {allHeadTypes.map(v => (
                      <option key={v.code} value={v.code}>
                        {v.description}
                      </option>
                    ))}
                  </select>
                  {errors.vesselTypeHead2 && <div className="invalid-feedback d-block">{errors.vesselTypeHead2}</div>}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">
                  <label htmlFor="vesselWallThickness">Vessel Wall Thickness</label>
                  <input type="number" className="form-control" id="vesselWallThickness" name="vesselWallThickness" value={formGroup.vesselWallThickness} onChange={handleChange} />
                  {errors.vesselWallThickness && <div className="invalid-feedback d-block">{errors.vesselWallThickness}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="vesselInsideCrownRadius">Vessel Inside Crown Radius</label>
                  <input type="number" className="form-control" id="vesselInsideCrownRadius" name="vesselInsideCrownRadius" value={formGroup.vesselInsideCrownRadius} onChange={handleChange} />
                  {errors.vesselInsideCrownRadius && <div className="invalid-feedback d-block">{errors.vesselInsideCrownRadius}</div>}
                </div>
                <div className="col-sm-4">
                  <label htmlFor="vesselRadiusAndThicknessUom">Vessel Radius and Thickness UOM</label>
                  <select className="form-control" id="vesselRadiusAndThicknessUom" name="vesselRadiusAndThicknessUom" value={formGroup.vesselRadiusAndThicknessUom} onChange={handleChange}>
                    <option value="">Select Vessel Radius and Thickness UOM</option>
                    {uomData.filter(uom => uom.uomType === 'LENGTH').map(u => (
                      <option key={u.uomId} value={u.uomId}>
                        {u.description}
                      </option>
                    ))}
                  </select>
                  {errors.vesselRadiusAndThicknessUom && <div className="invalid-feedback d-block">{errors.vesselRadiusAndThicknessUom}</div>}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="form-group mt-3 float-right">
        <button type="button" className="btn btn-secondary" onClick={() => navigate('/admin/configurations/equipment/list-equipment-data')}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary ml-2">
            {isEdit ? 'Update' : 'Save'}
          </button>
         
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddEquipment;
