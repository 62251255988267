import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';

const AddReason = () => {
    // const { reasonId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [form, setForm] = useState({
        reasonId: '',
        reasonDescription: '',
        reasonCategory: '',
        reasonType: '',
        userSelectableYN: '',
        controlableYN: '',
        scenarioDescription: '',
        status: 'A'
    });
    const [lstReasonCategory, setLstReasonCategory] = useState([]);
    const [lstReasonType, setLstReasonType] = useState([]);
    const lstYN = ['Y', 'N'];
    const statusOptions = ['A', 'D'];

    useEffect(() => {
        if (searchParams.get("reasonId")) {
            setIsEdit(true);
            fetchReason(searchParams.get("reasonId"));
        }
        fetchInitialData();
    }, [searchParams.get("reasonId")]);

    const fetchInitialData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' });
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setLstReasonCategory(data.filter(item => item.type === CodeMasterType.Reasoncategory));
            setLstReasonType(data.filter(item => item.type === CodeMasterType.ReasonType));
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    };

    const fetchReason = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/reason/${id}`, { credentials: 'include' });
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setForm({
                reasonId: data.reasonId,
                reasonDescription: data.reasonDescription,
                reasonCategory: data.reasonCategory,
                reasonType: data.reasonType,
                userSelectableYN: data.userSelectableYN,
                controlableYN: data.controlableYN,
                scenarioDescription: data.scenarioDescription,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch reason:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/reason/${isEdit ? 'update' : 'save'}`, {
                method: isEdit ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(form)
            });
            if (!response.ok) throw new Error('Network response was not ok');
            toast.success(`Reason ${isEdit ? 'updated' : 'added'} successfully!`);
            navigate('/admin/configurations/ems-master/list-reason');
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} reason:`, error);
            toast.error(`Failed to ${isEdit ? 'update' : 'add'} reason`);
        }
    };

    return (
        <div className="main-content">
             <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit Reason' : 'Add Reason'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Flare Master</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">{isEdit ? 'Edit Reason' : 'Add Reason'}</h4> */}
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="reasonId">Reason ID</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="reasonId"
                                                name="reasonId"
                                                value={form.reasonId}
                                                onChange={handleChange}
                                                disabled={isEdit}
                                                required
                                            />
                                            <div className="invalid-feedback">Reason Id is required.</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="reasonDescription">Reason Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="reasonDescription"
                                                name="reasonDescription"
                                                value={form.reasonDescription}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="reasonCategory">Reason Category</label>
                                            <select
                                                className="form-control"
                                                id="reasonCategory"
                                                name="reasonCategory"
                                                value={form.reasonCategory}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Reason Category</option>
                                                {lstReasonCategory.map((res) => (
                                                    <option key={res.code} value={res.code}>
                                                        {res.description}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="reasonType">Reason Type</label>
                                            <select
                                                className="form-control"
                                                id="reasonType"
                                                name="reasonType"
                                                value={form.reasonType}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Reason Type</option>
                                                {lstReasonType.map((c) => (
                                                    <option key={c.code} value={c.code}>
                                                        {c.description}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="userSelectableYN">User Selectable Y/N</label>
                                            <select
                                                className="form-control"
                                                id="userSelectableYN"
                                                name="userSelectableYN"
                                                value={form.userSelectableYN}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select User Selectable Y/N</option>
                                                {lstYN.map((z) => (
                                                    <option key={z} value={z}>
                                                        {z}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="controlableYN">Controllable Y/N</label>
                                            <select
                                                className="form-control"
                                                id="controlableYN"
                                                name="controlableYN"
                                                value={form.controlableYN}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Controllable Y/N</option>
                                                {lstYN.map((z) => (
                                                    <option key={z} value={z}>
                                                        {z}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="scenarioDescription">Scenario Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="scenarioDescription"
                                                name="scenarioDescription"
                                                value={form.scenarioDescription}
                                                onChange={handleChange}
                                                maxLength="50"
                                            />
                                            <div className="invalid-feedback">
                                                Scenario Description cannot be more than 50 characters.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="status">Status</label>
                                            <select
                                                className="form-control"
                                                id="status"
                                                name="status"
                                                value={form.status}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select status</option>
                                                {statusOptions.map((status) => (
                                                    <option key={status} value={status}>
                                                        {status}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">Status is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary float-right">
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary float-right mr-3"
                                            onClick={() => navigate('/admin/configurations/ems-master/list-reason')}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddReason;
