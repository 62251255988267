import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DeviceService from './DeviceService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddDevice = () => {
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const { deviceId } = useParams();

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState({
    deviceEmissionTypes: ['EMS', 'GHG', 'FMS', 'AMT', 'MIX'],
    allFacilities: [],
    filteredHeaders: [],
    filteredPlants: [],
    filteredEquipments: [],
    filterDeviceTypes: [],
    deviceClassifications: [],
    calcMethodTypes: [],
    dataCollectionTypes: [],
    deviceCategories: [],
    flowTypes: [],
    deviceModes: [],
    materialStates: [],
    valveTypes: [],
    cvToFlowMethods: [],
    allReasons: [],
    ratedFlowUomList: [],
    uomList: [],
    coefficientLookup: {},
    coefficients: [],
    lengthUomList: [],
    glgDisplayFlowFlagOptions: ['Y', 'N']
  });
  const [showModal, setShowModal] = useState(false);
  const selectedDataCollectionMode = watch('dataCollectionMode') || 'AUTO';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await DeviceService.fetchDropdownData();

      setDropdownData(prevData => ({
        ...prevData,
        ...data,
        coefficientLookup: data.coefficients.reduce((p, c) => { p[c.manApiType] = c.manApiType; return p; }, {})
      }));

      if (deviceId) {
        setIsEdit(true);
        const deviceData = await DeviceService.getDeviceById(deviceId);
        Object.keys(deviceData.data).forEach(key => setValue(key, deviceData.data[key]));
        filterPlantsAndHeadersByFacility(deviceData.data.facilityId);
        filterEquipmentsByPlant(deviceData.data.plantId);
        await filterDeviceTypesByDataCollectionMode(deviceData.data.dataCollectionMode);
      }
      setLoading(false);
    };

    fetchData();
  }, [deviceId, setValue]);

  const filterPlantsAndHeadersByFacility = async (facilityId) => {
    const { plants, headers } = await DeviceService.getPlantsAndHeaders();
    setDropdownData(prevData => ({
      ...prevData,
      filteredPlants: plants.filter(x => x.facilityId === facilityId),
      filteredHeaders: headers.filter(x => x.facilityId === facilityId)
    }));
  };

  const filterEquipmentsByPlant = async (plantId) => {
    const response = await DeviceService.getAllEquipments();
    const equipments = response.data; // Access the data property
    setDropdownData(prevData => ({
      ...prevData,
      filteredEquipments: equipments.filter(x => x.plantId === plantId)
    }));
  };

  const filterDeviceTypesByDataCollectionMode = async (dataCollectionMode) => {
    const response = await DeviceService.getAllDeviceTypes();
    // console.log("data collection", response);
    const deviceTypes = response.data; // Access the data property
    setDropdownData(prevData => ({
      ...prevData,
      filterDeviceTypes: deviceTypes.filter(x => x.dataCollectionMode === dataCollectionMode)
    }));
  };

  const onSubmit = async (data) => {
    if (isEdit) {
      await DeviceService.updateDevice(deviceId, data);
    } else {
      await DeviceService.saveDevice(data);
    }
    toast.success(`Source ${isEdit ? 'Updated' : 'Saved'} successfully`);
    setTimeout(() => {
      navigate('/admin/configurations/device/list-device');
    }, 1000);
  };

  const showErrorMessage = (field) => errors[field] && <span className="text-danger">This field is required.</span>;

  const isInputInValid = (field) => errors[field] ? 'is-invalid' : '';

  const handleManSizeUOMChange = (value) => {
    const manApiType = watch('manApiType');
    const manValveClass = watch('manValveClass');
    const manValveType = watch('manValveType');
    const manPort = watch('manPort');
    const manValveSize = watch('manValveSize');

    const coefficient = dropdownData.coefficients.find(c =>
      c.manApiType === manApiType &&
      c.manValveClass === manValveClass &&
      c.manValveType === manValveType &&
      c.manPort === manPort &&
      c.manValveSize === manValveSize &&
      c.manSizeUom === value
    );

    if (coefficient) {
      setValue('manCvValue', coefficient.manCvValue);
    }
  };

  const handleManPipeInsideDiameterChange = (value) => {
    const pipeInsideDiameter = parseFloat(value);
    let frictionFactor = 0;

    if (pipeInsideDiameter < 0.26) {
      frictionFactor = 0.1;
    } else if (pipeInsideDiameter > 16.01) {
      frictionFactor = 0.012;
    } else {
      const factor = dropdownData.coefficients.find(f => pipeInsideDiameter <= f.maxPipeDiameterIn && pipeInsideDiameter >= f.minPipeDiameterIn);
      frictionFactor = factor ? factor.frictionFactor : frictionFactor;
    }

    setValue('manFrictionFactor', frictionFactor);
  };

  const tryOpenFittingsConfigurationModal = () => setShowModal(true);

  const handleDisplayFlowFlagChange = (value) => {
    setValue('glgDisplayTag', value === 'Y' ? '' : '', { shouldValidate: true });
  };

  const getCoefficientManValveClasses = () => {
    const manApiType = watch('manApiType');
    return dropdownData.coefficients.filter(c => c.manApiType === manApiType);
  };

  const getCoefficientManValveTypes = () => {
    const manValveClass = watch('manValveClass');
    return getCoefficientManValveClasses().filter(c => c.manValveClass === manValveClass);
  };

  const getCoefficientManPorts = () => {
    const manValveType = watch('manValveType');
    return getCoefficientManValveTypes().filter(c => c.manValveType === manValveType);
  };

  const getCoefficientManValveSizes = () => {
    const manPort = watch('manPort');
    return getCoefficientManPorts().filter(c => c.manPort === manPort);
  };

  const getCoefficientManValveSizeUOMs = () => {
    const manValveSize = parseFloat(watch('manValveSize'));
    return getCoefficientManValveSizes().filter(c => c.manValveSize === manValveSize);
  };

  return (
    <div>
       <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit Source' : 'Add Source'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-General</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
      {/* <h4>{isEdit ? 'Edit Source' : 'Add Source'}</h4> */}
      {loading ? (
      <div className="loader">Loading...</div> // Replace this with your actual loader component or spinner
    ) : (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-4">
          <div className="col-sm-4"><h6><b>Source Location</b></h6></div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="deviceId">Source ID</label>
              <input type="text" className={`form-control ${isInputInValid('deviceId')}`} id="deviceId" {...register('deviceId', { required: true })} readOnly={isEdit} />
              {showErrorMessage('deviceId')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="deviceName">Source Name</label>
              <input type="text" className={`form-control ${isInputInValid('deviceName')}`} id="deviceName" {...register('deviceName', { required: true })} />
              {showErrorMessage('deviceName')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="deviceEmissionType"><i className="blink"></i> Emission Type</label>
              <select className={`form-control ${isInputInValid('deviceEmissionType')}`} id="deviceEmissionType" {...register('deviceEmissionType', { required: true })} defaultValue={watch('deviceEmissionType')}>
                <option value="">Select Emission Type</option>
                {dropdownData.deviceEmissionTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              {showErrorMessage('deviceEmissionType')}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="facilityId">Facility</label>
              <select className={`form-control ${isInputInValid('facilityId')}`} id="facilityId" {...register('facilityId', { required: true })} onChange={(e) => filterPlantsAndHeadersByFacility(e.target.value)} defaultValue={watch('facilityId')}>
                <option value="">Select Facility</option>
                {dropdownData.allFacilities.map(facility => (
                  <option key={facility.facilityId} value={facility.facilityId}>{facility.facilityName}</option>
                ))}
              </select>
              {showErrorMessage('facilityId')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="headerId">Header</label>
              <select className={`form-control ${isInputInValid('headerId')}`} id="headerId" {...register('headerId')} value={watch('headerId')}>
                <option value="">Select Header</option>
                {dropdownData.filteredHeaders.map(header => (
                  <option key={header.headerId} value={header.headerId}>{header.headerName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="plantId">Plant</label>
              <select className={`form-control ${isInputInValid('plantId')}`} id="plantId" {...register('plantId', { required: true })} onChange={(e) => filterEquipmentsByPlant(e.target.value)} defaultValue={watch('plantId')}>
                <option value="">Select Plant</option>
                {dropdownData.filteredPlants.map(plant => (
                  <option key={plant.plantId} value={plant.plantId}>{plant.plantName}</option>
                ))}
              </select>
              {showErrorMessage('plantId')}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="equipmentId">Equipment</label>
              <select className={`form-control ${isInputInValid('equipmentId')}`} id="equipmentId" {...register('equipmentId')} defaultValue={watch('equipmentId')}>
                <option value="">Select Equipment</option>
                {dropdownData.filteredEquipments.map(equipment => (
                  <option key={equipment.equipmentId} value={equipment.equipmentId}>{equipment.equipmentName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="calcMethod">Calc Method</label>
              <select className={`form-control ${isInputInValid('calcMethod')}`} id="calcMethod" {...register('calcMethod', { required: true })} defaultValue={watch('calcMethod')}>
                <option value="">Select Calc Method</option>
                {dropdownData.calcMethodTypes.map(calcType => (
                  <option key={calcType.code} value={calcType.code}>{calcType.code}</option>
                ))}
              </select>
              {showErrorMessage('calcMethod')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="status">Operational Status</label>
              <select className={`form-control ${isInputInValid('status')}`} id="status" {...register('status', { required: true })} defaultValue={watch('status')}>
                <option value="">Select Operational Status</option>
                {['A', 'D'].map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
              {showErrorMessage('status')}
            </div>
          </div>
        </div>

        {/* Device Classification Section */}
        <div className="row mt-4">
          <div className="col-sm-4"><h6><b>Source Classification</b></h6></div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="dataCollectionMode">Data Collection Mode</label>
              <select
                className={`form-control ${isInputInValid('dataCollectionMode')}`}
                id="dataCollectionMode"
                {...register('dataCollectionMode', {
                  required: true,
                  onChange: (e) => filterDeviceTypesByDataCollectionMode(e.target.value)
                })}
                defaultValue={watch('dataCollectionMode')}
              >
                <option value="">Select Collection Mode</option>
                {dropdownData.dataCollectionTypes.map(dataC => (
                  <option key={dataC.code} value={dataC.code}>{dataC.description}</option>
                ))}
              </select>
              {showErrorMessage('dataCollectionMode')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="deviceTypeId">Source Type</label>
              <select className={`form-control ${isInputInValid('deviceTypeId')}`} id="deviceTypeId" {...register('deviceTypeId')} value={watch('deviceTypeId')}>
                <option value="">Select Source Type</option>
                {dropdownData.filterDeviceTypes.map(deviceType => (
                  <option key={deviceType.deviceTypeId} value={deviceType.deviceTypeId}>{deviceType.description}</option>
                ))}
              </select>
              {showErrorMessage('deviceTypeId')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="classificationId">Source Classification</label>
              <select className={`form-control ${isInputInValid('classificationId')}`} id="classificationId" {...register('classificationId', { required: true })} defaultValue={watch('classificationId')}>
                <option value="">Select Source Classification</option>
                {dropdownData.deviceClassifications.map(deviceCl => (
                  <option key={deviceCl.classificationId} value={deviceCl.classificationId}>{deviceCl.description}</option>
                ))}
              </select>
              {showErrorMessage('classificationId')}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="categoryId">Source Category</label>
              <select className={`form-control ${isInputInValid('categoryId')}`} id="categoryId" {...register('categoryId', { required: true })} defaultValue={watch('categoryId')}>
                <option value="">Select Source Category</option>
                {dropdownData.deviceCategories.map(deviceCa => (
                  <option key={deviceCa.code} value={deviceCa.code}>{deviceCa.description}</option>
                ))}
              </select>
              {showErrorMessage('categoryId')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="flowType">Flow Type</label>
              <select className={`form-control ${isInputInValid('flowType')}`} id="flowType" {...register('flowType', { required: true })} defaultValue={watch('flowType')}>
                <option value="">Select Flow Type</option>
                {dropdownData.flowTypes.map(ft => (
                  <option key={ft.code} value={ft.code}>{ft.code}</option>
                ))}
              </select>
              {showErrorMessage('flowType')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="deviceMode">Source Mode</label>
              <select className={`form-control ${isInputInValid('deviceMode')}`} id="deviceMode" {...register('deviceMode', { required: true })} defaultValue={watch('deviceMode')}>
                <option value="">Select Source Mode</option>
                {dropdownData.deviceModes.map(deviceM => (
                  <option key={deviceM.code} value={deviceM.code}>{deviceM.description}</option>
                ))}
              </select>
              {showErrorMessage('deviceMode')}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="materialState">Material State</label>
              <select className={`form-control ${isInputInValid('materialState')}`} id="materialState" {...register('materialState', { required: true })} defaultValue={watch('materialState')}>
                <option value="">Select Material State</option>
                {dropdownData.materialStates.map(ms => (
                  <option key={ms.code} value={ms.code}>{ms.description}</option>
                ))}
              </select>
              {showErrorMessage('materialState')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="defaultReasonId">Default Reason</label>
              <select className={`form-control ${isInputInValid('defaultReasonId')}`} id="defaultReasonId" {...register('defaultReasonId')} defaultValue={watch('defaultReasonId')}>
                <option value="">Select Reason</option>
                {dropdownData.allReasons.map(r => (
                  <option key={r.reasonId} value={r.reasonId}>{r.reasonDescription}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="connectedDevicesNos">No of Connected Sources</label>
              <input type="number" className={`form-control ${isInputInValid('connectedDevicesNos')}`} id="connectedDevicesNos" {...register('connectedDevicesNos', { required: true })} defaultValue={watch('connectedDevicesNos')} />
              {showErrorMessage('connectedDevicesNos')}
            </div>
          </div>
          {selectedDataCollectionMode === 'AUTO' || selectedDataCollectionMode === 'FEED' ? (
            <div className="row mt-2">
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="splitOpeningRange">Split Opening Range</label>
                  <input
                    className={`form-control ${isInputInValid('splitOpeningRange')}`}
                    id="splitOpeningRange"
                    name="splitOpeningRange"
                    placeholder="Split Opening Range"
                    type="number"
                    {...register('splitOpeningRange', { required: true, valueAsNumber: true })}
                    defaultValue={watch('splitOpeningRange')}
                  />
                  {showErrorMessage('splitOpeningRange')}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="splitClosingRange">Split Closing Range</label>
                  <input
                    type="number"
                    className={`form-control ${isInputInValid('splitClosingRange')}`}
                    id="splitClosingRange"
                    name="splitClosingRange"
                    placeholder="Split Closing Range"
                    {...register('splitClosingRange', { required: true, valueAsNumber: true })}
                    defaultValue={watch('splitClosingRange')}
                  />
                  {showErrorMessage('splitClosingRange')}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/* Process Data Section */}
        <div className="row mt-4">
          <div className="col-sm-4"><h6><b>Process Data</b></h6></div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="designInletPressurePSIA">Design In Let Pressure PSIA</label>
              <input
                type="number"
                className={`form-control ${isInputInValid('designInletPressurePSIA')}`}
                id="designInletPressurePSIA"
                name="designInletPressurePSIA"
                placeholder="Design In-let Pressure PSIA"
                {...register('designInletPressurePSIA', { required: true, valueAsNumber: true })}
                defaultValue={watch('designInletPressurePSIA')}
              />
              {showErrorMessage('designInletPressurePSIA')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="designOutletPressurePSIA">Design Out Let Pressure PSIA</label>
              <input
                type="number"
                className={`form-control ${isInputInValid('designOutletPressurePSIA')}`}
                id="designOutletPressurePSIA"
                name="designOutletPressurePSIA"
                placeholder="Design Out-let Pressure PSIA"
                {...register('designOutletPressurePSIA', { required: true, valueAsNumber: true })}
                defaultValue={watch('designOutletPressurePSIA')}
              />
              {showErrorMessage('designOutletPressurePSIA')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="designInletTempDegF">Design In Let Temp DEGF</label>
              <input
                type="number"
                className={`form-control ${isInputInValid('designInletTempDegF')}`}
                id="designInletTempDegF"
                name="designInletTempDegF"
                placeholder="Design In-let Temp DegF"
                {...register('designInletTempDegF', { required: true, valueAsNumber: true })}
                defaultValue={watch('designInletTempDegF')}
              />
              {showErrorMessage('designInletTempDegF')}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="waterPercentage">Water Percentage</label>
              <input
                type="number"
                className={`form-control ${isInputInValid('waterPercentage')}`}
                id="waterPercentage"
                name="waterPercentage"
                placeholder="Water Percentage"
                {...register('waterPercentage', { required: true, valueAsNumber: true, min: 0, max: 100 })}
                defaultValue={watch('waterPercentage')}
              />
              {showErrorMessage('waterPercentage')}
            </div>
          </div>
        </div>

        {(selectedDataCollectionMode === 'FEED' || selectedDataCollectionMode === 'MOVMANUAL' || selectedDataCollectionMode === 'AUTO') && (
          <>
            <div className="row mt-2">
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="gasSpgravity">Gas SP Gravity</label>
                  <input
                    type="number"
                    className={`form-control ${isInputInValid('gasSpgravity')}`}
                    id="gasSpgravity"
                    name="gasSpgravity"
                    placeholder="Gas SP Gravity"
                    {...register('gasSpgravity', { required: true, valueAsNumber: true })}
                    defaultValue={watch('gasSpgravity')}
                  />
                  {showErrorMessage('gasSpgravity')}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="kmMixing">KM Mixing</label>
                  <input
                    type="number"
                    className={`form-control ${isInputInValid('kmMixing')}`}
                    id="kmMixing"
                    name="kmMixing"
                    placeholder="KM Mixing"
                    {...register('kmMixing', { required: true, valueAsNumber: true })}
                    defaultValue={watch('kmMixing')}
                  />
                  {showErrorMessage('kmMixing')}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="vapourPressurePsia">Vapour Pressure PSIA</label>
                  <input
                    type="number"
                    className={`form-control ${isInputInValid('vapourPressurePsia')}`}
                    id="vapourPressurePsia"
                    name="vapourPressurePsia"
                    placeholder="Vapour Pressure PSIA"
                    {...register('vapourPressurePsia', { required: true, valueAsNumber: true })}
                    defaultValue={watch('vapourPressurePsia')}
                  />
                  {showErrorMessage('vapourPressurePsia')}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="ycConstant">YC Constant</label>
                  <input
                    type="number"
                    className={`form-control ${isInputInValid('ycConstant')}`}
                    id="ycConstant"
                    name="ycConstant"
                    placeholder="YC Constant"
                    {...register('ycConstant', { required: true, valueAsNumber: true })}
                    defaultValue={watch('ycConstant')}
                  />
                  {showErrorMessage('ycConstant')}
                </div>
              </div>
            </div>
          </>
        )}

        {/* CONTROL VALVE DETAILS Section */}
        <div className="row mt-4">
          <div className="col-sm-4"><h6><b>Control Valve Details</b></h6></div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="valveManufacturer">Valve Manufacturer</label>
              <input
                type="text"
                className="form-control"
                id="valveManufacturer"
                name="valveManufacturer"
                placeholder="Valve Manufacturer"
                {...register('valveManufacturer', { maxLength: 50 })}
                defaultValue={watch('valveManufacturer')}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="valveModel">Valve Model</label>
              <input
                type="text"
                className="form-control"
                id="valveModel"
                name="valveModel"
                placeholder="Valve Model"
                {...register('valveModel', { maxLength: 50 })}
                defaultValue={watch('valveModel')}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="valveSizeIn">Valve Size In</label>
              <input
                type="number"
                className={`form-control ${isInputInValid('valveSizeIn')}`}
                id="valveSizeIn"
                name="valveSizeIn"
                placeholder="Valve Size In"
                {...register('valveSizeIn', { required: true, valueAsNumber: true })}
                defaultValue={watch('valveSizeIn')}
              />
              {showErrorMessage('valveSizeIn')}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-4">
            <div className="form-label-group select-group mb-0">
              <label htmlFor="valveType">Valve Type</label>
              <select
                className={`form-control ${isInputInValid('valveType')}`}
                id="valveType"
                name="valveType"
                {...register('valveType', { required: true })}
                defaultValue={watch('valveType')}
              >
                <option value="" selected>Select Valve Type</option>
                {dropdownData.valveTypes.map((r) => (
                  <option key={r.code} value={r.code}>{r.description}</option>
                ))}
              </select>
              {showErrorMessage('valveType')}
            </div>
          </div>
        </div>

        {
          (selectedDataCollectionMode === 'DIGVALVE' || selectedDataCollectionMode === 'DPVMANUAL') && (
            <>
              <div className="row mt-4">
                <div className="col-sm-4"><h6><b>Auto Sources</b></h6></div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoRatedFlow">Auto Rated Flow</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoRatedFlow')}`}
                      id="autoRatedFlow"
                      name="autoRatedFlow"
                      placeholder="Auto Rated Flow"
                      {...register('autoRatedFlow', { valueAsNumber: true })}
                      defaultValue={watch('autoRatedFlow')}
                    />
                    {showErrorMessage('autoRatedFlow')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="rated4FlowUnits">Auto Rated Flow Units</label>
                    <select
                      className={`form-control ${isInputInValid('rated4FlowUnits')}`}
                      id="rated4FlowUnits"
                      name="rated4FlowUnits"
                      {...register('rated4FlowUnits', { required: true })}
                      defaultValue={watch('rated4FlowUnits')}
                    >
                      <option value="" selected>Select Auto Rated Flow Units</option>
                      {dropdownData.ratedFlowUomList.map((r) => (
                        <option key={r.uomId} value={r.uomId}>{r.description}</option>
                      ))}
                    </select>
                    {showErrorMessage('rated4FlowUnits')}
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          (selectedDataCollectionMode === 'AUTO' || selectedDataCollectionMode === 'FEED') && (
            <>
              <div className="row mt-4">
                <div className="col-sm-4"><h6><b>Auto Sources</b></h6></div>
              </div>
              <div className="row mt-2">
                {Array.from({ length: 11 }, (_, index) => (
                  <div className="col-sm-4" key={index}>
                    <div className="form-label-group mb-0">
                      <label htmlFor={`auto${index * 10}CV`}>Auto {index * 10} CV</label>
                      <input
                        type="number"
                        className={`form-control ${isInputInValid(`auto${index * 10}CV`)}`}
                        id={`auto${index * 10}CV`}
                        name={`auto${index * 10}CV`}
                        placeholder={`Auto ${index * 10} CV`}
                        {...register(`auto${index * 10}CV`, { valueAsNumber: true })}
                        defaultValue={watch(`auto${index * 10}CV`)}
                      />
                      {showErrorMessage(`auto${index * 10}CV`)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoMinFlowCV">Auto Min Flow CV</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoMinFlowCV')}`}
                      id="autoMinFlowCV"
                      name="autoMinFlowCV"
                      placeholder="Auto Min Flow CV"
                      {...register('autoMinFlowCV', { valueAsNumber: true })}
                      defaultValue={watch('autoMinFlowCV')}
                    />
                    {showErrorMessage('autoMinFlowCV')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoNormalFlowCV">Auto Normal Flow CV</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoNormalFlowCV')}`}
                      id="autoNormalFlowCV"
                      name="autoNormalFlowCV"
                      placeholder="Auto Normal Flow CV"
                      {...register('autoNormalFlowCV', { valueAsNumber: true })}
                      defaultValue={watch('autoNormalFlowCV')}
                    />
                    {showErrorMessage('autoNormalFlowCV')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoMaxFlowCV">Auto Max Flow CV</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoMaxFlowCV')}`}
                      id="autoMaxFlowCV"
                      name="autoMaxFlowCV"
                      placeholder="Auto Max Flow CV"
                      {...register('autoMaxFlowCV', { valueAsNumber: true })}
                      defaultValue={watch('autoMaxFlowCV')}
                    />
                    {showErrorMessage('autoMaxFlowCV')}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoRatedFlowCV">Auto Rated Flow CV</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoRatedFlowCV')}`}
                      id="autoRatedFlowCV"
                      name="autoRatedFlowCV"
                      placeholder="Auto Rated Flow CV"
                      {...register('autoRatedFlowCV', { valueAsNumber: true })}
                      defaultValue={watch('autoRatedFlowCV')}
                    />
                    {showErrorMessage('autoRatedFlowCV')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="cvCalculationMethod">CV Calculation Method</label>
                    <select
                      className={`form-control ${isInputInValid('cvCalculationMethod')}`}
                      id="cvCalculationMethod"
                      name="cvCalculationMethod"
                      {...register('cvCalculationMethod', { required: true })}
                      defaultValue={watch('cvCalculationMethod')}
                    >
                      <option value="" selected>Select CV Calculation Method</option>
                      {dropdownData.cvToFlowMethods.map((r) => (
                        <option key={r.code} value={r.code}>{r.description}</option>
                      ))}
                    </select>
                    {showErrorMessage('cvCalculationMethod')}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoMinFlow">Auto Min Flow</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoMinFlow')}`}
                      id="autoMinFlow"
                      name="autoMinFlow"
                      placeholder="Auto Min Flow"
                      {...register('autoMinFlow', { valueAsNumber: true })}
                      defaultValue={watch('autoMinFlow')}
                    />
                    {showErrorMessage('autoMinFlow')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoNormalFlow">Auto Normal Flow</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoNormalFlow')}`}
                      id="autoNormalFlow"
                      name="autoNormalFlow"
                      placeholder="Auto Normal Flow"
                      {...register('autoNormalFlow', { valueAsNumber: true })}
                      defaultValue={watch('autoNormalFlow')}
                    />
                    {showErrorMessage('autoNormalFlow')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="autoMaxFlow">Auto Max Flow</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('autoMaxFlow')}`}
                      id="autoMaxFlow"
                      name="autoMaxFlow"
                      placeholder="Auto Max Flow"
                      {...register('autoMaxFlow', { valueAsNumber: true })}
                      defaultValue={watch('autoMaxFlow')}
                    />
                    {showErrorMessage('autoMaxFlow')}
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          (selectedDataCollectionMode !== 'MOVMANUAL' && selectedDataCollectionMode !== 'FIXEDFLOW' && selectedDataCollectionMode !== 'DIGVALVE' && selectedDataCollectionMode !== 'DPVMANUAL') && (
            <div className="row mt-2">
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="autoRatedFlow">Auto Rated Flow</label>
                  <input
                    type="number"
                    className={`form-control ${isInputInValid('autoRatedFlow')}`}
                    id="autoRatedFlow"
                    name="autoRatedFlow"
                    placeholder="Auto Rated Flow"
                    {...register('autoRatedFlow', { valueAsNumber: true })}
                    defaultValue={watch('autoRatedFlow')}
                  />
                  {showErrorMessage('autoRatedFlow')}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group select-group mb-0">
                  <label htmlFor="rated4FlowUnits">Auto Rated Flow Units</label>
                  <select
                    className={`form-control ${isInputInValid('rated4FlowUnits')}`}
                    id="rated4FlowUnits"
                    name="rated4FlowUnits"
                    {...register('rated4FlowUnits', { required: true })}
                    defaultValue={watch('rated4FlowUnits')}
                  >
                    <option value="" selected>Select Auto Rated Flow Units</option>
                    {dropdownData.ratedFlowUomList.map((r) => (
                      <option key={r.uomId} value={r.uomId}>{r.description}</option>
                    ))}
                  </select>
                  {showErrorMessage('rated4FlowUnits')}
                </div>
              </div>
            </div>
          )
        }

        {
          (selectedDataCollectionMode === 'FIXEDFLOW' || selectedDataCollectionMode === 'FEED') && (
            <>
              <div className="row mt-4">
                <div className="col-sm-4"><h6><b>Fixed Flow Sources</b></h6></div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="constantFlowQty">Constant Flow Qty</label>
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('constantFlowQty')}`}
                      id="constantFlowQty"
                      name="constantFlowQty"
                      placeholder="Constant Flow Qty"
                      {...register('constantFlowQty', { valueAsNumber: true })}
                      defaultValue={watch('constantFlowQty')}
                    />
                    {showErrorMessage('constantFlowQty')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="constantFlowQtyUOM">Constant Flow Qty Uom</label>
                    <select
                      className={`form-control ${isInputInValid('constantFlowQtyUOM')}`}
                      id="constantFlowQtyUOM"
                      name="constantFlowQtyUOM"
                      {...register('constantFlowQtyUOM', { required: true })}
                      defaultValue={watch('constantFlowQtyUOM')}
                    >
                      <option value="" selected>Select Constant Flow Qty Uom</option>
                      {dropdownData.uomList.map((r) => (
                        <option key={r.uomId} value={r.uomId}>{r.description}</option>
                      ))}
                    </select>
                    {showErrorMessage('constantFlowQtyUOM')}
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          selectedDataCollectionMode === 'MOVMANUAL' && (
            <>
              <div className="row mt-4">
                <div className="col-sm-12"><h6><b>Manual Sources</b></h6></div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manApiType">Man API Type</label>
                    <select
                      className="form-control"
                      id="manApiType"
                      name="manApiType"
                      {...register('manApiType', { required: true })}
                      defaultValue={watch('manApiType')}
                    >
                      <option value="" selected>Select API Type</option>
                      {Object.entries(dropdownData.coefficientLookup).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                    </select>
                    {showErrorMessage('manApiType')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manValveClass">Man Valve Class</label>
                    <select
                      className="form-control"
                      id="manValveClass"
                      name="manValveClass"
                      {...register('manValveClass', { required: true })}
                      defaultValue={watch('manValveClass')}
                    >
                      <option value="" selected>Select Valve Class</option>
                      {getCoefficientManValveClasses().map((r) => (
                        <option key={r.manValveClass} value={r.manValveClass}>{r.manValveClass}</option>
                      ))}
                    </select>
                    {showErrorMessage('manValveClass')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manValveType">Man Valve Type</label>
                    <select
                      className="form-control"
                      id="manValveType"
                      name="manValveType"
                      {...register('manValveType', { required: true })}
                      defaultValue={watch('manValveType')}
                    >
                      <option value="" selected>Select Valve Type</option>
                      {getCoefficientManValveTypes().map((r) => (
                        <option key={r.manValveType} value={r.manValveType}>{r.manValveType}</option>
                      ))}
                    </select>
                    {showErrorMessage('manValveType')}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manPort">Man Port</label>
                    <select
                      className="form-control"
                      id="manPort"
                      name="manPort"
                      {...register('manPort', { required: true })}
                      defaultValue={watch('manPort')}
                    >
                      <option value="" selected>Select Man Port</option>
                      {getCoefficientManPorts().map((r) => (
                        <option key={r.manPort} value={r.manPort}>{r.manPort}</option>
                      ))}
                    </select>
                    {showErrorMessage('manPort')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manValveSize">Man Valve Size</label>
                    <select
                      className="form-control"
                      id="manValveSize"
                      name="manValveSize"
                      {...register('manValveSize', { required: true })}
                      defaultValue={watch('manValveSize')}
                    >
                      <option value="" selected>Select Valve Size</option>
                      {getCoefficientManValveSizes().map((r) => (
                        <option key={r.manValveSize} value={r.manValveSize}>{r.manValveSize}</option>
                      ))}
                    </select>
                    {showErrorMessage('manValveSize')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manSizeUOM">Man Size Uom</label>
                    <select
                      className="form-control"
                      id="manSizeUOM"
                      name="manSizeUOM"
                      {...register('manSizeUOM', { required: true })}
                      onChange={(e) => handleManSizeUOMChange(e.target.value)}
                      defaultValue={watch('manSizeUOM')}
                    >
                      <option value="" selected>Select Size Uom</option>
                      {getCoefficientManValveSizeUOMs().map((r) => (
                        <option key={r.manSizeUom} value={r.manSizeUom}>{r.manSizeUom}</option>
                      ))}
                    </select>
                    {showErrorMessage('manSizeUOM')}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      id="manCvValue"
                      name="manCvValue"
                      placeholder="Man CV Valve"
                      {...register('manCvValue', { required: true })}
                      readOnly
                      defaultValue={watch('manCvValue')}
                    />
                    <label htmlFor="manCvValue">Man CV Valve</label>
                    {showErrorMessage('manCvValue')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('manPipeInsideDiameter')}`}
                      id="manPipeInsideDiameter"
                      name="manPipeInsideDiameter"
                      placeholder="Man Pipe Inside Diameter"
                      {...register('manPipeInsideDiameter', { required: true, valueAsNumber: true })}
                      onChange={(e) => handleManPipeInsideDiameterChange(e.target.value)}
                      defaultValue={watch('manPipeInsideDiameter')}
                    />
                    <label htmlFor="manPipeInsideDiameter">Man Pipe Inside Diameter</label>
                    {showErrorMessage('manPipeInsideDiameter')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manPipeInsideDiameterUnits">Man Pipe Inside Diameter Units</label>
                    <select
                      className="form-control"
                      id="manPipeInsideDiameterUnits"
                      name="manPipeInsideDiameterUnits"
                      {...register('manPipeInsideDiameterUnits', { required: true })}
                      defaultValue={watch('manPipeInsideDiameterUnits')}
                    >
                      <option value="" selected>Select Pipe Inside Diameter Units</option>
                      {dropdownData.lengthUomList.map((u) => (
                        <option key={u.uomId} value={u.uomId}>{u.description}</option>
                      ))}
                    </select>
                    {showErrorMessage('manPipeInsideDiameterUnits')}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('manPipeLength')}`}
                      id="manPipeLength"
                      name="manPipeLength"
                      placeholder="Man Pipe Length"
                      {...register('manPipeLength', { required: true, valueAsNumber: true })}
                      defaultValue={watch('manPipeLength')}
                    />
                    <label htmlFor="manPipeLength">Man Pipe Length</label>
                    {showErrorMessage('manPipeLength')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="manPipeLengthUnits">Man Pipe Length Units</label>
                    <select
                      className="form-control"
                      id="manPipeLengthUnits"
                      name="manPipeLengthUnits"
                      {...register('manPipeLengthUnits', { required: true })}
                      defaultValue={watch('manPipeLengthUnits')}
                    >
                      <option value="" selected>Select Pipe Length Units</option>
                      {dropdownData.lengthUomList.map((u) => (
                        <option key={u.uomId} value={u.uomId}>{u.description}</option>
                      ))}
                    </select>
                    {showErrorMessage('manPipeLengthUnits')}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('manFrictionFactor')}`}
                      id="manFrictionFactor"
                      name="manFrictionFactor"
                      placeholder="Man Friction Factor"
                      {...register('manFrictionFactor', { required: true, valueAsNumber: true })}
                      defaultValue={watch('manFrictionFactor')}
                    />
                    <label htmlFor="manFrictionFactor">Man Friction Factor</label>
                    {showErrorMessage('manFrictionFactor')}
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-4">
                  <h6>
                    <b>Manual Valve Calculation</b>
                    <span role="button" className="mx-1 badge badge-primary" onClick={tryOpenFittingsConfigurationModal}>Calc K-factor</span>
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className="form-control"
                      id="finalKFactor"
                      name="finalKFactor"
                      placeholder="Final K-Factor"
                      {...register('finalKFactor', { required: true, valueAsNumber: true })}
                      readOnly
                      defaultValue={watch('finalKFactor')}
                    />
                    <label htmlFor="finalKFactor">Final K-Factor</label>
                    {showErrorMessage('finalKFactor')}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className="form-control"
                      id="pipeKFactor"
                      name="pipeKFactor"
                      placeholder="Pipe K-Factor"
                      {...register('pipeKFactor', { required: true, valueAsNumber: true })}
                      readOnly
                      defaultValue={watch('pipeKFactor')}
                    />
                    <label htmlFor="pipeKFactor">Pipe K-Factor</label>
                    {showErrorMessage('pipeKFactor')}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className="form-control"
                      id="fittingsKFactor"
                      name="fittingsKFactor"
                      placeholder="Fittings K factor"
                      {...register('fittingsKFactor', { required: true, valueAsNumber: true })}
                      defaultValue={watch('fittingsKFactor')}
                    />
                    <label htmlFor="fittingsKFactor">Fittings K-Factor</label>
                    {showErrorMessage('fittingsKFactor')}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className="form-control"
                      id="coefficientKFactor"
                      name="coefficientKFactor"
                      placeholder="Coefficient K factor"
                      {...register('coefficientKFactor', { required: true, valueAsNumber: true })}
                      readOnly
                      defaultValue={watch('coefficientKFactor')}
                    />
                    <label htmlFor="coefficientKFactor">Coefficient K-Factor</label>
                    {showErrorMessage('coefficientKFactor')}
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <input
                      type="number"
                      className={`form-control ${isInputInValid('manSystemCV')}`}
                      id="manSystemCV"
                      name="manSystemCV"
                      placeholder="Man System CV"
                      {...register('manSystemCV', { required: true, valueAsNumber: true })}
                      defaultValue={watch('manSystemCV')}
                    />
                    <label htmlFor="manSystemCV">Man System CV</label>
                    {showErrorMessage('manSystemCV')}
                  </div>
                </div>
              </div>
            </>
          )
        }

        <div className="row mt-4">
          <div className="col-sm-4"><h6><b>Real Time Display Tag</b></h6></div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-label-group select-group mb-0">
              <label htmlFor="glgDisplayFlowFlag">Display Flow Flag</label>
              <select
                className="form-control"
                id="glgDisplayFlowFlag"
                name="glgDisplayFlowFlag"
                {...register('glgDisplayFlowFlag', { required: true })}
                onChange={(e) => handleDisplayFlowFlagChange(e.target.value)}
                defaultValue={watch('glgDisplayFlowFlag')}
              >
                <option value="" selected>Select Display Flow Flag</option>
                {dropdownData.glgDisplayFlowFlagOptions.map((r) => (
                  <option key={r} value={r}>{r}</option>
                ))}
              </select>
              {showErrorMessage('glgDisplayFlowFlag')}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-label-group mb-0">
              <label htmlFor="glgDisplayTag">Display Tag Name</label>
              <input
                type="text"
                className={`form-control ${isInputInValid('glgDisplayTag')}`}
                id="glgDisplayTag"
                name="glgDisplayTag"
                placeholder="Display Tag Name"
                {...register('glgDisplayTag', { required: true })}
                defaultValue={watch('glgDisplayTag')}
              />
              {showErrorMessage('glgDisplayTag')}
            </div>
          </div>
        </div>

        
        <div className="row mb-5">
          <div className="col-sm-12 text-right">
          <button type="button" className="btn btn-secondary" onClick={() => navigate('/admin/configurations/device/list-device')}>Cancel</button>&nbsp;&nbsp;&nbsp;
            <button type="submit" className="btn btn-primary">{isEdit ? 'Update' : 'Save'}</button>
          </div>
        </div>
      </form>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddDevice;
