import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';
const API_PREFIX = `${API_BASE_URL}/fmsdashboard`;

class DataCorrectionService {
  getDeviceDailyTxForDate(fromDate) {
    return axios.get(`${API_PREFIX}/device-daily-tx-date/?fromDate=${fromDate}`);
  }

  updateFlowValidation(data) {
    return axios.post(`${API_PREFIX}/device-daily-tx-date`, data);
  }

  getAmmnetValidation(fromDate) {
    return axios.get(`${API_BASE_URL}/dashboard/amt/amt-daily-tx-date/?fromDate=${fromDate}`);
  }

  updateAmmnetValidation(data) {
    return axios.post(`${API_BASE_URL}/dashboard/amt/amt-daily-tx-date/`, data);
  }

  getEmissionValidation(fromDate) {
    return axios.get(`${API_BASE_URL}/dashboard/ghg/pollutants/ghg-hourly-tx-date?fromDate=${fromDate}`);
  }

  updateEmissionValidation(data) {
    return axios.post(`${API_BASE_URL}/dashboard/ghg/pollutants/ghg-hourly-tx-date`, data);
  }

  updateGhgValidation(data) {
    return axios.post(`${API_BASE_URL}/dashboard/ghg/pollutants/ghg-hourly-tx-date`, data);
  }

  getShutdownSchedule(fromDate) {
    return axios.get(`dashboard/shutdownSchdule/shutdown-daily-tx-date`);
  }

  getFacilityDetails(facilityCategory) {
    return axios.get(`dashboard/shutdownSchdule/facility?facilitycategory=${facilityCategory}`);
  }

  getPlantDetails(facilityId) {
    return axios.get(`dashboard/shutdownSchdule/plant?facilityId=${facilityId}`);
  }

  getDeviceDetails(facilityId, plantId) {
    return axios.get(`dashboard/shutdownSchdule/device?facilityId=${facilityId}&plantId=${plantId}`);
  }

  addShutdownSchedule(data) {
    return axios.post(`dashboard/shutdownSchdule/shutdown-hourly-tx-date`, data);
  }

  deleteShutdownScheduleItem(id) {
    return axios.get(`dashboard/shutdownSchdule/delete?Id=${id}`);
  }

  getManualValves(fromDate) {
    return axios.get(`dashboard/manualvalves/manualvalves-daily-tx-date`);
  }

  getDeviceDetailsForManualValves(facilityId, dataCollectionMode) {
    return axios.get(`dashboard/manualvalves/device?facilityId=${facilityId}&dataCollectionMode=${dataCollectionMode}`);
  }

  addManualValvesData(data) {
    return axios.post(`dashboard/manualvalves/manualvalves-hourly-tx-date`, data);
  }

  deleteManualValvesItem(id) {
    return axios.get(`dashboard/manualvalves/delete?Id=${id}`);
  }

  getAllReasons() {
    const url = `${API_BASE_URL}/device/reasons`;
    return axios.get(url);
  }
}


export default new DataCorrectionService();
