import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';
const API_PREFIX = `${API_BASE_URL}/dashboard/ghg/pollutants`;

const GhgService = {
  getActivePollutants: () => axios.get(`${API_PREFIX}`),
  getActualEmissions: (areaId, facilityId, fromDate, toDate) => axios.get(`${API_PREFIX}/actual`, { params: { areaId, facilityId, fromDate, toDate } }),
  getPollutantStats: (areaId, facilityId, fromDate) => axios.get(`${API_PREFIX}/stats`, { params: { areaId, facilityId, fromDate } }),
  getEmissionsByReason: (areaId, facilityId, date) => axios.get(`${API_PREFIX}/reasons-by-type`, { params: { areaId, facilityId, date } }),
  getSourceEmission: (fromDate) => axios.get(`${API_PREFIX}/ghg-source-emission`, { params: { fromDate } }),
  getScopeEmission: (fromDate) => axios.get(`${API_PREFIX}/ghg-scope-emission`, { params: { fromDate } }),
  getPollutantEmission: (fromDate) => axios.get(`${API_PREFIX}/ghg-pollutant-emission`, { params: { fromDate } }),
  getPollutantEmissionV2: (fromDate) => axios.get(`${API_PREFIX}/ghg-pollutant-emission-v2`, { params: { fromDate } }),
  getSourceEmissionV2: (fromDate) => axios.get(`${API_PREFIX}/ghg-pollutant-emission-sources`, { params: { fromDate } })
};

export default GhgService;
