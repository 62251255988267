import { API_BASE_URL } from '../../../../../config';

const AnnualPriceService = {
    getAllAnnualPrices: async () => {
        const response = await fetch(`${API_BASE_URL}/master/annual-price`);
        if (!response.ok) {
            throw new Error('Failed to fetch annual prices');
        }
        return response.json();
    },

    getAnnualPriceByYear: async (year) => {
        const response = await fetch(`${API_BASE_URL}/master/annual-price/${year}`);
        if (!response.ok) {
            throw new Error('Failed to fetch annual price by year');
        }
        return response.json();
    },

    saveAnnualPrice: async (annualPrice, isEdit) => {
        const response = await fetch(`${API_BASE_URL}/master/annual-price/${isEdit ? 'update' : 'save'}`, {
            method: isEdit ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
            credentials: 'include',
            body: JSON.stringify(annualPrice),
        });
        if (!response.ok) {
            throw new Error('Failed to save annual price');
        }
        return response.json();
    },

    deleteAnnualPrice: async (year) => {
        const response = await fetch(`${API_BASE_URL}/master/annual-price/delete/${year}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
            credentials: 'include',
        });
        if (!response.ok) {
            throw new Error('Failed to delete annual price');
        }
        return response.json();
    },

    getYears: async () => {
        let lstYears = [];
        let startYear = 2000;
        while (startYear <= 2050) {
            lstYears.push(startYear);
            startYear++;
        }
        return lstYears;
    },

    canDeleteAnnualPrice: async (year) => {
        const response = await fetch(`${API_BASE_URL}/master/annual-price/can-delete/${year}`);
        if (!response.ok) {
            throw new Error('Failed to check if annual price can be deleted');
        }
        return response.json();
    }
};

export default AnnualPriceService;
