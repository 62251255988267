import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../config';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import FacilitySelector from '../../../common/FacilitySelector';
import 'react-datepicker/dist/react-datepicker.css';
import ExportComponent from '../../../common/ExportComponent';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../common/FormatNumber';
import { BUTTON_LABELS } from '../../../../constants';
import ViewDeviceFlaring from './ViewDeviceFlaring'; // Import the new component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'; // Import the eye icon
import './DeviceFlaring.css';
import { format } from 'date-fns';


export default function DeviceFlaring() {
  const [facilityData, setFacilityData] = useState([]);
  const [devicetFlaringData, setDeviceFlaringData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [startDatePickerClass, setStartDatePickerClass] = useState('');
  const [endDatePickerClass, setEndDatePickerClass] = useState('');
  const [exportFilteredData, setExportFilteredData] = useState([]); // Add this state

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDeviceFlaringButtonClick = () => {
    if (startDate && endDate && selectedFacilityId !== '') {
      setIsLoading(true);
      const apiUrl = `${API_BASE_URL}/reports/device-flaring?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}`;

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          setDeviceFlaringData(data.content);
          setFilteredData(data.content); // Initialize filtered data
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    } else {
      console.error('Please select valid options.');
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    let filtered = devicetFlaringData.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );

    if (filtered.length === 0) {
      filtered = [{
        deviceId: '',
        plantName: '',
        flaringType: '',
        materialState: '',
        deviceMode: '',
        headerName: '',
        reasonId: '',
        reason: '',
        mass: '',
        flaringMscf: '',
        leakageMscf: '',
        flareCost: '',
        remarks: ''
      }];
    }

    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Source ID': item.deviceId,
      'Plant Name': item.plantName,
      'Flaring Type': item.flaringType,
      'Material State': item.materialState,
      'Source Mode': item.deviceMode,
      'Header': item.header,
      'Reason ID': item.reasonId,
      'Reason': item.reason,
      'Mass': formatWithIndianThousandsSeparatorForExportData(item.mass ? item.mass.toFixed(0) : item.mass),  
      'Flaring': formatWithIndianThousandsSeparatorForExportData(item.flaringMscf ? item.flaringMscf.toFixed(0) : item.flaringMscf),
      'Leakage': formatWithIndianThousandsSeparatorForExportData(item.leakageMscf ? item.leakageMscf.toFixed(0) : item.leakageMscf),
      'Flare Cost': formatWithIndianThousandsSeparatorForExportData(item.flareCost ? item.flareCost.toFixed(0) : item.flareCost),
      'Remarks': item.remarks
  }));

  setExportFilteredData(exportData);

  }, [search, devicetFlaringData]);

  const openModal = (row) => {
    setSelectedRowData(row);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedRowData({});
  };

  const columns = [
    {
      name: 'Source ID',
      selector: row => row.deviceId,
      sortable: true
    },
    {
      name: 'Plant Name',
      selector: row => row.plantName,
      sortable: true
    },
    {
      name: 'Flaring Type',
      selector: row => row.flaringType,
      sortable: true
    },
    {
      name: 'Material State',
      selector: row => row.materialState,
      sortable: true
    },
    {
      name: 'Source Mode',
      selector: row => row.deviceMode,
      sortable: true
    },
    {
      name: 'Header',
      selector: row => row.headerName,
      sortable: true
    },
    {
      name: 'Reason ID',
      selector: row => row.reasonId,
      sortable: true
    },
    {
      name: 'Reason',
      selector: row => row.reason,
      sortable: true
    },
    {
      name: 'Mass',
      selector: row => row.mass,
      cell: row => formatWithIndianThousandsSeparator(row.mass ? row.mass.toFixed(0) : row.mass),  
      sortable: true
    },
    {
      name: 'Flaring',
      selector: row => row.flaringMscf,
      cell: row => formatWithIndianThousandsSeparator(row.flaringMscf ? row.flaringMscf.toFixed(0) : row.flaringMscf),
      sortable: true
    },
    {
      name: 'Leakage',
      selector: row => formatWithIndianThousandsSeparator(row.leakageMscf ? row.leakageMscf.toFixed(0) : row.leakageMscf),  
      sortable: true
    },
    {
      name: 'Flare Cost',
      selector: row => formatWithIndianThousandsSeparator(row.flareCost ? row.flareCost.toFixed(0) : row.flareCost),  
      sortable: true
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
      sortable: true
    },
    {
      name: 'View',
      cell: (row) => row.deviceId ? (
        <FontAwesomeIcon
          className='btn btn-sm btn-warning text-dark'
          icon={faEye}
          onClick={() => openModal(row)}
          style={{ cursor: 'pointer', color: 'blue' }}
        />
      ) : null,
    }
  ];

  
  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};


  const fileName = `FMS_Source_Flaring${new Date().toLocaleDateString()}`;

  const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
  const handleStartDateBlur = () => setStartDatePickerClass('');
  const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
  const handleEndDateBlur = () => setEndDatePickerClass('');

  const headerData = [
    { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
    { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
    { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

  const title = "Source Flaring Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Source Flaring</h1>
        <ul>
          <li>Reports</li>
          <li>Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className='row'>
          <FacilitySelector
            selectedFacilityId={selectedFacilityId}
            onChange={setSelectedFacilityId}
            facilityData={facilityData} 
            setFacilityData={setFacilityData}
          />
          <div className='col-md-2'>
            <label>Start Date</label>
            <div className={startDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleStartDateFocus}
                onBlur={handleStartDateBlur}
              />
            </div>
          </div>
          <div className='col-md-2'>
            <label>End Date</label>
            <div className={endDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleEndDateFocus}
                onBlur={handleEndDateBlur}
              />
            </div>
          </div>

          <div className='col-md-2 generate_report_button'>
            <button className='btn btn-primary' onClick={handleDeviceFlaringButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
          </div>
        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              <div className='row'>
                <div className='col-md-10'>
                  {exportFilteredData.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title} />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViewDeviceFlaring
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        rowData={selectedRowData}
      />
    </div>
  );
}
