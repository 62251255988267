import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import DataCorrectionService from '../services/DataCorrectionService';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditFlowValidation = ({ data, onClose, onSave }) => {
  console.log(data);
  const [formData, setFormData] = useState({
    id: data.id,
    deviceId: data.deviceId,
    flaring: data.flaring,
    flaringOil: data.flaringOil,
    adjustedFlaring: data.adjustedFlaring,
    adjustedFlaringOil: data.adjustedFlaringOil,
    adjustedReasonId: data.adjustedReasonId,
    engineerId: data.engineerId,
    userComments: data.userComments || '',
  });

  const [reasons, setReasons] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchReasons();
  }, []);

  const fetchReasons = async () => {
    try {
      const response = await DataCorrectionService.getAllReasons();
      setReasons(response.data);
    } catch (error) {
      console.error('Failed to fetch reasons:', error);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (formData.adjustedFlaring === '' || formData.adjustedFlaring === null) newErrors.adjustedFlaring = 'Adj Flaring is required';
    if (formData.adjustedFlaringOil === '' || formData.adjustedFlaringOil === null) newErrors.adjustedFlaringOil = 'Adj Flaring Oil is required';
    if (!formData.adjustedReasonId) newErrors.adjustedReasonId = 'Reason is required';
    if (!formData.userComments) newErrors.userComments = 'Comments are required';
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        await DataCorrectionService.updateFlowValidation(formData);
        onSave();
        onClose();
        toast.success(`Flow Validation Updated successfully!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.error('Failed to update flow validation:', error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Source Id</label>
            <input
              type="text"
              className="form-control"
              name="deviceId"
              value={formData.deviceId}
              readOnly
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Total Flaring</label>
            <input
              type="text"
              className="form-control"
              name="flaring"
              value={formData.flaring}
              readOnly
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Total Flaring Oil</label>
            <input
              type="text"
              className="form-control"
              name="flaringOil"
              value={formData.flaringOil}
              readOnly
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Adj Flaring</label>
            <input
              type="text"
              className={`form-control ${errors.adjustedFlaring ? 'is-invalid' : ''}`}
              name="adjustedFlaring"
              value={formData.adjustedFlaring}
              onChange={handleInputChange}
            />
            {errors.adjustedFlaring && <div className="invalid-feedback">{errors.adjustedFlaring}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Adj Flaring Oil</label>
            <input
              type="text"
              className={`form-control ${errors.adjustedFlaringOil ? 'is-invalid' : ''}`}
              name="adjustedFlaringOil"
              value={formData.adjustedFlaringOil}
              onChange={handleInputChange}
            />
            {errors.adjustedFlaringOil && <div className="invalid-feedback">{errors.adjustedFlaringOil}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Engineer Id</label>
            <input
              type="text"
              className="form-control"
              name="engineerId"
              value={formData.engineerId}
              readOnly
            />
          </div>
        </div>
        <div className='col-md-12'>
          <div className="form-group">
            <label>Reason</label>
            <select
              className={`form-control ${errors.adjustedReasonId ? 'is-invalid' : ''}`}
              name="adjustedReasonId"
              value={formData.adjustedReasonId}
              onChange={handleInputChange}
            >
              <option value="">Select Reason</option>
              {reasons.map(reason => (
                <option key={reason.reasonId} value={reason.reasonId}>
                  {reason.reasonDescription}
                </option>
              ))}
            </select>
            {errors.adjustedReasonId && <div className="invalid-feedback">{errors.adjustedReasonId}</div>}
          </div>
        </div>
        <div className='col-md-12'>
          <div className="form-group">
            <label>Comments</label>
            <textarea
              className={`form-control ${errors.userComments ? 'is-invalid' : ''}`}
              name="userComments"
              rows="4"
              value={formData.userComments}
              onChange={handleInputChange}
            />
            {errors.userComments && <div className="invalid-feedback">{errors.userComments}</div>}
          </div>
        </div>
      </div>
      <Button type="submit" className="btn btn-primary float-right">Save</Button>
      <Button type="button" className="btn btn-secondary float-right mr-3" onClick={onClose}>Cancel</Button>
    </form>
  );
};

export default EditFlowValidation;
