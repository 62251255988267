// flaringPlanService.js
import { API_BASE_URL } from "../../../../../config";

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || 'Unknown error');
  }
  return response.json();
};

export const getAllFlaringPlans = async () => {
  const response = await fetch(API_BASE_URL);
  return handleResponse(response);
};

export const getFlaringPlanById = async (id) => {
  const response = await fetch(`${API_BASE_URL}/system-plan/flaring-plan/${id}`);
  return handleResponse(response);
};

export const saveFlaringPlan = async (flaringPlan, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/system-plan/flaring-plan/update` : `${API_BASE_URL}/system-plan/flaring-plan/save`;
  const method = isEdit ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(flaringPlan),
  });

  return handleResponse(response);
};

export const deleteFlaringPlan = async (id) => {
  const response = await fetch(`${API_BASE_URL}/delete/${id}`, {
    method: 'DELETE',
  });
  return handleResponse(response);
};

export const canDeleteFlaringPlan = async (id) => {
  const response = await fetch(`${API_BASE_URL}/can-delete/${id}`);
  return handleResponse(response);
};
