import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';

const AddAmtExceedance = () => {
  const [formGroup, setFormGroup] = useState({
    standard: '',
    componentId: '',
    pollutant: '',
    avgInterval: '',
    avgPeriod: '',
    proposedExceedance: '',
    units: '',
    allowedExceedance: '',
    allowancePerTime: '',
    status: ''
  });
  const [amtCompositionItems, setAmtCompositionItems] = useState([]);
  const [uomItems, setUomItems] = useState([]);
  const [statusOptions] = useState(['A', 'D']);
  const [allowancePerTimeItems] = useState(['hour', 'month', 'year']);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // const { exceedanceId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = !!searchParams.get('exceedanceId');

  useEffect(() => {
    if (isEdit) {
      fetch(`${API_BASE_URL}/amt-exceedance/${searchParams.get('exceedanceId')}`)
        .then(response => response.json())
        .then(data => {
          setFormGroup(data);
        })
        .catch(error => {
          console.error('Error fetching exceedance details:', error);
        });
    }
  }, [searchParams.get('exceedanceId'), isEdit]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/composition/comp-details/AMMNET`)
      .then(response => response.json())
      .then(data => {
        setAmtCompositionItems(data);
      })
      .catch(error => {
        console.error('Error fetching AMT composition items:', error);
      });

    fetch(`${API_BASE_URL}/device/uoms`)
      .then(response => response.json())
      .then(data => {
        setUomItems(data.filter(t => t.uomType === 'CONC'));
      })
      .catch(error => {
        console.error('Error fetching UOMs:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.standard) {
      newErrors.standard = 'Standard is required.';
    }
    if (!formGroup.componentId) {
      newErrors.componentId = 'Component Id is required.';
    }
    if (!formGroup.pollutant) {
      newErrors.pollutant = 'Pollutant is required.';
    }
    if (!formGroup.avgInterval) {
      newErrors.avgInterval = 'Avg Interval is required.';
    }
    if (!formGroup.avgPeriod) {
      newErrors.avgPeriod = 'Avg Period is required.';
    }
    if (!formGroup.proposedExceedance) {
      newErrors.proposedExceedance = 'Proposed Exceedance is required.';
    }
    if (!formGroup.units) {
      newErrors.units = 'Units are required.';
    }
    if (!formGroup.allowedExceedance) {
      newErrors.allowedExceedance = 'Allowed Exceedance is required.';
    }
    if (!formGroup.allowancePerTime) {
      newErrors.allowancePerTime = 'Allowance Per Time is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/amt-exceedance/${isEdit ? `update/${searchParams.get('exceedanceId')}` : ''}`, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formGroup)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Exceedance ${isEdit ? 'updated' : 'added'} successfully.`);
      setTimeout(() => {
        navigate('/admin/configurations/amt-config/list-amt-exceedance');
      }, 1000);
    } catch (error) {
      console.error('Failed to save exceedance:', error);
      toast.error('Error saving exceedance');
    }
  };

  const handleComponentChange = (e) => {
    const { value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      componentId: value,
      pollutant: amtCompositionItems.find(t => t.componentId === value)?.eqptCode || ''
    }));
  };

  return (
    <div>
      {/* <h4>{isEdit ? 'Edit Exceedance' : 'Add Exceedance'}</h4> */}
      <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit AMT Exceedances' : 'Add AMT Exceedances'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-AMT</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Exceedance Details</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="standard">Standard</label>
                <input type="text" className={`form-control ${errors.standard ? 'is-invalid' : ''}`} id="standard" name="standard" value={formGroup.standard} onChange={handleChange}  />
                {errors.standard && <div className="invalid-feedback d-block">{errors.standard}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="componentId">Component ID</label>
                <select className={`form-control ${errors.componentId ? 'is-invalid' : ''}`} id="componentId" name="componentId" value={formGroup.componentId} onChange={handleComponentChange} >
                  <option value="">Select Component ID</option>
                  {amtCompositionItems.map(item => (
                    <option key={item.componentId} value={item.componentId}>{item.componentId}</option>
                  ))}
                </select>
                {errors.componentId && <div className="invalid-feedback d-block">{errors.componentId}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="pollutant">Pollutant</label>
                <input type="text" className={`form-control ${errors.pollutant ? 'is-invalid' : ''}`} id="pollutant" name="pollutant" value={formGroup.pollutant} readOnly />
                {errors.pollutant && <div className="invalid-feedback d-block">{errors.pollutant}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="avgInterval">Avg Interval</label>
                <input type="text" className={`form-control ${errors.avgInterval ? 'is-invalid' : ''}`} id="avgInterval" name="avgInterval" value={formGroup.avgInterval} onChange={handleChange}  />
                {errors.avgInterval && <div className="invalid-feedback d-block">{errors.avgInterval}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="avgPeriod">Avg Period</label>
                <select className={`form-control ${errors.avgPeriod ? 'is-invalid' : ''}`} id="avgPeriod" name="avgPeriod" value={formGroup.avgPeriod} onChange={handleChange} >
                  <option value="">Select Avg Period</option>
                  {allowancePerTimeItems.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
                {errors.avgPeriod && <div className="invalid-feedback d-block">{errors.avgPeriod}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="proposedExceedance">Proposed Exceedance</label>
                <input type="text" className={`form-control ${errors.proposedExceedance ? 'is-invalid' : ''}`} id="proposedExceedance" name="proposedExceedance" value={formGroup.proposedExceedance} onChange={handleChange}  />
                {errors.proposedExceedance && <div className="invalid-feedback d-block">{errors.proposedExceedance}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="units">Units</label>
                <select className={`form-control ${errors.units ? 'is-invalid' : ''}`} id="units" name="units" value={formGroup.units} onChange={handleChange} >
                  <option value="">Select Units</option>
                  {uomItems.map(item => (
                    <option key={item.uomId} value={item.uomId}>{item.uomId}</option>
                  ))}
                </select>
                {errors.units && <div className="invalid-feedback d-block">{errors.units}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="allowedExceedance">Allowed Exceedance</label>
                <input type="text" className={`form-control ${errors.allowedExceedance ? 'is-invalid' : ''}`} id="allowedExceedance" name="allowedExceedance" value={formGroup.allowedExceedance} onChange={handleChange}  />
                {errors.allowedExceedance && <div className="invalid-feedback d-block">{errors.allowedExceedance}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="allowancePerTime">Allowance Per Time</label>
                <select className={`form-control ${errors.allowancePerTime ? 'is-invalid' : ''}`} id="allowancePerTime" name="allowancePerTime" value={formGroup.allowancePerTime} onChange={handleChange} >
                  <option value="">Select Allowance Per Time</option>
                  {allowancePerTimeItems.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
                {errors.allowancePerTime && <div className="invalid-feedback d-block">{errors.allowancePerTime}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select className={`form-control ${errors.status ? 'is-invalid' : ''}`} id="status" name="status" value={formGroup.status} onChange={handleChange} required>
                  <option value="">Select Status</option>
                  {statusOptions.map(status => (
                    <option key={status} value={status}>{status}</option>
                  ))}
                </select>
                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
              </div>
            </div>
            <div className="form-group row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">
                  {isEdit ? 'Update' : 'Save'}
                </button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/amt-config/list-amt-exceedance')}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddAmtExceedance;
