import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../../config';

const AddStatus = ({ showModal, handleClose, fetchStatusData, status }) => {
    const [statusId, setStatusId] = useState('');
    const [statusDescription, setStatusDescription] = useState('');

    useEffect(() => {
        if (status) {
            setStatusId(status.statusId);
            setStatusDescription(status.statusDescription);
        } else {
            setStatusId('');
            setStatusDescription('');
        }
    }, [status]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const statusData = { statusId, statusDescription };

        try {
            const response = await fetch(`${API_BASE_URL}/master/status/${status ? 'update' : 'save'}`, {
                method: status ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(statusData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`Status ${status ? 'updated' : 'saved'} successfully`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            fetchStatusData();
            handleClose();
        } catch (error) {
            toast.error(`Error ${status ? 'updating' : 'saving'} Status`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{status ? 'Edit Status' : 'Add Status'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="statusId">
                        <Form.Label>Status ID</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Status ID"
                            value={statusId}
                            onChange={(e) => setStatusId(e.target.value)}
                            readOnly={!!status}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="statusDescription">
                        <Form.Label>Status Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Status Description"
                            value={statusDescription}
                            onChange={(e) => setStatusDescription(e.target.value)}
                        />
                    </Form.Group>
                   <div className='mt-2 float-right'>
                   <Button variant="secondary" onClick={handleClose} className="mr-2">
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        {status ? 'Update' : 'Save'}
                    </Button>
                   </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddStatus;
