import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddGhgFactor from './AddGhgFactor'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function GhgFactorList() {
    const [ghgFactorData, setGhgFactorData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedghgFactorId, setSelectedghgFactorId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchGhgFactorData();
    }, []);

    const fetchGhgFactorData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-factor`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setGhgFactorData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Source type data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/ghg-master/add-ghg-factor?ghgFactorId=${id}`);
    };

    const handleDelete = async (ghgFactorId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Ghg Factor: ${ghgFactorId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-factor/delete/${ghgFactorId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchGhgFactorData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = ghgFactorData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'GHG Key': item.ghgKey,
            'Methodology': item.methodology,
            'Year': item.year,
            'Scope': formatWithIndianThousandsSeparatorForExportData(item.scope),
            'Category Level 1': item.categoryLevel1,
            'Category Level 2': item.categoryLevel2,
            'Category Level 3': item.categoryLevel3,
            'Category Level 4': item.categoryLevel4,
            'Measurement Type': item.measurementType,
            'Activity UoM': item.activityUom,
            'GHG': item.ghg,
            'UoM': item.uom,
            'GHG Conversion Factor': formatWithIndianThousandsSeparatorForExportData(item.ghgConversionFactor ? item.ghgConversionFactor.toFixed(0) : item.ghgConversionFactor),
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, ghgFactorData]);

    const columns = [
        { name: 'GHG Key', selector: row => row.ghgKey, sortable: true, },
        { name: 'Methodology', selector: row => row.methodology, sortable: true, },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true,
        },
        {
            name: 'Scope',
            selector: row => row.scope,
            cell: row => formatWithIndianThousandsSeparator(row.scope),
            sortable: true,
        },
        { name: 'Category Level 1', selector: row => row.categoryLevel1, sortable: true, },
        { name: 'Category Level 2', selector: row => row.categoryLevel2, sortable: true, },
        { name: 'Category Level 3', selector: row => row.categoryLevel3, sortable: true, },
        { name: 'Category Level 4', selector: row => row.categoryLevel4, sortable: true, },
        { name: 'Measurement Type', selector: row => row.measurementType, sortable: true, },
        { name: 'Activity UoM', selector: row => row.activityUom, sortable: true, },
        { name: 'GHG', selector: row => row.ghg, sortable: true, resizable: true, },
        { name: 'UoM', selector: row => row.uom, sortable: true, resizable: true, },
        {
            name: 'GHG Conversion Factor',
            selector: row => row.ghgConversionFactor,
            cell: row => formatWithIndianThousandsSeparator(row.ghgConversionFactor ? row.ghgConversionFactor.toFixed(0) : row.ghgConversionFactor),
            sortable: true,
        },
        { name: 'Status', selector: row => row.status, sortable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.ghgKey)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.ghgKey)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Ghg_Factor_${new Date().toLocaleDateString()}`;
    const title = "GHG Factor Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>GHG Factor</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-GHG</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">GHG Factor</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/ghg-master/add-ghg-factor" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}


