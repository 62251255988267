import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function EmsFactorList() {
    const [emsFactorData, setEmsFactorData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchEmsFactorData();
    }, []);

    const fetchEmsFactorData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/ems-factor`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setEmsFactorData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Source type data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = emsFactorData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Factor ID': item.factorId,
            'SCC': formatWithIndianThousandsSeparatorForExportData(item.scc),
            'Level 1': item.level1,
            'Level 2': item.level2,
            'Level 3': item.level3,
            'Level 4': item.level4,
            'Pollutant': formatWithIndianThousandsSeparatorForExportData(item.pollutantId),
            'Control ID': formatWithIndianThousandsSeparatorForExportData(item.controlId),
            'Primary': formatWithIndianThousandsSeparatorForExportData(item.primary_Ems),
            'Calc Type': formatWithIndianThousandsSeparatorForExportData(item.calctype),
            'Factor': formatWithIndianThousandsSeparatorForExportData(item.factor ? item.factor.toFixed(0) : item.factor),
            'Formula': item.formula,
            'Tooltip': item.tooltip,
            'Unit': item.unit,
            'Measure': item.measure,
            'Material': item.material,
            'Action': item.action,
            'AP42section': item.ap42section,
            'Notes': item.notes,
            'Ref Desc': item.ref_desc,
            'Quality': item.quality,
            'NumSources': item.numsources,
            'DupCount': item.dupcount,
            'DupReason': item.dupreason,
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, emsFactorData]);

    const columns = [
        { name: 'Factor ID', selector: row => row.factorId, sortable: true, resizable: true, pinned: 'left' },
        {
            name: 'SCC',
            selector: row => row.scc,
            cell: row => formatWithIndianThousandsSeparator(row.scc),
            sortable: true,
            resizable: true
        },
        { name: 'Level 1', selector: row => row.level1, sortable: true, resizable: true },
        { name: 'Level 2', selector: row => row.level2, sortable: true, resizable: true },
        { name: 'Level 3', selector: row => row.level3, sortable: true, resizable: true },
        { name: 'Level 4', selector: row => row.level4, sortable: true, resizable: true },
        {
            name: 'Pollutant',
            selector: row => row.pollutantId,
            cell: row => formatWithIndianThousandsSeparator(row.pollutantId),
            sortable: true,
            resizable: true
        },
        {
            name: 'Control ID',
            selector: row => row.controlId,
            cell: row => formatWithIndianThousandsSeparator(row.controlId),
            sortable: true,
            resizable: true
        },
        {
            name: 'Primary',
            selector: row => row.primary_Ems,
            cell: row => formatWithIndianThousandsSeparator(row.primary_Ems),
            sortable: true,
            resizable: true
        },
        {
            name: 'Calc Type',
            selector: row => row.calctype,
            cell: row => formatWithIndianThousandsSeparator(row.calctype),
            sortable: true,
            resizable: true
        },
        {
            name: 'Factor',
            selector: row => row.factor,
            cell: row => formatWithIndianThousandsSeparator(row.factor ? row.factor.toFixed(0) : row.factor),
            sortable: true,
            resizable: true,
        },
        { name: 'Formula', selector: row => row.formula, sortable: true, resizable: true },
        { name: 'Tooltip', selector: row => row.tooltip, sortable: true, resizable: true },
        { name: 'Unit', selector: row => row.unit, sortable: true, resizable: true },
        { name: 'Measure', selector: row => row.measure, sortable: true, resizable: true },
        { name: 'Material', selector: row => row.material, sortable: true, resizable: true },
        { name: 'Action', selector: row => row.action, sortable: true, resizable: true },
        { name: 'AP42section', selector: row => row.ap42section, sortable: true, resizable: true },
        { name: 'Notes', selector: row => row.notes, sortable: true, resizable: true },
        { name: 'Ref Desc', selector: row => row.ref_desc, sortable: true, resizable: true },
        { name: 'Quality', selector: row => row.quality, sortable: true, resizable: true },
        { name: 'NumSources', selector: row => row.numsources, sortable: true, resizable: true },
        { name: 'DupCount', selector: row => row.dupcount, sortable: true, resizable: true },
        { name: 'DupReason', selector: row => row.dupreason, sortable: true, resizable: true },
        { name: 'Status', selector: row => row.status, sortable: true, resizable: true }
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Ems_Source_Factor_${new Date().toLocaleDateString()}`;

    const title = "EMS Factor Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>EMS Factors</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-EMS</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Device Type</h4> */}
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}

