import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import './ViewDeviceFlaring.css';

const ViewDeviceFlaring = ({ isOpen, onRequestClose, rowData }) => {
  return (
    <Modal className='' show={isOpen} onHide={onRequestClose}>
      <Modal.Header>
        <Modal.Title>View Device Flaring Details</Modal.Title>
        <Button variant="secondary" onClick={onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginTop: '20px' }}>
          <table className="table table-striped">
            {/* <thead className="">
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead> */}
            <tbody>
              {Object.keys(rowData).map(key => (
                <tr key={key}>
                  <td><strong>{key}</strong></td>
                  <td>{rowData[key]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewDeviceFlaring;
