import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../config';
import { Modal, Button } from 'react-bootstrap';

const AddShutdownForm = ({ setShowModal, fetchShutdownScheduleData }) => {
  const [formData, setFormData] = useState({
    facilityId: '',
    plantId: '',
    deviceId: '',
    reason: '',
    status: '',
    fromDate: '',
    toDate: '',
    comments: ''
  });

  const [allFacilities, setAllFacilities] = useState([]);
  const [allPlants, setAllPlants] = useState([]);
  const [filteredPlants, setFilteredPlants] = useState([]);
  const [allDeviceTypes, setAllDeviceTypes] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchAllFacilities();
    fetchAllReasons();
    fetchAllStatus();
    fetchAllPlants();
  }, []);

  const fetchAllFacilities = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/home/facilities`);
      setAllFacilities(response.data);
    } catch (error) {
      console.error('Failed to fetch facilities:', error);
    }
  };

  const fetchAllPlants = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/home/plants`);
      setAllPlants(response.data);
    } catch (error) {
      console.error('Failed to fetch plants:', error);
    }
  };

  const fetchAllReasons = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/device/reasons`);
      setReasons(response.data);
    } catch (error) {
      console.error('Failed to fetch reasons:', error);
    }
  };

  const fetchAllStatus = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/master/status`);
      setAllStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch status:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFacilityChange = (e) => {
    const facilityId = e.target.value;
    setFormData({ ...formData, facilityId, plantId: '' });
    const filteredPlants = allPlants.filter(plant => plant.facilityId == facilityId);
    setFilteredPlants(filteredPlants);
  };

  const handlePlantChange = async (e) => {
    const plantId = e.target.value;
    setFormData({ ...formData, plantId });
    try {
      const response = await axios.get(`${API_BASE_URL}/dashboard/shutdownSchdule/device?facilityId=${formData.facilityId}&plantId=${plantId}`);
      setAllDeviceTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch devices:', error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.facilityId) newErrors.facilityId = 'Facility is required';
    if (!formData.plantId) newErrors.plantId = 'Plant is required';
    if (!formData.deviceId) newErrors.deviceId = 'Device is required';
    if (!formData.reason) newErrors.reason = 'Reason is required';
    if (!formData.status) newErrors.status = 'Status is required';
    if (!formData.fromDate) newErrors.fromDate = 'From Date is required';
    if (!formData.toDate) newErrors.toDate = 'To Date is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const result = {
      FacilityId: formData.facilityId,
      PlantId: formData.plantId,
      DeviceId: formData.deviceId,
      ReasonId: formData.reason,
      Comments: formData.comments,
      Status: formData.status,
      dateFrom: new Date(formData.fromDate).toISOString(),
      dateTo: new Date(formData.toDate).toISOString()
    };

    try {
      await axios.post(`${API_BASE_URL}/dashboard/shutdownSchdule/shutdown-hourly-tx-date`, result);
      fetchShutdownScheduleData();
      setShowModal(false);
    } catch (error) {
      console.error('Failed to add shutdown schedule:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Facility</label>
            <select
              className={`form-control ${errors.facilityId ? 'is-invalid' : ''}`}
              name="facilityId"
              value={formData.facilityId}
              onChange={handleFacilityChange}
            >
              <option value="">Select Facility</option>
              {allFacilities.map(facility => (
                <option key={facility.facilityId} value={facility.facilityId}>
                  {facility.facilityName}
                </option>
              ))}
            </select>
            {errors.facilityId && <div className="invalid-feedback">{errors.facilityId}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Plant</label>
            <select
              className={`form-control ${errors.plantId ? 'is-invalid' : ''}`}
              name="plantId"
              value={formData.plantId}
              onChange={handlePlantChange}
              disabled={!formData.facilityId}
            >
              <option value="">Select Plant</option>
              {filteredPlants.map(plant => (
                <option key={plant.plantId} value={plant.plantId}>
                  {plant.plantId} ({plant.plantName})
                </option>
              ))}
            </select>
            {errors.plantId && <div className="invalid-feedback">{errors.plantId}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Device</label>
            <select
              className={`form-control ${errors.deviceId ? 'is-invalid' : ''}`}
              name="deviceId"
              value={formData.deviceId}
              onChange={handleInputChange}
            >
              <option value="">Select Source</option>
              {allDeviceTypes.map(device => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.deviceId} ({device.deviceName})
                </option>
              ))}
            </select>
            {errors.deviceId && <div className="invalid-feedback">{errors.deviceId}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Reason</label>
            <select
              className={`form-control ${errors.reason ? 'is-invalid' : ''}`}
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
            >
              <option value="">Select Reason</option>
              {reasons.map(reason => (
                <option key={reason.reasonId} value={reason.reasonId}>
                  {reason.reasonDescription}
                </option>
              ))}
            </select>
            {errors.reason && <div className="invalid-feedback">{errors.reason}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>From Date</label>
            <input
              type="datetime-local"
              className={`form-control ${errors.fromDate ? 'is-invalid' : ''}`}
              name="fromDate"
              value={formData.fromDate}
              onChange={handleInputChange}
            />
            {errors.fromDate && <div className="invalid-feedback">{errors.fromDate}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>To Date</label>
            <input
              type="datetime-local"
              className={`form-control ${errors.toDate ? 'is-invalid' : ''}`}
              name="toDate"
              value={formData.toDate}
              onChange={handleInputChange}
            />
            {errors.toDate && <div className="invalid-feedback">{errors.toDate}</div>}
          </div>
        </div>
        <div className='col-md-12'>
          <div className="form-group">
            <label>Status</label>
            <select
              className={`form-control ${errors.status ? 'is-invalid' : ''}`}
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value="">Select Status</option>
              {allStatus.map(status => (
                <option key={status.statusId} value={status.statusId}>
                  {status.statusDescription}
                </option>
              ))}
            </select>
            {errors.status && <div className="invalid-feedback">{errors.status}</div>}
          </div>
        </div>
        <div className='col-md-12'>
          <div className="form-group">
            <label>Comments</label>
            <textarea
              className="form-control"
              name="comments"
              rows="2"
              value={formData.comments}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <Button type="submit" className="btn btn-primary float-right">Save</Button>
      <Button type="button" className="btn btn-secondary float-right mr-3" onClick={() => setShowModal(false)}>Cancel</Button>
    </form>
  );
};

export default AddShutdownForm;
