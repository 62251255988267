import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExportComponent from '../../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../../constants';
import { format } from 'date-fns';
import formatDateTime from '../../../common/formatDateTime';

const FlareTxErrors = () => {
    const [facilities, setFacilities] = useState([]);
    const [plants, setPlants] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [selectedFacilityId, setSelectedFacilityId] = useState('-1');
    const [selectedPlantId, setSelectedPlantId] = useState('-1');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [rowcount, setRowcount] = useState(0);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disablePlant, setDisablePlant] = useState(true);

    const datepickerRef = useRef(null);

    useEffect(() => {
        fetchFacilities();
        fetchPlants();
    }, []);

    const fetchFacilities = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilities(data);
        } catch (error) {
            console.error('Failed to fetch facilities data:', error);
        }
    };

    const fetchPlants = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/plants`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPlants(data);
        } catch (error) {
            console.error('Failed to fetch plants data:', error);
        }
    };

    const handleFacilityChange = (e) => {
        const facId = e.target.value;
        setSelectedFacilityId(facId);
        if (facId === '-1') {
            setDisablePlant(true);
            setSelectedPlantId('-1');
        } else {
            setDisablePlant(false);
        }
    };

    const handlePlantChange = (e) => {
        setSelectedPlantId(e.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const getReport = () => {
        if (startDate && endDate && selectedFacilityId !== '') {
            setIsLoading(true); // Set loading state to true before fetching data

            const apiUrl = `${API_BASE_URL}/reports/emissiontransactionerrors?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&plantId=${selectedPlantId}&updatedByFilter=IPFMS`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    setRowData(data.content);
                    setFilteredData(data.content); // Update the filtered data as well
                    setRowcount(data.content.length);
                    setIsLoading(false); // Set loading state to false after data is fetched
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false); // Set loading state to false if there's an error
                });
        } else {
            console.error('Please select valid options.');
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = rowData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        if (filtered.length === 0) {
            filtered.push({
                transactionDateTime: '',
                facilityID: '',
                plantID: '',
                deviceID: '',
                tagname: '',
                codeLineNo: '',
                errorComments: ''
            });
        }
        setFilteredData(filtered);

        // Create exportFilteredData according to the columns order
        const exportData = filtered.map(item => ({
            'Tx Date & Time': item.transactionDateTime ? formatDateTime(item.transactionDateTime) : '',
            'Facility ID': item.facilityID,
            'Plant ID': item.plantID,
            'Source ID': item.deviceID,
            'Tag Name': item.tagname,
            'Error Code': item.codeLineNo,
            'Error': item.errorComments
        }));
        setExportFilteredData(exportData);

    }, [search, rowData]);

    const columns = [
        { name: 'Tx Date & Time', selector: row => row.transactionDateTime, sortable: true ,
            cell : row => row.transactionDateTime ? formatDateTime(row.transactionDateTime) : ''
        },
        { name: 'Facility ID', selector: row => row.facilityID, sortable: true },
        { name: 'Plant ID', selector: row => row.plantID, sortable: true },
        { name: 'Source ID', selector: row => row.deviceID, sortable: true },
        { name: 'Tag Name', selector: row => row.tagname, sortable: true },
        { name: 'Error Code', selector: row => row.codeLineNo, sortable: true },
        { name: 'Error', selector: row => row.errorComments, sortable: true }
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Flare_Error_Report_${new Date().toLocaleDateString()}`;

    const headerData = [
        { label: 'Facility Name', value: selectedFacilityId != -1 ? facilities.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
        { label: 'Plant Name', value: selectedPlantId != -1 && selectedFacilityId != -1 ? plants.find(plant => plant.plantId == selectedPlantId)?.plantName : 'All Plants' },
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];
    
    const title = "Flaring  Errors Report";

    return (
        <div className="main-content">
            <div className="breadcrumb mt-3">
                <h1>Flaring Errors</h1>
                <ul>
                    <li>Health Check</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="row filter-container">
                                    <div className="col-sm-2 form-group">
                                        <label>Select Facility</label>
                                        <select
                                            className="form-control"
                                            value={selectedFacilityId}
                                            onChange={handleFacilityChange}
                                        >
                                            <option value="-1">All Facilities</option>
                                            {facilities.map(fac => (
                                                <option key={fac.facilityId} value={fac.facilityId}>
                                                    {fac.facilityName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-2 form-group">
                                        <label>Select Plant</label>
                                        <select
                                            className="form-control"
                                            value={selectedPlantId}
                                            onChange={handlePlantChange}
                                            disabled={disablePlant}
                                        >
                                            <option value="-1">All Plants</option>
                                            {plants.map(pla => (
                                                <option key={pla.plantId} value={pla.plantId}>
                                                    {pla.plantName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-2 form-group">
                                        <label>Select Start Date</label>
                                        <div className="input-group date-picker-range">
                                            <div className="datepicker-parent">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={startDate}
                                                    onChange={handleStartDateChange}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    placeholderText="Start Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 form-group">
                                        <label>Select End Date</label>
                                        <div className="input-group date-picker-range">
                                            <div className="datepicker-parent">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={endDate}
                                                    onChange={handleEndDateChange}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    placeholderText="End Date"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 form-group">
                                        <button
                                            type="button"
                                            className="btn btn-primary mt-4"
                                            onClick={getReport}
                                        >
                                            {BUTTON_LABELS.REPORT_BUTTON}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 mt-3" style={{ height: '460px' }}>
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-md-10'>
                                    {exportFilteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} headerData={headerData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        customStyles={customStyles}
                                        pagination
                                        highlightOnHover
                                        striped
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default FlareTxErrors;
