// import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import AdminLayout from "./layouts/Admin/Admin.js";
// import { AuthProvider, useAuth } from './contexts/AuthContext';
// import { DateProvider } from './contexts/DateContext.js';

// function PrivateRoute({ children }) {
//     const { currentUser } = useAuth();
//     return currentUser ? children : <Navigate to="/login" />;
// }
// function App() {
//   return (
    
//     <DateProvider>
//       <BrowserRouter>
//         <Routes>
          // <Route path="admin/*" element={<AdminLayout />} />
          // <Route
          //   path="*"
          //   element={<Navigate to="/admin/flaring" replace />}
          // />
//         </Routes>
//       </BrowserRouter>
//     </DateProvider>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "./layouts/Admin/Admin";
import { DateProvider } from './contexts/DateContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/login/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function PrivateRoute({ children }) {
    const { currentUser } = useAuth();
    return currentUser ? children : <Navigate to="/login" />;
}

function App() {
    return (
        <AuthProvider>
            <DateProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="admin/*"
                            element={
                                <PrivateRoute>
                                    <AdminLayout />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/login" replace />}
                        />
                        
                    </Routes>
                </BrowserRouter>
            </DateProvider>
        </AuthProvider>
    );
}

export default App;

