import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config';

const PlantSelector = ({ selectedFacilityId, selectedPlantId, onChange }) => {
    const [allPlantData, setAllPlantData] = useState([]);
    const [filteredPlantData, setFilteredPlantData] = useState([]);

    useEffect(() => {
        const fetchAllPlantData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/home/plants`, {
                    method: 'GET',
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setAllPlantData(data);
            } catch (error) {
                console.error('Failed to fetch plant data:', error);
            }
        };

        fetchAllPlantData();
    }, []);

    useEffect(() => {
        if (selectedFacilityId !== -1) {
            const filteredPlants = allPlantData.filter(plant => plant.facilityId == selectedFacilityId);
            setFilteredPlantData(filteredPlants);
        } else {
            setFilteredPlantData([]);
        }
    }, [selectedFacilityId, allPlantData]);

    return (
        <div className='col-md-2'>
            <label>Select Plant</label>
            <select
                name='plant'
                className='form-control'
                value={selectedPlantId}
                onChange={(e) => onChange(e.target.value)}
                disabled={selectedFacilityId === -1}
            >
                <option value={-1}>All Plants</option>
                {filteredPlantData.map((plant) => (
                    <option key={plant.plantId} value={plant.plantId}>
                        {plant.plantName}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default PlantSelector;
