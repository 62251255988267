import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { savePipeFrictionFactor } from '../services/PipeService';

const AddPipeFrictionFactor = () => {
  const [formGroup, setFormGroup] = useState({
    pipeFittingId: '',
    minPipeDiameterIn: '',
    maxPipeDiameterIn: '',
    frictionFactor: '',
    status: 'A',
  });
  const [statusOptions] = useState(['A', 'D']);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.pipeFittingId) {
      newErrors.pipeFittingId = 'Pipe Fitting Id is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await savePipeFrictionFactor(formGroup);


      if (!response.isSuccess) {
        throw new Error(response.message || 'Error saving Pipe Fitting');
      }

      toast.success('Pipe Friction Factor saved successfully.');
      setTimeout(() => {
        navigate('/admin/configurations/pipe/list-pipe-friction-factor');
      }, 1000);
    } catch (error) {
      console.error('Failed to save pipe fitting:', error);
      toast.error(error.message || 'Error saving Pipe Fitting');
    }

  };

  return (
    <div>
      
      <div className='breadcrumb mt-2'>
                <h1>Add Pipe Friction Factor</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Pipes</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Pipe Friction Factor Info</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="pipeFittingId">Pipe Fitting ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="pipeFittingId"
                  name="pipeFittingId"
                  value={formGroup.pipeFittingId}
                  onChange={handleChange}
                  required
                />
                {errors.pipeFittingId && (
                  <div className="invalid-feedback d-block">
                    {errors.pipeFittingId}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="minPipeDiameterIn">Min Pipe Diameter In</label>
                <input
                  type="number"
                  className="form-control"
                  id="minPipeDiameterIn"
                  name="minPipeDiameterIn"
                  value={formGroup.minPipeDiameterIn}
                  onChange={handleChange}
                />
                {errors.minPipeDiameterIn && (
                  <div className="invalid-feedback d-block">
                    {errors.minPipeDiameterIn}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="maxPipeDiameterIn">Max Pipe Diameter In</label>
                <input
                  type="number"
                  className="form-control"
                  id="maxPipeDiameterIn"
                  name="maxPipeDiameterIn"
                  value={formGroup.maxPipeDiameterIn}
                  onChange={handleChange}
                />
                {errors.maxPipeDiameterIn && (
                  <div className="invalid-feedback d-block">
                    {errors.maxPipeDiameterIn}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="frictionFactor">Friction Factor</label>
                <input
                  type="number"
                  className="form-control"
                  id="frictionFactor"
                  name="frictionFactor"
                  value={formGroup.frictionFactor}
                  onChange={handleChange}
                />
                {errors.frictionFactor && (
                  <div className="invalid-feedback d-block">
                    {errors.frictionFactor}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Operational Status</label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  value={formGroup.status}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Operational Status</option>
                  {statusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                {errors.status && (
                  <div className="invalid-feedback d-block">
                    {errors.status}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary float-right">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary float-right mr-3"
              onClick={() => navigate('/admin/configurations/pipe/list-pipe-friction-factor')}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddPipeFrictionFactor;
