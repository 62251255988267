import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const HeaderWiseFlaringExport = ({ data, columns, fileName, headerData = [], title = '' }) => {
    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: 'A1' });

        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: columns.length + 3 } }];

        const headerString = headerData.map(item => `${item.label}: ${item.value}`).join(' | ');
        XLSX.utils.sheet_add_aoa(worksheet, [[headerString]], { origin: 'A2' });

        worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: columns.length + 3 } });

        XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A4', skipHeader: false });

        const wscols = columns.map(() => ({ wch: 20 }));
        worksheet['!cols'] = wscols;

        // Define header styles using cell comments
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "000000" } },
            alignment: { horizontal: "center" },
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
            }
        };

        // Apply header styles
        columns.forEach((col, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 2, c: index });
            if (!worksheet[cellAddress]) worksheet[cellAddress] = {};
            worksheet[cellAddress].s = headerStyle;
        });

        // Apply light blue color and bold text to "Total" row
        data.forEach((row, rowIndex) => {
            if (Object.values(row).includes('Total')) {
                const rowAddress = rowIndex + 4; // Adjust row index based on header and title rows
                for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowAddress, c: colIndex });
                    if (!worksheet[cellAddress]) worksheet[cellAddress] = {};
                    worksheet[cellAddress].s = {
                        fill: { fgColor: { rgb: "ADD8E6" } }, // Light blue color
                        font: { bold: true, color: { rgb: "0000FF" } } // Bold text and blue color
                    };
                }
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    const exportToPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'A3'
        });

        // Get the page width
        const pageWidth = doc.internal.pageSize.getWidth();

        // Add header information
        const titleFontSize = 12;
        const titleWidth = doc.getTextWidth(title) * titleFontSize / 12;
        const titleX = (pageWidth - titleWidth) / 2;

        doc.setFontSize(titleFontSize);
        doc.setFont('helvetica', 'bold'); // Set font to Helvetica and bold
        doc.setFontSize(12);
        doc.text(title, titleX, 30);

        if (headerData) {
            let headerX = 40;
            let headerY = 50;
            headerData.forEach(item => {
                doc.setFontSize(10);
                doc.text(`${item.label}: ${item.value}`, headerX, headerY);
                headerX += 170; // Adjust spacing as needed
            });
        }

        const convertToCSVFormat = (objArray) => {
            const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
            let csvContent = [];
            const row = Object.keys(array[0]);

            csvContent.push(row);

            for (let i = 0; i < array.length; i++) {
                let line = [];
                for (let index in array[i]) {
                    line.push(array[i][index]);
                }
                csvContent.push(line);
            }

            return csvContent;
        };

        const tableContent = convertToCSVFormat(data);
        const tableColumn = tableContent[0];
        const tableRows = tableContent.slice(1);

        // Determine column alignment based on data type
        const columnStyles = {};
        tableRows[0].forEach((cell, index) => {
            if (!isNaN(cell)) {
                columnStyles[index] = { cellWidth: 'auto', halign: 'right' }; // Align numbers to right
            } else {
                columnStyles[index] = { cellWidth: 'auto', halign: 'left' }; // Align text to left
            }
        });

        doc.autoTable({
            startY: headerData ? 80 : 40,
            head: [tableColumn],
            body: tableRows,
            styles: { fontSize: 8, cellWidth: 'wrap' },
            headStyles: {
                fillColor: [22, 160, 133],
                fontSize: 8,
                textColor: [255, 255, 255],
                lineWidth: 0.1,
                lineColor: [222, 226, 230] // Updated border color
            },
            theme: 'grid', // Ensure grid theme for proper borders
            margin: { top: 20 },
            bodyStyles: {
                valign: 'top',
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                lineColor: [222, 226, 230] // Updated border color
            },
            columnStyles: columnStyles,
            didParseCell: (data) => {
                if (data.row.raw.includes('Total')) {
                    data.cell.styles.textColor = [0, 0, 255]; // Light blue color
                    data.cell.styles.fontStyle = 'bold'; // Set text bold
                }
            }
        });

        doc.save(`${fileName}.pdf`);
    };

    const convertToCSV = (objArray, title, headerData) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        // Add title if provided
        if (title) {
            str += `${title}\r\n`;
        }

        // Add header data if provided
        if (headerData) {
            const headerString = headerData.map(item => `${item.label}: ${item.value}`).join(' | ');
            str += `${headerString}\r\n`;
            str += '\r\n';
        }

        const row = Object.keys(array[0]);

        str += row.join(',') + '\r\n';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';
                let value = array[i][index];
                if (value === 'Total') {
                    value = `"Total"`; // Add quotes to prevent issues with commas
                }
                line += value;
            }
            str += line + '\r\n';
        }

        return str;
    };

    const exportToCSV = () => {
        const csv = convertToCSV(data, title, headerData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${fileName}.csv`);
    };

    return (
        <div className="export-buttons">
            <button className="btn btn-sm btn-primary mr-2" onClick={exportToCSV}>
                Export to CSV
            </button>
            <button className="btn btn-sm btn-success mr-2" onClick={exportToExcel}>
                Export to Excel
            </button>
            <button className="btn btn-sm btn-danger" onClick={exportToPDF}>
                Export to PDF
            </button>
        </div>
    );
};

export default HeaderWiseFlaringExport;
