import React, { useEffect, useRef } from 'react';
import anychart from 'anychart';
import { formatNumberDashboard } from '../../../../common/FormatNumber';
const GhgSourceSunBurst = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && data.length > 0) {
      renderSunburstChart(data);
    }
  }, [data]);

  const renderSunburstChart = (data) => {
    // Clear any previous chart
    if (chartRef.current) {
      chartRef.current.innerHTML = '';
    }

    // Convert data to AnyChart format
    const chartData = convertToSunburstData(data);

    // Create the sunburst chart
    const chart = anychart.sunburst(chartData, "as-tree");

    // Set the container for the chart
    chart.container(chartRef.current);

    // Set the inner radius to create the inner circle
    chart.innerRadius("50%");

    // Set up colors for the segments
    chart.palette(["#FFA500", "#42047e", "#28a745", "#ffc107", "#dc3545"]);

    // Configure the labels
    chart.labels().useHtml(true);
    chart.labels().fontSize(10);
    chart.labels().format("<b>{%name}</b>");

    // Configure the tooltip
    chart.tooltip().useHtml(true);
    chart.tooltip().fontSize(14);
    chart.tooltip().format(function() {
      const context = this;
      const { name, value } = context;
    
      if (name === 'Co2e') {
        const totalCo2e = data.reduce((acc, curr) => acc + (curr.pollutants.find(p => p.key === 'Co2e')?.value || 0), 0);
        return `
          <div>Total Co2e: <b>${formatNumberDashboard(totalCo2e.toFixed(0))}</b></div>
        `;
      } else if (name.includes('Scope 1')) {
        const scop1Co2e = data.reduce((acc, curr) => acc + (curr.pollutants.find(p => p.key === 'Co2e')?.value || 0), 0);
        return `
          <div>Scope 1 Co2e: <b>${formatNumberDashboard(scop1Co2e.toFixed(0))}</b></div>
        `;
      } else {
        return `Reason: <b>${name}</b><br>Value: <b>${formatNumberDashboard(value)}</b>`;
      }
    });

    // Draw the chart
    chart.draw();

    // Add a central label manually
    const centerLabel = chart.label();
    centerLabel
      .text(`${formatNumberDashboard(data.reduce((acc, curr) => acc + curr.pollutants.find(p => p.key === 'Co2e')?.value || 0, 0).toFixed(0))} `)
      .fontColor("#000000")
      .fontSize(10)
      .fontWeight(800)
      .hAlign("center")
      .vAlign("middle")
      .anchor("center")
      .position("center")
      .useHtml(true);
  };

  // const convertToSunburstData = (data) => {
  //   // Calculate total Co2e and structure the data
  //   const groupedByScope = data.reduce((acc, item) => {
  //     const { scope, sourceName } = item;

  //     if (!acc[scope]) {
  //       acc[scope] = [];
  //     }

  //     acc[scope].push({
  //       name: sourceName || 'No Source',
  //       value: item.pollutants.find(p => p.key === 'Co2e')?.value || 0
  //     });

  //     return acc;
  //   }, {});

  //   // Structure data for AnyChart
  //   const chartData = [{
  //     name: 'Co2e',
  //     children: Object.keys(groupedByScope).map(scope => ({
  //       name: `Scope ${scope}`,
  //       children: groupedByScope[scope]
  //     }))
  //   }];

  //   return chartData;
  // };

  const convertToSunburstData = (data) => {
    // Calculate total Co2e and structure the data
    const groupedByScope = data.reduce((acc, item) => {
      const { scope, sourceName } = item;
  
      if (!acc[scope]) {
        acc[scope] = [];
      }
  
      acc[scope].push({
        name: sourceName || 'No Source',
        value: item.pollutants.find(p => p.key === 'Co2e')?.value || 0
      });
  
      return acc;
    }, {});
  
    // Structure data for AnyChart without 'Co2e' parent node
    const chartData = Object.keys(groupedByScope).map(scope => ({
      name: `Scope ${scope}`,
      children: groupedByScope[scope]
    }));
  
    return chartData;
  };
  

  return (
    <div className="ghg-source-sunburst">
      {data.length === 0 ? (
        <div className="alert alert-info">No data available for the selected scope!</div>
      ) : (
        <div ref={chartRef} style={{ width: '100%', height: '300px' }}></div>
      )}
    </div>
  );
};

export default GhgSourceSunBurst;
