import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getAllFacilities,
  getAllPlants,
  getAllUOMs,
  getAllCodeMasters,
  filterPlanTypeFromAllCodeMasters,
  getDistinctCompositionTypes,
  getCompositionDetailForCompositionType,
} from '../services/systemPlanService';
import {
  getEmissionPlanById,
  saveEmissionPlan,
} from '../services/emissionPlanService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEmissionPlan = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [plants, setPlants] = useState([]);
  const [filteredPlants, setFilteredPlants] = useState([]);
  const [uoms, setUoms] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [compTypes, setCompTypes] = useState([]);
  const [componentIDs, setComponentIDs] = useState([]);
  const [plantypeData, setPlantypeData] = useState('');
  const [existingEmissionPlan, setExistingEmissionPlan] = useState(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setIsEdit(true);
      loadEmissionPlanToUpdate(id);
    }

    Promise.all([
      getAllFacilities(),
      getAllPlants(),
      getAllUOMs(),
      getAllCodeMasters(),
      getDistinctCompositionTypes(),
    ]).then(([facilities, plants, uoms, codeMasters, compTypes]) => {
      setFacilities(facilities);
      setPlants(plants);
      setUoms(uoms);
      setPlanTypes(filterPlanTypeFromAllCodeMasters(codeMasters));
      setCompTypes(compTypes);
    }).catch(error => {
      console.error('Error fetching initial data:', error);
    });
  }, [searchParams]);

  const loadEmissionPlanToUpdate = (id) => {
    getEmissionPlanById(id).then(emissionPlan => {
      setExistingEmissionPlan(emissionPlan);
      setFilteredPlants(plants.filter(plant => plant.facilityId === emissionPlan.facilityId));
      setValue('facilityId', emissionPlan.facilityId);
      setValue('planDate', new Date(emissionPlan.planDate).toISOString().slice(0, 7)); // YYYY-MM format
      setValue('units', emissionPlan.units);
      setValue('planMonthlyEmission', emissionPlan.planMonthlyEmission);
      setValue('status', emissionPlan.status);
      setPlantypeData(emissionPlan.planType);
      fetchComponentIDs(emissionPlan.compType);
    }).catch(error => {
      console.error('Error loading emission plan:', error);
    });
  };

  const fetchComponentIDs = (compType) => {
    getCompositionDetailForCompositionType(compType).then(data => {
      setComponentIDs(data);
    });
  };

  // Update plantId and componentID values once the state has been set
  useEffect(() => {
    if (existingEmissionPlan && plants.length > 0) {
      setFilteredPlants(plants.filter(plant => plant.facilityId === existingEmissionPlan.facilityId));
      setValue('plantId', existingEmissionPlan.plantId);
    }
  }, [plants, existingEmissionPlan, setValue]);

  useEffect(() => {
    if (existingEmissionPlan && componentIDs.length > 0) {
      setValue('componentID', existingEmissionPlan.componentID);
    }
  }, [componentIDs, existingEmissionPlan, setValue]);

  const onFacilityChange = (facilityId) => {
    setFilteredPlants(plants.filter(plant => plant.facilityId === facilityId));
  };

  // Watch for changes in componentID and update plantypeData
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'componentID') {
        const selectedComponent = componentIDs.find(component => component.componentId === value.componentID);
        if (selectedComponent) {
          setPlantypeData(selectedComponent.eqptCode);
          setValue('planType', selectedComponent.eqptCode);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [componentIDs, setValue, watch]);

  const onSubmit = (data) => {
    const emissionPlan = {
      ...data,
      id: isEdit ? existingEmissionPlan.id : undefined,
    };

    saveEmissionPlan(emissionPlan, isEdit).then(res => {
      if (res.isSuccess) {
        toast.success(`Emission Plan ${isEdit ? 'updated' : 'saved'}  successfully`);
        setTimeout(() => {
          navigate('/admin/configurations/system-plan/list-emission-plan');
        }, 1000);
      } else {
        toast.error('Error saving Emission Plan: ' + res.message);
      }
    }).catch(() => {
      toast.error('Error saving Emission Plan');
    });
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Emission Plan' : 'Add Emission Plan'}</h1>
        <ul>
          <li>Operational Data</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-header">Emission Plan Info</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="facilityId">Facility</label>
                <select
                  id="facilityId"
                  className="form-control"
                  {...register('facilityId', { required: true })}
                  onChange={(e) => onFacilityChange(e.target.value)}
                >
                  <option value="">Select Facility</option>
                  {facilities.map(facility => (
                    <option key={facility.facilityId} value={facility.facilityId}>
                      {facility.facilityName}
                    </option>
                  ))}
                </select>
                {errors.facilityId && <div className="invalid-feedback d-block">Facility is required</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="plantId">Plant</label>
                <select
                  id="plantId"
                  className="form-control"
                  {...register('plantId', { required: true })}
                >
                  <option value="">Select Plant</option>
                  {filteredPlants.map(plant => (
                    <option key={plant.plantId} value={plant.plantId}>
                      {plant.plantName}
                    </option>
                  ))}
                </select>
                {errors.plantId && <div className="invalid-feedback d-block">Plant is required</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="planDate">Plan Date</label>
                <input
                  type="month"
                  id="planDate"
                  className="form-control"
                  {...register('planDate', { required: true })}
                />
                {errors.planDate && <div className="invalid-feedback d-block">Plan Date is required</div>}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="compType">Comp Type</label>
                <select
                  id="compType"
                  className="form-control"
                  {...register('compType', { required: true })}
                  onChange={(e) => fetchComponentIDs(e.target.value)}
                >
                  <option value="">Select Comp Type</option>
                  {compTypes.map(compType => (
                    <option key={compType} value={compType}>
                      {compType}
                    </option>
                  ))}
                </select>
                {errors.compType && <div className="invalid-feedback d-block">Comp Type is required</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="componentID">Component ID</label>
                <select
                  id="componentID"
                  className="form-control"
                  {...register('componentID', { required: true })}
                >
                  <option value="">Select Component ID</option>
                  {componentIDs.map(component => (
                    <option key={component.componentId} value={component.componentId}>
                      {component.componentId}
                    </option>
                  ))}
                </select>
                {errors.componentID && <div className="invalid-feedback d-block">Component ID is required</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="planType">Plan Type</label>
                <input
                  type="text"
                  id="planType"
                  className="form-control"
                  value={plantypeData}
                  readOnly
                  {...register('planType', { required: true })}
                />
                {errors.planType && <div className="invalid-feedback d-block">Plan Type is required</div>}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="planMonthlyEmission">Plan Monthly Emission</label>
                <input
                  type="number"
                  id="planMonthlyEmission"
                  className="form-control"
                  {...register('planMonthlyEmission')}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="units">Units</label>
                <select
                  id="units"
                  className="form-control"
                  {...register('units')}
                >
                  <option value="">Select Units</option>
                  {uoms.map(uom => (
                    <option key={uom.uomId} value={uom.uomId}>
                      {uom.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  className="form-control"
                  {...register('status', { required: true })}
                >
                  <option value="">Select Status</option>
                  <option value="A">Active</option>
                  <option value="D">Inactive</option>
                </select>
                {errors.status && <div className="invalid-feedback d-block">Status is required</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary float-right">Save</button>
            <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/system-plan/list-emission-plan')}>Cancel</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddEmissionPlan;
