import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { Pie } from 'react-chartjs-2';
import { formatNumber,formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../common/FormatNumber';
import FlaringReasonsExport from '../../common/exports/FlaringReasonsExport';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { Chart, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

// Register necessary Chart.js elements
Chart.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

export default function FlaringReasons() {
  const [facilityData, setFacilityData] = useState([]);
  const [flaringReasonData, setFlaringReasonData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [materialState, setMaterialState] = useState('GAS');
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDatePickerClass, setStartDatePickerClass] = useState('');
  const [endDatePickerClass, setEndDatePickerClass] = useState('');
  const [exportFilteredData, setExportFilteredData] = useState([]);
  

  useEffect(() => {
    fetchFacilityData();
  }, []);

  const fetchFacilityData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/home/facilities`, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setFacilityData(data);
    } catch (error) {
      console.error('Failed to fetch facility data:', error);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleMaterialStateChange = (e) => {
    setMaterialState(e.target.value);
  };

  const handleFlaringReasonButtonClick = () => {
    if (startDate && endDate) {
      setLoading(true);
      const apiUrl = `${API_BASE_URL}/reports/flaring-by-reason?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&materialState=${materialState}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.content && data.content.flaringAnalysis) {
            const analysis = data.content.flaringAnalysis;
            setFlaringReasonData(analysis);
            setFilteredData(analysis);

            const labels = analysis.map((item) => item.reason);
            const percentageData = analysis.map((item) => item.percentage);

            setChartData({
              labels: labels,
              datasets: [
                {
                  data: percentageData,
                  backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(100, 149, 237, 0.2)',  // Color for Total Routine
                    'rgba(255, 165, 0, 0.2)'    // Color for Total Non-Routine
                  ],
                  borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(100, 149, 237, 1)',  // Border color for Total Routine
                    'rgba(255, 165, 0, 1)'    // Border color for Total Non-Routine
                  ],
                  borderWidth: 1,
                  fill: true,
                },
              ],
            });
          } else {
            console.error('Data format is incorrect');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setError('Error fetching data');
          setIsLoading(false);
        });
    } else {
      console.error('Please select valid options.');
    }
  };

  const BoldCell = ({ value, isBold }) => (
    <span style={{ fontWeight: isBold ? 'bold' : 'normal', textAlign:'right', width:'100%' }}>{value}</span>
);

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    let filtered = flaringReasonData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );

    if (filtered.length === 0) {
      filtered = [{
        reasoncategory: '',
        reason: '',
        gasmMscf: '',
        percentage: ''
      }];
    }

    // Calculate totals
    const routineTotal = flaringReasonData.filter(item => item.reasoncategory === 'Routine').reduce((acc, item) => acc + item.gasmMscf, 0);
    const nonRoutineTotal = flaringReasonData.filter(item => item.reasoncategory === 'NonRoutine').reduce((acc, item) => acc + item.gasmMscf, 0);
    const totalFlaring = routineTotal + nonRoutineTotal;

    // Add total rows
    const totalRows = [
      { reasoncategory: '', reason:'',gasmMscf: 'Routine Total,(+)', percentage: routineTotal,},
      { reasoncategory: '', reason:'',gasmMscf: 'Non Routine Total,(+)', percentage: nonRoutineTotal,},
      { reasoncategory: '', reason:'', gasmMscf:'Un Accounted,(+)', percentage: 0,},
      { reasoncategory: '', reason:'', gasmMscf:'Recovery,(-)', percentage: 0,},
      { reasoncategory: '', reason:'', gasmMscf:'Total Flaring', percentage: totalFlaring,}
    ];

    setFilteredData([...filtered, ...totalRows]);

    const exportData = filtered.map(item => ({
      'Category': item.reasoncategory,
      'Reason': item.reason,
      'Flaring': formatWithIndianThousandsSeparatorForExportData(item.gasmMscf ? item.gasmMscf.toFixed(0) : item.gasmMscf),
      'Percentage': formatWithIndianThousandsSeparatorForExportData(item.percentage ? item.percentage.toFixed(2) : item.percentage),
    }));
    setExportFilteredData([...exportData, ...totalRows]);

    
  }, [search, flaringReasonData]);

  const columns = [
    {
      name: 'Category',
      selector: (row) => row.reasoncategory,
      sortable: true,
    },
    {
      name: 'Reason',
      selector: (row) => row.reason,
      sortable: true,
    },
    {
      name: 'Flaring',
      selector: (row) => row.gasmMscf,
      // cell: row => formatWithIndianThousandsSeparator(row.gasmMscf),
      cell: row => <BoldCell value={formatWithIndianThousandsSeparator(row.gasmMscf && typeof row.gasmMscf === 'number' ? row.gasmMscf.toFixed(0) : row.gasmMscf)} isBold={row.gasmMscf == 'Routine Total,(+)' || 
                                                                                              row.gasmMscf == 'Non Routine Total,(+)' ||
                                                                                              row.gasmMscf == 'Un Accounted,(+)' ||
                                                                                              row.gasmMscf == 'Recovery,(-)' ||
                                                                                              row.gasmMscf == 'Total Flaring' } />,
      sortable: true,
    },
    {
      name: 'Percentage',
      selector: (row) => row.percentage,
      // cell: row => formatWithIndianThousandsSeparator(row.percentage),
      cell: row => <BoldCell value={formatWithIndianThousandsSeparator(row.percentage ? row.percentage.toFixed(2) : row.percentage)} isBold={row.gasmMscf == 'Routine Total,(+)' || 
                                                                                        row.gasmMscf == 'Non Routine Total,(+)' ||
                                                                                        row.gasmMscf == 'Un Accounted,(+)' ||
                                                                                        row.gasmMscf == 'Recovery,(-)' ||
                                                                                        row.gasmMscf == 'Total Flaring' } />,
      sortable: true,
    },
  ];


  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `FMS_Flaring_Reasons${new Date().toLocaleDateString()}`;

  const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
  const handleStartDateBlur = () => setStartDatePickerClass('');
  const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
  const handleEndDateBlur = () => setEndDatePickerClass('');

  const headerData = [
    { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
    { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
    { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

  const title = "Flaring Reasons Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Flaring Reasons</h1>
        <ul>
          <li>Reports</li>
          <li>Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className="row">
          <div className='col-md-2'>
            <label>Select Facility</label>
            <select
              name='facility'
              className='form-control'
              value={selectedFacilityId}
              onChange={handleFacilityChange}
            >
              <option value={-1}>All Facilities</option>
              {facilityData.map((facility) => (
                <option key={facility.facilityId} value={facility.facilityId}>
                  {facility.facilityName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <label>Select Material State</label>
            <select
              name="materialState"
              className="form-control"
              value={materialState}
              onChange={handleMaterialStateChange}
            >
              <option value="GAS">Gas</option>
              <option value="LIQUID">Liquid</option>
            </select>
          </div>
          <div className="col-md-2">
            <label>Start Date</label>
            <div className={startDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleStartDateFocus}
                onBlur={handleStartDateBlur}
              />
            </div>
          </div>
          <div className="col-md-2">
            <label>End Date</label>
            <div className={endDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleEndDateFocus}
                onBlur={handleEndDateBlur}
              />
            </div>
          </div>
          <div className="col-md-3 generate_report_button">
            <button className="btn btn btn-primary" onClick={handleFlaringReasonButtonClick}>
              {BUTTON_LABELS.REPORT_BUTTON}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              <div className="row">
                <div className="col-md-10">
                  {exportFilteredData.length > 0 && (
                    <FlaringReasonsExport data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title} />
                  )}
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="table-responsive">
                    {isLoading && <div>Loading...</div>}
                    {!isLoading && (
                      <>
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          customStyles={customStyles}
                          pagination={false}
                          highlightOnHover
                          striped
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  {!loading && !error && Object.keys(chartData).length > 0 && (
                    <div>
                      <Pie data={chartData} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
