import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddEquipment from './AddEquipment'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import CoordinateFormatter from '../../../../common/CoordinateFormatter';

export default function EquipmentList() {
    const [equipmentData, setEquipmentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedequipmentId, setSelectedequipmentId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchEquipmentData();
    }, []);

    const fetchEquipmentData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/equipment/all`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setEquipmentData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Device data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/equipment/add-equipment-data?equipmentId=${id}`);
    };

    const handleDelete = async (equipmentId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Equipment: ${equipmentId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/equipment/delete/${equipmentId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchEquipmentData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = equipmentData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const formatCoordinates = (value) => {
            if (!value) return '';
        
            const degrees = Math.floor(value);
            const minutes = Math.floor((value - degrees) * 60);
            const seconds = ((value - degrees - minutes / 60) * 3600).toFixed(2);
        
            return `${degrees}°${minutes}'${seconds}"`;
        };

        const exportData = filtered.map(item => ({
            'Equipment ID': item.equipmentId,
            'Equipment Name': item.equipmentName,
            'Equipment Type': item.eqptTypeId,
            'Plant ID': item.plantId,
            'Latitude': formatCoordinates(item.latitude),
            'Longitude': formatCoordinates(item.longitute),
            'Unit Type': item.unitType,
            'Vessel Type': item.vesselType
        }));
        setExportFilteredData(exportData);

    }, [search, equipmentData]);

    const columns = [
        { name: 'Equipment ID', selector: row => row.equipmentId, sortable: true, resizable: true, pinned: 'left' },
        { name: 'Equipment Name', selector: row => row.equipmentName, sortable: true, resizable: true, pinned: 'left' },
        { name: 'Equipment Type', selector: row => row.eqptTypeId, sortable: true, resizable: true },
        { name: 'Plant ID', selector: row => row.plantId, sortable: true, resizable: true },
        {
            name: 'Latitude',
            selector: row => row.latitude,
            cell: row => <CoordinateFormatter value={row.latitude} />,
            sortable: true,
            resizable: true
        },
        {
            name: 'Longitude',
            selector: row => row.longitute,
            cell: row => <CoordinateFormatter value={row.longitute} />,
            sortable: true,
            resizable: true
        },
        { name: 'Unit Type', selector: row => row.unitType, sortable: true, resizable: true },
        { name: 'Vessel Type', selector: row => row.vesselType, sortable: true, resizable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.id)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.id)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Equipment_${new Date().toLocaleDateString()}`;

    const title = "Equipment Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>List of Equipment</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Equipment</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Equipment</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/equipment/add-equipment-data" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}




