// src/contexts/AuthContext.js
import React, { useContext, useState, useEffect } from 'react';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const user = sessionStorage.getItem('userName');
        if (user) {
            setCurrentUser({ userName: user });
        }
    }, []);

    const login = (userName, password) => {
        return new Promise((resolve, reject) => {
            if (userName === 'IP_ADMIN' && password === 'IP_ADMIN') {
                sessionStorage.setItem('userName', userName);
                setCurrentUser({ userName });
                resolve();
            } else {
                reject('Invalid credentials');
            }
        });
    };

    const logout = () => {
        sessionStorage.removeItem('userName');
        setCurrentUser(null);
    };

    const value = {
        currentUser,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}
