import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../../config';

export default function AddSubscriberConfiguration() {
  const [form, setForm] = useState({
    subscriberID: '',
    notificationID: '',
    status: ''
  });
  const [userConfig, setUserConfig] = useState([]);
  const [notificationRulesConfig, setNotificationRulesConfig] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming you are passing 'id' as a route parameter to identify edit mode
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      fetchSubscriberConfigById(id);
    }
    fetchUserConfig();
    fetchNotificationRules();
  }, [id]);

  const fetchUserConfig = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/configuration/data/notificationuser/all`, {
        method: 'GET',
        // headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
      const data = await response.json();
      setUserConfig(data);
    } catch (error) {
      console.error('Failed to fetch user config data:', error);
    }
  };

  const fetchNotificationRules = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      });
      const data = await response.json();
      setNotificationRulesConfig(data);
    } catch (error) {
      console.error('Failed to fetch notification rules data:', error);
    }
  };

  const fetchSubscriberConfigById = async (id) => {
    // Implement the logic to fetch and populate the form based on the id
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.subscriberID && form.notificationID && form.status) {
      const alert = {
        autoClose: true,
        keepAfterRouteChange: true
      };
      try {
        const url = isEdit 
          ? `${API_BASE_URL}/configuration/data/notificationsubscriber-update`
          : `${API_BASE_URL}/configuration/data/notificationsubscriber-save`;
        const method = isEdit ? 'PUT' : 'POST';
        const response = await fetch(url, {
          method,
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify(form)
        });
        const result = await response.json();
        if (result.isSuccess) {
          toast.success('Subscriber Configuration saved successfully', alert);
          navigate('/admin/configurations/notification/list-subscriber-configuration');
        } else {
          toast.error('Error saving Subscriber Configuration', alert);
        }
      } catch (error) {
        toast.error('Error saving Subscriber Configuration', alert);
      }
    } else {
      toast.error('Please fill out all required fields');
    }
  };

  const handleCancel = () => {
    navigate('/admin/configurations/notification/list-subscriber-configuration');
  };

  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
        <h1>Add Subscriber Configuration</h1>
        <ul>
          <li>System Configuration</li>
          <li>Notification</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              <h4 className="card-title mb-3">{isEdit ? 'Edit Subscriber Configuration' : 'Add Subscriber Configuration'}</h4>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="subscriberID">Subscriber ID</label>
                      <select className="form-control" id="subscriberID" name="subscriberID" value={form.subscriberID} onChange={handleChange} required>
                        <option value="">Select Subscriber ID</option>
                        {userConfig.map(user => (
                          <option key={user.subscriberID} value={user.subscriberID}>
                            {user.subscriberID}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="notificationID">Notification ID</label>
                      <select className="form-control" id="notificationID" name="notificationID" value={form.notificationID} onChange={handleChange} required>
                        <option value="">Select Notification ID</option>
                        {notificationRulesConfig.map(rule => (
                          <option key={rule.notificationID} value={rule.notificationID}>
                            {rule.notificationID}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select className="form-control" id="status" name="status" value={form.status} onChange={handleChange} required>
                        <option value="">Select Status</option>
                        <option value="A">A</option>
                        <option value="D">D</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <button type="submit" className="btn btn-primary float-right">Save</button>
                    <button type="button" className="btn btn-secondary float-right mr-3" onClick={handleCancel}>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
