import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import fmsDashboardService from '../services/fmsDashboardService';
import './DailyFlaring.css'; // Ensure the CSS file is imported for styling

const DailyFlaring = ({ selectedDate, fmsDashboardFilter, appSession }) => {
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const chartRef = useRef(null);

  const fetchDataAndSetupChart = useCallback(async (forceUpdate) => {
    if (!selectedDate || !fmsDashboardFilter) {
      return;
    }

    setShowChart(false);

    if (forceUpdate) {
      try {
        const response = await fmsDashboardService.getDailyFlarings(selectedDate, fmsDashboardFilter.type, fmsDashboardFilter.facilityId);
        const companyWideFlares = response.data;
        console.log(companyWideFlares);
        initializeGraphOptions();
        loadData(companyWideFlares);
        if (companyWideFlares && companyWideFlares.length !== 0) {
          setShowNoDataText(false);
        } else {
          setShowNoDataText(true);
        }
        setShowChart(true);
      } catch (error) {
        setShowNoDataText(true);
        setShowChart(true);
      }
    } else {
      initializeGraphOptions();
      loadData();
      setShowChart(true);
    }
  }, [selectedDate, fmsDashboardFilter]);

  useEffect(() => {
    fetchDataAndSetupChart(true);
  }, [fetchDataAndSetupChart]);

  useEffect(() => {
    if (chartRef.current) {
      fetchDataAndSetupChart(false);
    }
  }, [chartRef, fetchDataAndSetupChart]);

  const initializeGraphOptions = () => {
    setLineChartData([
      {
        label: 'Actual',
        fill: false,
        lineTension: 0.4,
        backgroundColor: '#870a30',
        borderColor: '#870a30',
        pointBackgroundColor: '#870a30',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#870a30',
        pointRadius: 8, // Increase the point radius
        pointHoverRadius: 10, // Increase the point hover radius
        data: [],
      }
    ]);

    setLineChartLabels([]);
  };

  const loadData = (companyWideFlares) => {
    if (companyWideFlares) {
      const data = companyWideFlares.map(x => x.actualFlaring.toFixed(2));
      const labels = companyWideFlares.map(x => moment(x.onDate).format('DD'));
      setLineChartData(prevData => {
        const newData = [...prevData];
        newData[0].data = data;
        return newData;
      });
      setLineChartLabels(labels);
    }
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        labels: {
          fontColor: '#FF6384' // Custom font color for legend
        }
      }
    },
    scales: {
      x: {
        ticks: {
          fontColor: '#000000', // Custom font color for x-axis ticks
          display: true,
          autoSkip: true,
          maxTicksLimit: 6,
          maxRotation: 0
        },
        grid: {
          display: true,
          color: '#CCCCCC'
        }
      },
      y: {
        ticks: {
          fontColor: '#000000', // Custom font color for y-axis ticks
          beginAtZero: true,
          callback: function(value) {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(0) + 'B'; // Show in Billions
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(0) + 'M'; // Show in Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(0) + 'K'; // Show in Thousands
            } else {
              return value; // Show as is
            }
          }          
        },
        grid: {
          display: true,
          color: '#CCCCCC'
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      intersect: true
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.raw} ${appSession.gasUnits}`;
          }
        }
      },
      datalabels: {
        display: true,
        color: '#FF6384'
      }
    }
  };
  
  return (
    <div className="daily-flaring">
      {showChart && !showNoDataText ? (
        <Line
          ref={chartRef}
          data={{
            datasets: lineChartData,
            labels: lineChartLabels
          }}
          options={chartOptions}
          height={300}
        />
      ) : (
        <div className="no-data-txt">
          <h5 className="mt-5"> No Data Found</h5>
        </div>
      )}
    </div>
  );
};

export default DailyFlaring;
