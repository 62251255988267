import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../common/FormatNumber';
import PlantFlaringAnalysisExport from '../../../common/exports/PlantFlaringAnalysisExport';
import ExportComponent from '../../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../../constants';
import { Pie } from 'react-chartjs-2';
import './PlantFlaringAnalysis.css';
import { format } from 'date-fns';

export default function PlantFlaringAnalysis() {
    const [facilityData, setFacilityData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
    const [materialState, setMaterialState] = useState("GAS");
    const [flaringPlantData, setFlaringPlantData] = useState([]);
    const [flaringPlantChartData, setFlaringPlantChartData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDatePickerClass, setStartDatePickerClass] = useState('');
    const [endDatePickerClass, setEndDatePickerClass] = useState('');
    const [search, setSearch] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]); // Add this state

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFacilityChange = (e) => {
        setSelectedFacilityId(e.target.value);
    };

    const handleMaterialStateChange = (e) => {
        setMaterialState(e.target.value);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchFacilityData();
    }, []);

    const fetchFacilityData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilityData(data);
        } catch (error) {
            console.error('Failed to fetch tracking data:', error);
        }
    };

    const handleFlaringPlantButtonClick = () => {
        if (startDate && endDate && selectedFacilityId !== '') {
            const apiUrl = `${API_BASE_URL}/reports/flaring-by-plant?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&materialState=${materialState}`;

            setIsLoading(true);

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    const reportData = data.content;
                    setFlaringPlantChartData(data.content);
                    const totalRows = calculateTotalRows(reportData);
                    setFlaringPlantData([...reportData, ...totalRows]);
                    setFilteredData([...reportData, ...totalRows]); // Initialize filtered data
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                });
        } else {
            console.error('Please select valid options.');
        }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = flaringPlantData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );

        if (filtered.length === 0) {
            filtered.push({
                plantId: '',
                plantName: '',
                gas: '',
                percentage: '',
                mm: ''
            });
        }

        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Plant ID': item.plantId,
            'Plant Name': item.plantName,
            'Flaring': formatWithIndianThousandsSeparatorForExportData(item.gas ? item.gas.toFixed(0) : item.gas),
            'Percentage': formatWithIndianThousandsSeparatorForExportData(item.percentage),
            'MM $': formatWithIndianThousandsSeparatorForExportData(item.mm && typeof item.mm === 'number' ? item.mm.toFixed(0) : item.mm)
        }));

        setExportFilteredData(exportData);


    }, [search, flaringPlantData]);

    const calculateTotalRows = (data) => {
        let totalFlaring = 0;
        let totalMM = 0;

        data.forEach(row => {
            totalFlaring += row.gas ? parseFloat(row.gas) : 0;
            totalMM += row.mm ? parseFloat(row.mm) : 0;
        });

        const totalRowGas = {
            plantId: '',
            plantName: '',
            gas: '',
            percentage: 'Total Flaring',
            mm: totalFlaring.toFixed(0)
        };

        const totalRowMM = {
            plantId: '',
            plantName: '',
            gas: '',
            percentage: 'MM $',
            mm: totalMM.toFixed(0)
        };

        return [totalRowGas, totalRowMM];
    };

    const BoldCell = ({ value, isBold }) => (
        <span style={{ fontWeight: isBold ? 'bold' : 'normal', textAlign: 'right', width: '100%', color: isBold ? '#000' : '' }}>{value}</span>
    );

    const columns = [
        {
            name: 'Plant ID',
            selector: row => row.plantId,
            sortable: true,
            resizable: true
        },
        {
            name: 'Plant Name',
            selector: row => row.plantName,
            sortable: true,
            resizable: true,
        },
        {
            name: 'Flaring',
            selector: row => row.gas,
            cell: row => formatWithIndianThousandsSeparator(row.gas ? row.gas.toFixed(0) : row.gas),
            sortable: true,
            resizable: true
        },
        {
            name: 'Percentage',
            selector: row => row.percentage,
            cell: row => <BoldCell value={formatWithIndianThousandsSeparator(row.percentage)} isBold={row.percentage === 'Total Flaring' || row.percentage === 'MM $'} />,
            sortable: true,
            resizable: true
        },
        {
            name: 'MM $',
            selector: row => row.mm,
            // cell: row => formatWithIndianThousandsSeparator(row.mm),
            cell: row => (row.percentage === 'Total Flaring' || row.percentage === 'MM $') ? <BoldCell value={formatWithIndianThousandsSeparator(row.mm)} isBold={row.percentage === 'Total Flaring' || row.percentage === 'MM $'} /> : formatWithIndianThousandsSeparator(row.mm ? row.mm.toFixed(0) : row.mm),
            sortable: true,
            resizable: true
        }
    ];

    const pieChartData = {
        labels: flaringPlantChartData.map(data => data.plantName),
        datasets: [
            {
                data: flaringPlantChartData.map(data => data.percentage),
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40'
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40'
                ]
            }
        ]
    };



    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `FMS_Plant_Flaring_Analysis_${new Date().toLocaleDateString()}`;

    const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
    const handleStartDateBlur = () => setStartDatePickerClass('');
    const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
    const handleEndDateBlur = () => setEndDatePickerClass('');

    const headerData = [
        { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
        { label: 'Material State', value: materialState || 'Gas' },
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];

    const title = "Plant Flaring Analysis Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Plant Flaring Analysis</h1>
                <ul>
                    <li>Reports</li>
                    <li>Flaring</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Select Facility</label>
                        <select
                            name='facility'
                            className='form-control'
                            // style={{ width: '20vw' }}
                            value={selectedFacilityId}
                            onChange={handleFacilityChange}
                        >
                            <option value={-1}>All Facilities</option>
                            {facilityData.map((facility) => (
                                <option key={facility.facilityId} value={facility.facilityId}>
                                    {facility.facilityName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='col-md-2'>
                        <label>Select Material State</label>
                        <select
                            name='materialState'
                            className='form-control'
                            // style={{ width: '20vw' }}
                            value={materialState}
                            onChange={handleMaterialStateChange}
                        >
                            <option value={'GAS'}>Gas</option>
                            <option value={'LIQUID'}>Liquid</option>
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label>Start Date</label>
                        <div className={startDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleStartDateFocus}
                                onBlur={handleStartDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <label>End Date</label>
                        <div className={endDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleEndDateFocus}
                                onBlur={handleEndDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-2 generate_report_button'>
                        <button className='btn btn-primary' onClick={handleFlaringPlantButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>
                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-8 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-md-10'>
                                    {exportFilteredData.length > 0 && (
                                        <PlantFlaringAnalysisExport data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        customStyles={customStyles}
                                        pagination={false}
                                        // paginationPerPage={filteredData.length}  // Set this to the length of your data to show all records
                                        // paginationRowsPerPageOptions={[10, 20, 30, filteredData.length]}  // Add the option to show all records
                                        highlightOnHover
                                        striped
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <Pie data={pieChartData} />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
