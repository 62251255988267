import axios from 'axios';
import { API_BASE_URL } from '../../../../config';

class TransactionService {
  getManualValves(fromDate) {
    return axios.get(`${API_BASE_URL}/dashboard/manualvalves/manualvalves-daily-tx-date`, { withCredentials: true });
  }

  getFaclityDetails(facilitycategory) {
    return axios.get(`${API_BASE_URL}/dashboard/shutdownSchdule/facility?facilitycategory=${facilitycategory}`, { withCredentials: true });
  }

  getDeviceDetailsForManualValves(facilityId, dataCollectionMode) {
    return axios.get(`${API_BASE_URL}/dashboard/manualvalves/device?facilityId=${facilityId}&dataCollectionMode=${dataCollectionMode}`, { withCredentials: true });
  }

  addManualValvesData(data) {
    return axios.post(`${API_BASE_URL}/dashboard/manualvalves/manualvalves-hourly-tx-date`, data, { withCredentials: true });
  }

  deleteManualValvesItem(id) {
    return axios.get(`${API_BASE_URL}/dashboard/manualvalves/delete?Id=${id}`, { withCredentials: true });
  }
}

export default new TransactionService();
