import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getAllFacilities,
  getAllPlants,
  getAllUOMs,
  getAllCodeMasters,
  filterPlanTypeFromAllCodeMasters,
  filterMaterialStateFromAllCodeMasters,
} from '../services/systemPlanService';
import { getFlaringPlanById, saveFlaringPlan } from '../services/flaringPlanService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddFlaringPlan = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [plants, setPlants] = useState([]);
  const [filteredPlants, setFilteredPlants] = useState([]);
  const [uoms, setUoms] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [materialStates, setMaterialStates] = useState([]);
  const [existingFlaringPlan, setExistingFlaringPlan] = useState(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setIsEdit(true);
    }

    Promise.all([
      getAllFacilities(),
      getAllPlants(),
      getAllUOMs(),
      getAllCodeMasters(),
    ]).then(([facilities, plants, uoms, codeMasters]) => {
      setFacilities(facilities);
      setPlants(plants);
      setUoms(uoms);
      setPlanTypes(filterPlanTypeFromAllCodeMasters(codeMasters));
      setMaterialStates(filterMaterialStateFromAllCodeMasters(codeMasters));

      if (isEdit && id) {
        loadFlaringPlanToUpdate(id);
      }
    }).catch(error => {
      console.error('Error fetching initial data:', error);
    });
  }, [isEdit, searchParams]);

  const loadFlaringPlanToUpdate = (id) => {
    getFlaringPlanById(id).then(flaringPlan => {
      setExistingFlaringPlan(flaringPlan);
      setFilteredPlants(plants.filter(plant => plant.facilityId === flaringPlan.facilityId));
      setValue('facilityId', flaringPlan.facilityId);
      setValue('plantId', flaringPlan.plantId.trim());
      setValue('planType', flaringPlan.planType);
      setValue('planDate', new Date(flaringPlan.planDate).toISOString().slice(0, 7)); // YYYY-MM format
      setValue('materialState', flaringPlan.materialState);
      setValue('units', flaringPlan.units);
      setValue('planMonthlyFlaring', flaringPlan.planMonthlyFlaring);
      setValue('status', flaringPlan.status);
    }).catch(error => {
      console.error('Error loading flaring plan:', error);
    });
  };

  const onFacilityChange = (facilityId) => {
    setFilteredPlants(plants.filter(plant => plant.facilityId === facilityId));
  };

  const onSubmit = (data) => {
    const flaringPlan = {
      ...data,
      id: isEdit ? existingFlaringPlan.id : undefined,
    };

    saveFlaringPlan(flaringPlan, isEdit).then(res => {
      if (res.isSuccess) {
        toast.success('Flaring Plan saved successfully');
        setTimeout(() => {
          navigate('/admin/configurations/system-plan/list-flaring-plan');
        }, 1000);
      } else {
        toast.error('Error saving Flaring Plan: ' + res.message);
      }
    }).catch(() => {
      toast.error('Error saving Flaring Plan');
    });
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Flaring Plan' : 'Add Flaring Plan'}</h1>
        <ul>
          <li>Operational Data</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-header">Flaring Plan Info</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="facilityId">Facility</label>
                <select
                  id="facilityId"
                  className="form-control"
                  {...register('facilityId', { required: true })}
                  onChange={(e) => onFacilityChange(e.target.value)}
                >
                  <option value="">Select Facility</option>
                  {facilities.map(facility => (
                    <option key={facility.facilityId} value={facility.facilityId}>
                      {facility.facilityName}
                    </option>
                  ))}
                </select>
                {errors.facilityId && <div className="invalid-feedback d-block">Facility is required</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="plantId">Plant</label>
                <select
                  id="plantId"
                  className="form-control"
                  {...register('plantId', { required: true })}
                >
                  <option value="">Select Plant</option>
                  {filteredPlants.map(plant => (
                    <option key={plant.plantId} value={plant.plantId}>
                      {plant.plantName}
                    </option>
                  ))}
                </select>
                {errors.plantId && <div className="invalid-feedback d-block">Plant is required</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="planDate">Plan Date</label>
                <input
                  type="month"
                  id="planDate"
                  className="form-control"
                  {...register('planDate', { required: true })}
                />
                {errors.planDate && <div className="invalid-feedback d-block">Plan Date is required</div>}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="planType">Plan Type</label>
                <select
                  id="planType"
                  className="form-control"
                  {...register('planType', { required: true })}
                >
                  <option value="">Select Plan Type</option>
                  {planTypes.map(plan => (
                    <option key={plan.code} value={plan.code}>
                      {plan.description}
                    </option>
                  ))}
                </select>
                {errors.planType && <div className="invalid-feedback d-block">Plan Type is required</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="materialState">Material State</label>
                <select
                  id="materialState"
                  className="form-control"
                  {...register('materialState')}
                >
                  <option value="">Select Material State</option>
                  {materialStates.map(material => (
                    <option key={material.code} value={material.code}>
                      {material.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="units">Units</label>
                <select
                  id="units"
                  className="form-control"
                  {...register('units')}
                >
                  <option value="">Select Units</option>
                  {uoms.map(uom => (
                    <option key={uom.uomId} value={uom.uomId}>
                      {uom.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="planMonthlyFlaring">Plan Monthly Flaring</label>
                <input
                  type="number"
                  id="planMonthlyFlaring"
                  className="form-control"
                  {...register('planMonthlyFlaring')}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  className="form-control"
                  {...register('status', { required: true })}
                >
                  <option value="">Select Status</option>
                  <option value="A">Active</option>
                  <option value="D">Inactive</option>
                </select>
                {errors.status && <div className="invalid-feedback d-block">Status is required</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary float-right">Save</button>
            <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/system-plan/list-flaring-plan')}>Cancel</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddFlaringPlan;
