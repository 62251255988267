import React, { useState, useEffect } from 'react';
import TransactionService from '../services/TransactionService';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap';
import AddManualValveForm from './AddManualValveForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ExportComponent from '../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../common/FormatNumber';
import formatDateTime from '../../../common/formatDateTime';
import './ManualValvesList.css';

export default function ManualValvesList() {
  const [manualValvesItems, setManualValvesItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [exportFilteredData, setExportFilteredData] = useState([]);

  useEffect(() => {
    fetchManualValvesData();
  }, []);

  const fetchManualValvesData = async () => {
    setIsLoading(true);
    try {
      const response = await TransactionService.getManualValves('');
      setManualValvesItems(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Failed to fetch manual valves data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete schedule: ${id}?`);
    if (confirmDelete) {
      try {
        await TransactionService.deleteManualValvesItem(id);
        toast.success('Successfully removed!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchManualValvesData();
      } catch (error) {
        console.error('Failed to delete manual valve item:', error);
      }
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = manualValvesItems.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Facility ID': item.siteId,
      'Tx DateTime':  formatDateTime(item.transactionDateTime),
      'Source ID': item.deviceId,
      'Valve Opened On': formatDateTime(item.valveOpenedOn),
      'Valve Closed On': formatDateTime(item.valveClosedOn),
      'Percentage': formatWithIndianThousandsSeparatorForExportData(item.valveOpenPercentage ? item.valveOpenPercentage.toFixed(2) : item.valveOpenPercentage),
      'Reason ID': item.reasonId,
      'Comments': item.userComments,
      'Transaction Key': formatWithIndianThousandsSeparatorForExportData(item.transactionKey ? item.transactionKey.toFixed(0) : item.transactionKey),
      'Locked On':  formatDateTime(item.lockedOn),
      'Status': item.status
    }));
    setExportFilteredData(exportData);

  }, [search, manualValvesItems]);

  const columns = [
    { name: 'Facility ID', selector: row => row.siteId, sortable: true, resizable: true },
    { name: 'Tx DateTime', selector: row => new Date(row.transactionDateTime).toLocaleString(), sortable: true, resizable: true,
      cell: row => formatDateTime(row.transactionDateTime)
     },
    { name: 'Source ID', selector: row => row.deviceId, sortable: true, resizable: true },
    { name: 'Valve Opened On', selector: row => new Date(row.valveOpenedOn).toLocaleString(), sortable: true, resizable: true,
      cell: row => formatDateTime(row.valveOpenedOn)
     },
    { name: 'Valve Closed On', selector: row => new Date(row.valveClosedOn).toLocaleString(), sortable: true, resizable: true,
      cell: row => formatDateTime(row.valveClosedOn)
     },
    {
      name: 'Percentage',
      selector: row => row.valveOpenPercentage,
      cell: row => formatWithIndianThousandsSeparator(row.valveOpenPercentage ? row.valveOpenPercentage.toFixed(2) : row.valveOpenPercentage),
      sortable: true,
      resizable: true
    },
    { name: 'Reason ID', selector: row => row.reasonId, sortable: true, resizable: true },
    { name: 'Comments', selector: row => row.userComments, sortable: true, resizable: true },
    {
      name: 'Transaction Key',
      selector: row => row.transactionKey,
      cell: row => formatWithIndianThousandsSeparator(row.transactionKey ? row.transactionKey.toFixed(0) : row.transactionKey),
      sortable: true,
      resizable: true
    },
    { name: 'Locked On', selector: row => new Date(row.lockedOn).toLocaleString(), sortable: true, resizable: true ,
      cell: row => formatDateTime(row.lockedOn)
    },
    { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
    {
      name: 'Delete',
      width: '75px',
      cell: row => (
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => handleDelete(row.transactionKey)}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      )
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Manual_Valves_${new Date().toLocaleDateString()}`;

  const title = 'Manual Valves';

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Manual Valves</h1>
        <ul>
          <li>Operational Data</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">Manual Valves</h4> */}
              <div className='row pull-right'>
                <div className='col-md-10'>
                  <Button onClick={() => setShowModal(true)} className="btn btn-primary">
                    Add New Record
                  </Button>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-10'>
                  {filteredData.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Manual Valve</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddManualValveForm
            setShowModal={setShowModal}
            fetchManualValvesData={fetchManualValvesData}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
