import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { API_BASE_URL } from '../../../../config';

const AddProjectMaster = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [formGroup, setFormGroup] = useState({
        projectID: '',
        projectName: '',
        projectStartDate: '',
        projectDesc: '',
        facilityID: '',
        projectCategoryID: '',
        projectTypeID: '',
        projectStatusID: '',
        type: '',
        compType: '',
        componentID: '',
        pollutant: '',
        projectCost: '',
        reductionExpected: '',
        units: '',
        projectGovtFundedYN: '',
    });
    const [errors, setErrors] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [facilityData, setFacilityData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [projectTypeData, setProjectTypeData] = useState([]);
    const [projectStatusData, setProjectStatusData] = useState([]);
    const [compTypeData, setCompTypeData] = useState([]);
    const [pollutantData, setPollutantData] = useState([]);
    const [uomData, setUomData] = useState([]);
    const [componentData, setComponentData] = useState([]);

    useEffect(() => {
        fetchInitialData();
        if (projectId) {
            console.log("id gett", projectId)
            setIsEditMode(true);
            fetchProjectMasterData(projectId);
        }
    }, [projectId]);

    const fetchInitialData = async () => {
        try {
            const [facilityResponse, categoryResponse, typeResponse, statusResponse, compTypeResponse, pollutantResponse, uomResponse] = await Promise.all([
                fetch(`${API_BASE_URL}/home/facilities`),
                fetch(`${API_BASE_URL}/projectcategory/all`),
                fetch(`${API_BASE_URL}/project/project-types`),
                fetch(`${API_BASE_URL}/project/project-statuses`),
                fetch(`${API_BASE_URL}/master/comptyes`),
                fetch(`${API_BASE_URL}/master/pollutants`),
                fetch(`${API_BASE_URL}/device/uoms`)
            ]);

            if (!facilityResponse.ok || !categoryResponse.ok || !typeResponse.ok || !statusResponse.ok || !compTypeResponse.ok || !pollutantResponse.ok || !uomResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const [facilityData, categoryData, typeData, statusData, compTypeData, pollutantData, uomData] = await Promise.all([
                facilityResponse.json(),
                categoryResponse.json(),
                typeResponse.json(),
                statusResponse.json(),
                compTypeResponse.json(),
                pollutantResponse.json(),
                uomResponse.json()
            ]);

            setFacilityData(facilityData);
            setCategoryData(categoryData);
            setProjectTypeData(typeData);
            setProjectStatusData(statusData);
            setCompTypeData(compTypeData);
            setPollutantData(pollutantData);
            setUomData(uomData);
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    };

    const fetchProjectMasterData = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/project/project-master/${id}`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFormGroup({
                ...data,
                projectStartDate: new Date(data.projectStartDate)
            });
        } catch (error) {
            console.error('Failed to fetch project master data:', error);
        }
    };

    const fetchComponentData = async (compType) => {
        try {
            const response = await fetch(`${API_BASE_URL}/composition/physical-properties/components-all`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setComponentData(data.filter(t => t.compType === compType));
        } catch (error) {
            console.error('Failed to fetch component data:', error);
        }
    };

    useEffect(() => {
        if (formGroup.compType) {
            fetchComponentData(formGroup.compType);
        }
    }, [formGroup.compType]);

    const validate = () => {
        const newErrors = {};
        if (!formGroup.projectID) newErrors.projectID = 'Project ID is required.';
        if (!formGroup.projectName) newErrors.projectName = 'Project Name is required.';
        if (!formGroup.projectStartDate) newErrors.projectStartDate = 'Project Start Date is required.';
        if (!formGroup.projectDesc) newErrors.projectDesc = 'Project Description is required.';
        if (!formGroup.facilityID) newErrors.facilityID = 'Facility is required.';
        if (!formGroup.projectCategoryID) newErrors.projectCategoryID = 'Project Category is required.';
        if (!formGroup.projectTypeID) newErrors.projectTypeID = 'Project Type is required.';
        if (!formGroup.projectStatusID) newErrors.projectStatusID = 'Project Status is required.';
        if (!formGroup.type) newErrors.type = 'Type is required.';
        if (!formGroup.compType) newErrors.compType = 'CompType is required.';
        if (!formGroup.componentID) newErrors.componentID = 'Component ID is required.';
        if (!formGroup.pollutant) newErrors.pollutant = 'Pollutant is required.';
        if (!formGroup.projectCost) newErrors.projectCost = 'Project Cost is required.';
        if (!formGroup.reductionExpected) newErrors.reductionExpected = 'Reduction Expected is required.';
        if (!formGroup.units) newErrors.units = 'Units are required.';
        if (!formGroup.projectGovtFundedYN) newErrors.projectGovtFundedYN = 'Govt Funded Y/N is required.';

        // Add length validation based on the database schema
        if (formGroup.projectID.length > 50) newErrors.projectID = 'Project ID must be less than 50 characters.';
        if (formGroup.projectName.length > 100) newErrors.projectName = 'Project Name must be less than 100 characters.';
        if (formGroup.projectDesc.length > 500) newErrors.projectDesc = 'Project Description must be less than 500 characters.';
        // Add more length validations as per your database schema

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormGroup(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }

        const apiEndpoint = isEditMode ? `${API_BASE_URL}/project/project-update` : `${API_BASE_URL}/project/project-save`;
        const method = isEditMode ? 'POST' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(formGroup)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`Project Master ${isEditMode ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                navigate('/admin/project/list-project-master');
            }, 1000);
        } catch (error) {
            console.error(`Failed to ${isEditMode ? 'update' : 'add'} project master:`, error);
            toast.error(`Failed to ${isEditMode ? 'update' : 'add'} project master.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className="main-content">
            <div className='breadcrumb mt-2'>
                <h1>{isEditMode ? 'Edit Project Master' : 'Add Project Master'}</h1>
                <ul>
                    <li>Projects</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">{isEditMode ? 'Edit Project Master' : 'Add Project Master'}</h4> */}
                            <hr></hr>
                            <form onSubmit={handleSubmit} noValidate>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Project ID</label>
                                            <input
                                                type="text"
                                                name="projectID"
                                                className={`form-control ${errors.projectID ? 'is-invalid' : ''}`}
                                                value={formGroup.projectID}
                                                onChange={handleChange}
                                                required
                                                readOnly={isEditMode}
                                            />
                                            {errors.projectID && <div className="invalid-feedback">{errors.projectID}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Project Name</label>
                                            <input
                                                type="text"
                                                name="projectName"
                                                className={`form-control ${errors.projectName ? 'is-invalid' : ''}`}
                                                value={formGroup.projectName}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.projectName && <div className="invalid-feedback">{errors.projectName}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Project Start Date</label>
                                            <div className="input-group date-picker-range">
                                                <div className="datepicker-parent">
                                                    <DatePicker
                                                        selected={formGroup.projectStartDate}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        onChange={(date) => handleChange({ target: { name: 'projectStartDate', value: date } })}
                                                        className={`form-control ${errors.projectStartDate ? 'is-invalid' : ''}`}
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                </div>
                                            </div>
                                            {errors.projectStartDate && <div className="invalid-feedback">{errors.projectStartDate}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="form-group">
                                            <label>Project Description</label>
                                            <input
                                                type="text"
                                                name="projectDesc"
                                                className={`form-control ${errors.projectDesc ? 'is-invalid' : ''}`}
                                                value={formGroup.projectDesc}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.projectDesc && <div className="invalid-feedback">{errors.projectDesc}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Facility</label>
                                            <select
                                                name="facilityID"
                                                className={`form-control ${errors.facilityID ? 'is-invalid' : ''}`}
                                                value={formGroup.facilityID}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Facility</option>
                                                {facilityData.map(facility => (
                                                    <option key={facility.facilityId} value={facility.facilityId}>
                                                        {facility.facilityName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.facilityID && <div className="invalid-feedback">{errors.facilityID}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Project Category</label>
                                            <select
                                                name="projectCategoryID"
                                                className={`form-control ${errors.projectCategoryID ? 'is-invalid' : ''}`}
                                                value={formGroup.projectCategoryID}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Project Category</option>
                                                {categoryData.map(category => (
                                                    <option key={category.projectCategoryId} value={category.projectCategoryId}>
                                                        {category.projectCategory}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.projectCategoryID && <div className="invalid-feedback">{errors.projectCategoryID}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Project Type</label>
                                            <select
                                                name="projectTypeID"
                                                className={`form-control ${errors.projectTypeID ? 'is-invalid' : ''}`}
                                                value={formGroup.projectTypeID}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Project Type</option>
                                                {projectTypeData.map(type => (
                                                    <option key={type.projectTypeID} value={type.projectTypeID}>
                                                        {type.projectType}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.projectTypeID && <div className="invalid-feedback">{errors.projectTypeID}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Project Status</label>
                                            <select
                                                name="projectStatusID"
                                                className={`form-control ${errors.projectStatusID ? 'is-invalid' : ''}`}
                                                value={formGroup.projectStatusID}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Project Status</option>
                                                {projectStatusData.map(status => (
                                                    <option key={status.projectStatusID} value={status.projectStatusID}>
                                                        {status.projectStatus}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.projectStatusID && <div className="invalid-feedback">{errors.projectStatusID}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Type</label>
                                            <select
                                                name="type"
                                                className={`form-control ${errors.type ? 'is-invalid' : ''}`}
                                                value={formGroup.type}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    fetchComponentData(e.target.value);
                                                }}
                                                required
                                            >
                                                <option value="">Select Type</option>
                                                <option value="EMS">EMS</option>
                                                <option value="GHG">GHG</option>
                                                <option value="AMT">AMT</option>
                                                <option value="FMS">FMS</option>
                                            </select>
                                            {errors.type && <div className="invalid-feedback">{errors.type}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>CompType</label>
                                            <select
                                                name="compType"
                                                className={`form-control ${errors.compType ? 'is-invalid' : ''}`}
                                                value={formGroup.compType}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select CompType</option>
                                                {compTypeData.map(compType => (
                                                    <option key={compType.key} value={compType.key}>
                                                        {compType.value}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.compType && <div className="invalid-feedback">{errors.compType}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Component</label>
                                            <select
                                                name="componentID"
                                                className={`form-control ${errors.componentID ? 'is-invalid' : ''}`}
                                                value={formGroup.componentID}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Component</option>
                                                {componentData.map(comp => (
                                                    <option key={comp.componentId} value={comp.componentId}>
                                                        {comp.commonName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.componentID && <div className="invalid-feedback">{errors.componentID}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Pollutant</label>
                                            <select
                                                name="pollutant"
                                                className={`form-control ${errors.pollutant ? 'is-invalid' : ''}`}
                                                value={formGroup.pollutant}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Pollutant</option>
                                                {pollutantData.map(pollutant => (
                                                    <option key={pollutant.pollutantid} value={pollutant.pollutantid}>
                                                        {pollutant.pollutantName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.pollutant && <div className="invalid-feedback">{errors.pollutant}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Project Cost (USD)</label>
                                            <input
                                                type="number"
                                                name="projectCost"
                                                className={`form-control ${errors.projectCost ? 'is-invalid' : ''}`}
                                                value={formGroup.projectCost}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.projectCost && <div className="invalid-feedback">{errors.projectCost}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Reduction Expected</label>
                                            <input
                                                type="number"
                                                name="reductionExpected"
                                                className={`form-control ${errors.reductionExpected ? 'is-invalid' : ''}`}
                                                value={formGroup.reductionExpected}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.reductionExpected && <div className="invalid-feedback">{errors.reductionExpected}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Units</label>
                                            <select
                                                name="units"
                                                className={`form-control ${errors.units ? 'is-invalid' : ''}`}
                                                value={formGroup.units}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Units</option>
                                                {uomData.map(uom => (
                                                    <option key={uom.uomId} value={uom.uomId}>
                                                        {uom.description}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.units && <div className="invalid-feedback">{errors.units}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group">
                                            <label>Govt Funded YN?</label>
                                            <select
                                                name="projectGovtFundedYN"
                                                className={`form-control ${errors.projectGovtFundedYN ? 'is-invalid' : ''}`}
                                                value={formGroup.projectGovtFundedYN}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Govt Funded</option>
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                            </select>
                                            {errors.projectGovtFundedYN && <div className="invalid-feedback">{errors.projectGovtFundedYN}</div>}
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary float-right">Save</button>
                                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/project/list-project-master')}>Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddProjectMaster;

