import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UomService } from '../services/UomService';
import { TailSpin } from 'react-loader-spinner';

const AddUom = () => {
  const navigate = useNavigate();
  const { uomId } = useParams();
  const [formGroup, setFormGroup] = useState({
    uomId: '',
    description: '',
    uomType: '',
    materialType: '',
    systemOfUnits: '',
    status: 'A'
  });
  const [statusOptions] = useState(['A', 'D']);
  const [lstUomType, setLstUomType] = useState([]);
  const [lstMaterialType, setLstMaterialType] = useState([]);
  const [lstSystemOfUnit] = useState(['SI', 'FPS', 'BOTH']);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (uomId) {
      setIsEdit(true);
    }
    setUpInitialData(uomId);
  }, [uomId]);

  const setUpInitialData = async (uomId) => {
    try {
      const codeMasters = await UomService.getAllCodeMasters();
      setLstUomType(UomService.filterUomTypesFromCodeMaster(codeMasters));
      setLstMaterialType(UomService.filterMaterialStateFromCodeMaster(codeMasters));

      if (uomId) {
        const uom = await UomService.getUomByUomId(uomId);
        setFormGroup(uom);
      }
    } catch (error) {
      console.error('Failed to load initial data:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.uomId) newErrors.uomId = 'Uom Id is required.';
    if (!formGroup.status) newErrors.status = 'Status is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await UomService.saveUom(formGroup, isEdit);
      toast.success('UOM saved successfully.');
      setTimeout(() => {
        navigate('/admin/configurations/master/list-uom');
      }, 1000);
    } catch (error) {
      console.error('Failed to save UOM:', error);
      toast.error('Error saving UOM');
    }
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit UoM' : 'Add UoM'}</h1>
        <ul>
          <li>System Configuration</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      {loading ? (
        <div className="loader-container">
          <TailSpin height="50" width="50" color="#4A90E2" ariaLabel="loading" />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="uomId">UoM ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="uomId"
                      name="uomId"
                      placeholder="UoM ID"
                      value={formGroup.uomId}
                      onChange={handleChange}
                      readOnly={isEdit}
                      required
                    />
                  </div>
                  {errors.uomId && <div className="invalid-feedback d-block">{errors.uomId}</div>}
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group mb-0">
                    <label htmlFor="description">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      name="description"
                      placeholder="Description"
                      value={formGroup.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="uomType">UoM Type</label>
                    <select
                      className="form-control"
                      id="uomType"
                      name="uomType"
                      value={formGroup.uomType}
                      onChange={handleChange}
                    >
                      <option value="">Select UoM Type</option>
                      {lstUomType.map(uo => (
                        <option key={uo.code} value={uo.code}>{uo.description}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="materialType">Material State</label>
                    <select
                      className="form-control"
                      id="materialType"
                      name="materialType"
                      value={formGroup.materialType}
                      onChange={handleChange}
                    >
                      <option value="">Select Material State</option>
                      {lstMaterialType.map(uo => (
                        <option key={uo.code} value={uo.code}>{uo.description}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="systemOfUnits">System Of Units</label>
                    <select
                      className="form-control"
                      id="systemOfUnits"
                      name="systemOfUnits"
                      value={formGroup.systemOfUnits}
                      onChange={handleChange}
                    >
                      <option value="">Select System of Units</option>
                      {lstSystemOfUnit.map(uo => (
                        <option key={uo} value={uo}>{uo}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-label-group select-group mb-0">
                    <label htmlFor="status">Status</label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      value={formGroup.status}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select status</option>
                      {statusOptions.map(status => (
                        <option key={status} value={status}>{status}</option>
                      ))}
                    </select>
                    {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-12">
                  <button type="submit" className="btn btn-primary float-right">Save</button>
                  <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/master/list-uom')}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddUom;
