import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { useEffect, useRef, useState } from 'react';
import { NavLink, Link, useLocation } from "react-router-dom";
import { REPORTS_LABELS } from '../constants/labels';
import { Nav, OverlayTrigger, Button } from 'react-bootstrap';
import { API_BASE_URL } from '../../config';
// import ConnectionStatusPopover from '../views/connection-status/ConnectionStatusPopover';
import ConnectionStatusPopover from '../views/connection-status/ConnectionStatusPopover';

export default function LeftNav({ sidebarOpened }) {

  const [showPopover, setShowPopover] = useState(false);
  const sidebarRef = useRef(null);
  const secondarySidebarRef = useRef(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    if (sidebarRef.current) {
      new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
      });
    }
    if (secondarySidebarRef.current) {
      new PerfectScrollbar(secondarySidebarRef.current, {
        suppressScrollX: true,
      });
    }
  }, []);


  const openSidebar = () => {
    sidebarRef.current.classList.add('open');
    document.querySelector('.main-content-wrap').classList.add('sidenav-open');
  };

  const closeSidebar = () => {
    sidebarRef.current.classList.remove('open');
    document.querySelector('.main-content-wrap').classList.remove('sidenav-open');
  };

  const openSidebarSecondary = () => {
    secondarySidebarRef.current.classList.add('open');
    document.querySelector('.sidebar-overlay').classList.add('open');
  };

  const closeSidebarSecondary = () => {
    secondarySidebarRef.current.classList.remove('open');
    document.querySelector('.sidebar-overlay').classList.remove('open');
  };


  const handleMouseEnter = (event) => {
    const navItem = event.currentTarget;
    const dataItem = navItem.getAttribute('data-item');
  
    if (dataItem) {
      document.querySelectorAll('.nav-item').forEach(item => item.classList.remove('active'));
      navItem.classList.add('active');
      openSidebarSecondary();
    } else {
      closeSidebarSecondary();
    }


  
    document.querySelectorAll('.childNav').forEach(childNav => {
      childNav.style.display = 'none';
    });
    if (dataItem) {
      document.querySelector(`[data-parent="${dataItem}"]`).style.display = 'block';
    }
  };

  useEffect(() => {
    const fetchFacilityData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/home/facilities`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setFacilities(data);
      } catch (error) {
        console.error('Failed to fetch facility data:', error);
      }
    };

    fetchFacilityData();
  }, []);


  const handleMenuClick = (menu) => {
    setOpenSubMenu(openSubMenu === menu ? null : menu);
  };

  const handleLinkClick = () => {
    const secondarySidebar = secondarySidebarRef.current;
    if (secondarySidebar) {
      secondarySidebar.classList.remove('open');
    }

    const sidebarOverlay = document.querySelector('.sidebar-overlay');
    if (sidebarOverlay) {
      sidebarOverlay.classList.remove('open');
    }
    
    setShowPopover(false);
  };

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  const toggleDropdownMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const dropdownMenu = event.currentTarget.parentElement;
    const isOpen = dropdownMenu.classList.contains('open');

    // Close all other dropdown menus
    document.querySelectorAll('.dropdown-sidemenu').forEach(menu => {
      if (menu !== dropdownMenu) {
        menu.classList.remove('open');
      }
    });

    // Toggle the clicked dropdown menu
    dropdownMenu.classList.toggle('open', !isOpen);
  };

  const handleMouseLeave = (event) => {
    const relatedTarget = event.relatedTarget;
  
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(relatedTarget) &&
      secondarySidebarRef.current &&
      !secondarySidebarRef.current.contains(relatedTarget)
    ) {
      closeSidebarSecondary();
    }
  };

  return (
    <div className="side-content-wrap">
      <div className={`sidebar-left ${sidebarOpened ? '' : 'open'} rtl-ps-none`} ref={sidebarRef}>
        <ul className="navigation-left">
          <li className="nav-item " data-item="dashboard" onMouseEnter={handleMouseEnter}>
            <a className="nav-item-hold" href="#">
              <i className="nav-icon i-Bar-Chart" />
              <span className="nav-text">Dashboard</span>
            </a>
            <div className="triangle" />
          </li>
          <li className="nav-item " data-item="reports" onMouseEnter={handleMouseEnter}>
            <a className="nav-item-hold" href="#">
              <i className="nav-icon i-Double-Tap" />
              <span className="nav-text">Reports</span>
            </a>
            <div className="triangle" />
          </li>
          {/* <li className="nav-item " data-item="health-check">
            <a className="nav-item-hold" href="#">
              <i className="nav-icon i-Windows-2" />
              <span className="nav-text">Health Check</span>
            </a>
            <div className="triangle" />
          </li> */}
          <li class="nav-item " data-item="health-check" onMouseEnter={handleMouseEnter}>
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Computer-Secure"></i>
              <span class="nav-text">Quality Check</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li className="nav-item " data-item="master-data-management" onMouseEnter={handleMouseEnter}>
            <a className="nav-item-hold" href="#">
              <i className="nav-icon i-File-Clipboard-File--Text" />
              <span className="nav-text">Master Data Management</span>
            </a>
            <div className="triangle" />
          </li>
          <li className="nav-item " data-item="system-configuration" onMouseEnter={handleMouseEnter}>
            <a className="nav-item-hold" href="/test.html">
              <i className="nav-icon i-Administrator" />
              <span className="nav-text">System Configuration</span>
            </a>
            <div className="triangle" />
          </li>
          <li class="nav-item " data-item="operational-data" onMouseEnter={handleMouseEnter}>
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Computer-Secure"></i>
              <span class="nav-text">Operational Data</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li className="nav-item " data-item="Projects" onMouseEnter={handleMouseEnter}>
            <a className="nav-item-hold" href="#">
              <i className="nav-icon i-Double-Tap" />
              <span className="nav-text">Projects</span>
            </a>
            <div className="triangle" />
          </li>
          <li className="nav-item" data-item="displays" onMouseEnter={handleMouseEnter}>
            <a className="nav-item-hold" href="#">
              <i className="nav-icon i-Computer-Secure"></i>
              <span className="nav-text">Displays</span>
            </a>
            <div className="triangle"></div>
          </li>
          <li className="nav-item">
            <Link className="nav-item-hold" to="help">
              <i className="nav-icon i-Safe-Box1" />
              <span className="nav-text">Help</span>
            </Link>
            <div className="triangle" />
          </li>
          {/* <li className="nav-item">
            <Link className="nav-item-hold" to="data-import/file-upload">
              <i className="nav-icon i-Safe-Box1" />
              <span className="nav-text">Connections</span>
            </Link>
            <div className="triangle" />
          </li> */}
        </ul>
      </div>
      <div className="sidebar-left-secondary rtl-ps-none"  onMouseLeave={handleMouseLeave} ref={secondarySidebarRef} data-perfect-scrollbar data-suppress-scroll-x="true">
        <ul className="childNav" data-parent="dashboard">
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="flaring" onClick={handleLinkClick}>
              <i className="nav-icon i-Clock-3" />
              <span className="item-name">FMS</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="emissions" onClick={handleLinkClick}>
              <i className="nav-icon i-Clock-4" />
              <span className="item-name">EMS</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="greenhouse" onClick={handleLinkClick}>
              <i className="nav-icon i-Over-Time" />
              <span className="item-name">GHG</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="ammnet" onClick={handleLinkClick}>
              <i className="nav-icon i-Clock" />
              <span className="item-name">AMT</span>
            </NavLink>
          </li>
        </ul>

        {/* Reports Links */}
        <ul className="childNav" data-parent="reports">
          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Receipt" /> */}
              <span className="item-name">{REPORTS_LABELS.FLARING.MAIN}</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/header-wise-flaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.HEADER_WISE_FLARING}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/gasflaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.PLANT_FLARING_ANALYSIS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/daily-plant-wise-flaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.DAILY_PLANT_WISE_FLARING}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/daily-flaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.DAILY_FLARING}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/device-flaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.DEVICE_FLARING}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/reason-wise-flaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.REASON_WISE_FLARING}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/facility-wise-flaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.FACILITY_FLARING}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/un-accounted-flaring" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.UNACCOUNTED_FLARING}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/plan-vs-actual" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.PLAN_VS_ACTUAL}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/flaring-audit" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.FLARING_AUDIT}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/flaring-reports/flaring-kpi" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.FLARING.FLARING_KPI_REPORT}</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Receipt" /> */}
              <span className="item-name">{REPORTS_LABELS.EMISSIONS.MAIN}</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/emission-reports/FacilityEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.EMISSIONS.FACILITY_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/emission-reports/EmissionAnalysisByPlant" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.EMISSIONS.PLANT_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/emission-reports/EmissionAnalysisByHeader" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.EMISSIONS.SOURCE_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/emission-reports/EmissionAnalysisByHourly" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.EMISSIONS.HOURLY_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/emission-reports/ReasonsEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.EMISSIONS.EMISSION_REASONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/emission-reports/StacksEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.EMISSIONS.STACK_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/emission-reports/PlanVsActualEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.EMISSIONS.PLAN_VS_ACTUAL_EMISSIONS}</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Receipt" /> */}
              <span className="item-name">{REPORTS_LABELS.GHG.MAIN}</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/facility-ghg-emissions" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.FACILITY_GHG_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/plant-ghg-emission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.PLANT_GHG_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/source-ghg-emission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.SOURCE_GHG_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/hourly-ghg-emission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.HOURLY_GHG_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/stacks-ghg-emission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.STACK_GHG_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/ghg-reasons-emission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.GHG_REASONS_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/planvsactual-ghg-emission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.PLAN_VS_ACTUAL_GHG_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/scope-wise-ghg-emission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.SCOPE_WISE_GHG_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/emission-by-greenhouse-gas" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.COMPANYWIDE_GHG}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/ghg-inventory" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.GHG_INVENTORY}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/ghg-inventory-scope" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.GHG_INVENTORY_SCOPE}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ghg-reports/uncertainity-report" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.GHG.GHG_UNCERTAINTIES}</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Receipt" /> */}
              <span className="item-name">{REPORTS_LABELS.AMMNET.MAIN}</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ammnet-reports/PlantEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.AMMNET.STATION_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ammnet-reports/SourceEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.AMMNET.SOURCE_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ammnet-reports/PeriodicEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.AMMNET.PERIODIC_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ammnet-reports/RegionEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.AMMNET.REGION_EMISSIONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ammnet-reports/ReasonsEmission" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.AMMNET.EMISSION_REASONS}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ammnet-reports/Exceedances" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.AMMNET.EXCEEDANCES}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="reports/ammnet-reports/Violations" onClick={handleLinkClick}>
                  <span className="item-name">{REPORTS_LABELS.AMMNET.VIOLATIONS}</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
        {/* // health-check nav old code with links start here   */}
        {/* <ul className="childNav" data-parent="health-check">
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="alerts/fmstxerrors" onClick={handleLinkClick}>
              <i className="nav-icon i-Receipt-4" />
              <span className="item-name">Flaring Errors</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="alerts/emstxerrors" onClick={handleLinkClick}>
              <i className="nav-icon i-Receipt-4" />
              <span className="item-name">Emission Errors</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="alerts/ghgtxerrors" onClick={handleLinkClick}>
              <i className="nav-icon i-Receipt-4" />
              <span className="item-name">GHG Errors</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="alerts/ammnettxerrors" onClick={handleLinkClick}>
              <i className="nav-icon i-Receipt-4" />
              <span className="item-name">AMMNET Errors</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="alerts/fmshealthcheck" onClick={handleLinkClick}>
              <i className="nav-icon i-Receipt-4" />
              <span className="item-name">FMS Health</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="alerts/emshealthcheck" onClick={handleLinkClick}>
              <i className="nav-icon i-Receipt-4" />
              <span className="item-name">EMS Health</span>
            </NavLink>
          </li>
        </ul> */}
        {/* health-check nav old code with links end here  */}
        <ul class="childNav" data-parent="health-check">
          <li class="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i class="nav-icon i-Receipt"></i> */}
              <span class="item-name">Error Reporting</span>
              <i class="dd-arrow i-Arrow-Down"></i>
            </a>
            <ul class="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="alerts/fmstxerrors" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Receipt"></i> */}
                  <span class="item-name">Flaring Errors</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="alerts/emstxerrors" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Receipt-4"></i> */}
                  <span class="item-name">EMS Errors</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="alerts/ghgtxerrors" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Receipt-4"></i> */}
                  <span class="item-name">GHG Errors</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="alerts/ammnettxerrors" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Receipt-4"></i> */}
                  <span class="item-name">AMT Errors</span>
                </NavLink>
              </li>
              <li></li>
            </ul>
          </li>

          <li class="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i class="nav-icon i-Cash-Register"></i> */}
              <span class="item-name">Health Check</span>
              <i class="dd-arrow i-Arrow-Down"></i>
            </a>
            <ul class="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="alerts/fmshealthcheck" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Shop-2"></i> */}
                  <span class="item-name">FMS</span>
                </NavLink>
              </li>


              <li>
                <NavLink className="nav-link" activeClassName="active" to="alerts/emshealthcheck" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Tag-2"></i> */}
                  <span class="item-name">EMS,GHG and AMT</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i class="nav-icon i-Business-ManWoman"></i> */}
              <span class="item-name">Data Corrections</span>
              <i class="dd-arrow i-Arrow-Down"></i>
            </a>
            <ul class="submenu">

              <li>
                <NavLink className="nav-link" activeClassName="active" to="transactions/flow-validation-list" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Business-Mens"></i> */}
                  <span class="item-name">
                    FLOW
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="transactions/emission-validation-list" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Business-Mens"></i> */}
                  <span class="item-name">EMS</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="transactions/ghg-validation-list" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Conference"></i> */}
                  <span class="item-name">GHG</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="transactions/ammnet-validation-list" onClick={handleLinkClick}>
                  {/* <i class="nav-icon i-Find-User"></i> */}
                  <span class="item-name">AMT</span>
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="childNav" data-parent="master-data-management">
          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Receipt" /> */}
              <span className="item-name">Organizational Structure</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/area" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Receipt" /> */}
                  <span className="item-name">Area</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/facility" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Receipt-4" /> */}
                  <span className="item-name">Facility</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/plant" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Receipt-4" /> */}
                  <span className="item-name">Plant</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Cash-Register" /> */}
              <span className="item-name">Flare master</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ems-master/list-header" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Shop-2" /> */}
                  <span className="item-name">Headers</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ems-master/list-reason" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Tag-2" /> */}
                  <span className="item-name">Reasons</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Business-ManWoman" /> */}
              <span className="item-name">Source Master-General</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/device/device-classification-list" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Source Classification</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/device/list-device-type" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Source Types</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/device/list-device" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Conference" /> */}
                  <span className="item-name">Sources</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Business-ManWoman" /> */}
              <span className="item-name">Source Master – EMS</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ems-device-master/list-factor" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">EMS Factors</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ems-device-master/list-method" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">EMS Methods</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ems-device-master/list-ems-device-type" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Conference" /> */}
                  <span className="item-name">EMS Source Types</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ems-device-master/list-ems-device" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Conference" /> */}
                  <span className="item-name">EMS Sources</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Business-ManWoman" /> */}
              <span className="item-name">Source Master – GHG</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ghg-master/list-ghg-factor" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">GHG Factors</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ghg-master/list-ghg-method" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">GHG Methods</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/device/list-ghg-device-type" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Conference" /> */}
                  <span className="item-name">GHG Source Types</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/ghg-master/list-gwp-potential" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Conference" /> */}
                  <span className="item-name">GWP Potentials</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/device/list-ghg-device" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Conference" /> */}
                  <span className="item-name">GHG Sources</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Business-ManWoman" /> */}
              <span className="item-name">Source Master – AMT</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/amt-config/list-amt-device" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">AMT Sources</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/amt-config/list-amt-exceedance" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">AMT Exceedances</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="configurations/master/list-tags-master" onClick={handleLinkClick} href="apps/invoice">
              {/* <i class="nav-icon i-Add-File"></i> */}
              <span className="item-name">Tag Master</span>
            </NavLink>
          </li>

          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Business-ManWoman" /> */}
              <span className="item-name">Equipment</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/equipment/list-equipment-data" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">List <span className='text-lowercase'>of</span> Equipment</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/equipment/list-equipment-classification" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Equipment Classification</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Business-ManWoman" /> */}
              <span className="item-name">Pipes</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/pipe/fitting-list" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Pipe Fittings</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/pipe/list-pipe-friction-factor" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Pipe Friction Factors</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/pipe/list-coefficient" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Flow Coefficients</span>
                </NavLink>
              </li>
            </ul>
          </li>

        </ul>
        <ul className="childNav" data-parent="system-configuration">
          <li className="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i className="nav-icon i-Business-ManWoman" /> */}
              <span className="item-name">Composition</span>
              <i className="dd-arrow i-Arrow-Down" />
            </a>
            <ul className="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/composition/list-physical-properties" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Properties-Gas</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/composition/list-liquid-composition" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Properties-Solids & Liquids</span>
                </NavLink>
              </li>
              {/* <li>
                <Link to="configurations/composition/list-pollutant-limit" onClick={handleLinkClick}>
                  <i className="nav-icon i-Business-Mens" />
                  <span className="item-name">Pollutant Limits</span>
                </Link>
              </li> */}
            </ul>

            <li class="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="configurations/master/list-code-master" onClick={handleLinkClick}>
                {/* <i class="nav-icon i-Add-File"></i> */}
                <span className="item-name">Code Master</span>
              </NavLink>
            </li>

            <li class="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="configurations/master/list-uom" onClick={handleLinkClick}>
                {/* <i className="nav-icon i-Business-Mens" /> */}
                <span className="item-name">Engg Units</span>
              </NavLink>
            </li>
            <li class="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="configurations/master/list-uom-conversion" onClick={handleLinkClick}>
                {/* <i className="nav-icon i-Business-Mens" /> */}
                <span className="item-name">UoM Conversion</span>
              </NavLink>
            </li>
            <li class="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="configurations/master/list-status" onClick={handleLinkClick}>
                {/* <i className="nav-icon i-Business-Mens" /> */}
                <span className="item-name">Status</span>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className="nav-link" activeClassName="active" to="configurations/ems-defaults" onClick={handleLinkClick}>
                {/* <i className="nav-icon i-Business-Mens" /> */}
                <span className="item-name">System Defaults</span>
              </NavLink>
            </li>

            <li className="nav-item dropdown-sidemenu">
              <a onClick={toggleDropdownMenu}>
                {/* <i className="nav-icon i-Business-ManWoman" /> */}
                <span className="item-name">Notifications</span>
                <i className="dd-arrow i-Arrow-Down" />
              </a>
              <ul className="submenu">
                <li>
                  <NavLink className="nav-link" activeClassName="active" to="configurations/notification/list-user-configuration" onClick={handleLinkClick}>
                    {/* <i className="nav-icon i-Business-Mens" /> */}
                    <span className="item-name">User Configuration</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" activeClassName="active" to="configurations/notification/list-subscriber-configuration" onClick={handleLinkClick}>
                    {/* <i className="nav-icon i-Business-Mens" /> */}
                    <span className="item-name">Subscriber Configuration</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" activeClassName="active" to="configurations/notification/list-rule-configuration" onClick={handleLinkClick}>
                    {/* <i className="nav-icon i-Business-Mens" /> */}
                    <span className="item-name">Rule Configuration</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className='nav-item'>
              <NavLink className="nav-link" activeClassName="active" to="data-import/file-upload" onClick={handleLinkClick}>
                {/* <i className="nav-icon i-Business-Mens" /> */}
                <span className="item-name">Data Import</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <OverlayTrigger trigger="click" placement="right" overlay={<ConnectionStatusPopover />} rootClose show={showPopover} onToggle={togglePopover}>
                <div className="nav-item-hold">
                  <span className="nav-icon lnr lnr-sync"></span>
                  <a className="nav-link" href="#">Connection Status</a>
                </div>
              </OverlayTrigger>
              <div className="triangle" />
            </li>
          </li>
        </ul>

        <ul class="childNav" data-parent="operational-data">
          <li class="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="transactions/manual-valves-list" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-File-Clipboard-Text--Image" /> */}
              <span className="item-name">Manual Valves</span>
            </NavLink>
          </li>
          <li class="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="configurations/ems-master/list-facility-feed" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-Add-Cart" /> */}
              <span className="item-name">Facility Feed</span>
            </NavLink>
          </li>
          <li class="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="configurations/ghg-master/list-facility-equity" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-Receipt-4" /> */}
              <span className="item-name">Facility Equity</span>
            </NavLink>
          </li>
          <li class="nav-item dropdown-sidemenu">
            <a onClick={toggleDropdownMenu}>
              {/* <i class="nav-icon i-Receipt"></i> */}
              <span class="item-name">Corporate Emission Plans</span>
              <i class="dd-arrow i-Arrow-Down"></i>
            </a>
            <ul class="submenu">
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/system-plan/list-flaring-plan" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Flaring Plans</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" activeClassName="active" to="configurations/system-plan/list-emission-plan" onClick={handleLinkClick}>
                  {/* <i className="nav-icon i-Business-Mens" /> */}
                  <span className="item-name">Emission Plans</span>
                </NavLink>
              </li>
              <li></li>
            </ul>
          </li>

          <li class="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="transactions/shutdown-schedule-list" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-File-Clipboard-Text--Image" /> */}
              <span className="item-name">Shutdown Schedule</span>
            </NavLink>
          </li>
          <li class="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="configurations/composition/list-pollutant-limit" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-Business-Mens" /> */}
              <span className="item-name">Pollutant Limits</span>
            </NavLink>
          </li>
          <li class="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="configurations/master/list-annual-price" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-Business-Mens" /> */}
              <span className="item-name">Annual Prices</span>
            </NavLink>
          </li>


        </ul>

        <ul className="childNav" data-parent="Projects">
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="project/list-project-master" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-Error-404-Window" /> */}
              <span className="item-name">Project Master</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="project/list-project-transaction" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-Billing" /> */}
              <span className="item-name">Project Transactions</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="project/list-project-category" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-File-Search" /> */}
              <span className="item-name">Project Category</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="project/list-project-type" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-Male" /> */}
              <span className="item-name">Project Type</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="active" to="project/list-project-status" onClick={handleLinkClick}>
              {/* <i className="nav-icon i-File-Horizontal" /> */}
              <span className="item-name">Project Status</span>
            </NavLink>
          </li>
        </ul>
        <ul className="childNav" data-parent="displays">
          {facilities.map((facility) => (
            <li className="nav-item" key={facility.id}>
              <a className="nav-link" href={facility.piURL} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                <span className="item-name">{facility.facilityName}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="sidebar-overlay" />
    </div>
  );
}
