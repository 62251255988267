import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { API_BASE_URL } from '../../../../../config';

const AddPhysicalProperties = () => {
  const [formGroup, setFormGroup] = useState({
    componentId: '',
    iupacName: '',
    commonName: '',
    eqptCode: '',
    cAtoms: '',
    hAtoms: '',
    mwGmol: '',
    meltingPointDegC: '',
    boilingPointDegC: '',
    densityAt20DegCGmPerMl: '',
    flashPointDegC: '',
    autoIgnitionTempDegC: '',
    ghvBtuScf: '',
    lhvBtuScf: '',
    status: 'A'
  });
  const [statusOptions] = useState(['A', 'D']);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // const { componentId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = !!searchParams.get("componentId");

  useEffect(() => {
    if (isEdit) {
      fetch(`${API_BASE_URL}/composition/physical-properties/${searchParams.get("componentId")}`)
        .then(response => response.json())
        .then(data => {
          setFormGroup(data);
        })
        .catch(error => {
          console.error('Error fetching composition details:', error);
        });
    }
  }, [searchParams.get("componentId"), isEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.componentId) {
      newErrors.componentId = 'Component Id is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/${isEdit ? 'composition/physical-properties/update' : 'composition/physical-properties/save'}`, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formGroup)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Physical properties ${isEdit ? 'updated' : 'added'} successfully.`);
      setTimeout(() => {
        navigate('/admin/configurations/composition/list-physical-properties');
      }, 1000);
    } catch (error) {
      console.error('Failed to save physical properties:', error);
      toast.error('Error saving physical properties');
    }
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Properties-Gas' : 'Add Properties-Gas'}</h1>
        <ul>
          <li>System Configuration</li>
          <li>Composition</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Composition Info</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="componentId">Component Id</label>
                <input type="text" className={`form-control ${errors.componentId ? 'is-invalid' : ''}`} id="componentId" name="componentId" value={formGroup.componentId} onChange={handleChange} readOnly={isEdit} />
                {errors.componentId && <div className="invalid-feedback d-block">{errors.componentId}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="iupacName">IUPAC Name</label>
                <input type="text" className="form-control" id="iupacName" name="iupacName" value={formGroup.iupacName} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="commonName">Common Name</label>
                <input type="text" className="form-control" id="commonName" name="commonName" value={formGroup.commonName} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="eqptCode">EQPT Code</label>
                <input type="text" className="form-control" id="eqptCode" name="eqptCode" value={formGroup.eqptCode} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-header">Composition Metrics</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="cAtoms">C Atoms</label>
                <input type="number" className="form-control" id="cAtoms" name="cAtoms" value={formGroup.cAtoms} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="hAtoms">H Atoms</label>
                <input type="number" className="form-control" id="hAtoms" name="hAtoms" value={formGroup.hAtoms} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="mwGmol">Mol. Wt g/mol</label>
                <input type="number" className="form-control" id="mwGmol" name="mwGmol" value={formGroup.mwGmol} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="meltingPointDegC">Melting Point DegC</label>
                <input type="number" className="form-control" id="meltingPointDegC" name="meltingPointDegC" value={formGroup.meltingPointDegC} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="boilingPointDegC">Boiling Point DegC</label>
                <input type="number" className="form-control" id="boilingPointDegC" name="boilingPointDegC" value={formGroup.boilingPointDegC} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="densityAt20DegCGmPerMl">Density At 20 DegC/GM</label>
                <input type="number" className="form-control" id="densityAt20DegCGmPerMl" name="densityAt20DegCGmPerMl" value={formGroup.densityAt20DegCGmPerMl} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="flashPointDegC">Flash Point DegC</label>
                <input type="number" className="form-control" id="flashPointDegC" name="flashPointDegC" value={formGroup.flashPointDegC} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="autoIgnitionTempDegC">Auto Ignition Temp DegC</label>
                <input type="number" className="form-control" id="autoIgnitionTempDegC" name="autoIgnitionTempDegC" value={formGroup.autoIgnitionTempDegC} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="ghvBtuScf">GHV BtuScf</label>
                <input type="number" className="form-control" id="ghvBtuScf" name="ghvBtuScf" value={formGroup.ghvBtuScf} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="lhvBtuScf">LHV BtuScf</label>
                <input type="number" className="form-control" id="lhvBtuScf" name="lhvBtuScf" value={formGroup.lhvBtuScf} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select className="form-control" id="status" name="status" value={formGroup.status} onChange={handleChange} >
                  <option value="">Select operational status</option>
                  {statusOptions.map(status => (
                    <option key={status} value={status}>{status}</option>
                  ))}
                </select>
                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mt-4">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary float-right">Save</button>
            <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/composition/list-physical-properties')}>Cancel</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddPhysicalProperties;
