import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';
export const getPollutantLimitData = async () => {
    const response = await fetch(`${API_BASE_URL}/composition/pollutant-limit/all`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
        credentials: 'include'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch pollutant limit data');
    }
    return response.json();
};

export const getPollutantLimitByPollutantIdAndYear = async (pollutantId, year) => {
    const response = await fetch(`${API_BASE_URL}/composition/pollutant-limit/pollutant/${pollutantId}/year/${year}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
        credentials: 'include'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch pollutant limit data');
    }
    return response.json();
};

export const savePollutantLimit = async (pollutantLimit, isEdit) => {
    const apiUrl = isEdit ? `${API_BASE_URL}/composition/pollutant-limit/update` : `${API_BASE_URL}/composition/pollutant-limit/save`;
    const method = isEdit ? 'PUT' : 'POST';
    const response = await fetch(apiUrl, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
        body: JSON.stringify(pollutantLimit)
    });
    if (!response.ok) {
        throw new Error('Failed to save pollutant limit');
    }
    return response.json();
};

export const deletePollutantLimit = async (pollutantId, year) => {
    const response = await fetch(`${API_BASE_URL}/composition/pollutant-limit/delete/pollutant/${pollutantId}/year/${year}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
        credentials: 'include'
    });
    if (!response.ok) {
        throw new Error('Failed to delete pollutant limit');
    }
    return response.json();
};

export const fetchYears = async () => {
    let years = [];
    for (let i = 2000; i <= 2050; i++) {
        years.push(i);
    }
    return years;
};

export const fetchUOMs = async () => {
    const response = await fetch(`${API_BASE_URL}/device/uoms`);
    if (!response.ok) throw new Error('Failed to fetch UOMs');
    return await response.json();
};

export const fetchCodeMasters = async () => {
    const response = await fetch(`${API_BASE_URL}/device/codemasters`);
    if (!response.ok) throw new Error('Failed to fetch code masters');
    return await response.json();
};

export const filterPollutantLimitsFromCodeMasters = (codeMasters) => {
    return codeMasters.filter(x => x.type === CodeMasterType.PollutantID);
};
