import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { API_BASE_URL } from '../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExportComponent from '../../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator , formatWithIndianThousandsSeparatorForExportData} from '../../../../common/FormatNumber';

export default function GhgMethodList() {
  const [ghgMethodData, setGhgMethodData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [exportFilteredData, setExportFilteredData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      toast.success(location.state.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    fetchGhgMethodData();
  }, [location.state]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const fetchGhgMethodData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-method`, {
        method: 'GET',
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setGhgMethodData(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Failed to fetch GHG method data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/configurations/ghg-master/add-ghg-method?ghgMethodId=${id}`);
  };

  const handleDelete = async (ghgMethodId) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete GHG Method: ${ghgMethodId}?`);
    if (confirmDelete) {
      try {
        const response = await fetch(`${API_BASE_URL}/ghg-master/ghg-method/delete/${ghgMethodId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        toast.success('Successfully removed!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        fetchGhgMethodData();
      } catch (error) {
        console.error('Failed to delete GHG method:', error);
      }
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = ghgMethodData.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Method ID': item.ghgMethodId,
      'Approaches': item.approaches,
      'CO2 Emissions': item.co2Emissions,
      'CH4N2O Combustion Emissions': item.ch4No2CombustionEmissions,
      'EF Uncertainty Percentage': formatWithIndianThousandsSeparatorForExportData(item.efUncertinityPercent ? item.efUncertinityPercent.toFixed(0) : item.efUncertinityPercent),
      'AF Uncertainty Percentage': formatWithIndianThousandsSeparatorForExportData(item.afUncertinityPercent ? item.afUncertinityPercent.toFixed(0) : item.afUncertinityPercent),
      'Status': item.status
  }));
  setExportFilteredData(exportData);

  }, [search, ghgMethodData]);

  const columns = [
    { name: 'Method ID', selector: row => row.ghgMethodId, sortable: true, resizable: true, pinned: 'left' },
    { name: 'Approaches', selector: row => row.approaches, sortable: true, resizable: true },
    { name: 'CO2 Emissions', selector: row => row.co2Emissions, sortable: true, resizable: true },
    { name: 'CH4N2O Combustion Emissions', selector: row => row.ch4No2CombustionEmissions, sortable: true, resizable: true },
    { name: 'EF Uncertainty Percentage', 
      selector: row => row.efUncertinityPercent,
      cell: row => formatWithIndianThousandsSeparator(row.efUncertinityPercent ? row.efUncertinityPercent.toFixed(0) : row.efUncertinityPercent), 
      sortable: true, 
      resizable: true 
    },
    { name: 'AF Uncertainty Percentage', 
      selector: row => row.afUncertinityPercent,
      cell: row => formatWithIndianThousandsSeparator(row.afUncertinityPercent ? row.afUncertinityPercent.toFixed(0) : row.afUncertinityPercent), 
      sortable: true, 
      resizable: true 
    },
    { name: 'Status', selector: row => row.status, sortable: true },
    {
      name: 'Edit',
      width: '75px',
      cell: row => (
        <FontAwesomeIcon
          icon={faPen}
          onClick={() => handleEdit(row.ghgMethodId)}
          style={{ cursor: 'pointer' }}
        />
      )
    },
    {
      name: 'Delete',
      width: '75px',
      cell: row => (
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => handleDelete(row.ghgMethodId)}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      )
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Ghg_Method_${new Date().toLocaleDateString()}`;
  const title = "GHG Method Report";
  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>GHG Methods</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Source Master-GHG</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">GHG Method</h4> */}
              <div className='row pull-right'>
                <div className='col-md-10'>
                  <Link to="/admin/configurations/ghg-master/add-ghg-method" className="btn btn-primary">
                    Add New Record
                  </Link>
                </div>
              </div>
              <div className='row mt-3'>
                      <div className='col-md-10'>
                        {filteredData.length > 0 && (
                          <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                        )}
                      </div>
                      <div className='col-md-2'>
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control mb-3"
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
              <div className="table-responsive">
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>
                    
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
