import axios from 'axios';
import { API_BASE_URL } from '../../../../../../config';
import CodeMasterType from '../../../../../constants/CodeMasterType';

const BASE_URL = `${API_BASE_URL}/master/tags-master`;

export const TagsMasterService = {
  getAllTagsMaster: () => axios.get(BASE_URL).then(res => res.data),

  getTagsMasterById: (id) => axios.get(`${BASE_URL}/${id}`).then(res => res.data),

  saveTagsMaster: (tagsMaster, isEdit) => {
    if (isEdit) {
      return axios.put(`${BASE_URL}/update`, tagsMaster).then(res => res.data);
    } else {
      return axios.post(`${BASE_URL}/save`, tagsMaster).then(res => res.data);
    }
  },

  deleteTagsMaster: (id) => axios.delete(`${BASE_URL}/delete/${id}`).then(res => res.data),

  canDeleteTagsMaster: (id) => axios.get(`${BASE_URL}/can-delete/${id}`).then(res => res.data),

  getAllLiquidCompositions: () => axios.get(`${API_BASE_URL}/composition/liquid-composition/all`).then(res => res.data),

  getAllCompositions: () => axios.get(`${API_BASE_URL}/composition/physical-properties/all`).then(res => res.data),

  getReadingTypesForDataCollectionMode: () => axios.get(`${API_BASE_URL}/master/dcvsrts/get-reading-types`).then(res => res.data),

  getAllFacilityFeeds: () => axios.get(`${API_BASE_URL}/ems-master/facility-feed/all`).then(res => res.data),

  getHelpMessage: (deviceId, property) => axios.get(encodeURI(`${API_BASE_URL}/master/tags-master/get-help-note/${deviceId}/${property}`)).then(res => res.data),

  getAllCodeMaster: () => axios.get(`${API_BASE_URL}/device/codemasters`).then(res => res.data),

  getAllHeaders: () => axios.get(`${API_BASE_URL}/device/headers`).then(res => res.data),

  getAllDevices: () => axios.get(`${API_BASE_URL}/device/all`).then(res => res.data),

  getAppSession: () => axios.get(`${API_BASE_URL}/session`).then(res => res.data),

  getAllUOMs: () => axios.get(`${API_BASE_URL}/device/uoms`).then(res => res.data),

  filterDeviceTypesFromCodeMaster: (allCodeMasters) => allCodeMasters.filter(x => x.type === CodeMasterType.DeviceType),

  filterReadingTypesFromCodeMaster: (allCodeMasters) => allCodeMasters.filter(x => x.type === CodeMasterType.ReadingType),

  filterLimsPropertyFromCodeMaster: (allCodeMasters) => allCodeMasters.filter(x => x.type === CodeMasterType.LIMSproperty),
};
