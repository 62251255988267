import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import './Login.css'; // Ensure the path is correct

const images = [
    require('../../assets/images/emissions1.jpg'),
    require('../../assets/images/emissions2.jpg'),
    require('../../assets/images/emissions3.jpg'),
    require('../../assets/images/emissions4.jpg'),
    require('../../assets/images/emissions5.jpg')
];

const getRandomImage = () => {
    const index = Math.floor(Math.random() * images.length);
    return images[index];
};

const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const navigate = useNavigate();
    const { login, currentUser } = useAuth();

    useEffect(() => {
        setBackgroundImage(getRandomImage());
        if (currentUser) {
            navigate('/admin');
        }
    }, [currentUser, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        setError('');

        if (!userName || !password) {
            return;
        }

        setLoading(true);

        try {
            await login(userName, password);
            navigate('/admin');
        } catch (error) {
            setError('Failed to login. Please check your credentials.');
            setLoading(false);
        }
    };

    return (
        <div className="container-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="login-container text-center">
                <Form className="form-signin" onSubmit={handleSubmit}>
                    <img className="" src={require('../../assets/images/green6.png')} style={{ width:'150px',height:'150px' }} alt="GreenVue Logo" />
                    {/* <h1 className="h3 mb-3 font-weight-normal text-center">GreenVue Login</h1> */}

                    {error && (
                        <Alert variant="danger" onClose={() => setError('')} dismissible>
                            {error}
                        </Alert>
                    )}

                    <Form.Group controlId="userName">
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            isInvalid={submitted && !userName}
                        />
                        <Form.Control.Feedback type="invalid">
                            Username is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <br/> */}
                    <Form.Group controlId="password">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={submitted && !password}
                        />
                        <Form.Control.Feedback type="invalid">
                            Password is required
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit" block disabled={loading}>
                        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        Login
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default Login;
