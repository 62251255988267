import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllCodeMasters,
  getPipeFittingByFittingId,
  savePipeFitting,
} from '../services/PipeService';
import { filterFittingTypesFromCodeMaster } from '../utils/CodeMasterUtils';

const AddPipeFitting = () => {
  const [formGroup, setFormGroup] = useState({
    fittingId: '',
    description: '',
    fittingType: '',
    fittingConstant: '',
    kFactor: '',
    status: 'A',
  });
  const [lstFittingType, setLstFittingType] = useState([]);
  const [statusOptions] = useState(['A', 'D']);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // const { pipeFittingId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = !!searchParams.get("pipeFittingId");

  useEffect(() => {
    fetchInitialData();
    if (isEdit) {
      fetchPipeFittingData(searchParams.get("pipeFittingId"));
    }
  }, [searchParams.get("pipeFittingId"), isEdit]);

  const fetchInitialData = async () => {
    try {
      const codeMasters = await getAllCodeMasters();
      setLstFittingType(filterFittingTypesFromCodeMaster(codeMasters));
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const fetchPipeFittingData = async (fittingId) => {
    try {
      const data = await getPipeFittingByFittingId(fittingId);
      setFormGroup(data);
    } catch (error) {
      console.error('Error fetching pipe fitting data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.fittingId) {
      newErrors.fittingId = 'Fitting Id is required.';
    }
    if (!formGroup.fittingType) {
      newErrors.fittingType = 'Fitting Type is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await savePipeFitting(isEdit, formGroup);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Pipe Fitting ${isEdit ? 'updated' : 'added'} successfully.`);
      setTimeout(() => {
        navigate('/admin/configurations/pipe/fitting-list');
      }, 1000);
    } catch (error) {
      console.error('Failed to save pipe fitting:', error);
      toast.error('Error saving Pipe Fitting');
    }
  };

  const isInputInValid = (name) => {
    const formControl = formGroup[name];
    return !formControl && errors[name];
  };

  return (
    <div>
      {/* <h4>{isEdit ? 'EDIT PIPE FITTING' : 'ADD PIPE FITTING'}</h4> */}
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Pipe Fitting' : 'Add Pipe Fitting'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Pipes</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Pipe Fitting Info</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="fittingId">Fitting ID</label>
                <input
                  type="text"
                  className={`form-control ${isInputInValid('fittingId') ? 'is-invalid' : ''}`}
                  id="fittingId"
                  name="fittingId"
                  value={formGroup.fittingId}
                  onChange={handleChange}
                  readOnly={isEdit}
                  required
                />
                {errors.fittingId && (
                  <div className="invalid-feedback">
                    {errors.fittingId}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  value={formGroup.description}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="fittingType">Fitting Type</label>
                <select
                  className={`form-control ${isInputInValid('fittingType') ? 'is-invalid' : ''}`}
                  id="fittingType"
                  name="fittingType"
                  value={formGroup.fittingType}
                  onChange={handleChange}
                  required
                >
                  <option value=""> Select Fitting Type</option>
                  {lstFittingType.map((ft) => (
                    <option key={ft.code} value={ft.code}>
                      {ft.description}
                    </option>
                  ))}
                </select>
                {errors.fittingType && (
                  <div className="invalid-feedback">
                    {errors.fittingType}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="fittingConstant">Fitting Constant</label>
                <input
                  type="number"
                  className="form-control"
                  id="fittingConstant"
                  name="fittingConstant"
                  value={formGroup.fittingConstant}
                  onChange={handleChange}
                />
                {errors.fittingConstant && (
                  <div className="invalid-feedback">
                    {errors.fittingConstant}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="kFactor">K Factor</label>
                <input
                  type="number"
                  className="form-control"
                  id="kFactor"
                  name="kFactor"
                  value={formGroup.kFactor}
                  onChange={handleChange}
                />
                {errors.kFactor && (
                  <div className="invalid-feedback">
                    {errors.kFactor}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Operational Status</label>
                <select
                  className={`form-control ${isInputInValid('status') ? 'is-invalid' : ''}`}
                  id="status"
                  name="status"
                  value={formGroup.status}
                  onChange={handleChange}
                  required
                >
                  <option value="" selected>
                    Select Operational Status
                  </option>
                  {statusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                {errors.status && (
                  <div className="invalid-feedback">
                    {errors.status}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary float-right">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary float-right mr-3"
              onClick={() => navigate('/admin/configurations/pipe/fitting-list')}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddPipeFitting;
