import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import AddArea from './AddArea';
import ExportComponent from '../../../../common/ExportComponent';

export default function AreaList() {
    const [areaData, setAreaData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [columnSearch, setColumnSearch] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedAreaId, setSelectedAreaId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    useEffect(() => {
        fetchAreaData();
    }, []);

    const fetchAreaData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/facility/areas`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setAreaData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let filtered = [...areaData];

        if (search) {
            const lowercasedSearch = search.toLowerCase();
            filtered = filtered.filter(item =>
                String(item.areaKey).toLowerCase().includes(lowercasedSearch) ||
                String(item.areaName).toLowerCase().includes(lowercasedSearch) ||
                String(item.status).toLowerCase().includes(lowercasedSearch)
            );
        }

        Object.keys(columnSearch).forEach(column => {
            if (columnSearch[column]) {
                const searchValue = columnSearch[column].toLowerCase();
                filtered = filtered.filter(item =>
                    String(item[column] || '').toLowerCase().includes(searchValue)
                );
            }
        });

        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Area ID': item.areaKey,
            'Area Name': item.areaName,
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, columnSearch, areaData]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleColumnSearch = (column, value) => {
        // setColumnSearch(prevState => {
        //     const newColumnSearch = {
        //         ...prevState,
        //         [column]: value,
        //     };
        //     console.log('Updated columnSearch:', newColumnSearch);
        //     return newColumnSearch;
        // });
    };

    const handleEdit = (id) => {
        setSelectedAreaId(id);
        setShowModal(true);
    };

    const handleDelete = async (areaKey) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete area: ${areaKey}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/area/delete/${areaKey}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchAreaData();
            } catch (error) {
                console.error('Failed to delete area:', error);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedAreaId(null);
        fetchAreaData(); // Refresh data after adding or editing
    };

    const columns = [
        {
            name: 'Area ID',
            selector: row => row.areaKey,
            sortable: true,
            filterable: true,
            cell: row => row.areaKey,
        },
        {
            name: 'Area Name',
            selector: row => row.areaName,
            sortable: true,
            filterable: true,
            cell: row => row.areaName,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            filterable: true,
            cell: row => row.status,
        },
        {
            name: 'Edit',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.areaKey)}
                    style={{ cursor: 'pointer' }}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '100px',
        },
        {
            name: 'Delete',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.areaKey)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '100px',
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Area_${new Date().toLocaleDateString()}`;

    const title = "Area Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Area</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Organizational Structure</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>Add New Record</button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header >
                    <Modal.Title>{selectedAreaId ? 'Edit Area' : 'Add Area'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddArea areaId={selectedAreaId} closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    );
}