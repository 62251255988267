import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import AddStatus from './AddStatus'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';

export default function StatusList() {
    const [statusData, setStatusData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchStatusData();
    }, []);

    const fetchStatusData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/master/status`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setStatusData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch status data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (status) => {
        setSelectedStatus(status);
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Status: ${id}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/master/status/delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchStatusData();
            } catch (error) {
                console.error('Failed to delete status:', error);
            }
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = statusData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Status ID': item.statusId,
            'Status Description': item.statusDescription,
        }));
        setExportFilteredData(exportData);

    }, [search, statusData]);

    const columns = [
        { name: 'Status ID', selector: row => row.statusId, sortable: true, resizable: true },
        { name: 'Status Description', selector: row => row.statusDescription, sortable: true, resizable: true },
        // {
        //     name: 'Edit',
        //     cell: row => (
        //         <FontAwesomeIcon
        //             icon={faPen}
        //             onClick={() => handleEdit(row)}
        //             style={{ cursor: 'pointer' }}
        //         />
        //     )
        // },
        // {
        //     name: 'Delete',
        //     cell: row => (
        //         <FontAwesomeIcon
        //             icon={faTrash}
        //             onClick={() => handleDelete(row.statusId)}
        //             style={{ cursor: 'pointer', color: 'red' }}
        //         />
        //     )
        // },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Status_${new Date().toLocaleDateString()}`;

    const title = "Status Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Status</h1>
                <ul>
                    <li>System Configration</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Status List</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Button className="btn btn-primary" onClick={() => { setShowModal(true); setSelectedStatus(null); }}>
                                        Add New Record
                                    </Button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
            <AddStatus showModal={showModal} handleClose={() => setShowModal(false)} fetchStatusData={fetchStatusData} status={selectedStatus} />
        </div>
    );
}
