import React, { useState, useEffect, useContext } from 'react';
import PollutantSummary from '../pollutant-summary/PollutantSummary';
import EmissionsSummary from '../emissions-summary/EmissionsSummary';
import EmissionsByReason from '../emissions-by-reason/EmissionByReason';
import FacilityEmissionGraph from '../facility-emission/FacilityEmissionGraph';
import EmsService from '../services/EmsService';
import { DateContext } from '../../../../../contexts/DateContext';
import Loader from '../../../../common/loader/Loader';

const EmsDashboard = () => {
  const { selectedDate } = useContext(DateContext);
  const [activePollutants, setActivePollutants] = useState([]);
  const [pollutantStats, setPollutantStats] = useState([]);
  const [emissionsByReason, setEmissionsByReason] = useState([]);
  const [loadingPollutants, setLoadingPollutants] = useState(false);
  const [loadingPollutantStats, setLoadingPollutantStats] = useState(false);
  const [loadingEmissionsByReason, setLoadingEmissionsByReason] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (selectedDate) {
      resetDashboardData();
      const datePart = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
      getDashboardData(datePart);
      setCurrentDate(selectedDate);
    }
  }, [selectedDate]);

  const getDashboardData = async (date) => {
    try {
      setLoadingPollutants(true);
      setLoadingPollutantStats(true);
      setLoadingEmissionsByReason(true);

      const [pollutantsResp, pollutantStatsResp, emissionsByReasonResp] = await Promise.all([
        EmsService.getActivePollutants(),
        EmsService.getPollutantStatsV2('', '', date),
        EmsService.getEmissionsByReason('', '', date)
      ]);

      setActivePollutants(pollutantsResp.data);
      setPollutantStats(pollutantStatsResp.data);
      setEmissionsByReason(emissionsByReasonResp.data);
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    } finally {
      setLoadingPollutants(false);
      setLoadingPollutantStats(false);
      setLoadingEmissionsByReason(false);
    }
  };

  const resetDashboardData = () => {
    setActivePollutants([]);
    setPollutantStats([]);
    setEmissionsByReason([]);
  };

  return (
    <div className="ems-dashboard">
      {loadingPollutantStats ? (
        <Loader />
      ) : (
        pollutantStats && pollutantStats.length > 0 && (
          <div className="row mt-1">
            <div className="col-sm-12">
              <PollutantSummary areaId={''} facilityId={''} date={selectedDate} pollutantStats={pollutantStats} setPollutantStats={setPollutantStats} />
            </div>
          </div>
        )
      )}
      <div className="row">
        <div className="col-sm-12">
          {loadingEmissionsByReason ? (
            <Loader />
          ) : (
            <EmissionsSummary areaId={''} facilityId={''} date={selectedDate} />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ marginTop:'-20px' }}>
          {loadingEmissionsByReason ? (
            <Loader />
          ) : (
            emissionsByReason && emissionsByReason.length > 0 && (
              <EmissionsByReason areaId={''} facilityId={''} date={selectedDate} emissionByReasons={emissionsByReason} />
            )
          )}
        </div>
        <div className="col-sm-6">
          {loadingEmissionsByReason ? (
            <Loader />
          ) : (
            emissionsByReason && emissionsByReason.length > 0 && (
              <FacilityEmissionGraph areaId={''} facilityId={''} date={selectedDate} emissionByReasons={emissionsByReason} />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default EmsDashboard;
