import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { getProjectCategory, deleteProjectCategory } from '../services/projectService';
import AddProjectCategoryModal from './AddProjectCategoryModal';
import ExportComponent from '../../../common/ExportComponent';

const ListProjectCategory = () => {
  const [projectCategories, setProjectCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [exportFilteredData, setExportFilteredData] = useState([]);

  useEffect(() => {
    loadProjectCategories();
  }, []);

  const loadProjectCategories = async () => {
    setIsLoading(true);
    try {
      const data = await getProjectCategory();
      setProjectCategories(data);
      setFilteredCategories(data);
    } catch (error) {
      toast.error('Failed to load project categories.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const text = event.target.value;
    setSearchText(text);
    const filtered = projectCategories.filter((category) =>
      category.projectCategory.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredCategories(filtered);
  };

  const handleDelete = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      await deleteProjectCategory(selectedCategoryId);
      toast.success('Project Category deleted successfully.');
      setFilteredCategories(filteredCategories.filter((cat) => cat.projectCategoryId !== selectedCategoryId));
      loadProjectCategories();
    } catch (error) {
      toast.error('Failed to delete project category.');
    }
  };

  const handleEdit = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setIsEdit(true);
    setShowAddEditModal(true);
  };

  const handleAdd = () => {
    setSelectedCategoryId(null);
    setIsEdit(false);
    setShowAddEditModal(true);
  };

  useEffect(() => {
    const lowercasedSearch = searchText.toLowerCase();
    const filtered = projectCategories.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredCategories(filtered);

    const exportData = filtered.map(item => ({
      'Project Category ID': item.projectCategoryId,
      'Project Category': item.projectCategory,
      'Status': item.status
    }));
    setExportFilteredData(exportData);
  }, [searchText, projectCategories]);

  const columns = [
    {
      name: 'Project Category ID',
      selector: row => row.projectCategoryId,
      sortable: true,
    },
    {
      name: 'Project Category',
      selector: row => row.projectCategory,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      name: 'Actions',
      width: '100px',
      cell: (row) => (
        <>
          {/* <FontAwesomeIcon
            icon={faEdit}
            onClick={() => handleEdit(row.projectCategoryId)}
            style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
          /> */}
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => handleDelete(row.id)}
            style={{ cursor: 'pointer', color: 'red' }}
          />
        </>
      ),
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Project_Category_${new Date().toLocaleDateString()}`;

  const title = "Project Category Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Project Category</h1>
        <ul>
          <li>Projects</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-5">
        
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
            <div className="row">
          <div className="col-md-6 mb-3">
            <button
              className="btn btn-primary"
              onClick={handleAdd}
            >
              <span className="lnr lnr-plus-circle mr-1"></span>
              <span className="font-weight-light">Add New Record</span>
            </button>
          </div>
        </div>
              <div className='row mb-3'>
                <div className='col-md-10'>
                  {filteredCategories.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    className="form-control"
                    id="searchText"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>

                    <DataTable
                      columns={columns}
                      data={filteredCategories}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>

                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this project category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <AddProjectCategoryModal
        show={showAddEditModal}
        onHide={() => setShowAddEditModal(false)}
        categoryId={selectedCategoryId}
        isEdit={isEdit}
        reload={loadProjectCategories}
      />
    </div>
  );
};

export default ListProjectCategory;
