
import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
// import '../../assets/css/lite-purple.min.css';
// import '../../assets/css/perfect-scrollbar.css';
// core components
import TopNav from "../../components/TopNav/TopNav.js";
import Footer from "../../components/Footer/Footer.js";
import Sidebar from "../../components/LeftNav/LeftNav.js";

import routes from "../../routes";
import LeftNav from "../../components/LeftNav/LeftNav.js";


var ps;

function Admin(props) {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );



  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <React.Fragment>

      {/* <div className="loadscreen" id="preloader">
        <div className="loader spinner-bubble spinner-bubble-primary">
        </div>
      </div> */}
      {/* <div className="main-panel" > */}
      <div className="app-admin-wrap layout-sidebar-large clearfix" ref={mainPanelRef}>
        <TopNav
          brandText={getBrandText(location.pathname)}
          toggleSidebar={toggleSidebar}
          sidebarOpened={sidebarOpened}
        />
        <LeftNav sidebarOpened={sidebarOpened} />
        <div className={`main-content-wrap ${sidebarOpened ? '' : 'sidenav-open'} d-flex flex-column`}>
          <Routes>
            {getRoutes(routes)}
            <Route
              path="/"
              element={<Navigate to="/admin/flaring" replace />}
            />
          </Routes>
          <Footer />
          <div className="customizer">
            {/* <div className="handle">
              <i className="i-Gear spin" />
            </div> */}
            <div className="customizer-body" data-perfect-scrollbar data-suppress-scroll-x="true">
              <div className="accordion" id="accordionCustomizer">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <p className="mb-0">
                      Sidebar Layout
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="layouts">
                      {/**/}
                      <div className="layout-box mb-4 ">
                        <a href="large-compact-sidebar/dashboard/dashboard1">
                          <img alt src={require('../../assets/images/screenshots/02_preview.png')} /><i className="i-Eye" />
                        </a>
                      </div>
                      <div className="layout-box mb-4 active">
                        <a href="large-sidebar/dashboard/dashboard1">
                          <img alt src={require('../../assets/images/screenshots/04_preview.png')} /><i className="i-Eye" />
                        </a>
                      </div>
                      <div className="layout-box mb-4 ">
                        <a href="horizontal-bar/dashboard/dashboard1">
                          <img alt src={require('../../assets/images/screenshots/horizontal.png')} /><i className="i-Eye" />
                        </a>
                      </div>
                      <div className="layout-box mb-4 mt-30 ">
                        <a href="vertical/dashboard/dashboard1">
                          <span className="badge badge-danger p-1">New</span>
                          <img alt src={require('../../assets/images/screenshots/verticallayout.png')} />
                          <i className="i-Eye" />
                        </a>
                      </div>
                    </div>
                    <div className="text-center pt-3">More layouts coming...</div>
                  </div>
                  <div className="card d-none">
                    <div className="card-header" id="headingOne">
                      <p className="mb-0">
                        Sidebar Colors
                      </p>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionCustomizer">
                      <div className="card-body">
                        <div className="colors sidebar-colors">
                          <a className="color gradient-purple-indigo" data-sidebar-class="sidebar-gradient-purple-indigo">
                            <i className="i-Eye" />
                          </a>
                          <a className="color gradient-black-blue" data-sidebar-class="sidebar-gradient-black-blue">
                            <i className="i-Eye" />
                          </a>
                          <a className="color gradient-black-gray" data-sidebar-class="sidebar-gradient-black-gray">
                            <i className="i-Eye" />
                          </a>
                          <a className="color gradient-steel-gray" data-sidebar-class="sidebar-gradient-steel-gray">
                            <i className="i-Eye" />
                          </a>
                          <a className="color dark-purple active" data-sidebar-class="sidebar-dark-purple">
                            <i className="i-Eye" />
                          </a>
                          <a className="color slate-gray" data-sidebar-class="sidebar-slate-gray">
                            <i className="i-Eye" />
                          </a>
                          <a className="color midnight-blue" data-sidebar-class="sidebar-midnight-blue">
                            <i className="i-Eye" />
                          </a>
                          <a className="color blue" data-sidebar-class="sidebar-blue">
                            <i className="i-Eye" />
                          </a>
                          <a className="color indigo" data-sidebar-class="sidebar-indigo">
                            <i className="i-Eye" />
                          </a>
                          <a className="color pink" data-sidebar-class="sidebar-pink">
                            <i className="i-Eye" />
                          </a>
                          <a className="color red" data-sidebar-class="sidebar-red">
                            <i className="i-Eye" />
                          </a>
                          <a className="color purple" data-sidebar-class="sidebar-purple">
                            <i className="i-Eye" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="card">
                  <div className="card-header" id="headingTwo">
                    <p className="mb-0">
                      RTL
                    </p>
                  </div>
                  <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
                    <div className="card-body">
                      <label className="checkbox checkbox-primary">
                        <input type="checkbox" id="rtl-checkbox" />
                        <span>Enable RTL</span>
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <p className="mb-0">
                      Dark Version
                    </p>
                  </div>
                  <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
                    <div className="card-body">
                      <label className="checkbox checkbox-primary">
                        <input type="checkbox" id="dark-checkbox" />
                        <span>Enable Dark Mode</span>
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );

}

export default Admin;
