import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';
import {
  getAllUOMs,
  getGhgFactorById,
  saveGhgFactor,
  getYears,
  getAllGhgFactors,
  filterScopesFromAllCodeMasters
} from '../services/GhgFactorService'; // Correct the imports

const AddGhgFactor = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formGroup, setFormGroup] = useState({
    ghgKey: '',
    description: '',
    methodology: '',
    year: '',
    scope: '',
    categoryLevel1: '',
    categoryLevel2: '',
    categoryLevel3: '',
    categoryLevel4: '',
    measurementType: '',
    activityUom: '',
    ghg: '',
    uom: '',
    ghgConversionFactor: '',
    references: '',
    status: 'A'
  });
  const [statusOptions] = useState(['A', 'D']);
  const [years, setYears] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [ghgPollutants, setGhgPollutants] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (searchParams.get("ghgFactorId")) {
      setIsEdit(true);
      fetchGhgFactorData(searchParams.get("ghgFactorId"));
    }
    fetchInitialData();
  }, [searchParams.get("ghgFactorId")]);

  const fetchGhgFactorData = async (id) => {
    try {
      const data = await getGhgFactorById(id);
      setFormGroup(data);
    } catch (error) {
      console.error('Failed to fetch GHG factor data:', error);
    }
  };

  const fetchInitialData = async () => {
    try {
      const [codeMastersResponse, uomsResponse, yearsResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' }),
        getAllUOMs(),
        getYears()
      ]);

      if (!codeMastersResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const codeMastersData = await codeMastersResponse.json();

      setGhgPollutants(codeMastersData.filter(x => x.type === CodeMasterType.PollutantID && x.subType === 'GHG'));
      setScopes(filterScopesFromAllCodeMasters(codeMastersData));
      setUomList(uomsResponse.filter(x => x.status === 'A'));
      setYears(yearsResponse);

    } catch (error) {
      console.error('Failed to fetch initial data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.ghgKey || formGroup.ghgKey.length > 10) {
      newErrors.ghgKey = 'GHG Key is required and has a maximum length of 10 characters.';
    }
    if (formGroup.methodology && formGroup.methodology.length > 10) {
      newErrors.methodology = 'Maximum length for Methodology is 10 characters.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      const response = await saveGhgFactor(formGroup, isEdit);

      if (!response.isSuccess) {
        toast.error(`Failed to ${isEdit ? 'update' : 'add'} GHG Factor. ${response.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Exit the function if the response indicates failure
      }

      toast.success(`GHG Factor ${isEdit ? 'updated' : 'added'} successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate('/admin/configurations/ghg-master/list-ghg-factor');
      }, 1000);
    } catch (error) {
      toast.error(`Failed to ${isEdit ? 'update' : 'add'} GHG Factor.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };


  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit GHG Factor' : 'Add GHG Factor'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Source Master-GHG</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">{isEdit ? 'Edit GHG Factor' : 'Add GHG Factor'}</h4> */}
              <hr></hr>
              <form onSubmit={handleSubmit} noValidate>
                <h4>GHG Factor Details</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>GHG Key</label>
                      <input
                        type="text"
                        name="ghgKey"
                        className={`form-control ${errors.ghgKey ? 'is-invalid' : ''}`}
                        value={formGroup.ghgKey}
                        onChange={handleChange}
                        required
                        disabled={isEdit}
                      />
                      {errors.ghgKey && <div className="invalid-feedback">{errors.ghgKey}</div>}
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <div className="form-group">
                      <label>Description</label>
                      <input
                        type="text"
                        name="description"
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                        value={formGroup.description}
                        onChange={handleChange}
                      />
                      {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Methodology</label>
                      <input
                        type="text"
                        name="methodology"
                        className={`form-control ${errors.methodology ? 'is-invalid' : ''}`}
                        value={formGroup.methodology}
                        onChange={handleChange}
                      />
                      {errors.methodology && <div className="invalid-feedback">{errors.methodology}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Year</label>
                      <select
                        name="year"
                        className={`form-control ${errors.year ? 'is-invalid' : ''}`}
                        value={formGroup.year}
                        onChange={handleChange}
                      >
                        <option value="">Select Year</option>
                        {years.map(year => (
                          <option key={year} value={year}>{year}</option>
                        ))}
                      </select>
                      {errors.year && <div className="invalid-feedback">{errors.year}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Scope</label>
                      <select
                        name="scope"
                        className={`form-control ${errors.scope ? 'is-invalid' : ''}`}
                        value={formGroup.scope}
                        onChange={handleChange}
                      >
                        <option value="">Select Scope</option>
                        {scopes.map(scope => (
                          <option key={scope.code} value={scope.code}>{scope.description}</option>
                        ))}
                      </select>
                      {errors.scope && <div className="invalid-feedback">{errors.scope}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Category Level 1</label>
                      <input
                        type="text"
                        name="categoryLevel1"
                        className={`form-control ${errors.categoryLevel1 ? 'is-invalid' : ''}`}
                        value={formGroup.categoryLevel1}
                        onChange={handleChange}
                      />
                      {errors.categoryLevel1 && <div className="invalid-feedback">{errors.categoryLevel1}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Category Level 2</label>
                      <input
                        type="text"
                        name="categoryLevel2"
                        className={`form-control ${errors.categoryLevel2 ? 'is-invalid' : ''}`}
                        value={formGroup.categoryLevel2}
                        onChange={handleChange}
                      />
                      {errors.categoryLevel2 && <div className="invalid-feedback">{errors.categoryLevel2}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Category Level 3</label>
                      <input
                        type="text"
                        name="categoryLevel3"
                        className={`form-control ${errors.categoryLevel3 ? 'is-invalid' : ''}`}
                        value={formGroup.categoryLevel3}
                        onChange={handleChange}
                      />
                      {errors.categoryLevel3 && <div className="invalid-feedback">{errors.categoryLevel3}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Category Level 4</label>
                      <input
                        type="text"
                        name="categoryLevel4"
                        className={`form-control ${errors.categoryLevel4 ? 'is-invalid' : ''}`}
                        value={formGroup.categoryLevel4}
                        onChange={handleChange}
                      />
                      {errors.categoryLevel4 && <div className="invalid-feedback">{errors.categoryLevel4}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Measurement Type</label>
                      <input
                        type="text"
                        name="measurementType"
                        className={`form-control ${errors.measurementType ? 'is-invalid' : ''}`}
                        value={formGroup.measurementType}
                        onChange={handleChange}
                      />
                      {errors.measurementType && <div className="invalid-feedback">{errors.measurementType}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Activity UoM</label>
                      <select
                        name="activityUom"
                        className={`form-control ${errors.activityUom ? 'is-invalid' : ''}`}
                        value={formGroup.activityUom}
                        onChange={handleChange}
                      >
                        <option value="">Select Activity UoM</option>
                        {uomList.map(uom => (
                          <option key={uom.uomId} value={uom.uomId}>{uom.description}</option>
                        ))}
                      </select>
                      {errors.activityUom && <div className="invalid-feedback">{errors.activityUom}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>GHG Pollutant</label>
                      <select
                        name="ghg"
                        className={`form-control ${errors.ghg ? 'is-invalid' : ''}`}
                        value={formGroup.ghg}
                        onChange={handleChange}
                      >
                        <option value="">Select GHG Pollutant</option>
                        {ghgPollutants.map(ghgP => (
                          <option key={ghgP.code} value={ghgP.code}>{ghgP.description}</option>
                        ))}
                      </select>
                      {errors.ghg && <div className="invalid-feedback">{errors.ghg}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>GHG UoM</label>
                      <select
                        name="uom"
                        className={`form-control ${errors.uom ? 'is-invalid' : ''}`}
                        value={formGroup.uom}
                        onChange={handleChange}
                      >
                        <option value="">Select UoM</option>
                        {uomList.map(uom => (
                          <option key={uom.uomId} value={uom.uomId}>{uom.description}</option>
                        ))}
                      </select>
                      {errors.uom && <div className="invalid-feedback">{errors.uom}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>GHG Conversion Factor</label>
                      <input
                        type="number"
                        name="ghgConversionFactor"
                        className={`form-control ${errors.ghgConversionFactor ? 'is-invalid' : ''}`}
                        value={formGroup.ghgConversionFactor}
                        onChange={handleChange}
                      />
                      {errors.ghgConversionFactor && <div className="invalid-feedback">{errors.ghgConversionFactor}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className="form-group">
                      <label>References</label>
                      <input
                        type="text"
                        name="references"
                        className={`form-control ${errors.references ? 'is-invalid' : ''}`}
                        value={formGroup.references}
                        onChange={handleChange}
                      />
                      {errors.references && <div className="invalid-feedback">{errors.references}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={formGroup.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        {statusOptions.map(status => (
                          <option key={status} value={status}>{status}</option>
                        ))}
                      </select>
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/ghg-master/list-ghg-factor')}>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddGhgFactor;
