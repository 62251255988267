import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';
const apiPrefix = `${API_BASE_URL}/dashboard/amt`;

const getDailyAverages = (fromDate) => {
  return axios.get(`${apiPrefix}/daily-averages?fromDate=${fromDate}`);
};

const getStats = (fromDate) => {
  return axios.get(`${apiPrefix}/stats?fromDate=${fromDate}`);
};

const getHourlyAvgForMonth = (fromDate) => {
  return axios.get(`${apiPrefix}/hourly-avg-month?fromDate=${fromDate}`);
};

export default {
  getDailyAverages,
  getStats,
  getHourlyAvgForMonth,
};
