import React, { useState, useEffect, useRef } from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../config';
import ExportComponent from '../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../common/FormatNumber';
import formatDate from '../../../common/formatDate ';

const ListProjectTransaction = () => {
  const navigate = useNavigate();
  const [projectTransactions, setProjectTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [exportFilteredData, setExportFilteredData] = useState([]);


  useEffect(() => {
    fetchProjectTransactions();
  }, []);

  const fetchProjectTransactions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/project/project-tx`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setProjectTransactions(data);
      setFilteredTransactions(data);
    } catch (error) {
      console.error('Failed to fetch project transactions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/project/add-project-transaction/${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete project transaction: ${id}?`);
    if (confirmDelete) {
      try {
        const response = await fetch(`${API_BASE_URL}/project/project-tx/${id}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        toast.success('Project Transaction deleted successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        fetchProjectTransactions();
      } catch (error) {
        console.error('Failed to delete project transaction:', error);
        toast.error('Failed to delete project transaction.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = projectTransactions.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredTransactions(filtered);

    const exportData = filtered.map(item => ({
      'Project ID': item.projectID,
      'Date': item.txDateTime ? formatDate(item.txDateTime) : '',
      'Reduction Achieved': formatWithIndianThousandsSeparatorForExportData(item.reductionAchived ? parseInt(item.reductionAchived).toFixed(0) : item.reductionAchived),
      '% Completed': formatWithIndianThousandsSeparatorForExportData(item.projectPercentCompleted ? parseFloat(item.projectPercentCompleted).toFixed(2) : item.projectPercentCompleted),
      'Details': item.projectDetails
    }));
    setExportFilteredData(exportData);

  }, [search, projectTransactions]);

  const columns = [
    { name: 'Project ID', selector: row => row.projectID, sortable: true },
    {
      name: 'Date',
      selector: row => row.txDateTime,
      sortable: true,
      cell: row => formatDate(row.txDateTime)
    },
    {
      name: 'Reduction Achieved',
      selector: row => row.reductionAchived,
      cell: row => formatWithIndianThousandsSeparator(row.reductionAchived ? parseInt(row.reductionAchived).toFixed(0) : row.reductionAchived),
      sortable: true,
      // format: row => row.reductionAchived ? parseFloat(row.reductionAchived).toFixed(0) : row.reductionAchived
    },
    {
      name: '% Completed',
      selector: row => row.projectPercentCompleted,
      cell: row => formatWithIndianThousandsSeparator(row.projectPercentCompleted ? parseFloat(row.projectPercentCompleted).toFixed(2) : row.projectPercentCompleted),
      sortable: true
    },
    { name: 'Details', selector: row => row.projectDetails, sortable: true },
    {
      name: 'Edit',
      width: '75px',
      cell: row => (
        <FontAwesomeIcon
          icon={faPen}
          onClick={() => handleEdit(row.id)}
          style={{ cursor: 'pointer' }}
        />
      )
    },
    {
      name: 'Delete',
      width: '75px',
      cell: row => (
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => handleDelete(row.id)}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      )
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Project_Transaction_${new Date().toLocaleDateString()}`;

  const title = "Project Transactions Report";

  return (
    <div className="main-content">
      <div className="breadcrumb mt-3">
        <h1>Project Transactions</h1>
        <ul>
          <li>Projects</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              <div className='row pull-right'>
                <div className='col-md-10'>
                  <Link to="/admin/project/add-project-transaction" className="btn btn-primary">
                    Add New Record
                  </Link>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-10'>
                  {filteredTransactions.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>

                    <DataTable
                      columns={columns}
                      data={filteredTransactions}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ListProjectTransaction;
