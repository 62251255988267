import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getProjectCategoryById, saveProjectCategory } from '../services/projectService';

const AddProjectCategoryModal = ({ show, onHide, categoryId, isEdit, reload }) => {
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [title, setTitle] = useState('Add Project Category');

  useEffect(() => {
    if (isEdit && categoryId) {
      setTitle('Edit Project Category');
      loadProjectCategory(categoryId);
    } else {
      setTitle('Add Project Category');
      resetForm();
    }
  }, [isEdit, categoryId, show]);

  const loadProjectCategory = async (id) => {
    try {
      const data = await getProjectCategoryById(id);
      setValue('projectCategoryId', data.projectCategoryId);
      setValue('projectCategory', data.projectCategory);
      setValue('status', data.status);
    } catch (error) {
      toast.error('Failed to load project category.');
    }
  };

  const resetForm = () => {
    reset();
  };

  const onSubmit = async (data) => {
    try {
      const response = await saveProjectCategory(data, isEdit);
      if (response.isSuccess) {
        toast.success('Project saved successfully');
        onHide();
        reload();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error('Error saving project category.');
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="projectCategoryId">Project ID</label>
            <input
              type="text"
              className={`form-control ${errors.projectCategoryId ? 'is-invalid' : ''}`}
              id="projectCategoryId"
              name="projectCategoryId"
              placeholder="Project Category ID"
              {...register('projectCategoryId', { required: true })}
              readOnly={isEdit}
            />
            {errors.projectCategoryId && <div className="invalid-feedback">Project CategoryId is required.</div>}
          </div>
          <div className="form-group">
            <label htmlFor="projectCategory">Project Category</label>
            <input
              type="text"
              className={`form-control ${errors.projectCategory ? 'is-invalid' : ''}`}
              id="projectCategory"
              name="projectCategory"
              placeholder="Project Category Description"
              {...register('projectCategory', { required: true })}
            />
            {errors.projectCategory && <div className="invalid-feedback">Project Category is required.</div>}
          </div>
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              className={`form-control ${errors.status ? 'is-invalid' : ''}`}
              name="status"
              id="status"
              {...register('status', { required: true })}
            >
              <option value="">Status</option>
              <option value="A">Active</option>
              <option value="D">Deactivated</option>
            </select>
            {errors.status && <div className="invalid-feedback">Status is required.</div>}
          </div>
          <div className="form-group">
            <Button type="submit" className="btn btn-primary">
              Save
            </Button>
            <Button type="button" className="btn btn-secondary ml-3" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};

export default AddProjectCategoryModal;
