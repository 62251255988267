import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';

const AddHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [header, setHeader] = useState({
    headerId: '',
    headerName: '',
    facilityId: '',
    headerType: '',
    headerClassification: '',
    stackId: '',
    molecularWeight: '',
    btuscf: '',
    recoveryValveNumber: '',
    recoveryValveType: '',
    recoveryValveSetPoint: '',
    minUnaccountedFixed: '',
    unAccountedUOM: '',
    recycleMaxCapacity: '',
    status: 'A'
  });
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [headerTypes, setHeaderTypes] = useState([]);
  const [headerClassifications, setHeaderClassifications] = useState([]);
  const [uoms, setUoms] = useState([]);

  useEffect(() => {
    if (searchParams.get("headerId")) {
      fetchHeaderData(searchParams.get("headerId"));
      setIsEdit(true);
    }
    fetchInitialData();
  }, [searchParams.get("headerId")]);

  const fetchHeaderData = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/ems-master/header/${id}`, {
        method: 'GET',
        // headers: {
        //   'Content-Type': 'application/json',
        // },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setHeader(data);
    } catch (error) {
      console.error('Failed to fetch header data:', error);
    }
  };

  const fetchInitialData = async () => {
    try {
      const [facilitiesResponse, headerTypesResponse, headerClassificationsResponse, uomsResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/home/facilities`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/uoms`, { credentials: 'include' })
      ]);

      if (!facilitiesResponse.ok || !headerTypesResponse.ok || !headerClassificationsResponse.ok || !uomsResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const [facilitiesData, headerTypesData, headerClassificationsData, uomsData] = await Promise.all([
        facilitiesResponse.json(),
        headerTypesResponse.json(),
        headerClassificationsResponse.json(),
        uomsResponse.json()
      ]);

      setFacilities(facilitiesData);

      const filteredHeaderTypes = headerTypesData.filter(item => item.type === CodeMasterType.HeaderType);
      setHeaderTypes(filteredHeaderTypes);

      const filteredHeaderClassifications = headerClassificationsData.filter(item => item.type === CodeMasterType.HeaderClassification);
      setHeaderClassifications(filteredHeaderClassifications);

      setUoms(uomsData);
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!header.headerId || header.headerId.length > 10) {
      newErrors.headerId = 'Header ID is required and has a maximum length of 10 characters.';
    }
    if (!header.headerName) {
      newErrors.headerName = 'Header Name is required.';
    }
    if (!header.facilityId) {
      newErrors.facilityId = 'Facility is required.';
    }
    if (!header.headerType) {
      newErrors.headerType = 'Header Type is required.';
    }
    if (!header.headerClassification) {
      newErrors.headerClassification = 'Header Classification is required.';
    }
    if (!header.stackId) {
      newErrors.stackId = 'Stack ID is required.';
    }
    if (!header.molecularWeight) {
      newErrors.molecularWeight = 'Molecular Weight is required.';
    }
    if (!header.btuscf) {
      newErrors.btuscf = 'BTUSCF is required.';
    }
    if (!header.recoveryValveNumber) {
      newErrors.recoveryValveNumber = 'Recovery Valve Number is required.';
    }
    if (!header.recoveryValveType) {
      newErrors.recoveryValveType = 'Recovery Valve Type is required.';
    }
    if (!header.recoveryValveSetPoint) {
      newErrors.recoveryValveSetPoint = 'Recovery Valve Set Point is required.';
    }
    if (!header.minUnaccountedFixed) {
      newErrors.minUnaccountedFixed = 'Min UnAccounted Fixed is required.';
    }
    if (!header.unAccountedUOM) {
      newErrors.unAccountedUOM = 'UnAccounted UOM is required.';
    }
    if (!header.recycleMaxCapacity) {
      newErrors.recycleMaxCapacity = 'Recycle Max Capacity is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHeader(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const apiEndpoint = isEdit ? `${API_BASE_URL}/ems-master/header/update` : `${API_BASE_URL}/ems-master/header/save`;
    const method = isEdit ? 'PUT' : 'POST';

    try {
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(header)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Header ${isEdit ? 'updated' : 'added'} successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate('/admin/configurations/ems-master/list-header');
    } catch (error) {
      console.error(`Failed to ${isEdit ? 'update' : 'add'} header:`, error);
      toast.error(`Failed to ${isEdit ? 'update' : 'add'} header.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Header' : 'Add Header'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Flare Master</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">{isEdit ? 'Edit Header' : 'Add Header'}</h4> */}
              <hr></hr>
              <form onSubmit={handleSubmit} noValidate>
                <h4>Header Details</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Header ID</label>
                      <input
                        type="text"
                        name="headerId"
                        className={`form-control ${errors.headerId ? 'is-invalid' : ''}`}
                        value={header.headerId}
                        onChange={handleChange}
                        disabled={isEdit}
                        required
                      />
                      {errors.headerId && <div className="invalid-feedback">{errors.headerId}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Header Name</label>
                      <input
                        type="text"
                        name="headerName"
                        className={`form-control ${errors.headerName ? 'is-invalid' : ''}`}
                        value={header.headerName}
                        onChange={handleChange}
                        required
                      />
                      {errors.headerName && <div className="invalid-feedback">{errors.headerName}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Facility</label>
                      <select
                        name="facilityId"
                        className={`form-control ${errors.facilityId ? 'is-invalid' : ''}`}
                        value={header.facilityId}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Facility</option>
                        {facilities.map(facility => (
                          <option key={facility.facilityId} value={facility.facilityId}>{facility.facilityName}</option>
                        ))}
                      </select>
                      {errors.facilityId && <div className="invalid-feedback">{errors.facilityId}</div>}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Header Type</label>
                      <select
                        name="headerType"
                        className={`form-control ${errors.headerType ? 'is-invalid' : ''}`}
                        value={header.headerType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Header Type</option>
                        {headerTypes.map(type => (
                          <option key={type.code} value={type.code}>{type.description}</option>
                        ))}
                      </select>
                      {errors.headerType && <div className="invalid-feedback">{errors.headerType}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Header Classification</label>
                      <select
                        name="headerClassification"
                        className={`form-control ${errors.headerClassification ? 'is-invalid' : ''}`}
                        value={header.headerClassification}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Header Classification</option>
                        {headerClassifications.map(classification => (
                          <option key={classification.code} value={classification.code}>{classification.description}</option>
                        ))}
                      </select>
                      {errors.headerClassification && <div className="invalid-feedback">{errors.headerClassification}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Stack ID</label>
                      <input
                        type="text"
                        name="stackId"
                        className={`form-control ${errors.stackId ? 'is-invalid' : ''}`}
                        value={header.stackId}
                        onChange={handleChange}
                        required
                      />
                      {errors.stackId && <div className="invalid-feedback">{errors.stackId}</div>}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Molecular Weight</label>
                      <input
                        type="text"
                        name="molecularWeight"
                        className={`form-control ${errors.molecularWeight ? 'is-invalid' : ''}`}
                        value={header.molecularWeight}
                        onChange={handleChange}
                        required
                      />
                      {errors.molecularWeight && <div className="invalid-feedback">{errors.molecularWeight}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>BTUSCF</label>
                      <input
                        type="text"
                        name="btuscf"
                        className={`form-control ${errors.btuscf ? 'is-invalid' : ''}`}
                        value={header.btuscf}
                        onChange={handleChange}
                        required
                      />
                      {errors.btuscf && <div className="invalid-feedback">{errors.btuscf}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Recovery Valve Number</label>
                      <input
                        type="text"
                        name="recoveryValveNumber"
                        className={`form-control ${errors.recoveryValveNumber ? 'is-invalid' : ''}`}
                        value={header.recoveryValveNumber}
                        onChange={handleChange}
                        required
                      />
                      {errors.recoveryValveNumber && <div className="invalid-feedback">{errors.recoveryValveNumber}</div>}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Recovery Valve Type</label>
                      <input
                        type="text"
                        name="recoveryValveType"
                        className={`form-control ${errors.recoveryValveType ? 'is-invalid' : ''}`}
                        value={header.recoveryValveType}
                        onChange={handleChange}
                        required
                      />
                      {errors.recoveryValveType && <div className="invalid-feedback">{errors.recoveryValveType}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Recovery Valve Set Point</label>
                      <input
                        type="text"
                        name="recoveryValveSetPoint"
                        className={`form-control ${errors.recoveryValveSetPoint ? 'is-invalid' : ''}`}
                        value={header.recoveryValveSetPoint}
                        onChange={handleChange}
                        required
                      />
                      {errors.recoveryValveSetPoint && <div className="invalid-feedback">{errors.recoveryValveSetPoint}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Min Unaccounted Fixed</label>
                      <input
                        type="text"
                        name="minUnaccountedFixed"
                        className={`form-control ${errors.minUnaccountedFixed ? 'is-invalid' : ''}`}
                        value={header.minUnaccountedFixed}
                        onChange={handleChange}
                        required
                      />
                      {errors.minUnaccountedFixed && <div className="invalid-feedback">{errors.minUnaccountedFixed}</div>}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Unaccounted UoM</label>
                      <select
                        name="unAccountedUOM"
                        className={`form-control ${errors.unAccountedUOM ? 'is-invalid' : ''}`}
                        value={header.unAccountedUOM}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select UoM</option>
                        {uoms.map(uom => (
                          <option key={uom.uomId} value={uom.uomId}>{uom.description}</option>
                        ))}
                      </select>
                      {errors.unAccountedUOM && <div className="invalid-feedback">{errors.unAccountedUOM}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Recycle Max Capacity</label>
                      <input
                        type="text"
                        name="recycleMaxCapacity"
                        className={`form-control ${errors.recycleMaxCapacity ? 'is-invalid' : ''}`}
                        value={header.recycleMaxCapacity}
                        onChange={handleChange}
                        required
                      />
                      {errors.recycleMaxCapacity && <div className="invalid-feedback">{errors.recycleMaxCapacity}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Status</label>
                      <input
                        type="text"
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={header.status}
                        onChange={handleChange}
                        required
                      />
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>

                <button type="button" className="btn btn-secondary" onClick={() => navigate('/admin/configurations/ems-master/list-header')}>Cancel</button>
                <button type="submit" className="btn btn-primary float-right mr-3">
                  {isEdit ? 'Update' : 'Save'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddHeader;
