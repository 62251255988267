const CodeMasterType = {
    CategoryID: "categoryID",
    ComponentUOM: "ComponentUOM",
    CVtoFlowMethod: "CVtoFlowMethod",
    DataCollectionMode: "dataCollectionMode",
    DeviceClass: "deviceClass",
    DeviceMode: "deviceMode",
    EqptType: "eqptType",
    ErrorType: "errorType",
    FacilityCategory: "facilityCategory",
    FEEDTYPE: "FEEDTYPE",
    FittingType: "fittingType",
    HeaderClassification: "headerClassification",
    HeaderType: "headerType",
    LeakageCalcSwitch: "leakageCalcSwitch",
    MaterialState: "materialState",
    PlanType: "planType",
    ReadingDataType: "readingDataType",
    ReadingType: "readingType",
    Reasoncategory: "reasoncategory",
    ReasonType: "reasonType",
    Tagtype: "TAGTYPE",
    ValveType: "ValveType",
    ZoneOrArea: "zoneOrArea",
    SubClass: "subclass",
    UomType: "uomType",
    DeviceType: "deviceType",
    LIMSproperty: "LIMSproperty",
    PollutantID: "PollutantId",
    GHGPollutant: "GHGPollutant",
    Scope: "SCOPE",
    IpccYear: "IPCCYear",
    EmissionGroup: "EmissionGroup",
    AlertTable: "AlertTable",
    AlertCondition: "AlertCondition",
    AlertPeriod: "AlertPeriod",
    FlowType: "FLOWTYPE",
    Company: "Company",
    FormulaVariable: "FormulaVariable",
    CompositionType: "CompositionType",
    CalcMethod: 'CalcMethod',
    VesselType: 'vesselType',
  };
  
  export default CodeMasterType;
  