// src/constants/labels.js

export const REPORTS_LABELS = {
    FLARING: {
      MAIN: "Flaring",
      HEADER_WISE_FLARING: "Header Flaring",
      PLANT_FLARING_ANALYSIS: "Plant Flaring Analysis",
      DAILY_PLANT_WISE_FLARING: "Plant Flaring",
      DAILY_FLARING: "Daily Flaring",
      DEVICE_FLARING: "Source Flaring",
      REASON_WISE_FLARING: "Flaring Reasons",
      FACILITY_FLARING: "Facility Flaring",
      UNACCOUNTED_FLARING: "Unaccounted Flaring",
      PLAN_VS_ACTUAL: "Plan vs Actual",
      FLARING_AUDIT: "Flaring Audit",
      FLARING_KPI_REPORT: "Flaring KPI Report"
    },
    EMISSIONS: {
      MAIN: "Emissions",
      FACILITY_EMISSIONS: "Facility Emissions",
      PLANT_EMISSIONS: "Plant Emissions",
      SOURCE_EMISSIONS: "Source Emissions",
      HOURLY_EMISSIONS: "Hourly Emissions",
      EMISSION_REASONS: "Emission Reasons",
      STACK_EMISSIONS: "Stack Emissions",
      PLAN_VS_ACTUAL_EMISSIONS: "Plan Vs Actual Emissions"
    },
    GHG: {
      MAIN: "Greenhouse Gases",
      FACILITY_GHG_EMISSIONS: "Facility Emissions",
      PLANT_GHG_EMISSIONS: "Plant Emissions",
      SOURCE_GHG_EMISSIONS: "Source Emissions",
      HOURLY_GHG_EMISSIONS: "Hourly Emissions",
      STACK_GHG_EMISSIONS: "Stack Emissions",
      GHG_REASONS_EMISSIONS: "Emission Reasons",
      PLAN_VS_ACTUAL_GHG_EMISSIONS: "Plan vs Actual Emissions",
      SCOPE_WISE_GHG_EMISSIONS: "Emission by Scope",
      COMPANYWIDE_GHG: "Companywide GHG",
      GHG_INVENTORY: "GHG Inventory",
      GHG_INVENTORY_SCOPE: "Inventory By Scope",
      GHG_UNCERTAINTIES: "GHG Uncertainties"
    },
    AMMNET: {
      MAIN: "AMMNET",
      STATION_EMISSIONS: "Station Emissions",
      SOURCE_EMISSIONS: "Source Emissions",
      PERIODIC_EMISSIONS: "Periodic Emissions",
      REGION_EMISSIONS: "Region Emissions",
      EMISSION_REASONS: "Emission Reasons",
      EXCEEDANCES: "Exceedances",
      VIOLATIONS: "Violations"
    },
    // HEALTH_CHECK: {
    //   FLARING_ERRORS: "Flaring Errors",
    //   EMISSION_ERRORS: "Emission Errors",
    //   GHG_ERRORS: "GHG Errors",
    //   AMMNET_ERRORS: "AMMNET Errors",
    //   FMS_HEALTH: "FMS Health",
    //   EMS_HEALTH: "EMS Health"
    // }
  };
  