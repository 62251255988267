import axios from 'axios';

import { API_BASE_URL } from '../../../../../config';

export const getAllEmissionPlans = async () => {
  const response = await axios.get(API_BASE_URL);
  return response.data;
};

export const getEmissionPlanById = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/system-plan/emission-plan/${id}`);
  return response.data;
};

export const saveEmissionPlan = async (emissionPlan, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/system-plan/emission-plan/update` : `${API_BASE_URL}/system-plan/emission-plan/save`;
  const method = isEdit ? 'put' : 'post';
  const response = await axios[method](url, emissionPlan);
  return response.data;
};

export const deleteEmissionPlan = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/system-plan/emission-plan/delete/${id}`);
  return response.data;
};

export const canDeleteEmissionPlan = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/system-plan/emission-plan/can-delete/${id}`);
  return response.data;
};
