// Example of a service file using axios for HTTP requests
import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';
const API_URL = `${API_BASE_URL}/configuration`;
const getOnboardings = async () => {
  const url = `${API_URL}/getonboarding`;
  const response = await axios.get(url);
  return response.data;
};

const saveOnboarding = async (formData) => {
  const url = `${API_URL}/saveonboarding`;
  const response = await axios.post(url, formData);
  return response.data;
};

export { getOnboardings, saveOnboarding };
