import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../config';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import formatDate from '../../common/formatDate ';

const HelpComponent = () => {
  const [uploadForm, setUploadForm] = useState({
    file: null,
    category: '',
  });
  const [name, setName] = useState("Choose Files");
  const [fileCategories, setFileCategories] = useState([]);
  const [fileCategoriesDropdown, setFileCategoriesDropdown] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchHelpItems();
    fetchCategories();
  }, []);

  const fetchHelpItems = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/help/items`);
      organizeData(response.data);
    } catch (error) {
      toast.error('Failed to fetch help items.');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/help/directorycategory`);
      setFileCategoriesDropdown(response.data);
    } catch (error) {
      toast.error('Failed to fetch categories.');
    }
  };

  const onFileSelected = (event) => {
    setFileError(null);
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      if (validateFile(selectedFile)) {
        setUploadForm({
          ...uploadForm,
          file: selectedFile,
        });
        setName(selectedFile.name);
      }
    }
  };

  const validateFile = (file) => {
    const allowedExtensions = ['docx', 'pdf', 'xlsx', 'csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      setFileError('Invalid file type. Allowed types are DOCX, PDF, XLSX, and CSV.');
      return false;
    }
    return true;
  };

  const organizeData = (data) => {
    const groupedData = {};
    data.forEach(file => {
      const categoryId = file.categoryId;
      if (!groupedData[categoryId]) {
        groupedData[categoryId] = {
          category: categoryId,
          files: [],
        };
      }
      groupedData[categoryId].files.push(file);
    });
    setFileCategories(Object.values(groupedData));
  };

  const uploadFile = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (uploadForm.file && uploadForm.category) {
      formData.append('CategoryId', uploadForm.category);
      formData.append('File', uploadForm.file, uploadForm.file.name);

      try {
        const response = await axios.post(`${API_BASE_URL}/help/Upload`, formData);
        if (response.status === 200) {
          fetchHelpItems();
        }
      } catch (error) {
        toast.error('Failed to upload file.');
      }
    }
  };

  const downloadFile = async (fileName) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/help/download?fileName=${fileName}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      toast.error('File not found.');
    }
  };

  const deleteFile = async (fileName, categoryId) => {
    try {
      await axios.delete(`${API_BASE_URL}/help/Delete?fileName=${fileName}&categoryid=${categoryId}`);
      fetchHelpItems();
    } catch (error) {
      toast.error('Failed to delete file.');
    }
  };

  const getCategoryName = (categoryId) => {
    const category = fileCategoriesDropdown.find(cat => cat.id === categoryId);
    return category ? category.name : 'Unknown Category';
  };

  const allCategories = fileCategoriesDropdown.map((category) => {
    const matchedCategory = fileCategories.find(cat => cat.category === category.id);
    return {
      category: category.id,
      name: category.name,
      files: matchedCategory ? matchedCategory.files : [],
    };
  });

  const filteredCategories = allCategories.map(category => ({
    ...category,
    files: category.files.filter(file => file.fileName.toLowerCase().includes(searchTerm.toLowerCase()))
  }));

  return (
    <div>
      <ToastContainer />
      <form onSubmit={uploadFile}>
        <div className="card mt-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <div className="custom-file">
                  <input type="file" className="custom-file-input" onChange={onFileSelected} />
                  <label className="custom-file-label">{name}</label>
                </div>
              </div>
              <div className='col-md-3 col-sm-3'>
                <select
                  className="form-control"
                  value={uploadForm.category}
                  onChange={(e) => setUploadForm({ ...uploadForm, category: e.target.value })}
                >
                  <option value="">Select Category</option>
                  {fileCategoriesDropdown.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3 col-sm-3'>
                <button type="submit" className="btn btn-primary" disabled={!uploadForm.file || !uploadForm.category}>
                  Upload Files
                </button>
              </div>
              <div className='col-md-2 col-sm-2'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            {fileError && (
              <div className="row">
                <div className="error">
                  <label style={{ fontSize: '12px', color: 'red' }}>{fileError}</label>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>

      <div className="mt-4">
        {filteredCategories.map((category) => (
          <div key={category.category} className="card mb-3">
            <div className="card-header">
              <h4>{category.name}</h4>
            </div>
            <div className="card-body">
              {category.files.length === 0 ? (
                <div className='row'>
                  <div className='col-md-12'>
                <h5 className='text-center'>No data found for this ({category.name}) Category </h5>
                </div>
                </div>
              ) : (
                <ul className="list-group">
                  <div className="row">
                  {category.files.map((file) => (
                    <li key={file.fileName} className="list-group-item d-flex justify-content-between align-items-center">
                        <div className='col-sm-9'>
                        <div className="file-name">
                          {/* <a href='#' onClick={() => downloadFile(file.fileName)}> */}
                            <span>{file.fileName}</span>
                          {/* </a> */}
                        </div>
                        </div>
                        <div className='col-sm-2 ml-5'>
                        <small className=''>Date Added: {formatDate(file.dateCreated)}</small>
                        </div>
                      
                      {/* <div className='col-sm-1'> */}
                      <a href='#' onClick={() => downloadFile(file.fileName)}>
                        <FontAwesomeIcon
                        icon={faDownload} />
                          </a>
                      {/* </div> */}
                      {/* <div className='col-sm-1 pull-right'> */}
                      <FontAwesomeIcon
                        icon={faTrash}
                        className='btn btn-white'
                        title='Delete File'
                        onClick={() => deleteFile(file.fileName, file.categoryId)}
                        style={{ cursor: 'pointer', color: 'red' }}
                      />
                      {/* </div> */}
                    </li>
                  ))}
                  </div>
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpComponent;
