import React, { useState, useEffect, useRef } from 'react';
import DataCorrectionService from '../services/DataCorrectionService';
import DataTable from 'react-data-table-component';
import { Button, Modal, Card } from 'react-bootstrap';
import EditEmissionValidation from './EditEmissionValidation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';
import ExportComponent from '../../../../common/ExportComponent';
import { format } from 'date-fns';
import formatDateTime from '../../../../common/formatDateTime';
const EmissionValidationList = () => {
  const [emissionValidationItems, setEmissionValidationItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null); // New state for selected date
  const [exportFilteredData, setExportFilteredData] = useState([]);

  const datepickerRef = useRef();

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
      fetchEmissionValidationData(formattedDate);
    }
  }, [selectedDate]);

  const fetchEmissionValidationData = async (date = '') => {
    setIsLoading(true);
    try {
      const response = await DataCorrectionService.getEmissionValidation(date);
      setEmissionValidationItems(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Failed to fetch emission validation data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };



  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete item: ${id}?`);
    if (confirmDelete) {
      try {
        await DataCorrectionService.deleteManualValvesItem(id);
        if (selectedDate) {
          const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
          fetchEmissionValidationData(formattedDate);
        }
      } catch (error) {
        console.error('Failed to delete item:', error);
      }
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = emissionValidationItems.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );

    // If filtered data is empty, add a placeholder row to ensure headers are displayed
    if (filtered.length === 0) {
      filtered.push({
        txDateTime: '',
        deviceID: '',
        headerID: '',
        facilityID: '',
        plantID: '',
        emissionType: '',
        pollutant: '',
        uomID: '',
        emission: '',
        emissionAvg: '',
        emissionMin: '',
        emissionMax: '',
        reasonID: '',
        adjEmission: '',
        adjAvgEmission: '',
        adjEmissionMin: '',
        adjEmissionMax: '',
        adjReasonID: '',
        engineerID: '',
        userComments: ''
      });
    }

    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Tx Date & Time': item.txDateTime ? formatDateTime(item.txDateTime) : '',
      'Source ID': item.deviceID,
      'Header ID': item.headerID,
      'Facility ID': item.facilityID,
      'Plant ID': item.plantID,
      'Emission Type': item.emissionType,
      'Pollutant': item.pollutant,
      'UoM': item.uomID,
      'Emission': formatWithIndianThousandsSeparatorForExportData(item.emission ? item.emission.toFixed(0) : item.emission),
      'Emission Avg': formatWithIndianThousandsSeparatorForExportData(item.emissionAvg ? item.emissionAvg.toFixed(0) : item.emissionAvg),
      'Emission Min': formatWithIndianThousandsSeparatorForExportData(item.emissionMin ? item.emissionMin.toFixed(0) : item.emissionMin),
      'Emission Max': formatWithIndianThousandsSeparatorForExportData(item.emissionMax ? item.emissionMax.toFixed(0) : item.emissionMax),
      'Reason': item.reasonID,
      'Adj Emission': formatWithIndianThousandsSeparatorForExportData(item.adjEmission ? item.adjEmission.toFixed(0) : item.adjEmission),
      'Adj Avg Emission': formatWithIndianThousandsSeparatorForExportData(item.adjAvgEmission ? item.adjAvgEmission.toFixed(0) : item.adjAvgEmission),
      'Adj Emission Min': formatWithIndianThousandsSeparatorForExportData(item.adjEmissionMin ? item.adjEmissionMin.toFixed(0) : item.adjEmissionMin),
      'Adj Emission Max': formatWithIndianThousandsSeparatorForExportData(item.adjEmissionMax ? item.adjEmissionMax.toFixed(0) : item.adjEmissionMax),
      'Adj Reason': item.adjReasonID,
      'Engineer ID': item.engineerID,
      'User Comments': item.userComments
    }));
    setExportFilteredData(exportData);

  }, [search, emissionValidationItems]);

  const handleEdit = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };

  const columns = [
    { name: 'Tx Date & Time', selector: row => (row.txDateTime) ? new Date(row.txDateTime).toLocaleString() : '', sortable: true, resizable: true, width: '150px',
      cell: row => (row.txDateTime) ? formatDateTime(row.txDateTime) : ''
     },
    { name: 'Source ID', selector: row => row.deviceID, sortable: true, resizable: true },
    { name: 'Header ID', selector: row => row.headerID, sortable: true, resizable: true },
    { name: 'Facility ID', selector: row => row.facilityID, sortable: true, resizable: true },
    { name: 'Plant ID', selector: row => row.plantID, sortable: true, resizable: true },
    { name: 'Emission Type', selector: row => row.emissionType, sortable: true, resizable: true },
    { name: 'Pollutant', selector: row => row.pollutant, sortable: true, resizable: true },
    { name: 'UoM', selector: row => row.uomID, sortable: true, resizable: true },
    {
      name: 'Emission',
      selector: row => row.emission,
      cell: row => formatWithIndianThousandsSeparator(row.emission ? row.emission.toFixed(0) : row.emission),
      sortable: true,
      resizable: true
    },
    {
      name: 'Emission Avg',
      selector: row => row.emissionAvg,
      cell: row => formatWithIndianThousandsSeparator(row.emissionAvg ? row.emissionAvg.toFixed(0) : row.emissionAvg),
      sortable: true,
      resizable: true
    },
    {
      name: 'Emission Min',
      selector: row => row.emissionMin,
      cell: row => formatWithIndianThousandsSeparator(row.emissionMin ? row.emissionMin.toFixed(0) : row.emissionMin),
      sortable: true,
      resizable: true
    },
    {
      name: 'Emission Max',
      selector: row => row.emissionMax,
      cell: row => formatWithIndianThousandsSeparator(row.emissionMax ? row.emissionMax.toFixed(0) : row.emissionMax),
      sortable: true,
      resizable: true
    },
    {
      name: 'Reason',
      selector: row => row.reasonID,
      sortable: true,
      resizable: true
    },
    {
      name: 'Adj Emission',
      selector: row => row.adjEmission,
      cell: row => formatWithIndianThousandsSeparator(row.adjEmission ? row.adjEmission.toFixed(0) : row.adjEmission),
      sortable: true,
      resizable: true
    },
    {
      name: 'Adj Avg Emission',
      selector: row => row.adjAvgEmission,
      cell: row => formatWithIndianThousandsSeparator(row.adjAvgEmission ? row.adjAvgEmission.toFixed(0) : row.adjAvgEmission),
      sortable: true,
      resizable: true
    },
    {
      name: 'Adj Emission Min',
      selector: row => row.adjEmissionMin,
      cell: row => formatWithIndianThousandsSeparator(row.adjEmissionMin ? row.adjEmissionMin.toFixed(0) : row.adjEmissionMin),
      sortable: true,
      resizable: true
    },
    {
      name: 'Adj Emission Max',
      selector: row => row.adjEmissionMax,
      cell: row => formatWithIndianThousandsSeparator(row.adjEmissionMax ? row.adjEmissionMax.toFixed(0) : row.adjEmissionMax),
      sortable: true,
      resizable: true
    },
    { name: 'Adj Reason', selector: row => row.adjReasonID, sortable: true, resizable: true },
    { name: 'Engineer ID', selector: row => row.engineerID, sortable: true, resizable: true },
    { name: 'User Comments', selector: row => row.userComments, sortable: true, resizable: true },
    {
      name: 'Edit',
      width: '75px',
      cell: row => row.txDateTime ? (
        <FontAwesomeIcon
          icon={faPencilAlt}
          onClick={() => handleEdit(row)}
          style={{ cursor: 'pointer', color: 'blue' }}
        />
      ) : null,
    },
    // {
    //   name: 'Delete',
    //   cell: row => (
    //     <FontAwesomeIcon
    //       icon={faTrash}
    //       onClick={() => handleDelete(row.txDateTime)}
    //       style={{ cursor: 'pointer', color: 'red' }}
    //     />
    //   )
    // },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Emission_Validation_${new Date().toLocaleDateString()}`;

  const headerData = [
    { label: 'Select Date Range', value: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

const title = "Emission Validations";

  return (
    <div className="main-content">
      <div className="row mb-4">
        <div className="col-sm-12">
          <div className='breadcrumb mt-3'>
            <h1>Emission Validations</h1>
            <ul>
              <li>Data Corrections</li>
              <li>EMS</li>
            </ul>
            <div className="separator-breadcrumb border-top" />
          </div>
          {/* <div className="separator-breadcrumb border-top" /> */}
          <div className='row'>
            <div className="col-sm-3">
              <label htmlFor="dateRange" className="control-label">Select Date Range</label>
              <div className={'datepicker-parent'}>
                <DatePicker
                  ref={datepickerRef}
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select a date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-sm-12">
              {isLoading && <div>Loading...</div>}
              {!isLoading && (

                <>
                  <div className='row mt-3'>
                    <div className='col-md-10'>
                      {exportFilteredData.length > 0 && (
                        <ExportComponent data={exportFilteredData} headerData={headerData} columns={columns} fileName={fileName} title={title} />
                      )}
                    </div>
                    <div className='col-md-2'>
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control mb-3"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    customStyles={customStyles}
                    pagination
                    highlightOnHover
                    striped
                  />
                </>

              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Emission Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditEmissionValidation data={selectedData} onClose={() => setShowModal(false)} onSave={() => {
            if (selectedDate) {
              const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
              fetchEmissionValidationData(formattedDate);
            }
          }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmissionValidationList;
