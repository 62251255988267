import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataCorrectionService from '../services/DataCorrectionService';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';

const EditEmissionValidation = ({ data, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    id: data.id,
    deviceID: data.deviceID,
    emission: data.emission,
    emissionAvg: data.emissionAvg,
    emissionMin: data.emissionMin,
    emissionMax: data.emissionMax,
    reasonID: data.reasonID,
    adjEmission: data.adjEmission,
    adjAvgEmission: data.adjAvgEmission,
    adjEmissionMin: data.adjEmissionMin,
    adjEmissionMax: data.adjEmissionMax,
    adjReasonID: data.adjReasonID,
    engineerID: data.engineerID,
    userComments: data.userComments || '', // Ensure this field is properly initialized
  });
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    fetchReasons();
  }, []);

  const fetchReasons = async () => {
    try {
      const response = await DataCorrectionService.getAllReasons();
      setReasons(response.data);
    } catch (error) {
      console.error('Failed to fetch reasons:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await DataCorrectionService.updateEmissionValidation(formData);
      onSave();
      onClose();
      toast.success(`EMS Validation Updated successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Failed to update emission validation:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            {/* <h4>Edit Emission Validation</h4> */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Source Id</label>
              <input
                type="text"
                className="form-control"
                name="deviceID"
                value={formData.deviceID}
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Emission</label>
              <input
                type="text"
                className="form-control"
                name="emission"
                value={formData.emission}
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Avg Emission</label>
              <input
                type="text"
                className="form-control"
                name="emissionAvg"
                value={formData.emissionAvg}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Min Emission</label>
              <input
                type="text"
                className="form-control"
                name="emissionMin"
                value={formData.emissionMin}
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Max Emission</label>
              <input
                type="text"
                className="form-control"
                name="emissionMax"
                value={formData.emissionMax}
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Reason</label>
              <input
                type="text"
                className="form-control"
                name="reasonID"
                value={formData.reasonID}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Adj Emission</label>
              <input
                type="text"
                className="form-control"
                name="adjEmission"
                value={formData.adjEmission}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Adj Avg Emission</label>
              <input
                type="text"
                className="form-control"
                name="adjAvgEmission"
                value={formData.adjAvgEmission}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Adj Min Emission</label>
              <input
                type="text"
                className="form-control"
                name="adjEmissionMin"
                value={formData.adjEmissionMin}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label>Adj Max Emission</label>
              <input
                type="text"
                className="form-control"
                name="adjEmissionMax"
                value={formData.adjEmissionMax}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Adj Reason</label>
              <select
                className="form-control"
                name="adjReasonID"
                value={formData.adjReasonID}
                onChange={handleInputChange}
              >
                <option value="">Select Reason</option>
                {reasons.map(reason => (
                  <option key={reason.reasonId} value={reason.reasonId}>
                    {reason.reasonId}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>Engineer Id</label>
              <input
                type="text"
                className="form-control"
                name="engineerID"
                value={formData.engineerID}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label>Comments</label>
              <textarea
                className="form-control"
                name="userComments"
                rows="4"
                value={formData.userComments}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Button type="submit" className="btn btn-primary float-right">Save</Button>
            <Button type="button" className="btn btn-secondary float-right mr-3" onClick={onClose}>Cancel</Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditEmissionValidation;
