import axios from 'axios';
import { API_BASE_URL } from '../../../../config';

class DataImportService {
  constructor() {
    this.entityImportSet = [];
  }

  setImportDataForEntity(entity, importDataset) {
    this.entityImportSet.push(importDataset);
  }

  resetImportDataForEntity(entity) {
    this.entityImportSet = this.entityImportSet.filter((t) => t.entity !== entity);
  }

  getImportDataForEntity(entity) {
    return this.entityImportSet.find((t) => t.entity === entity) || null;
  }

  async uploadDataAndTryImport(entity, importData) {
    try {
      const response = await axios.post(`${API_BASE_URL}/import/${entity}/save`, importData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Failed to import data');
    }
  }
}

export default new DataImportService();
