import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';
import ExportComponent from '../../../../common/ExportComponent';

const AddDeviceClassification = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const classificationId = searchParams.get("classificationId") || '';
  const [isEdit, setIsEdit] = useState(!!classificationId);
  const [formData, setFormData] = useState({
    classificationId: '',
    deviceClass: '',
    description: '',
    subclass: '',
    defaultvalue: '',
    defaultdesc: '',
    defaultUOM: '',
    status: 'A'
  });
  const [deviceClasses, setDeviceClasses] = useState([]);
  const [subClasses, setSubClasses] = useState([]);
  const [uoms, setUoms] = useState([]);
  const statusOptions = ['A', 'D'];
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchInitialData();
    if (isEdit) {
      fetchDeviceClassificationData(classificationId);
    }
  }, [classificationId, isEdit]);

  const fetchInitialData = async () => {
    try {
      const [codemastersResponse, uomsResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/uoms`, { credentials: 'include' })
      ]);

      if (!codemastersResponse.ok || !uomsResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const [codemasters, uomsData] = await Promise.all([
        codemastersResponse.json(),
        uomsResponse.json()
      ]);

      setUoms(uomsData);

      const deviceClasses = codemasters.filter(item => item.type === CodeMasterType.DeviceClass);
      const subClasses = codemasters.filter(item => item.type === CodeMasterType.SubClass);

      setDeviceClasses(deviceClasses);
      setSubClasses(subClasses);
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
    }
  };

  const fetchDeviceClassificationData = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/device/device-classification/classificationId/${id}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setFormData({
        classificationId: data.classificationId,
        deviceClass: data.deviceClass.trim(),
        description: data.description,
        subclass: data.subclass.trim(),
        defaultvalue: data.defaultvalue,
        defaultdesc: data.defaultdesc,
        defaultUOM: data.defaultUOM.trim(),
        status: data.status
      });
    } catch (error) {
      console.error('Failed to fetch device classification data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.classificationId.trim() || formData.classificationId.length > 10) {
      newErrors.classificationId = 'Classification Id is required and has a maximum length of 10 characters.';
    }

    if (!formData.deviceClass.trim()) {
      newErrors.deviceClass = 'Source Class is required.';
    }

    if (!formData.subclass.trim()) {
      newErrors.subclass = 'Sub Class is required.';
    }

    if (!formData.description.trim()) {
      newErrors.description = 'Description is required.';
    }

    if (typeof formData.defaultvalue === 'string' && !formData.defaultvalue.trim()) {
      newErrors.defaultvalue = 'Default Value is required.';
    } else if (typeof formData.defaultvalue !== 'string' && !formData.defaultvalue) {
      newErrors.defaultvalue = 'Default Value is required.';
    }

    if (!formData.defaultdesc.trim()) {
      newErrors.defaultdesc = 'Default Description is required.';
    }

    if (!formData.defaultUOM.trim()) {
      newErrors.defaultUOM = 'Default UOM is required.';
    }

    if (!formData.status.trim()) {
      newErrors.status = 'Status is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const apiEndpoint = isEdit ? `${API_BASE_URL}/device/device-classification/update` : `${API_BASE_URL}/device/device-classification/save`;
    const method = isEdit ? 'PUT' : 'POST';

    try {
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Source Classification ${isEdit ? 'updated' : 'added'} successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate('/admin/configurations/device/device-classification-list');
      }, 1000);
    } catch (error) {
      console.error(`Failed to ${isEdit ? 'update' : 'add'} Source Classification:`, error);
      toast.error(`Failed to ${isEdit ? 'update' : 'add'} Source Classification.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleCancel = () => {
    navigate('/admin/configurations/device/device-classification-list');
  };

  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit Source Classification' : 'Add Source Classification'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-General</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">{isEdit ? 'Edit Device Classification' : 'Add Device Classification'}</h4> */}
              <hr />
              <form onSubmit={handleSubmit}>
                <h4>Classification Info</h4>
                <div className='row mt-1'>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="classificationId">Classification ID</label>
                      <input
                        type="text"
                        name="classificationId"
                        className={`form-control ${errors.classificationId ? 'is-invalid' : ''}`}
                        value={formData.classificationId}
                        onChange={handleChange}
                        maxLength="10"
                        disabled={isEdit}
                      />
                      {errors.classificationId && <div className="invalid-feedback">{errors.classificationId}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="deviceClass">Source Class</label>
                      <select
                        name="deviceClass"
                        className={`form-control ${errors.deviceClass ? 'is-invalid' : ''}`}
                        value={formData.deviceClass}
                        onChange={handleChange}
                      >
                        <option value="">Select Source Class</option>
                        {deviceClasses.map(deviceClass => (
                          <option key={deviceClass.code} value={deviceClass.code}>
                            {deviceClass.description}
                          </option>
                        ))}
                      </select>
                      {errors.deviceClass && <div className="invalid-feedback">{errors.deviceClass}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="subclass">Sub Class</label>
                      <select
                        name="subclass"
                        className={`form-control ${errors.subclass ? 'is-invalid' : ''}`}
                        value={formData.subclass}
                        onChange={handleChange}
                      >
                        <option value="">Select Sub Class</option>
                        {subClasses.map(subClass => (
                          <option key={subClass.code} value={subClass.code}>
                            {subClass.description}
                          </option>
                        ))}
                      </select>
                      {errors.subclass && <div className="invalid-feedback">{errors.subclass}</div>}
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <input
                        type="text"
                        name="description"
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                        value={formData.description}
                        onChange={handleChange}
                        maxLength="50"
                      />
                      {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="defaultvalue">Default Value</label>
                      <input
                        type="number"
                        name="defaultvalue"
                        className={`form-control ${errors.defaultvalue ? 'is-invalid' : ''}`}
                        value={formData.defaultvalue}
                        onChange={handleChange}
                      />
                      {errors.defaultvalue && <div className="invalid-feedback">{errors.defaultvalue}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="defaultdesc">Default Description</label>
                      <input
                        type="text"
                        name="defaultdesc"
                        className={`form-control ${errors.defaultdesc ? 'is-invalid' : ''}`}
                        value={formData.defaultdesc}
                        onChange={handleChange}
                        maxLength="50"
                      />
                      {errors.defaultdesc && <div className="invalid-feedback">{errors.defaultdesc}</div>}
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="defaultUOM">Default UoM</label>
                      <select
                        name="defaultUOM"
                        className={`form-control ${errors.defaultUOM ? 'is-invalid' : ''}`}
                        value={formData.defaultUOM}
                        onChange={handleChange}
                      >
                        <option value="">Select UoM</option>
                        {uoms.map(uom => (
                          <option key={uom.uomId} value={uom.uomId}>
                            {uom.description}
                          </option>
                        ))}
                      </select>
                      {errors.defaultUOM && <div className="invalid-feedback">{errors.defaultUOM}</div>}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option value="">Select Status</option>
                        {statusOptions.map(status => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button type="button" className="btn btn-secondary mr-3" onClick={handleCancel}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {isEdit ? 'Update' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddDeviceClassification;
