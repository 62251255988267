import { API_BASE_URL } from "../../../../config";

export const getFacilities = async () => {
  const response = await fetch(`${API_BASE_URL}/home/facilities`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const getCategories = async () => {
  const response = await fetch(`${API_BASE_URL}/projectcategory/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const getTypes = async () => {
  const response = await fetch(`${API_BASE_URL}/project/project-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const getCompTypes = async () => {
  const response = await fetch(`${API_BASE_URL}/master/comptyes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const getPollutants = async () => {
  const response = await fetch(`${API_BASE_URL}/master/pollutants`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const getUoms = async () => {
  const response = await fetch(`${API_BASE_URL}/device/uoms`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const getActiveProjects = async () => {
  const response = await fetch(`${API_BASE_URL}/project/project-active-master`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const getProjectTransactionById = async (id) => {
  const response = await fetch(`${API_BASE_URL}/project/project-tx/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

export const saveProjectTransaction = async (projectTransaction, isEdit) => {
  console.log("checking",projectTransaction)
  const apiEndpoint = isEdit ? `${API_BASE_URL}/project/project-tx-update` : `${API_BASE_URL}/project/project-tx-save`;
  const method = isEdit ? 'POST' : 'POST';

  const response = await fetch(apiEndpoint, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(projectTransaction)
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};
