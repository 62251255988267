import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, TimeScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FacilitySelector from '../../common/FacilitySelector';
import PlantSelector from '../../common/PlantSelector';
import { API_BASE_URL } from '../../../config';
import { BUTTON_LABELS } from '../../../constants';

// Register the components
Chart.register(TimeScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function PlantwiseFlaring() {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [selectedPlantId, setSelectedPlantId] = useState(-1);
  const [materialState, setMaterialState] = useState("GAS");
  const [avgFlaring, setAvgFlaring] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const handleMaterialStateChange = (e) => {
    setMaterialState(e.target.value);
  };

  const calculateAverageFlaring = (data, fromDate, toDate) => {
    if (data && data.length > 0) {
      const gasData = data.map(item => Number((item.gasMscf / 1000).toFixed(2)));
      const totalFlaring = gasData.reduce((sum, value) => sum + value, 0);
      const dateDiff = (toDate - fromDate) / (1000 * 3600 * 24);
      const avgFlaring = (totalFlaring / dateDiff).toFixed(2);
      setAvgFlaring(avgFlaring);
    }
  };

  const handlePlantWiseFlaringButtonClick = () => {
    if (startDate && endDate) {
      const apiUrl = `${API_BASE_URL}/reports/daily-plant-flaring?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&plantId=${selectedPlantId}&materialState=${materialState}`;
      
      setLoading(true);
      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data && data.content) {
            const labels = data.content.map(item => new Date(item.day));
            const gasData = data.content.map(item => item.gasMscf / 1000);

            setChartData({
                labels: labels,
                datasets: [
                  {
                    label: 'Gas Flaring (Mscf)',
                    data: gasData,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    fill: true,
                  }
                ]
              });
            calculateAverageFlaring(data.content, startDate, endDate);
          } else {
            setError('Data format is incorrect');
          }
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
          setError('Error fetching data');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('Please select valid options.');
    }
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'yyyy-mm-dd'
        },
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Gas Flaring (Mscf)'
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
          }
        }
      }
    }
  };

  return (
    <div className="main-content">
       <div className='breadcrumb mt-3'>
        <h1>Daily Flaring</h1>
        <ul>
          <li>Reports</li>
          <li>Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className='row'>
          <FacilitySelector
            selectedFacilityId={selectedFacilityId}
            onChange={setSelectedFacilityId}
          />

          <PlantSelector
            selectedFacilityId={selectedFacilityId}
            selectedPlantId={selectedPlantId}
            onChange={setSelectedPlantId}
          />

          <div className='col-md-2'>
            <label>Select Material State</label>
            <select
              name='materialState'
              className='form-control'
              value={materialState}
              onChange={handleMaterialStateChange}
            >
              <option value={'GAS'}>Gas</option>
              <option value={'LIQUID'}>Liquid</option>
            </select>
          </div>
          <div className='col-md-2'>
            <label>Start Date</label>
            <DatePicker
              className='form-control'
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className='col-md-2'>
            <label>End Date</label>
            <DatePicker
              className='form-control'
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className='col-md-2 generate_report_button'>
            <button className='btn btn-primary' onClick={handlePlantWiseFlaringButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
          </div>
        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">Daily Flaring</h4> */}
               {avgFlaring && (
                    <h4 className="mt-3">Average Daily Flaring : {avgFlaring} MMSCF</h4>
                  )}
              {loading && <div>Loading...</div>}
              {error && <div>{error}</div>}
              {!loading && !error && Object.keys(chartData).length > 0 && (
                <div>
                  {/* <h2>Daily Flaring</h2> */}
                  <Bar data={chartData} options={options} height={90} />
                 
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end of col*/}
      </div>
    </div>
  );
}
