import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddPlant from './AddPlant'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';
import PlantExportComponent from '../../../../common/exports/PlantExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function PlantList() {
    const [plantData, setPlantData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedPlantId, setSelectedPlantId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchPlantData();
    }, []);

    const fetchPlantData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/plant/plants`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPlantData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        setSelectedPlantId(id);
        setShowModal(true);
    };

    const handleDelete = async (plantId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete plant: ${plantId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/plant/delete/${plantId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchPlantData();
            } catch (error) {
                console.error('Failed to delete area:', error);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedPlantId(null);
        // fetchPlantData(); // Refresh data after adding or editing
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = plantData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Plant ID': item.plantId,
            'Plant Name': item.plantName,
            'Facility ID': item.facilityId,
            'BTUSCF': formatWithIndianThousandsSeparatorForExportData(item.btuscf ? item.btuscf.toFixed(0) : item.btuscf),
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, plantData]);

    const columns = [
        { name: 'Plant ID', selector: row => row.plantId, sortable: true },
        { name: 'Plant Name', selector: row => row.plantName, sortable: true },
        { name: 'Facility ID', selector: row => row.facilityId, sortable: true },
        {
            name: 'BTUSCF',
            selector: row => row.btuscf,
            cell: row => formatWithIndianThousandsSeparator(row.btuscf ? row.btuscf.toFixed(0) : row.btuscf),
            sortable: true
        },
        { name: 'Status', selector: row => row.status, sortable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.plantId)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.plantId)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Plant_${new Date().toLocaleDateString()}`;

    const title = "Plant Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Plant</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Organizational Structure</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Plants</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>Add New Record</button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <PlantExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            {/* Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedPlantId ? 'Edit Plant' : 'Add Plant'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddPlant plantId={selectedPlantId} closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
    );
}
