import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatWithIndianThousandsSeparator } from '../../common/FormatNumber';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { format } from 'date-fns';

const GreenHouseCompanyWideGhg = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [startDatePickerClass, setStartDatePickerClass] = useState('');
    const [endDatePickerClass, setEndDatePickerClass] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const datepickerRef = useRef(null);

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = rowData.filter(item =>
            String(item.pollutant).toLowerCase().includes(lowercasedSearch) ||
            String(item.scopeOneEmission).toLowerCase().includes(lowercasedSearch) ||
            String(item.emission).toLowerCase().includes(lowercasedSearch)
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Greenhouse Gas': item.pollutant,
            'Scope 1 (tGHG)': formatWithIndianThousandsSeparator(item.scopeOneEmission),
            'Emission (tCO2e)': formatWithIndianThousandsSeparator(item.emission)
        }));
        setExportFilteredData(exportData);

    }, [search, rowData]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleGetReport = () => {
        if (fromDate && toDate) {
            setIsLoading(true);
            const apiUrl = `${API_BASE_URL}/reports/GHGGreenHouseEmissionsPivoted?fromDate=${fromDate.toLocaleDateString()}&toDate=${toDate.toLocaleDateString()}`;
            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    const total = data.reduce((acc, item) => {
                        acc.scopeOneEmission += item.scopeOneEmission || 0;
                        acc.emission += item.emission || 0;
                        return acc;
                    }, { pollutant: 'Total', scopeOneEmission: 0, emission: 0 });
                    const updatedData = [...data, total];
                    setRowData(updatedData);
                    setFilteredData(updatedData);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                });
        } else {
            console.error('Please select valid options.');
        }
    };

    const columns = [
        { name: 'Greenhouse Gas', selector: 'pollutant' },
        { name: 'Scope 1 (tGHG)', selector: 'scopeOneEmission', format: formatWithIndianThousandsSeparator },
        { name: 'Emission (tCO2e)', selector: 'emission', format: formatWithIndianThousandsSeparator }
    ];

    const fileName = `GHG_Company_Wide_GHG${new Date().toLocaleDateString()}`;

    const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
    const handleStartDateBlur = () => setStartDatePickerClass('');
    const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
    const handleEndDateBlur = () => setEndDatePickerClass('');

    const headerData = [
        { label: 'Start Date', value: fromDate ? format(fromDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: toDate ? format(toDate, 'yyyy-MM-dd') : 'no date selected' },
    ];
    
    const title = "Companywide GHG Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Companywide GHG</h1>
                <ul>
                    <li>Reports</li>
                    <li>Greenhouse Gases</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <div className='col-md-4'>
                        <label>Start Date</label>
                        <div className={startDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={fromDate}
                                onChange={date => setFromDate(date)}
                                selectsStart
                                startDate={fromDate}
                                endDate={toDate}
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleStartDateFocus}
                                onBlur={handleStartDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label>End Date</label>
                        <div className={endDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={toDate}
                                onChange={date => setToDate(date)}
                                selectsEnd
                                startDate={fromDate}
                                endDate={toDate}
                                minDate={fromDate}
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleEndDateFocus}
                                onBlur={handleEndDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-1 generate_report_button'>
                        <button className='btn  btn-primary' onClick={handleGetReport}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>
                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Companywide GHG</h4> */}
                            <div className='row'>
                                            <div className='col-md-10'>
                                                {filteredData.length > 0 && (
                                                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title} />
                                                )}
                                            </div>
                                            <div className='col-md-2'>
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="form-control mb-3"
                                                    value={search}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    {columns.map(col => (
                                                        <th key={col.selector}>{col.name}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredData.map((row, index) => (
                                                    <tr key={index}>
                                                        <td className={row.pollutant == 'Total' ? 'fw-light' : 'h6 fw-light' }>{row.pollutant}</td>
                                                        <td className={row.pollutant == 'Total' ? 'fw-light' : 'h6 fw-light' }>{formatWithIndianThousandsSeparator(row.scopeOneEmission)}</td>
                                                        <td className={row.pollutant == 'Total' ? 'fw-light' : 'h6 fw-light' }>{formatWithIndianThousandsSeparator(row.emission)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            {filteredData.length === 0 && (
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={columns.length} className="text-center">No data to display</td>
                                                    </tr>
                                                </tfoot>
                                            )}
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col*/}
            </div>
        </div>
    );
};

export default GreenHouseCompanyWideGhg;
