import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatWithIndianThousandsSeparatorForExportData } from './FormatNumber';

const KPIExportComponent = ({ data, fileName, headerData = [], title = '' }) => {
    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        // Add title
        XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: 'A1' });

        // Merge cells for title
        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 16 } }];

        // Add header information
        const headerString = headerData.map(item => `${item.label}: ${item.value}`).join(' | ');
        XLSX.utils.sheet_add_aoa(worksheet, [[headerString]], { origin: 'A2' });

        // Merge cells for header information
        worksheet['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: 16 } });

        // Define table headers
        const tableHeaders = [
            ['Facility Feed', '', '', '', '', '', '', 'Flaring', '', '', '', 'KPI', '', '', '', '', ''],
            [
                'Facility ID', 'Actual Gas(mmSCF)', 'Actual Liquid(mBBL)', 'Actual Thousand BOE(MBOE)',
                'Plan Gas(mmSCF)', 'Plan Liquid(mBBL)', 'Plan Thousand BOE(MBOE)',
                'Actual Gas(mmSCF)', 'Actual Liquid(mBBL)', 'Plan Gas(mmSCF)', 'Plan Liquid(mBBL)',
                'Actual Gas(scf/boe)', 'Plan Gas(scf/boe)', 'Variance Gas(%)',
                'Actual Liquid(bbl/boe)', 'Plan Liquid(bbl/boe)', 'Variance'
            ]
        ];
        XLSX.utils.sheet_add_aoa(worksheet, tableHeaders, { origin: 'A4' });

        // Add data
        const formattedData = data.map(item => ({
            'Facility ID': item.facilityId,
            'Actual Gas(mmSCF)': item.feedItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualGas.toFixed(2)) : item.feedItem?.actualGas,
            'Actual Liquid(mBBL)': item.feedItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualLiquid.toFixed(2)) : item.feedItem?.actualLiquid,
            'Actual Thousand BOE(MBOE)': item.feedItem?.actualThousandBOE ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualThousandBOE.toFixed(2)) : item.feedItem?.actualThousandBOE,
            'Plan Gas(mmSCF)': item.feedItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planGas.toFixed(2)) : item.feedItem?.planGas,
            'Plan Liquid(mBBL)': item.feedItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planLiquid.toFixed(2)) : item.feedItem?.planLiquid,
            'Plan Thousand BOE(MBOE)': item.feedItem?.planThousandBOE ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planThousandBOE.toFixed(2)) : item.feedItem?.planThousandBOE,
            'Actual Gas(mmSCF)': item.flaringItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualGas.toFixed(2)) : item.flaringItem?.actualGas,
            'Actual Liquid(mBBL)': item.flaringItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualLiquid.toFixed(2)) : item.flaringItem?.actualLiquid,
            'Plan Gas(mmSCF)': item.flaringItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planGas.toFixed(2)) : item.flaringItem?.planGas,
            'Plan Liquid(mBBL)': item.flaringItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planLiquid.toFixed(2)) : item.flaringItem?.planLiquid,
            'Actual Gas(scf/boe)': item.varianceItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualGas.toFixed(2)) : item.varianceItem?.actualGas,
            'Plan Gas(scf/boe)': item.varianceItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planGas.toFixed(2)) : item.varianceItem?.planGas,
            'Variance Gas(%)': item.varianceItem?.varianceGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceGas.toFixed(2)) : item.varianceItem?.varianceGas,
            'Actual Liquid(bbl/boe)': item.varianceItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualLiquid.toFixed(2)) : item.varianceItem?.actualLiquid,
            'Plan Liquid(bbl/boe)': item.varianceItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planLiquid.toFixed(2)) : item.varianceItem?.planLiquid,
            'Variance': item.varianceItem?.varianceLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceLiquid.toFixed(2)) : item.varianceItem?.varianceLiquid
        }));
        XLSX.utils.sheet_add_json(worksheet, formattedData, { origin: 'A6', skipHeader: true });

        // Adjust column widths
        const wscols = new Array(17).fill({ wch: 20 });
        worksheet['!cols'] = wscols;

        // Apply header styles
        const headerStyle = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "000000" } },
            alignment: { horizontal: "center" },
            border: {
                top: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
            }
        };
        for (let colIndex = 0; colIndex < tableHeaders[1].length; colIndex++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 4, c: colIndex });
            worksheet[cellAddress].s = headerStyle;
        }

        // Apply number alignment styles
        const rightAlignStyle = { alignment: { horizontal: "right" } };
        formattedData.forEach((row, rowIndex) => {
            Object.keys(row).forEach((col, colIndex) => {
                if (!isNaN(row[col])) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + 6, c: colIndex });
                    worksheet[cellAddress] = worksheet[cellAddress] || {};
                    worksheet[cellAddress].s = { ...worksheet[cellAddress].s, ...rightAlignStyle };
                }
            });
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    const exportToPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: [1241.89, 1590.55] // Increased width (A3 size)
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleFontSize = 12;
        const titleWidth = doc.getTextWidth(title) * titleFontSize / 6;
        const titleX = (pageWidth - titleWidth) / 2;

        doc.setFontSize(titleFontSize);
        doc.setFont('helvetica', 'bold');
        doc.text(title, titleX, 30);

        if (headerData) {
            let headerX = 40;
            let headerY = 50;
            headerData.forEach(item => {
                doc.setFontSize(10);
                doc.text(`${item.label}: ${item.value}`, headerX, headerY);
                headerX += 170;
            });
        }

        const tableHeaders = [
            ['Facility Feed', '', '', '', '', '', '', 'Flaring', '', '', '', 'KPI', '', '', '', '', ''],
            [
                'Facility ID', 'Actual Gas(mmSCF)', 'Actual Liquid(mBBL)', 'Actual Thousand BOE(MBOE)',
                'Plan Gas(mmSCF)', 'Plan Liquid(mBBL)', 'Plan Thousand BOE(MBOE)',
                'Actual Gas(mmSCF)', 'Actual Liquid(mBBL)', 'Plan Gas(mmSCF)', 'Plan Liquid(mBBL)',
                'Actual Gas(scf/boe)', 'Plan Gas(scf/boe)', 'Variance Gas(%)',
                'Actual Liquid(bbl/boe)', 'Plan Liquid(bbl/boe)', 'Variance'
            ]
        ];
        const formattedData = data.map(item => [
            item.facilityId,
            item.feedItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualGas.toFixed(2)) : item.feedItem?.actualGas,
            item.feedItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualLiquid.toFixed(2)) : item.feedItem?.actualLiquid,
            item.feedItem?.actualThousandBOE ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualThousandBOE.toFixed(2)) : item.feedItem?.actualThousandBOE,
            item.feedItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planGas.toFixed(2)) : item.feedItem?.planGas,
            item.feedItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planLiquid.toFixed(2)) : item.feedItem?.planLiquid,
            item.feedItem?.planThousandBOE ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planThousandBOE.toFixed(2)) : item.feedItem?.planThousandBOE,
            item.flaringItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualGas.toFixed(2)) : item.flaringItem?.actualGas,
            item.flaringItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualLiquid.toFixed(2)) : item.flaringItem?.actualLiquid,
            item.flaringItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planGas.toFixed(2)) : item.flaringItem?.planGas,
            item.flaringItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planLiquid.toFixed(2)) : item.flaringItem?.planLiquid,
            item.varianceItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualGas.toFixed(2)) : item.varianceItem?.actualGas,
            item.varianceItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planGas.toFixed(2)) : item.varianceItem?.planGas,
            item.varianceItem?.varianceGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceGas.toFixed(2)) : item.varianceItem?.varianceGas,
            item.varianceItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualLiquid.toFixed(2)) : item.varianceItem?.actualLiquid,
            item.varianceItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planLiquid.toFixed(2)) : item.varianceItem?.planLiquid,
            item.varianceItem?.varianceLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceLiquid.toFixed(2)) : item.varianceItem?.varianceLiquid
        ]);

        const columnStyles = {};
        tableHeaders[1].forEach((header, index) => {
            if (index !== 0) {
                columnStyles[index] = { halign: 'right' };
            }
        });

        doc.autoTable({
            startY: headerData ? 80 : 40,
            head: tableHeaders,
            body: formattedData,
            styles: { fontSize: 8, cellWidth: 'wrap', lineColor: [222, 226, 230], lineWidth: 0.1 },
            headStyles: { fillColor: [22, 160, 133], fontSize: 8, textColor: [255, 255, 255], lineColor: [222, 226, 230], lineWidth: 0.1 },
            theme: 'grid',
            margin: { top: 20 },
            bodyStyles: { valign: 'top', textColor: [0, 0, 0], lineColor: [222, 226, 230], lineWidth: 0.1 },
            columnStyles
        });

        doc.save(`${fileName}.pdf`);
    };

    const convertToCSV = (objArray, title, headerData) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        // Add title if provided
        if (title) {
            str += `${title}\r\n`;
        }

        // Add header data if provided
        if (headerData) {
            const headerString = headerData.map(item => `${item.label}: ${item.value}`).join(' | ');
            str += `${headerString}\r\n`;
            str += '\r\n';
        }

        // Add table headers
        str += 'Facility Feed,,,,,,,Flaring,,,,,KPI,,,,\r\n';
        str += 'Facility ID,Actual Gas(mmSCF),Actual Liquid(mBBL),Actual Thousand BOE(MBOE),Plan Gas(mmSCF),Plan Liquid(mBBL),Plan Thousand BOE(MBOE),Actual Gas(mmSCF),Actual Liquid(mBBL),Plan Gas(mmSCF),Plan Liquid(mBBL),Actual Gas(scf/boe),Plan Gas(scf/boe),Variance Gas(%),Actual Liquid(bbl/boe),Plan Liquid(bbl/boe),Variance\r\n';

        array.forEach(item => {
            str += [
                item.facilityId,
                item.feedItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualGas.toFixed(2)) : item.feedItem?.actualGas,
                item.feedItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualLiquid.toFixed(2)) : item.feedItem?.actualLiquid,
                item.feedItem?.actualThousandBOE ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualThousandBOE.toFixed(2)) : item.feedItem?.actualThousandBOE,
                item.feedItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planGas.toFixed(2)) : item.feedItem?.planGas,
                item.feedItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planLiquid.toFixed(2)) : item.feedItem?.planLiquid,
                item.feedItem?.planThousandBOE ? formatWithIndianThousandsSeparatorForExportData(item.feedItem.planThousandBOE.toFixed(2)) : item.feedItem?.planThousandBOE,
                item.flaringItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualGas.toFixed(2)) : item.flaringItem?.actualGas,
                item.flaringItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualLiquid.toFixed(2)) : item.flaringItem?.actualLiquid,
                item.flaringItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planGas.toFixed(2)) : item.flaringItem?.planGas,
                item.flaringItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planLiquid.toFixed(2)) : item.flaringItem?.planLiquid,
                item.varianceItem?.actualGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualGas.toFixed(2)) : item.varianceItem?.actualGas,
                item.varianceItem?.planGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planGas.toFixed(2)) : item.varianceItem?.planGas,
                item.varianceItem?.varianceGas ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceGas.toFixed(2)) : item.varianceItem?.varianceGas,
                item.varianceItem?.actualLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualLiquid.toFixed(2)) : item.varianceItem?.actualLiquid,
                item.varianceItem?.planLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planLiquid.toFixed(2)) : item.varianceItem?.planLiquid,
                item.varianceItem?.varianceLiquid ? formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceLiquid.toFixed(2)) : item.varianceItem?.varianceLiquid
            ].join(',') + '\r\n';
        });

        return str;
    };

    const exportToCSV = () => {
        const csv = convertToCSV(data, title, headerData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${fileName}.csv`);
    };

    return (
        <div className="export-buttons">
            <button className="btn btn-sm btn-primary mr-2" onClick={exportToCSV}>
                Export to CSV
            </button>
            <button className="btn btn-sm btn-success mr-2" onClick={exportToExcel}>
                Export to Excel
            </button>
            <button className="btn btn-sm btn-danger" onClick={exportToPDF}>
                Export to PDF
            </button>
        </div>
    );
};

export default KPIExportComponent;
