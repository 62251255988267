import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import fmsDashboardService from '../services/fmsDashboardService';
import './FlaringKpiPredictions.css';

const FlaringKpiPredictions = ({ fmsDashboardFilter, selectedDate, appSession }) => {
  const [facilityChart, setFacilityChart] = useState({
    labels: [],
    datasets: [
      {
        label: 'Actual',
        type: 'bar',
        backgroundColor: [],
        fill: true,
        lineTension: 0.3,
        pointRadius: 0,
        data: []
      },
      {
        label: 'Prediction',
        type: 'bar',
        backgroundColor: 'rgba(255, 77, 77, 0.5)', // light red
        borderColor: 'rgba(255, 77, 77, 1)', // red
        fill: true,
        lineTension: 0.3,
        pointRadius: 0,
        data: []
      },
      {
        label: 'Planned',
        type: 'line',
        backgroundColor: 'rgba(102, 235, 255, 0.5)', // light blue
        borderColor: 'rgba(102, 235, 255, 1)', // blue
        fill: false,
        lineTension: 0.3,
        pointRadius: 0,
        data: []
      }
    ]
  });
  const [showChart, setShowChart] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    fetchDataAndSetupChart(true);
  }, [selectedDate, fmsDashboardFilter]);

  const fetchDataAndSetupChart = async (forceUpdate) => {
    if (!selectedDate || !fmsDashboardFilter || !appSession) {
      return;
    }

    setShowChart(false);
    setShowNoDataText(false);

    try {
      const response = await fmsDashboardService.getKPIPredictionByLayerAndTargetType(
        selectedDate.getFullYear(),
        fmsDashboardFilter.facilityId,
        fmsDashboardFilter.type
      );
      const predictionResults = response.data;
      loadData(predictionResults);
      if (predictionResults && predictionResults.length !== 0) {
        setShowNoDataText(false);
        setShowChart(true);
      } else {
        setShowNoDataText(true);
      }
    } catch (error) {
      setShowNoDataText(true);
    }
  };

  const loadData = (predictions) => {
    if (predictions) {
      const labels = predictions.map(x => moment(x.predictionDate).format('DD'));
      const actualData = predictions.map(x => x.predictionValue.toFixed(2));
      const plannedData = predictions.map(x => x.planValue.toFixed(2));
      const predictionData = predictions.map(x => (new Date(x.predictionDate) >= new Date() ? x.predictionValue.toFixed(2) : null));

      setFacilityChart({
        labels,
        datasets: [
          { ...facilityChart.datasets[0], backgroundColor: '#1976D2', data: actualData },
          { ...facilityChart.datasets[1], data: predictionData },
          { ...facilityChart.datasets[2], data: plannedData }
        ]
      });
    }
  };

  const chartOptions = {
    legend: {
      labels: {
        fontColor: '#FFFFFF'
      }
    },
    tooltips: {
      mode: 'index',
      intersect: true,
      callbacks: {
        label: (tooltipItem, data) => {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${value} ${appSession.gasUnits}`;
        }
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: false,
        grid: {
          color: '#2d2d2d'
        },
        ticks: {
          fontColor: '#FFFFFF',
          display: true,
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0
        }
      },
      y: {
        stacked: false,
        grid: {
          color: '#2d2d2d'
        },
        ticks: {
          fontColor: '#FFFFFF',
          callback: function(value) {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(0) + 'B'; // Show in Billions
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(0) + 'M'; // Show in Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(0) + 'K'; // Show in Thousands
            } else {
              return value; // Show as is
            }
          }          
        },
        title: {
          display: true,
          text: `Flaring (${appSession?.gasUnits || 'units'})`,
          fontColor: '#FFFFFF'
        }
      }
    },
    plugins: {
      datalabels: {
        display: false,
        font: {
          weight: 'bold',
          size: 11
        },
        formatter: Math.round
      }
    }
  };

  return (
    <div className="flaring-kpi-predictions">
      {showChart && !showNoDataText ? (
        <Bar
          ref={chartRef}
          data={facilityChart}
          options={chartOptions}
          height={100}
        />
      ) : (
        <div className="no-data-txt">
          <h5 className="mt-5"> No Data Found</h5>
        </div>
      )}
    </div>
  );
};

export default FlaringKpiPredictions;
