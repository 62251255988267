import * as XLSX from 'xlsx';
import jsonData from '../../../../assets/json/import-property-map.json';

class ExcelFileGeneratorService {
  constructor() {
    this.columnRepoData = jsonData;
  }

  getEntityColumnMapModelForEntity(entity) {
    return this.columnRepoData[entity];
  }

  generateAndDownloadImportExcelTemplate(entity) {
    const entityConfig = this.getEntityColumnMapModelForEntity(entity);
    if (!entityConfig) return;

    const sheetName = `Import for ${entity}`;
    const workSheet = XLSX.utils.json_to_sheet([{}], {
      header: entityConfig.columnNamesAsHeaders,
      skipHeader: false
    });

    const workBookSheets = {};
    workBookSheets[sheetName] = workSheet;

    const workBook = {
      Sheets: workBookSheets,
      SheetNames: [sheetName]
    };

    XLSX.writeFile(workBook, `ImportFor${entity}.xlsx`, {
      bookType: 'xlsx'
    });
  }

  generateAndDownloadImportExcelTemplateWithData(entity, data) {
    const entityConfig = this.getEntityColumnMapModelForEntity(entity);
    if (!entityConfig) return;

    const sheetName = `Import for ${entity}`;
    const workSheet = XLSX.utils.json_to_sheet(data, {
      header: entityConfig.columnNamesAsHeaders,
      skipHeader: false,
    });

    const workBookSheets = {};
    workBookSheets[sheetName] = workSheet;

    const workBook = {
      Sheets: workBookSheets,
      SheetNames: [sheetName]
    };

    XLSX.writeFile(workBook, `ImportFor${entity}.xlsx`, {
      bookType: 'xlsx'
    });
  }
}

export default new ExcelFileGeneratorService();
