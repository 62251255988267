import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';
import { getProjectType, deleteProjectType } from '../services/projectService';
import AddProjectTypeModal from './AddProjectTypeModal';
import ExportComponent from '../../../common/ExportComponent';

const ListProjectType = () => {
  const [projectTypes, setProjectTypes] = useState([]);
  const [filteredProjectTypes, setFilteredProjectTypes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [exportFilteredData, setExportFilteredData] = useState([]);

  useEffect(() => {
    loadProjectTypes();
  }, []);

  const loadProjectTypes = async () => {
    setIsLoading(true);
    try {
      const data = await getProjectType();
      console.log('Project Types:', data);
      setProjectTypes(data);
      setFilteredProjectTypes(data);
    } catch (error) {
      toast.error('Failed to load project types.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const text = event.target.value;
    setSearchText(text);
    const filtered = projectTypes.filter((type) =>
      type.projectType.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredProjectTypes(filtered);
  };

  const handleDelete = (typeId) => {
    setSelectedTypeId(typeId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      await deleteProjectType(selectedTypeId);
      toast.success('Project Type deleted successfully.');
      setFilteredProjectTypes(filteredProjectTypes.filter((type) => type.projectTypeID !== selectedTypeId));
    } catch (error) {
      toast.error('Failed to delete project type.');
    }
  };

  const handleAdd = () => {
    setShowAddModal(true);
  };


  useEffect(() => {
    const lowercasedSearch = searchText.toLowerCase();
    const filtered = projectTypes.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredProjectTypes(filtered);

    const exportData = filtered.map(item => ({
      'Project Type ID': item.projectTypeID,
      'Project Type': item.projectType,
      'Status': item.status
    }));
    setExportFilteredData(exportData);
  }, [searchText, projectTypes]);


  const columns = [
    {
      name: 'Project Type ID',
      selector: row => row.projectTypeID,
      sortable: true,
    },
    {
      name: 'Project Type',
      selector: row => row.projectType,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      name: 'Delete',
      width: '75px',
      cell: (row) => (
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => handleDelete(row.projectTypeID)}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      ),
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Project_Type_${new Date().toLocaleDateString()}`;

  const title = "Project Type Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Project Type</h1>
        <ul>
          <li>Projects</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-5">

      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
            <div className="row">
          <div className="col-md-6 mb-3">
            <button
              className="btn btn-primary"
              onClick={handleAdd}
            >
              <span className="lnr lnr-plus-circle mr-1"></span>
              <span className="font-weight-light">Add New Record</span>
            </button>
          </div>
        </div>
              <div className='row mb-3'>
                <div className='col-md-10'>
                  {filteredProjectTypes.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    className="form-control"
                    id="searchText"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <DataTable
                      columns={columns}
                      data={filteredProjectTypes}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>

                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this project type?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <AddProjectTypeModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        reload={loadProjectTypes}
      />
    </div>
  );
};

export default ListProjectType;
