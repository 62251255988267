import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddEmsMethod from './AddEmsMethod'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';

export default function EmsMethodList() {
    const [emsMethodData, setEmsMethodData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedEmsMethodId, setSelectedEmsMethodId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchEmsMethodData();
    }, []);

    const fetchEmsMethodData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/ems-method`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setEmsMethodData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch EMS method data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = emsMethodData.filter(item =>
            String(item.emsMethodId).toLowerCase().includes(lowercasedSearch) ||
            String(item.approaches).toLowerCase().includes(lowercasedSearch) ||
            String(item.status).toLowerCase().includes(lowercasedSearch)
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'EMS Method ID': item.emsMethodId,
            'Approaches': item.approaches,
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, emsMethodData]);

    const handleEdit = (id) => {
        setSelectedEmsMethodId(id);
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete EMS method: ${id}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/ems-master/delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                // Show success message
                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // Refresh the data after deletion
                fetchEmsMethodData();
            } catch (error) {
                console.error('Failed to delete EMS method:', error);
            }
        }
    };

    const columns = [
        { name: 'EMS Method ID', selector: row => row.emsMethodId, sortable: true },
        { name: 'Approaches', selector: row => row.approaches, sortable: true },
        { name: 'Status', selector: row => row.status, sortable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.emsMethodId)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.emsMethodId)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedEmsMethodId(null);
        fetchEmsMethodData(); // Refresh data after adding or editing
    };

    const fileName = `Ems_Method_${new Date().toLocaleDateString()}`;

    const title = "EMS Method Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>EMS Methods</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-EMS</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <h4 className="card-title mb-3">EMS Methods</h4>
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Button variant="primary" onClick={() => setShowModal(true)}>
                                        Add New Record
                                    </Button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <AddEmsMethod
                emsMethodId={selectedEmsMethodId}
                closeModal={handleCloseModal}
                showModal={showModal}
            />
            <ToastContainer />
        </div>
    );
}
