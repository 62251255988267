import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';

const AddAmtDevice = () => {
  const [formGroup, setFormGroup] = useState({
    deviceId: '',
    standard: '',
    brief: '',
    analyzer: '',
    manufacturer: '',
    latencyInMinutes: '',
    compType: 'AMMNET',
    componentId: '',
    pollutant: '',
    unit: '',
    status: ''
  });
  const [availableAmtDevices, setAvailableAmtDevices] = useState([]);
  const [amtCompositionItems, setAmtCompositionItems] = useState([]);
  const [uomItems, setUomItems] = useState([]);
  const [statusOptions] = useState(['A', 'D']);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const isEdit = !!deviceId;

  useEffect(() => {
    if (isEdit) {
      fetch(`${API_BASE_URL}/amt-device/${deviceId}`)
        .then(response => response.json())
        .then(data => {
          setFormGroup(data);
        })
        .catch(error => {
          console.error('Error fetching AMT Device details:', error);
        });
    }
  }, [deviceId, isEdit]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/amt-device/available`)
      .then(response => response.json())
      .then(data => {
        setAvailableAmtDevices(data);
      })
      .catch(error => {
        console.error('Error fetching available AMT devices:', error);
      });

    fetch(`${API_BASE_URL}/composition/comp-details/AMMNET`)
      .then(response => response.json())
      .then(data => {
        setAmtCompositionItems(data);
      })
      .catch(error => {
        console.error('Error fetching AMT composition items:', error);
      });

    fetch(`${API_BASE_URL}/device/uoms`)
      .then(response => response.json())
      .then(data => {
        setUomItems(data.filter(t => t.uomType === 'CONC'));
      })
      .catch(error => {
        console.error('Error fetching UOMs:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.deviceId) {
      newErrors.deviceId = 'Source Id is required.';
    }
    if (!formGroup.standard) {
      newErrors.standard = 'Standard is required.';
    }
    if (!formGroup.analyzer) {
      newErrors.analyzer = 'Analyzer is required.';
    }
    if (!formGroup.manufacturer) {
      newErrors.manufacturer = 'Manufacturer is required.';
    }
    if (!formGroup.latencyInMinutes) {
      newErrors.latencyInMinutes = 'Latency is required.';
    }
    if (!formGroup.compType) {
      newErrors.compType = 'Comp Type is required.';
    }
    if (!formGroup.componentId) {
      newErrors.componentId = 'Component Id is required.';
    }
    if (!formGroup.pollutant) {
      newErrors.pollutant = 'Pollutant is required.';
    }
    if (!formGroup.unit) {
      newErrors.unit = 'UOM is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const amtDevice = { ...formGroup };

    try {
      const response = await fetch(`${API_BASE_URL}/amt-device/${isEdit ? deviceId : ''}`, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(amtDevice)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`AMT Device ${isEdit ? 'updated' : 'added'} successfully.`);
      setTimeout(() => {
        navigate('/admin/configurations/amt-config/list-amt-device');
      }, 1000);
    } catch (error) {
      console.error('Failed to save AMT device:', error);
      toast.error('Error saving AMT Device');
    }
  };

  const handleComponentChange = (e) => {
    const { value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      componentId: value,
      pollutant: amtCompositionItems.find(t => t.componentId === value)?.eqptCode || ''
    }));
  };

  return (
    <div>
      {/* <h4>{isEdit ? 'Edit AMT Device' : 'Add AMT Device'}</h4> */}
      <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit AMT Source' : 'Add AMT Source'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-AMT</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">AMT Source Details</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-3">
                <label htmlFor="deviceId">Source ID</label>
                {isEdit ? (
                  <input type="text" className="form-control" id="deviceId" name="deviceId" value={formGroup.deviceId} readOnly />
                ) : (
                  <select className={`form-control ${errors.deviceId ? 'is-invalid' : ''}`}
                           id="deviceId" name="deviceId" value={formGroup.deviceId} onChange={handleChange}>
                    <option value="">Select AMT Source</option>
                    {availableAmtDevices.map(device => (
                      <option key={device} value={device}>{device}</option>
                    ))}
                  </select>
                )}
                {errors.deviceId && <div className="invalid-feedback d-block">{errors.deviceId}</div>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="standard">Standard</label>
                <input type="text" className={`form-control ${errors.standard ? 'is-invalid' : ''}`} id="standard" name="standard" value={formGroup.standard} onChange={handleChange} />
                {errors.standard && <div className="invalid-feedback d-block">{errors.standard}</div>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="brief">Brief</label>
                <input type="text" className="form-control" id="brief" name="brief" value={formGroup.brief} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-6">
                <label htmlFor="analyzer">Analyzer</label>
                <input type="text" className={`form-control ${errors.analyzer ? 'is-invalid' : ''}`} id="analyzer" name="analyzer" value={formGroup.analyzer} onChange={handleChange}  />
                {errors.analyzer && <div className="invalid-feedback d-block">{errors.analyzer}</div>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="manufacturer">Manufacturer</label>
                <input type="text" className={`form-control ${errors.manufacturer ? 'is-invalid' : ''}`} id="manufacturer" name="manufacturer" value={formGroup.manufacturer} onChange={handleChange}  />
                {errors.manufacturer && <div className="invalid-feedback d-block">{errors.manufacturer}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-3">
                <label htmlFor="latencyInMinutes">Latency In Minutes</label>
                <input type="number" className={`form-control ${errors.latencyInMinutes ? 'is-invalid' : ''}`} id="latencyInMinutes" name="latencyInMinutes" value={formGroup.latencyInMinutes} onChange={handleChange} min="0"  />
                {errors.latencyInMinutes && <div className="invalid-feedback d-block">{errors.latencyInMinutes}</div>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="compType">Comp Type</label>
                <input type="text" className="form-control" id="compType" name="compType" value={formGroup.compType} readOnly />
                {errors.compType && <div className="invalid-feedback d-block">{errors.compType}</div>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="componentId">Component ID</label>
                <select className="form-control" id="componentId" name="componentId" value={formGroup.componentId} onChange={handleComponentChange} >
                  <option value="">Select Component ID</option>
                  {amtCompositionItems.map(item => (
                    <option key={item.componentId} value={item.componentId}>{item.componentId}</option>
                  ))}
                </select>
                {errors.componentId && <div className="invalid-feedback d-block">{errors.componentId}</div>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="pollutant">Pollutant</label>
                <input type="text" className="form-control" id="pollutant" name="pollutant" value={formGroup.pollutant} readOnly />
                {errors.pollutant && <div className="invalid-feedback d-block">{errors.pollutant}</div>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-3">
                <label htmlFor="unit">UoM</label>
                <select className={`form-control ${errors.unit ? 'is-invalid' : ''}`} id="unit" name="unit" value={formGroup.unit} onChange={handleChange}>
                  <option value="">Select UoM</option>
                  {uomItems.map(item => (
                    <option key={item.uomId} value={item.uomId}>{item.uomId}</option>
                  ))}
                </select>
                {errors.unit && <div className="invalid-feedback d-block">{errors.unit}</div>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="status">Status</label>
                <select className={`form-control ${errors.status ? 'is-invalid' : ''}`} id="status" name="status" value={formGroup.status} onChange={handleChange} >
                  {/* <option value="">Select Status</option> */}
                  {statusOptions.map(status => (
                    <option key={status} value={status}>{status}</option>
                  ))}
                </select>
                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
              </div>
            </div>
            <div className="form-group row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">
                  {isEdit ? 'Update' : 'Save'}
                </button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/amt-config/list-amt-device')}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddAmtDevice;
