import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CodeMasterService } from '../services/CodeMasterService';

const AddCodeMaster = () => {
  const navigate = useNavigate();
  const [formGroup, setFormGroup] = useState({
    code: '',
    description: '',
    type: '',
    comments: '',
    status: ''
  });
  const [statusOptions] = useState(['A', 'D']);
  const [lstCodeType, setLstCodeType] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    buildAddCodeMasterForm();
    setUpInitialData();
  }, []);

  const buildAddCodeMasterForm = () => {
    setFormGroup({
      code: '',
      description: '',
      type: '',
      comments: '',
      status: ''
    });
  };

  const setUpInitialData = async () => {
    try {
      const codeMasters = await CodeMasterService.getAllCodeMaster();
      setLstCodeType(Array.from(new Set(codeMasters.map(x => x.type))));
    } catch (error) {
      console.error('Failed to load initial data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.code) newErrors.code = 'Code is required.';
    if (!formGroup.type) newErrors.type = 'Code Type is required.';
    if (!formGroup.status) newErrors.status = 'Status is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const codeMaster = { ...formGroup, code: formGroup.code.toUpperCase() };
      await CodeMasterService.saveCodeMaster(codeMaster);
      toast.success('Code master saved successfully.');
      navigate('/admin/configurations/master/list-code-master');
    } catch (error) {
      console.error('Failed to save Code Master:', error);
      toast.error('Error saving Code Master');
    }
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-2'>
        <h1>ADD Code Master</h1>
        <ul>
          <li>System Configuration</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="code">Code</label>
                  <input
                    type="text"
                    className="form-control uppercase"
                    id="code"
                    name="code"
                    placeholder="Code"
                    value={formGroup.code}
                    onChange={handleChange}
                    required
                  />
                </div>
                {errors.code && <div className="invalid-feedback d-block">{errors.code}</div>}
              </div>
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={formGroup.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group select-group mb-0">
                  <label htmlFor="type">Code Type</label>
                  <select
                    className="form-control"
                    id="type"
                    name="type"
                    value={formGroup.type}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Code Type</option>
                    {lstCodeType.map(ty => (
                      <option key={ty} value={ty}>{ty}</option>
                    ))}
                  </select>
                  {errors.type && <div className="invalid-feedback d-block">{errors.type}</div>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-4">
                <div className="form-label-group mb-0">
                  <label htmlFor="comments">Comments</label>
                  <input
                    type="text"
                    className="form-control"
                    id="comments"
                    name="comments"
                    placeholder="Comments"
                    value={formGroup.comments}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group select-group mb-0">
                  <label htmlFor="status">Status</label>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    value={formGroup.status}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select status</option>
                    {statusOptions.map(status => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </select>
                  {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/master/list-code-master')}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddCodeMaster;
