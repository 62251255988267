import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';

const BASE_URL = `${API_BASE_URL}/master/uom`;

export const UomService = {
  getAllUom: () => axios.get(BASE_URL).then(res => res.data),

  getUomByUomId: (uomId) => axios.get(`${BASE_URL}/${uomId}`).then(res => res.data),

  getAllCodeMasters: () => axios.get(`${API_BASE_URL}/device/codemasters`).then(res => res.data),

  saveUom: (uom, isEdit) => {
    if (isEdit) {
      return axios.put(`${BASE_URL}/update`, uom).then(res => res.data);
    } else {
      return axios.post(`${BASE_URL}/save`, uom).then(res => res.data);
    }
  },

  deleteUom: (uomId) => axios.delete(`${BASE_URL}/delete/${uomId}`).then(res => res.data),

  canDeleteUom: (uomId) => axios.get(`${BASE_URL}/can-delete/${uomId}`).then(res => res.data),

  filterUomTypesFromCodeMaster: (allCodeMasters) => allCodeMasters.filter(x => x.type === CodeMasterType.UomType),

  filterMaterialStateFromCodeMaster: (allCodeMasters) => allCodeMasters.filter(x => x.type === CodeMasterType.MaterialState),
};
