import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import AddPollutantLimit from './AddPollutantLimit';
import {
    getPollutantLimitData,
    deletePollutantLimit
} from '../services/PollutantLimitService';
import ExportComponent from '../../../../common/ExportComponent';
import PollutantLimitExportComponent from '../../../../common/exports/PollutantLimitExportComponent';
import { format } from 'date-fns';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

const PollutantLimitList = () => {
    const [pollutantLimitData, setPollutantLimitData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedPollutantId, setSelectedPollutantId] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchPollutantLimitData();
    }, []);

    const fetchPollutantLimitData = async () => {
        setIsLoading(true);
        try {
            const data = await getPollutantLimitData();
            setPollutantLimitData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch pollutant limit data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (pollutantId, year) => {
        setSelectedPollutantId(pollutantId);
        setSelectedYear(year);
        setShowModal(true);
    };

    const handleDelete = async (pollutantId, year) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Pollutant Limit for ${pollutantId} in year ${year}?`);
        if (confirmDelete) {
            try {
                await deletePollutantLimit(pollutantId, year);
                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                fetchPollutantLimitData();
            } catch (error) {
                console.error('Failed to delete pollutant limit:', error);
            }
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = pollutantLimitData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Pollutant ID': item.pollutantId,
            'Year': item.year,
            'Limit': formatWithIndianThousandsSeparatorForExportData(item.limit ? item.limit.toFixed(0) : item.limit),
            'UoM': item.uom,
            'Status': item.status
        }));
        setExportFilteredData(exportData);

    }, [search, pollutantLimitData]);

    const columns = [
        { name: 'Pollutant ID', selector: row => row.pollutantId, sortable: true },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true
        },
        {
            name: 'Limit',
            selector: row => row.limit,
            cell: row => formatWithIndianThousandsSeparator(row.limit ? row.limit.toFixed(0) : row.limit),
            sortable: true
        },
        { name: 'UoM', selector: row => row.uom, sortable: true },
        { name: 'Status', selector: row => row.status, sortable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.pollutantId, row.year)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.pollutantId, row.year)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedPollutantId(null);
        setSelectedYear(null);
    };

    const fileName = `Pollutant_Limit_${new Date().toLocaleDateString()}`;

    const title = "Pollutant Limit Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Pollutant Limits</h1>
                <ul>
                    <li>Operational Data</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Pollutant Limit</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Button variant="primary" onClick={() => setShowModal(true)}>Add New Record</Button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <PollutantLimitExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
            <AddPollutantLimit
                show={showModal}
                handleClose={handleClose}
                fetchPollutantLimitData={fetchPollutantLimitData}
                pollutantId={selectedPollutantId}
                year={selectedYear}
            />
        </div>
    );
};

export default PollutantLimitList;

