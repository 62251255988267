import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';

const AddFacilityFeed = () => {
  const navigate = useNavigate();
  // const { feedId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [facilityFeed, setFacilityFeed] = useState({
    feedId: '',
    feedName: '',
    facilityId: '',
    materialState: '',
    lhV_BtuScf: '',
    status: 'A'
  });
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [materialStates, setMaterialStates] = useState([]);
  const [statusOptions] = useState(['A', 'D']);

  useEffect(() => {
    if (searchParams.get("feedId")) {
      fetchFacilityFeedData(searchParams.get("feedId"));
      setIsEdit(true);
    }
    fetchInitialData();
  }, [searchParams.get("feedId")]);

  const fetchFacilityFeedData = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/ems-master/facility-feed/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setFacilityFeed(data);
    } catch (error) {
      console.error('Failed to fetch facility feed data:', error);
    }
  };

  const fetchInitialData = async () => {
    try {
      const [facilitiesResponse, codeMastersResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/home/facilities`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' })
      ]);

      if (!facilitiesResponse.ok || !codeMastersResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const [facilitiesData, codeMastersData] = await Promise.all([
        facilitiesResponse.json(),
        codeMastersResponse.json()
      ]);

      setFacilities(facilitiesData);

      const filteredMaterialStates = codeMastersData.filter(item => item.type === CodeMasterType.MaterialState);
      setMaterialStates(filteredMaterialStates);
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!facilityFeed.feedId) {
      newErrors.feedId = 'Feed ID is required.';
    }
    if (!facilityFeed.status) {
      newErrors.status = 'Status is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFacilityFeed(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const apiEndpoint = isEdit ? `${API_BASE_URL}/ems-master/facility-feed/update` : `${API_BASE_URL}/ems-master/facility-feed/save`;
    const method = isEdit ? 'PUT' : 'POST';

    try {
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(facilityFeed)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Facility Feed ${isEdit ? 'updated' : 'added'} successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate('/admin/configurations/ems-master/list-facility-feed');
      }, 1000);
    } catch (error) {
      console.error(`Failed to ${isEdit ? 'update' : 'add'} facility feed:`, error);
      toast.error(`Failed to ${isEdit ? 'update' : 'add'} facility feed.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Facility Feed' : 'Add Facility Feed'}</h1>
        <ul>
          <li>Operational Data</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">{isEdit ? 'Edit Facility Feed' : 'Add Facility Feed'}</h4> */}
              <hr></hr>
              <form onSubmit={handleSubmit} noValidate>
                <h4>Reason Info</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Feed ID</label>
                      <input
                        type="text"
                        name="feedId"
                        className={`form-control ${errors.feedId ? 'is-invalid' : ''}`}
                        value={facilityFeed.feedId}
                        onChange={handleChange}
                        disabled={isEdit}
                        required
                      />
                      {errors.feedId && <div className="invalid-feedback">{errors.feedId}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Feed Name</label>
                      <input
                        type="text"
                        name="feedName"
                        className={`form-control ${errors.feedName ? 'is-invalid' : ''}`}
                        value={facilityFeed.feedName}
                        onChange={handleChange}
                      />
                      {errors.feedName && <div className="invalid-feedback">{errors.feedName}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Facility</label>
                      <select
                        name="facilityId"
                        className={`form-control ${errors.facilityId ? 'is-invalid' : ''}`}
                        value={facilityFeed.facilityId}
                        onChange={handleChange}
                      >
                        <option value="">Select Facility</option>
                        {facilities.map(facility => (
                          <option key={facility.facilityId} value={facility.facilityId}>{facility.facilityName}</option>
                        ))}
                      </select>
                      {errors.facilityId && <div className="invalid-feedback">{errors.facilityId}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Material State</label>
                      <select
                        name="materialState"
                        className={`form-control ${errors.materialState ? 'is-invalid' : ''}`}
                        value={facilityFeed.materialState}
                        onChange={handleChange}
                      >
                        <option value="">Select Material State</option>
                        {materialStates.map(state => (
                          <option key={state.code} value={state.code}>{state.description}</option>
                        ))}
                      </select>
                      {errors.materialState && <div className="invalid-feedback">{errors.materialState}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>LHV BTUSCF</label>
                      <input
                        type="number"
                        name="lhV_BtuScf"
                        className={`form-control ${errors.lhV_BtuScf ? 'is-invalid' : ''}`}
                        value={facilityFeed.lhV_BtuScf}
                        onChange={handleChange}
                      />
                      {errors.lhV_BtuScf && <div className="invalid-feedback">{errors.lhV_BtuScf}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={facilityFeed.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        {statusOptions.map(status => (
                          <option key={status} value={status}>{status}</option>
                        ))}
                      </select>
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <button type="submit" className="btn btn-primary float-right">Save</button>
                    <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/ems-master/list-facility-feed')}>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddFacilityFeed;
