import { API_BASE_URL } from "../../../../../config";

export const getAllEquipment = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/equipment/all`, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      credentials: 'include'
    });
    return await response.json();
  } catch (error) {
    console.error('Failed to fetch all equipment:', error);
    throw error;
  }
};

export const getEquipmentById = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/equipment/${id}`, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      credentials: 'include'
    });
    return await response.json();
  } catch (error) {
    console.error(`Failed to fetch equipment with ID ${id}:`, error);
    throw error;
  }
};

export const saveEquipment = async (equipment, isEdit,equipmentId) => {
  try {
    const url = `${API_BASE_URL}/equipment/${isEdit ? `update/${equipmentId}` : 'save'}`;
    const method = isEdit ? 'PUT' : 'POST';
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(equipment)
    });
    return await response.json();
  } catch (error) {
    console.error(`Failed to ${isEdit ? 'update' : 'save'} equipment:`, error);
    throw error;
  }
};

export const deleteEquipment = async (id) => {
  try {
    const response = await fetch(`${API_BASE_URL}/equipment/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    return await response.json();
  } catch (error) {
    console.error(`Failed to delete equipment with ID ${id}:`, error);
    throw error;
  }
};
