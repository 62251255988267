import React from 'react'
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import FacilitySelector from '../../common/FacilitySelector';
import ExportComponent from '../../common/ExportComponent';
import 'react-datepicker/dist/react-datepicker.css';
import { BUTTON_LABELS } from '../../../constants';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../common/FormatNumber';
import { format } from 'date-fns';
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
export default function PlanVsActual() {

  const [facilityData, setFacilityData] = useState([]);
  const [planVsActualData, setPlanVsActualData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [startDatePickerClass, setStartDatePickerClass] = useState('');
  const [endDatePickerClass, setEndDatePickerClass] = useState('');
  const [exportFilteredData, setExportFilteredData] = useState([]);


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handlePlanVsActualButtonClick = () => {
    if (startDate && endDate && selectedFacilityId !== '') {
      setIsLoading(true);
      const apiUrl = `${API_BASE_URL}/reports/PlanningVsActual-flaring?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facility=${selectedFacilityId}`;

      // Make API call using fetch or any other HTTP client library
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // Process the data returned by the API
          setPlanVsActualData(data.content);
          setFilteredData(data.content);
          setIsLoading(false);
        })
        .catch(error => {
          // Handle errors
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    } else {
      // Handle invalid selection or missing data
      console.error('Please select valid options.');
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    let filtered = planVsActualData.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
  
    // If filtered data is empty, add a placeholder row to ensure headers are displayed
    if (filtered.length === 0) {
      filtered = [{
        faciltyId: '',
        plantId: '',
        actualFlaring: '',
        plannedFlaring: '',
        ytda: '',
        ytdp: '',
        variance: ''
      }];
    }
  
    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Facility ID': item.faciltyId,
      'Plant ID': item.plantId,
      'Actual Flaring': formatWithIndianThousandsSeparatorForExportData(item.actualFlaring ? item.actualFlaring.toFixed(0) : item.actualFlaring),
      'Planned Flaring': formatWithIndianThousandsSeparatorForExportData(item.plannedFlaring ? item.plannedFlaring.toFixed(0) : item.plannedFlaring),
      'YTDA': formatWithIndianThousandsSeparatorForExportData(item.ytda ? item.ytda.toFixed(0) : item.ytda),
      'YTDP': formatWithIndianThousandsSeparatorForExportData(item.ytdp ? item.ytdp.toFixed(0) : item.ytdp),
      'Variance': formatWithIndianThousandsSeparatorForExportData(item.variance ? item.variance.toFixed(0) : item.variance)
    }));
    setExportFilteredData(exportData);

  }, [search, planVsActualData]);


  const columns = [
    {
      name: 'Facility ID',
      selector: row => row.faciltyId,
      sortable: true
    },
    {
      name: 'Plant ID',
      selector: row => row.plantId,
      sortable: true
    },
    {
      name: 'Actual Flaring',
      selector: row => row.actualFlaring,
      sortable: true,
      cell: row => formatWithIndianThousandsSeparator(row.actualFlaring ? row.actualFlaring.toFixed(0) : row.actualFlaring)
    },
    {
      name: 'Planned Flaring',
      selector: row => row.plannedFlaring,
      sortable: true
    },
    {
      name: 'YTDA',
      selector: row => row.ytda,
      sortable: true,
      cell: row => formatWithIndianThousandsSeparator(row.ytda ? row.ytda.toFixed(0) : row.ytda)
    },
    {
      name: 'YTDP',
      selector: row => row.ytdp,
      sortable: true,
      cell: row => formatWithIndianThousandsSeparator(row.ytdp ? row.ytdp.toFixed(0) : row.ytdp)
    },
    {
      name: 'Variance',
      selector: row => row.variance,
      sortable: true,
      cell: row => formatWithIndianThousandsSeparator(row.variance ? row.variance.toFixed(0) : row.variance)
    },

  ];

  // const [selectionRange, setSelectionRange] = useState({
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection',
  //   });

  //   const handleSelect = (ranges) => {
  //     setSelectionRange(ranges.selection);
  //   };

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `FMS_Plan_Vs_Actual${new Date().toLocaleDateString()}`;
  const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
  const handleStartDateBlur = () => setStartDatePickerClass('');
  const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
  const handleEndDateBlur = () => setEndDatePickerClass('');

  const headerData = [
    { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
    { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
    { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

  const title = "Plan Vs Actual Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Plan Vs Actual</h1>
        <ul>
          <li>Reports</li>
          <li>Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className='row'>
          <FacilitySelector
            selectedFacilityId={selectedFacilityId}
            onChange={setSelectedFacilityId}
            facilityData={facilityData} 
            setFacilityData={setFacilityData}
          />
          <div className='col-md-2'>
            <label>Start Date</label>
            <div className={startDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleStartDateFocus}
                onBlur={handleStartDateBlur}
              />
            </div>
          </div>
          <div className='col-md-2'>
            <label>End Date</label>
            <div className={endDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleEndDateFocus}
                onBlur={handleEndDateBlur}
              />
            </div>
          </div>

          {/* <div className='col-md-3'>
        <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
        </div> */}
          <div className='col-md-3 generate_report_button'>
            <button className='btn btn-primary' onClick={handlePlanVsActualButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
          </div>

        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3"> Plan Vs Actual </h4> */}
              <div className='row'>
                <div className='col-md-10'>
                  {exportFilteredData.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title} />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="table-responsive">

                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>

                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>

                )}

              </div>
            </div>
          </div>
        </div>
        {/* end of col*/}
      </div>
    </div>

  )
}
