import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import fmsDashboardService from '../services/fmsDashboardService';
import './FacilitiesFlaringSummary.css';

const FacilitiesFlaringSummary = ({ fmsDashboardFilter, selectedDate, appSession }) => {
  const [facilityChart, setFacilityChart] = useState({
    labels: [],
    datasets: [
      {
        label: 'Actual',
        backgroundColor: '#ff708e', // pink
        borderColor: '#ffb4c4',
        fill: true,
        lineTension: 0.3,
        data: [],
        borderRadius: 10 // Add borderRadius for rounded bars
      },
      {
        label: 'Planned',
        backgroundColor: '#FFC107', // yellow
        borderColor: '#FFC107',
        fill: true,
        lineTension: 0.3,
        data: [],
        borderRadius: 10 // Add borderRadius for rounded bars
      }
    ]
  });
  const [showChart, setShowChart] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [isStacked, setIsStacked] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    fetchDataAndSetupChart(true);
  }, [selectedDate, fmsDashboardFilter]);

  const fetchDataAndSetupChart = async (forceUpdate) => {
    if (!selectedDate || !fmsDashboardFilter) {
      return;
    }

    setShowChart(false);

    try {
      const response = await fmsDashboardService.getFacilityFlaringSummaries(selectedDate, fmsDashboardFilter.type, fmsDashboardFilter.facilityId);
      initChartOptions();
      const flaringSummaries = response.data;
      loadData(flaringSummaries);
      if (flaringSummaries && flaringSummaries.length !== 0) {
        setShowNoDataText(false);
      } else {
        setShowNoDataText(true);
      }
      setShowChart(true);
    } catch (error) {
      setShowChart(true);
      setShowNoDataText(true);
    }
  };

  const initChartOptions = () => {
    setFacilityChart({
      labels: [],
      datasets: [
        {
          label: 'Actual',
          backgroundColor: '#ff708e',
          borderColor: '#ff708e',
          borderWidth: 2,
          fill: true,
          lineTension: 0.3,
          data: [],
          borderRadius: 50 // Add borderRadius for rounded bars
        },
        {
          label: 'Planned',
          backgroundColor: '#FFC107',
          borderColor: '#FFC107',
          fill: true,
          lineTension: 0.3,
          data: [],
          borderRadius: 10 // Add borderRadius for rounded bars
        }
      ]
    });
  };

  const loadData = (flaringSummaries) => {
    if (flaringSummaries) {
      const labels = flaringSummaries.map(x => x.facilityId);
      const actualData = flaringSummaries.map(x => x.actual.toFixed(2));
      const plannedData = flaringSummaries.map(x => x.planned.toFixed(2));
      setFacilityChart(prevChart => ({
        ...prevChart,
        labels,
        datasets: [
          { ...prevChart.datasets[0], data: actualData },
          { ...prevChart.datasets[1], data: plannedData }
        ]
      }));
    }
  };

  const chartOptions = {
    indexAxis: 'y', // This option makes the chart horizontal
    plugins: {
      legend: {
        labels: {
          fontColor: '#FFFFFF'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: true,
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            // Safely access appSession.gasUnits
            const units = appSession?.gasUnits || 'MMscf';
            return `${value} ${units}`;
          }
        }
      },
      datalabels: {
        display: false,
        font: {
          weight: 'bold',
          size: 11
        },
        formatter: Math.round
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: isStacked,
        scaleLabel: {
          display: true,
          labelString: 'Value',
          fontColor: '#FFFFFF'
        },
        grid: {
          color: '#2d2d2d'
        },
        ticks: {
          fontColor: '#FFFFFF',
          display: true,
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0,
          callback: function(value) {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(0) + 'B'; // Show in Billions
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(0) + 'M'; // Show in Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(0) + 'K'; // Show in Thousands
            } else {
              return value; // Show as is
            }
          }
          
        }
      },
      y: {
        stacked: isStacked,
        scaleLabel: {
          display: true,
          labelString: 'Facility',
          fontColor: '#FFFFFF'
        },
        grid: {
          color: '#2d2d2d'
        },
        ticks: {
          fontColor: '#FFFFFF'
        }
      }
    }
  };
  

  return (
    <div className="facilities-flaring-summary">
      <div className="action-container">
        {/* Add any action buttons if needed */}
      </div>
      {showChart && !showNoDataText ? (
        <Bar
          ref={chartRef}
          data={facilityChart}
          options={chartOptions}
          height={100}
        />
      ) : (
        <div className="no-data-txt">
          <h5 className="mt-5"> No Data Found</h5>
        </div>
      )}
    </div>
  );
};

export default FacilitiesFlaringSummary;
