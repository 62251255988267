// AddGhgDevice.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    fetchGhgDeviceData, fetchUnregisteredDevices, fetchCodeMasters, fetchGhgMethods, fetchGhgDeviceTypes,
    fetchGwpPotentials, fetchSourceLevel1, fetchSourceLevel2, fetchSourceLevel3, fetchSourceLevel4, fetchCompositionTypes, fetchScope3Categories, fetchFinalGhgFactor,
    saveGhgDevice, updateGhgDevice
} from '../services/GhgDeviceService';

const AddGhgDevice = () => {
    const navigate = useNavigate();
    const { ghgDeviceId } = useParams();
    const [formGroup, setFormGroup] = useState({
        deviceId: '',
        materialState: '',
        ghgMethod: '',
        sourceLayer1: '',
        sourceLayer2: '',
        sourceLayer3: '',
        sourceLayer4: '',
        ghgMethodComments: '',
        efUncertaintyPercentage: '',
        afUncertaintyPercentage: '',
        scope: '',
        scope3Id: '',
        categoryLevel1: '',
        categoryLevel2: '',
        categoryLevel3: '',
        categoryLevel4: '',
        compType: '',
        measurementType: '',
        ghg: '',
        componentId: '',
        ghgKey: '',
        ghgFactorComments: '',
        ghgUom: '',
        fuom: '',
        ghgFactor: '',
        ipccYear: '',
        ipccValue: '',
        status: 'A'
    });
    const [isEdit, setIsEdit] = useState(false);
    const [unregisteredDevices, setUnregisteredDevices] = useState([]);
    const [lstMaterialStates, setLstMaterialStates] = useState([]);
    const [lstGhgMethods, setLstGhgMethods] = useState([]);
    const [lstScopes, setLstScopes] = useState([]);
    const [lstScope3Categories, setLstScope3Categories] = useState([]);
    const [lstCategoryLevel1s, setLstCategoryLevel1s] = useState([]);
    const [lstCategoryLevel2s, setLstCategoryLevel2s] = useState([]);
    const [lstCategoryLevel3s, setLstCategoryLevel3s] = useState([]);
    const [lstCategoryLevel4s, setLstCategoryLevel4s] = useState([]);
    const [filteredSourceLevel2, setFilteredSourceLevel2] = useState([]);
    const [filteredSourceLevel3, setFilteredSourceLevel3] = useState([]);
    const [filteredSourceLevel4, setFilteredSourceLevel4] = useState([]);
    const [lstGwpPotentials, setLstGwpPotentials] = useState([]);
    const [lstIpccYears, setLstIpccYears] = useState([]);
    const [showIpccWarningMessage, setShowIpccWarningMessage] = useState(false);
    const [ghgFactorList, setGhgFactorList] = useState([]);
    const [ghgCompositionDetails, setGhgCompositionDetails] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (ghgDeviceId) {
            setIsEdit(true);
            loadData(ghgDeviceId);
        }
        fetchInitialData();
    }, [ghgDeviceId]);

    const loadData = async (id) => {
        try {
            const data = await fetchGhgDeviceData(id);
            setFormGroup(data);
            setShowIpccWarningMessage(false);
            fetchFinalGhgFactorData(data.scope, data.categoryLevel1, data.categoryLevel2, data.categoryLevel3, data.categoryLevel4, data.ghg);
            fetchIpccValues(data.ghg);
        } catch (error) {
            console.error('Failed to fetch GHG Source data:', error);
        }
    };

    const fetchInitialData = async () => {
        try {
            const [
                unregisteredDevicesData, codeMastersData, ghgMethodsData, ghgDeviceTypesData, gwpPotentialsData,
                sourceLevelsData1, sourceLevelsData2, sourceLevelsData3, sourceLevelsData4, compositionTypesData, scope3CategoriesData
            ] = await Promise.all([
                fetchUnregisteredDevices(),
                fetchCodeMasters(),
                fetchGhgMethods(),
                fetchGhgDeviceTypes(),
                fetchGwpPotentials(),
                fetchSourceLevel1(),
                fetchSourceLevel2(),
                fetchSourceLevel3(),
                fetchSourceLevel4(),
                fetchCompositionTypes(),
                fetchScope3Categories()
            ]);

            // console.log("data",);
            setUnregisteredDevices(unregisteredDevicesData);
            setLstScopes(codeMastersData.filter(t => t.type === 'Scope').map(t => t.code));
            setLstGhgMethods(ghgMethodsData);
            setLstMaterialStates(ghgDeviceTypesData.filter(t => t.type === 'MaterialState'));
            setLstGwpPotentials(gwpPotentialsData.filter(x => x.status === 'A'));
            setFilteredSourceLevel2(sourceLevelsData2);
            setFilteredSourceLevel3(sourceLevelsData3);
            setFilteredSourceLevel4(sourceLevelsData4);
            setLstScope3Categories(scope3CategoriesData);
            setGhgCompositionDetails(compositionTypesData);

            if (isEdit) {
                loadData(ghgDeviceId);
            }
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    };

    const fetchFinalGhgFactorData = async (scope, level1, level2, level3, level4, ghg) => {
        try {
            const data = await fetchFinalGhgFactor({ scope, level1, level2, level3, level4, ghg });
            setGhgFactorList(data);
            patchSelectedFactorValues();
        } catch (error) {
            console.error('Failed to fetch final GHG factor:', error);
        }
    };

    const fetchIpccValues = (ghg) => {
        setLstIpccYears(lstGwpPotentials.filter(t => t.gwpCompound === ghg).map(x => x.year));
    };

    const loadIpccValue = () => {
        setShowIpccWarningMessage(true);
        const ghg = formGroup.ghg;
        const ipccYear = formGroup.ipccYear;
        const filteredValues = lstGwpPotentials.filter(t => t.gwpCompound === ghg && t.year === ipccYear);
        if (filteredValues && filteredValues.length > 0) {
            setFormGroup(prevState => ({
                ...prevState,
                ipccValue: filteredValues[0].ipccValue
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormGroup(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };

    const validate = () => {
        const newErrors = {};
        if (!formGroup.deviceId) newErrors.deviceId = 'Source ID is required.';
        if (!formGroup.materialState) newErrors.materialState = 'Material State is required.';
        if (!formGroup.ghgMethod) newErrors.ghgMethod = 'GHG Method is required.';
        if (!formGroup.sourceLayer1) newErrors.sourceLayer1 = 'GHG Source Layer 1 is required.';
        if (!formGroup.sourceLayer2) newErrors.sourceLayer2 = 'GHG Source Layer 2 is required.';
        if (!formGroup.sourceLayer3) newErrors.sourceLayer3 = 'GHG Source Layer 3 is required.';
        if (!formGroup.scope) newErrors.scope = 'Scope is required.';
        if (!formGroup.categoryLevel1) newErrors.categoryLevel1 = 'Category Level 1 is required.';
        if (!formGroup.categoryLevel2) newErrors.categoryLevel2 = 'Category Level 2 is required.';
        if (!formGroup.ipccYear) newErrors.ipccYear = 'IPCC Year is required.';
        if (!formGroup.ipccValue) newErrors.ipccValue = 'IPCC Value is required.';
        if (!formGroup.status) newErrors.status = 'Status is required.';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const ghgDevice = { ...formGroup };

        try {
            const saveOrUpdate = isEdit ? updateGhgDevice : saveGhgDevice;
            await saveOrUpdate(ghgDevice);
            toast.success(`GHG Source ${isEdit ? 'updated' : 'added'} successfully.`);
            navigate('/configurations/device/list-ghg-device');
        } catch (error) {
            console.error('Failed to save GHG Source:', error);
            toast.error('Error adding GHG Source. Please try again.');
        }
    };

    const applyDeviceDetailsOnSelection = (deviceId) => {
        const selectedDevice = unregisteredDevices.find(t => t.deviceId === deviceId);
        if (selectedDevice) {
            setFormGroup(prevState => ({
                ...prevState,
                materialState: selectedDevice.materialState
            }));
        }
    };

    const patchSelectedFactorValues = () => {
        if (ghgFactorList && ghgFactorList.length > 0) {
            setFormGroup(prevState => ({
                ...prevState,
                measurementType: ghgFactorList[0].measurementType,
                ghgKey: ghgFactorList[0].ghgKey,
                ghgUom: ghgFactorList[0].activityUom,
                fuom: ghgFactorList[0].uom,
                ghgFactor: ghgFactorList[0].ghgConversionFactor,
                ghg: ghgFactorList[0].ghg
            }));
        }
    };

    return (
        <div>
            {/* <h4>{isEdit ? 'Edit GHG Source' : 'Add GHG Source'}</h4> */}
            <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit GHG Source' : 'Add GHG Source'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Source Master-GHG</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <form onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-header">
                        GHG Source Details
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="deviceId">Source ID</label>
                                {isEdit ? (
                                    <input type="text" className="form-control" id="deviceId" name="deviceId" value={formGroup.deviceId} readOnly />
                                ) : (
                                    <select className="form-control" id="deviceId" name="deviceId" value={formGroup.deviceId} onChange={handleChange}>
                                        <option value="">Select GHG Source</option>
                                        {unregisteredDevices.map(device => (
                                            <option key={device.deviceId} value={device.deviceId}>{device.deviceId}</option>
                                        ))}
                                    </select>
                                )}
                                {errors.deviceId && <div className="invalid-feedback d-block">{errors.deviceId}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="materialState">Material State</label>
                                <select className="form-control" id="materialState" name="materialState" value={formGroup.materialState} onChange={handleChange} disabled>
                                    <option value="">Select Material State</option>
                                    {lstMaterialStates.map(material => (
                                        <option key={material.code} value={material.code}>{material.description}</option>
                                    ))}
                                </select>
                                {errors.materialState && <div className="invalid-feedback d-block">{errors.materialState}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="sourceLayer1">GHG Source Layer 1</label>
                                <select className="form-control" id="sourceLayer1" name="sourceLayer1" value={formGroup.sourceLayer1} onChange={handleChange}>
                                    <option value="">Select Source Layer 1</option>
                                    {filteredSourceLevel2.map(srcLevel => (
                                        <option key={srcLevel.sourceLayerId} value={srcLevel.sourceLayerId}>{srcLevel.sourceName}</option>
                                    ))}
                                </select>
                                {errors.sourceLayer1 && <div className="invalid-feedback d-block">{errors.sourceLayer1}</div>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="sourceLayer2">GHG Source Layer 2</label>
                                <select className="form-control" id="sourceLayer2" name="sourceLayer2" value={formGroup.sourceLayer2} onChange={handleChange}>
                                    <option value="">Select Source Layer 2</option>
                                    {filteredSourceLevel2.map(srcLevel => (
                                        <option key={srcLevel.sourceLayerId} value={srcLevel.sourceLayerId}>{srcLevel.sourceName}</option>
                                    ))}
                                </select>
                                {errors.sourceLayer2 && <div className="invalid-feedback d-block">{errors.sourceLayer2}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="sourceLayer3">GHG Source Layer 3</label>
                                <select className="form-control" id="sourceLayer3" name="sourceLayer3" value={formGroup.sourceLayer3} onChange={handleChange}>
                                    <option value="">Select Source Layer 3</option>
                                    {filteredSourceLevel3.map(srcLevel => (
                                        <option key={srcLevel.sourceLayerId} value={srcLevel.sourceLayerId}>{srcLevel.sourceName}</option>
                                    ))}
                                </select>
                                {errors.sourceLayer3 && <div className="invalid-feedback d-block">{errors.sourceLayer3}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="sourceLayer4">GHG Final Layer</label>
                                <select className="form-control" id="sourceLayer4" name="sourceLayer4" value={formGroup.sourceLayer4} onChange={handleChange}>
                                    <option value="">Select Final Layer</option>
                                    {filteredSourceLevel4.map(srcLevel => (
                                        <option key={srcLevel.sourceLayerId} value={srcLevel.sourceLayerId}>{srcLevel.sourceName}</option>
                                    ))}
                                </select>
                                {errors.sourceLayer4 && <div className="invalid-feedback d-block">{errors.sourceLayer4}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header">
                        GHG Methods
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="ghgMethod">GHG Method</label>
                                <select className="form-control" id="ghgMethod" name="ghgMethod" value={formGroup.ghgMethod} onChange={handleChange}>
                                    <option value="">Select GHG Method</option>
                                    {lstGhgMethods.map(ghg => (
                                        <option key={ghg.ghgMethodId} value={ghg.ghgMethodId}>{ghg.ghgMethodId}</option>
                                    ))}
                                </select>
                                {errors.ghgMethod && <div className="invalid-feedback d-block">{errors.ghgMethod}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="ghgMethodComments">Reason for Choosing the Method</label>
                                <input type="text" className="form-control" id="ghgMethodComments" name="ghgMethodComments" value={formGroup.ghgMethodComments} onChange={handleChange} />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="efUncertaintyPercentage">EF Uncertainty Percentage</label>
                                <input type="number" className="form-control" id="efUncertaintyPercentage" name="efUncertaintyPercentage" value={formGroup.efUncertaintyPercentage} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="afUncertaintyPercentage">AF Uncertainty Percentage</label>
                                <input type="number" className="form-control" id="afUncertaintyPercentage" name="afUncertaintyPercentage" value={formGroup.afUncertaintyPercentage} onChange={handleChange} />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="scope">Scope</label>
                                <select className="form-control" id="scope" name="scope" value={formGroup.scope} onChange={handleChange}>
                                    <option value="">Select Scope</option>
                                    {lstScopes.map(scope => (
                                        <option key={scope} value={scope}>{scope}</option>
                                    ))}
                                </select>
                                {errors.scope && <div className="invalid-feedback d-block">{errors.scope}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="scope3Id">Scope 3 ID</label>
                                <select className="form-control" id="scope3Id" name="scope3Id" value={formGroup.scope3Id} onChange={handleChange}>
                                    <option value="">Select Scope 3 ID</option>
                                    {lstScope3Categories.map(scope3 => (
                                        <option key={scope3.scope3id} value={scope3.scope3id}>{scope3.category}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header">
                        GHG Factors
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="categoryLevel1">Category Level 1</label>
                                <select className="form-control" id="categoryLevel1" name="categoryLevel1" value={formGroup.categoryLevel1} onChange={handleChange}>
                                    <option value="">Select Level 1</option>
                                    {lstCategoryLevel1s.map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))}
                                </select>
                                {errors.categoryLevel1 && <div className="invalid-feedback d-block">{errors.categoryLevel1}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="categoryLevel2">Category Level 2</label>
                                <select className="form-control" id="categoryLevel2" name="categoryLevel2" value={formGroup.categoryLevel2} onChange={handleChange}>
                                    <option value="">Select Level 2</option>
                                    {lstCategoryLevel2s.map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))}
                                </select>
                                {errors.categoryLevel2 && <div className="invalid-feedback d-block">{errors.categoryLevel2}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="categoryLevel3">Category Level 3</label>
                                <select className="form-control" id="categoryLevel3" name="categoryLevel3" value={formGroup.categoryLevel3} onChange={handleChange}>
                                    <option value="">Select Level 3</option>
                                    {lstCategoryLevel3s.map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))}
                                </select>
                                {errors.categoryLevel3 && <div className="invalid-feedback d-block">{errors.categoryLevel3}</div>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="categoryLevel4">Category Level 4</label>
                                <select className="form-control" id="categoryLevel4" name="categoryLevel4" value={formGroup.categoryLevel4} onChange={handleChange}>
                                    <option value="">Select Level 4</option>
                                    {lstCategoryLevel4s.map(cat => (
                                        <option key={cat} value={cat}>{cat}</option>
                                    ))}
                                </select>
                                {errors.categoryLevel4 && <div className="invalid-feedback d-block">{errors.categoryLevel4}</div>}
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="measurementType">Measurement Type</label>
                                <input type="text" className="form-control" id="measurementType" name="measurementType" value={formGroup.measurementType} readOnly />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="ghgUom">Activity UoM</label>
                                <input type="text" className="form-control" id="ghgUom" name="ghgUom" value={formGroup.ghgUom} readOnly />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="ghgKey">GHG Key</label>
                                <input type="text" className="form-control" id="ghgKey" name="ghgKey" value={formGroup.ghgKey} readOnly />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="ghgFactor">GHG Conversion Factor</label>
                                <input type="text" className="form-control" id="ghgFactor" name="ghgFactor" value={formGroup.ghgFactor} readOnly />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="fuom">Factor UoM</label>
                                <input type="text" className="form-control" id="fuom" name="fuom" value={formGroup.fuom} readOnly />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="ghgFactorComments">Reason for Choosing Factor</label>
                                <input type="text" className="form-control" id="ghgFactorComments" name="ghgFactorComments" value={formGroup.ghgFactorComments} onChange={handleChange} />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="ghg">GHG Pollutant</label>
                                <input type="text" className="form-control" id="ghg" name="ghg" value={formGroup.ghg} readOnly />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="componentId">Component ID</label>
                                <input type="text" className="form-control" id="componentId" name="componentId" value={formGroup.componentId} readOnly />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header">
                        IPCC Values
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-sm-4">
                                <label htmlFor="ipccYear">IPCC Year</label>
                                <select className="form-control" id="ipccYear" name="ipccYear" value={formGroup.ipccYear} onChange={handleChange}>
                                    <option value="">Select IPCC Year</option>
                                    {lstIpccYears.map(year => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="ipccValue">IPCC Value</label>
                                <input type="text" className="form-control" id="ipccValue" name="ipccValue" value={formGroup.ipccValue} readOnly />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="status">Status</label>
                                <select className="form-control" id="status" name="status" value={formGroup.status} onChange={handleChange}>
                                    <option value="">Select Status</option>
                                    <option value="A">A</option>
                                    <option value="D">D</option>
                                </select>
                                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
                            </div>
                        </div>
                        {showIpccWarningMessage && (
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="text-warning">
                                        IPCC year changed for 'GHG' and 'Source', for all GHG's for this source IPCC year will change.
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group row mt-4">
                    <div className="col-sm-12">
                        <button type="submit" className="btn btn-primary float-right">
                            {isEdit ? 'Update' : 'Save'}
                        </button>
                        <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/device/list-ghg-device')}>Cancel</button>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default AddGhgDevice;
