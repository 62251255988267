import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import moment from 'moment';
import GhgService from '../services/GhgService';

const GhgSummary = ({ areaId, facilityId, date }) => {
  const [pollutantEmissions, setPollutantEmissions] = useState([]);
  const [barChartData, setBarChartData] = useState([
    {
      label: 'CO2',
      data: [],
      backgroundColor: 'rgba(75, 192, 192, 0.5)', // Light blue
      borderColor: 'rgba(75, 192, 192, 1)', // Darker blue
      borderWidth: 1,
      barThickness: 20 // Set the bar thickness
    },
    {
      label: 'CH4',
      data: [],
      backgroundColor: 'rgba(255, 159, 64, 0.5)', // Light orange
      borderColor: 'rgba(255, 159, 64, 1)', // Darker orange
      borderWidth: 1,
      barThickness: 20 // Set the bar thickness
    },
    {
      label: 'N2O',
      data: [],
      backgroundColor: 'rgba(255, 99, 132, 0.5)', // Light pink
      borderColor: 'rgba(255, 99, 132, 1)', // Darker pink
      borderWidth: 1,
      barThickness: 20 // Set the bar thickness
    },
    {
      label: 'CO2e',
      data: [],
      backgroundColor: 'rgba(153, 102, 255, 0.5)', // Light purple
      borderColor: 'rgba(153, 102, 255, 1)', // Darker purple
      borderWidth: 1,
      barThickness: 20 // Set the bar thickness
    }
  ]);
  const [barChartLabels, setBarChartLabels] = useState([]);
  const [showNoDataText, setShowNoDataText] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [areaId, facilityId, date]);

  const fetchData = async () => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const lastDateOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');
      const [pollutantsResp, emissionsResp] = await Promise.all([
        GhgService.getActivePollutants(),
        GhgService.getActualEmissions(areaId, facilityId, formattedDate, lastDateOfMonth)
      ]);

      const emissions = emissionsResp.data;
      if (emissions.length === 0) {
        setShowNoDataText(true);
      } else {
        setShowNoDataText(false);
        setPollutantEmissions(emissions);
        setChartData(emissions);
      }
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const setChartData = (emissions) => {
    const newBarChartData = barChartData.map(dataset => ({ ...dataset, data: [] }));
    const newBarChartLabels = [];

    emissions.forEach(e => {
      newBarChartData[0].data.push((e.Co2 / 1000).toFixed(2));
      newBarChartData[1].data.push((e.Ch4 / 1000).toFixed(2));
      newBarChartData[2].data.push((e.N2o / 1000).toFixed(2));
      newBarChartData[3].data.push((e.Co2e / 1000).toFixed(2));
      newBarChartLabels.push(moment(e.day).format('DD'));
    });

    setBarChartData(newBarChartData);
    setBarChartLabels(newBarChartLabels);
  };

  const barChartOptions = {
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem) => {
            let value = tooltipItem.raw;
            return value + ' Tons';
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    legend: {
      display: true,
      labels: {
        fontColor: '#FFFFFF'
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          fontColor: '#FFFFFF',
          display: true,
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0
        }
      },
      y: {
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'Emissions (Tons)',
          fontColor: '#FFFFFF'
        },
        ticks: {
          fontColor: '#FFFFFF',
          callback: function(value) {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(0) + 'B'; // Show in Billions
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(0) + 'M'; // Show in Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(0) + 'K'; // Show in Thousands
            } else {
              return value; // Show as is
            }
          }
        },
        grid: {
          display: false
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest',
      intersect: true
    }
  };
  
  return (
    <div className="ghg-summary">
      {isLoading ? (
        <div className="alert alert-info">Loading data...</div>
      ) : showNoDataText ? (
        <div className="alert alert-info">No data for greenhouse summary for selected date!</div>
      ) : (
        <div className="ghg-summary-chart">
          <Bar data={{ labels: barChartLabels, datasets: barChartData }} options={barChartOptions} height={400} />
        </div>
      )}
    </div>
  );
};

GhgSummary.propTypes = {
  areaId: PropTypes.string.isRequired,
  facilityId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};

export default GhgSummary;
