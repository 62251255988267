import React, { useState, useEffect, useContext } from 'react';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import PollutantSummary from '../pollutant-summary/PollutantSummary';
import GhgSummary from '../ghg-summary/GhgSummary';
import GhgService from '../services/GhgService';
import { DateContext } from '../../../../../contexts/DateContext';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import GhgPollutantSunburst from '../ghg-pollutant/GhgPollutantSunburst';
import GhgSourceSunBurst from '../ghg-sources/GhgSourceSunBurst';
import { formatNumberDashboard } from '../../../../common/FormatNumber';

const GhgDashboard = () => {
  const { selectedDate } = useContext(DateContext);
  const [activePollutants, setActivePollutants] = useState([]);
  const [pollutantStats, setPollutantStats] = useState([]);
  const [ghgByReason, setGhgByReason] = useState([]);
  const [ghgSourceEmissions, setGhgSourceEmissions] = useState([]);
  const [ghgScopeEmissions, setGhgScopeEmissions] = useState([]);
  const [ghgPollutantEmissions, setGhgPollutantEmissions] = useState([]);
  const [ghgPollutantEmissionsV2, setGhgPollutantEmissionsV2] = useState([]);
  const [ghgSourceEmissionsV2, setGhgSourceEmissionsV2] = useState([]);
  const [totalCO2e, setTotalCO2e] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (selectedDate) {
      resetDashboardData();
      const datePart = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
      getDashboardData(datePart);
      setCurrentDate(selectedDate);
    }
  }, [selectedDate]);

  const getDashboardData = async (date) => {
    try {
      const [
        pollutantsResp,
        pollutantStatsResp,
        emissionsByReasonResp,
        scopeEmissionsResp,
        pollutantEmissionsResp,
        pollutantEmissionsV2Resp,
        ghgSourceEmissionsV2Resp
      ] = await Promise.all([
        GhgService.getActivePollutants(),
        GhgService.getPollutantStats('', '', date),
        GhgService.getEmissionsByReason('', '', date),
        GhgService.getScopeEmission(date),
        GhgService.getPollutantEmission(date),
        GhgService.getPollutantEmissionV2(date),
        GhgService.getSourceEmissionV2(date)
      ]);

      setActivePollutants(pollutantsResp.data);
      setPollutantStats(pollutantStatsResp.data);
      setGhgByReason(emissionsByReasonResp.data);
      setGhgScopeEmissions(scopeEmissionsResp.data);
      setGhgPollutantEmissions(pollutantEmissionsResp.data);
      setGhgPollutantEmissionsV2(pollutantEmissionsV2Resp.data);
      setGhgSourceEmissionsV2(ghgSourceEmissionsV2Resp.data);

      if (pollutantEmissionsResp.data.length > 0) {
        setTotalCO2e(pollutantEmissionsResp.data[0].totalCO2e);
      }
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    }
  };

  const resetDashboardData = () => {
    setActivePollutants([]);
    setPollutantStats([]);
    setGhgByReason([]);
    setGhgSourceEmissions([]);
    setGhgScopeEmissions([]);
    setGhgPollutantEmissions([]);
    setGhgPollutantEmissionsV2([]);
    setGhgSourceEmissionsV2([]);
    setTotalCO2e(0);
  };

  // Scope chart data definition
  const scopeChartData = {
    labels: ghgScopeEmissions.map(t => `Scope - ${t.scope}`),
    datasets: [{
      label: 'Scope',
      backgroundColor: ['#0074D9', '#FF4136', '#2ECC40', '#FF851B'],
      data: ghgScopeEmissions.map(t => Math.floor(t.adjEmission / 1000))
    }]
  };

  // Common chart options
  const commonChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
        boxWidth: 5
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value + ' tons';
        }
      }
    }
  };

  // Updated chart data and options for Bar chart
  const pollutantBarChartData = {
    labels: ghgPollutantEmissions.map(t => `${t.pollutant}`),
    datasets: ghgPollutantEmissions.map((t, index) => ({
      label: `${t.pollutant}`, // This makes sure each pollutant has a legend
      backgroundColor: ['#0074D9', '#FF4136', '#2ECC40', '#FF851B'][index % 4], // Cycle through colors
      data: [Math.floor(t.emission / 1000)] // Each dataset contains only one data point
    }))
  };
  

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y', // This option makes the bar chart horizontal
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(0) + 'B'; // Show in Billions
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(0) + 'M'; // Show in Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(0) + 'K'; // Show in Thousands
            } else {
              return value; // Show as is
            }
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true, // Ensure the legend is displayed
        position: 'top', // You can change this to 'bottom', 'left', or 'right'
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw}`
        }
      }
    }
  };
  
  
  
  return (
    <div className="ghg-dashboard">
      {pollutantStats && pollutantStats.length > 0 && (
        <div className="row">
          <div className="col-sm-12">
            <PollutantSummary pollutantStats={pollutantStats} />
          </div>
        </div>
      )}
      <div className="row mt-3">
        <div className="col-sm-12">
          <GhgSummary areaId={''} facilityId={''} date={selectedDate} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-4">
          {ghgPollutantEmissionsV2.length > 0 && (
            <div className="card">
              <div className="card-header">
                <span className="lnr lnr-chart-bars mr-1"></span>
                <span> GHG Methods</span>
              </div>
              <div className="card-body">
                <GhgPollutantSunburst data={ghgPollutantEmissionsV2} />
              </div>
            </div>
          )}
        </div>
        <div className="col-sm-4">
          {ghgSourceEmissionsV2.length > 0 && (
            <div className="card">
              <div className="card-header">
                <span className="lnr lnr-pie-chart mr-1"></span>
                <span>GHG Sources</span>
              </div>
              <div className="card-body">
                <GhgSourceSunBurst data={ghgSourceEmissionsV2} />
                {/* <Doughnut data={scopeChartData} options={commonChartOptions} /> */}
              </div>
            </div>
          )}
        </div>
        <div className="col-sm-4">
          {ghgScopeEmissions.length > 0 && (
            <div className="card">
              <div className="card-header">
                <span className="lnr lnr-pie-chart mr-1"></span>
                <span>GHG Emissions 
                  {/* <span className="float-right">{formatNumberDashboard(Math.floor(totalCO2e))}</span> */}
                  </span>
              </div>
              <div className="card-body d-flex align-items-center">
                <Bar data={pollutantBarChartData} options={barChartOptions} height={300} /> {/* Updated to Bar chart */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GhgDashboard;
