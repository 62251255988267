import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API_BASE_URL } from '../../../../../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CodeMasterType from '../../../../constants/CodeMasterType';

const AddFacilityEquity = ({ facilityEquityId, closeModal, showModal }) => {
    const [facilityEquity, setFacilityEquity] = useState({
        facilityId: '',
        companyId: '',
        equity: '',
        status: 'A'
    });
    const [isEdit, setIsEdit] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        if (facilityEquityId) {
            fetchFacilityEquityData(facilityEquityId);
            setIsEdit(true);
        }
        fetchInitialData();
    }, [facilityEquityId]);

    const fetchFacilityEquityData = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/ghg-master/facility-equity/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilityEquity({
                facilityId: data.facilityId,
                companyId: data.companyId,
                equity: data.equity,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch facility equity data:', error);
        }
    };

    const fetchInitialData = async () => {
        try {
            const [facilitiesResponse, companiesResponse] = await Promise.all([
                fetch(`${API_BASE_URL}/home/facilities`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                      },
                    credentials: 'include'
                }),
                fetch(`${API_BASE_URL}/device/codemasters`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                      },
                    credentials: 'include'
                })
            ]);

            if (!facilitiesResponse.ok || !companiesResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const facilitiesData = await facilitiesResponse.json();
            const companiesData = await companiesResponse.json();
            setFacilities(facilitiesData);
            setCompanies(companiesData.filter(x => x.type === CodeMasterType.Company));
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFacilityEquity(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Include the ID for update
        const dataToSend = isEdit ? { ...facilityEquity, id: facilityEquityId } : facilityEquity;

        const apiEndpoint = isEdit ? `${API_BASE_URL}/ghg-master/facility-equity/update` : `${API_BASE_URL}/ghg-master/facility-equity/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(dataToSend)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            toast.success(`Facility Equity ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            closeModal();
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} facility equity:`, error);
            toast.error(`Failed to ${isEdit ? 'update' : 'add'} facility equity. ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Facility Equity' : 'Add Facility Equity'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="facilityId">
                        <Form.Label>Facility</Form.Label>
                        <Form.Control as="select" name="facilityId" value={facilityEquity.facilityId} onChange={handleChange} required>
                            <option value="">Select Facility</option>
                            {facilities.map(facility => (
                                <option key={facility.facilityId} value={facility.facilityId}>
                                    {facility.facilityName}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="companyId">
                        <Form.Label>Company</Form.Label>
                        <Form.Control as="select" name="companyId" value={facilityEquity.companyId} onChange={handleChange} required>
                            <option value="">Select Company</option>
                            {companies.map(company => (
                                <option key={company.code} value={company.code}>
                                    {company.description}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="equity">
                        <Form.Label>Equity</Form.Label>
                        <Form.Control type="number" name="equity" value={facilityEquity.equity} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" name="status" value={facilityEquity.status} onChange={handleChange} required>
                            <option value="">Select Status</option>
                            <option value="A">A</option>
                            <option value="D">D</option>
                        </Form.Control>
                    </Form.Group>
                   <div className='mt-2 float-right'>
                   <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="ml-2">
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                   </div>
                </Form>
            </Modal.Body>
            <ToastContainer />
        </Modal>
    );
};

export default AddFacilityEquity;
