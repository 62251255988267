import { API_BASE_URL } from '../../../../../config';

export const getAllCodeMasters = async () => {
  const response = await fetch(`${API_BASE_URL}/master/code-master`);
  if (!response.ok) {
    throw new Error('Failed to fetch code masters');
  }
  return await response.json();
};

export const getEquipmentClassificationByTypeId = async (typeId) => {
  const response = await fetch(`${API_BASE_URL}/equipment/equipment-classification/${typeId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch equipment classification');
  }
  return await response.json();
};

export const saveEquipmentClassification = async (isEdit, equipmentClassification) => {
  const url = `${API_BASE_URL}/equipment/equipment-classification/${isEdit ? `update` : 'save'}`;
  const method = isEdit ? 'PUT' : 'POST';

  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(equipmentClassification),
  });

  if (!response.ok) {
    throw new Error('Failed to save equipment classification');
  }

  return response;
};
