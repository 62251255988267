// src/constants.js

export const BUTTON_LABELS = {
    REPORT_BUTTON: "Generate Report",
    SUBMIT_BUTTON: "Submit",
    CANCEL_BUTTON: "Cancel",
    // Add more button labels here
  };
  
  export const MESSAGES = {
    SUCCESS_MESSAGE: "Operation was successful.",
    ERROR_MESSAGE: "An error occurred. Please try again.",
    // Add more messages here
  };
  
  // You can add more categories and constants as needed
  