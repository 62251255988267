import { API_BASE_URL } from '../../../../../../config';

const NotificationService = {
  getFacilities: async () => {
    const response = await fetch(`${API_BASE_URL}/home/facilities`);
    return response.json();
  },
  getSourceCategory: async () => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules-src-category/all/SOURCE`);
    return response.json();
  },
  getSourceColumn: async () => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules-src-data/all/SOURCE`);
    return response.json();
  },
  getDestinationCategory: async () => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules-src-category/all/Destination`);
    return response.json();
  },
  getDestinationColumn: async () => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules-src-data/all/Destination`);
    return response.json();
  },
  getDataCollectionMode: async () => {
    const response = await fetch(`${API_BASE_URL}/master/code-master/dataCollectionMode`);
    return response.json();
  },
  getCondition: async () => {
    const response = await fetch(`${API_BASE_URL}/master/code-master/AlertCondition`);
    return response.json();
  },
  getAllUoms: async () => {
    const response = await fetch(`${API_BASE_URL}/device/uoms`);
    return response.json();
  },
  getAllUserConfig: async () => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationuser/all`);
    return response.json();
  },
  saveUserConfig: async (userConfig) => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationuser-save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userConfig),
    });
    return response.json();
  },
  deleteUserConfig: async (id) => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationuser-delete/${id}`, {
      method: 'DELETE',
    });
    return response.json();
  },
  getAllSubscriberConfig: async () => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationsubscribers/all`);
    return response.json();
  },
  saveSubscriberConfig: async (userConfig) => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationsubscriber-save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userConfig),
    });
    return response.json();
  },
  deleteSubscriberConfig: async (id) => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationsubscriber-delete/${id}`, {
      method: 'DELETE',
    });
    return response.json();
  },
  getAllNotificationRules: async () => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules/all`);
    return response.json();
  },
  getNotificationRulesById: async (id) => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules/${id}`);
    return response.json();
  },
  saveNotificationRules: async (notificationRule, isEdit) => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules-${isEdit ? 'update' : 'save'}`, {
      method: isEdit ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(notificationRule),
    });
    return response.json();
  },
  deleteNotificationRule: async (id) => {
    const response = await fetch(`${API_BASE_URL}/configuration/data/notificationrules-delete/${id}`, {
      method: 'DELETE',
    });
    return response.json();
  },
};

export default NotificationService;
