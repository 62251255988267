import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddSubscribeConfiguration from './AddSubscribeConfiguration'; // Adjust the path as necessary
import ExportComponent from '../../../../../common/ExportComponent';

export default function SubscriberConfigurationList() {
  const [subscriberConfigurationData, setSubscriberConfigurationData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [exportFilteredData, setExportFilteredData] = useState([]);
  const navigate = useNavigate();

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    fetchSubscriberConfigurationData();
  }, []);

  const fetchSubscriberConfigurationData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/configuration/data/notificationsubscribers/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setSubscriberConfigurationData(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Failed to fetch subscriber configuration data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/configurations/notification/add-subscriber-configuration/${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete subscriber configuration with ID: ${id}?`);
    if (confirmDelete) {
      try {
        const response = await fetch(`${API_BASE_URL}/configuration/data/notificationsubscriber-delete/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        toast.success('Successfully removed!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        fetchSubscriberConfigurationData();
      } catch (error) {
        console.error('Failed to delete subscriber configuration:', error);
      }
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = subscriberConfigurationData.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Subscriber ID': item.subscriberID,
      'Notification ID': item.notificationID,
      'Status': item.status
    }));
    setExportFilteredData(exportData);

  }, [search, subscriberConfigurationData]);

  const columns = [
    { name: 'Subscriber ID', selector: row => row.subscriberID, sortable: true, resizable: true, pinned: 'left' },
    { name: 'Notification ID', selector: row => row.notificationID, sortable: true, resizable: true },
    { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
    // {
    //   name: 'Edit',
    //   cell: row => (
    //     <FontAwesomeIcon
    //       icon={faPen}
    //       onClick={() => handleEdit(row.id)}
    //       style={{ cursor: 'pointer' }}
    //     />
    //   )
    // },
    {
      name: 'Delete',
      width: '75px',
      cell: row => (
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => handleDelete(row.id)}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      )
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Subscriber_Configration_${new Date().toLocaleDateString()}`;

  const title = "Subscriber Configuration Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Subscriber Configuration</h1>
        <ul>
          <li>System Configuration</li>
          <li>Notification</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">Subscriber Configuration List</h4> */}
              <div className='row pull-right'>
                <div className='col-md-10'>
                  <Link to="/admin/configurations/notification/add-subscriber-configuration" className="btn btn-primary">
                    Add New Record
                  </Link>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-10'>
                  {filteredData.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>

                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of col */}
      </div>
      <ToastContainer />
    </div>
  );
}
