import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../../../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPlant = ({ plantId, closeModal }) => {
    const [plant, setPlant] = useState({
        plantId: '',
        plantName: '',
        facilityId: '',
        btuscf: '',
        status: 'A'
    });
    const [facilities, setFacilities] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (plantId) {
            fetchPlantData(plantId);
            setIsEdit(true);
        }
        fetchFacilities();
    }, [plantId]);

    const fetchPlantData = async (key) => {
        try {
            const response = await fetch(`${API_BASE_URL}/plant/${key}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPlant({
                plantId: data.plantId,
                plantName: data.plantName,
                facilityId: data.facilityId,
                btuscf: data.btuscf,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch plant data:', error);
        }
    };

    const fetchFacilities = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilities(data);
        } catch (error) {
            console.error('Failed to fetch facilities:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlant(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEdit ? `${API_BASE_URL}/plant/update` : `${API_BASE_URL}/plant/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(plant)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`Plant ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            closeModal();
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} plant:`, error);
            toast.error(`Failed to ${isEdit ? 'update' : 'add'} plant.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Plant ID</label>
                        <input
                            type="text"
                            name="plantId"
                            className="form-control"
                            value={plant.plantId}
                            onChange={handleChange}
                            disabled={isEdit}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Plant Name</label>
                        <input
                            type="text"
                            name="plantName"
                            className="form-control"
                            value={plant.plantName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Plant Facility</label>
                        <select
                            name="facilityId"
                            className="form-control"
                            value={plant.facilityId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Plant Facility</option>
                            {facilities.map(facility => (
                                <option key={facility.facilityId} value={facility.facilityId}>
                                    {facility.facilityName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>BTU SCF</label>
                        <input
                            type="number"
                            name="btuscf"
                            className="form-control"
                            value={plant.btuscf}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Select Status</label>
                        <select
                            name="status"
                            className="form-control"
                            value={plant.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="A">A</option>
                            <option value="D">D</option>
                        </select>
                    </div>
                    <button type="button" className="btn btn-secondary float-left" onClick={closeModal}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary float-right">
                        {isEdit ? 'Update' : 'Save'}
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddPlant;
