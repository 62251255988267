import React from 'react'
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import FacilitySelector from '../../common/FacilitySelector';
import 'react-datepicker/dist/react-datepicker.css';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../common/FormatNumber';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { format } from 'date-fns';
import formatDateTime from '../../common/formatDateTime';
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
export default function FlaringAudit() {

  const [facilityData, setFacilityData] = useState([]);
  const [flaringAuditData, setFlaringAuditData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [startDatePickerClass, setStartDatePickerClass] = useState('');
  const [endDatePickerClass, setEndDatePickerClass] = useState('');
  const [exportFilteredData, setExportFilteredData] = useState([]);

  
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const formatDate = (date) => {
    const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date components without leading zeros
    return `${month}-${day}-${year}`;
  };


  const handlePlanVsActualButtonClick = () => {
    if (startDate && endDate && selectedFacilityId !== '') {
      setIsLoading(true);
      const apiUrl = `${API_BASE_URL}/reports/audit-adjusted-flaring?fromDate=${formatDate(startDate)}&toDate=${formatDate(endDate)}&facility=${selectedFacilityId}`;

      // Make API call using fetch or any other HTTP client library
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // Process the data returned by the API
          setFlaringAuditData(data.content);
          setFilteredData(data.content);
          setIsLoading(false);
        })
        .catch(error => {
          // Handle errors
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    } else {
      // Handle invalid selection or missing data
      console.error('Please select valid options.');
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    let filtered = flaringAuditData.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
  
    // If filtered data is empty, add a placeholder row to ensure headers are displayed
    if (filtered.length === 0) {
      filtered = [{
        txDateTime: '',
        deviceId: '',
        facilityId: '',
        materialState: '',
        flaring: '',
        flaringOil: '',
        adjustedFlaring: '',
        adjustedFlaringOil: '',
        engineerId: '',
        userComments: '',
        commentDate: ''
      }];
    }
  
    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Tx Date & Time': item.txDateTime,
      'Source ID': item.deviceId,
      'Facility ID': item.facilityId,
      'Material State': item.materialState,
      'Flaring': formatWithIndianThousandsSeparatorForExportData(item.flaring ? item.flaring.toFixed(0) : item.flaring),
      'Flaring Oil': formatWithIndianThousandsSeparatorForExportData(item.flaringOil ? item.flaringOil.toFixed(0) : item.flaringOil),
      'Adj Flaring': formatWithIndianThousandsSeparatorForExportData(item.adjustedFlaring ? item.adjustedFlaring.toFixed(0) : item.adjustedFlaring),
      'Adj Flaring Oil': formatWithIndianThousandsSeparatorForExportData(item.adjustedFlaringOil ? item.adjustedFlaringOil.toFixed(0) : item.adjustedFlaringOil),
      'Engineer ID': item.engineerId,
      'User Comments': item.userComments,
      'Comment Date': item.commentDate ? formatDateTime(item.commentDate) : ''
    }));

    setExportFilteredData(exportData);

  }, [search, flaringAuditData]);
  



  const columns = [
    {
      name: 'Tx Date & Time',
      selector: row => row.txDateTime,
      sortable: true
    },
    {
      name: 'Source ID',
      selector: row => row.deviceId,
      sortable: true
    },
    {
      name: 'Facility ID',
      selector: row => row.facilityId,
      sortable: true
    },
    {
      name: 'Material State',
      selector: row => row.materialState,
      sortable: true
    },
    {
      name: 'Flaring',
      selector: row => row.flaring,
      cell: row => formatWithIndianThousandsSeparator(row.flaring ? row.flaring.toFixed(0) : row.flaring),
      sortable: true
    },
    {
      name: 'Flaring Oil',
      selector: row => formatWithIndianThousandsSeparator(row.flaringOil ? row.flaringOil.toFixed(0) : row.flaringOil),
      sortable: true
    },
    {
      name: 'Adj Flaring',
      selector: row => formatWithIndianThousandsSeparator(row.adjustedFlaring ? row.adjustedFlaring.toFixed(0) : row.adjustedFlaring),
      sortable: true
    },
    {
      name: 'Adj Flaring Oil',
      selector: row => formatWithIndianThousandsSeparator(row.adjustedFlaringOil ? row.adjustedFlaringOil.toFixed(0) : row.adjustedFlaringOil),
      sortable: true
    },
    {
      name: 'Engineer ID',
      selector: row => row.engineerId,
      sortable: true
    },
    {
      name: 'User Comments',
      selector: row => row.userComments,
      sortable: true
    },
    {
      name: 'Comment Date',
      selector: row => row.commentDate,
      cell : row => row.commentDate ? formatDateTime(row.commentDate) : '',
      sortable: true
    },


  ];

  // const [selectionRange, setSelectionRange] = useState({
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection',
  //   });

  //   const handleSelect = (ranges) => {
  //     setSelectionRange(ranges.selection);
  //   };

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `FMS_Flaring_Audit${new Date().toLocaleDateString()}`;

  const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
  const handleStartDateBlur = () => setStartDatePickerClass('');
  const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
  const handleEndDateBlur = () => setEndDatePickerClass('');

  const headerData = [
    { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
    { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
    { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

  const title = "Flaring Audit Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Flaring Audit</h1>
        <ul>
          <li>Reports</li>
          <li>Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className='row'>
          <FacilitySelector
            selectedFacilityId={selectedFacilityId}
            onChange={setSelectedFacilityId}
            facilityData={facilityData} 
            setFacilityData={setFacilityData}
          />
          <div className='col-md-2'>
            <label>Start Date</label>
            <div className={startDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleStartDateFocus}
                onBlur={handleStartDateBlur}
              />
            </div>
          </div>
          <div className='col-md-2'>
            <label>End Date</label>
            <div className={endDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleEndDateFocus}
                onBlur={handleEndDateBlur}
              />
            </div>
          </div>

          {/* <div className='col-md-3'>
        <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
        </div> */}
          <div className='col-md-3 generate_report_button'>
            <button className='btn btn-primary' onClick={handlePlanVsActualButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
          </div>

        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3"> Flaring Audit </h4> */}
              <div className='row'>
                <div className='col-md-10'>
                  {filteredData.length > 0 && (
                    <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title}/>
                  )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="table-responsive">

                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>

                )}

              </div>
            </div>
          </div>
        </div>
        {/* end of col*/}
      </div>
    </div>

  )
}
