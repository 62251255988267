import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import AddShutdownForm from './AddShutdownForm';
import ExportComponent from '../../../common/ExportComponent';
import formatDate from '../../../common/formatDate ';

export default function ShutdownScheduleList() {
  const [shutdownScheduleItems, setShutdownScheduleItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [exportFilteredData, setExportFilteredData] = useState([]);

  useEffect(() => {
    fetchShutdownScheduleData();
  }, []);

  const fetchShutdownScheduleData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/dashboard/shutdownSchdule/shutdown-daily-tx-date`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setShutdownScheduleItems(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Failed to fetch shutdown schedule data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (shutdownId) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete schedule: ${shutdownId}?`);
    if (confirmDelete) {
      try {
        const response = await fetch(`${API_BASE_URL}/dashboard/shutdownSchdule/delete?Id=${shutdownId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        toast.success('Successfully removed!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        fetchShutdownScheduleData();
      } catch (error) {
        console.error('Failed to delete shutdown schedule:', error);
      }
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = shutdownScheduleItems.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Facility ID': item.facilityId,
      'Plant ID': item.plantId,
      'Reason ID': item.reasonId,
      'Comments': item.comments,
      'Source ID': item.deviceId,
      'From Date': formatDate(item.dateFrom),
      'To Date': formatDate(item.dateTo),
  }));
  setExportFilteredData(exportData)

  }, [search, shutdownScheduleItems]);

  const columns = [
    { name: 'Facility ID', selector: row => row.facilityId, sortable: true, resizable: true },
    { name: 'Plant ID', selector: row => row.plantId, sortable: true, resizable: true },
    { name: 'Reason ID', selector: row => row.reasonId, sortable: true, resizable: true },
    { name: 'Comments', selector: row => row.comments, sortable: true, resizable: true },
    { name: 'Source ID', selector: row => row.deviceId, sortable: true, resizable: true },
    { name: 'From Date', selector: row => new Date(row.dateFrom).toLocaleString(), sortable: true, resizable: true,
      cell: row => formatDate(row.dateFrom)
     },
    { name: 'To Date', selector: row => new Date(row.dateTo).toLocaleString(), sortable: true, resizable: true,
      cell: row => formatDate(row.dateTo)
     },
    // {
    //   name: 'Edit',
    //   cell: row => (
    //     <FontAwesomeIcon
    //       icon={faPen}
    //       // onClick={() => handleEdit(row.deviceId)} // Implement this if needed
    //       style={{ cursor: 'pointer' }}
    //     />
    //   )
    // },
    {
      name: 'Delete',
      width: '75px',
      cell: row => (
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => handleDelete(row.id)}
          style={{ cursor: 'pointer', color: 'red' }}
        />
      )
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Shutdown_Schedule_${new Date().toLocaleDateString()}`;

  const title = "Shutdown Schedule Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Shutdown Schedule</h1>
        <ul>
          <li>Operational Data</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">Shutdown Schedule</h4> */}
              <div className='row pull-right'>
                <div className='col-md-10'>
                  <Button onClick={() => setShowModal(true)} className="btn btn-primary">
                    Add New Record
                  </Button>
                </div>
              </div>
              <div className='row mt-3'>
                      <div className='col-md-10'>
                        {filteredData.length > 0 && (
                          <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                        )}
                      </div>
                      <div className='col-md-2'>
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control mb-3"
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
              <div className="table-responsive">
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>
                    
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Shutdown Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddShutdownForm
            setShowModal={setShowModal}
            fetchShutdownScheduleData={fetchShutdownScheduleData}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
