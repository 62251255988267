import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, CategoryScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FacilitySelector from '../../common/FacilitySelector';
import PlantSelector from '../../common/PlantSelector';
import { API_BASE_URL } from '../../../config';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
// Register the components
Chart.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, CategoryScale);

export default function PlantwiseFlaring() {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [selectedPlantId, setSelectedPlantId] = useState(-1);
  const [materialState, setMaterialState] = useState("GAS");
  const [search, setSearch] = useState('');

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleMaterialStateChange = (e) => {
    setMaterialState(e.target.value);
  };

  const handlePlantWiseFlaringButtonClick = () => {
    if (startDate && endDate) {
      const apiUrl = `${API_BASE_URL}/reports/daily-plantwise-flaring?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&plantId=${selectedPlantId}&materialState=${materialState}`;
  
      setLoading(true);
      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data && data.content) {
            const groupedData = data.content.reduce((acc, item) => {
              const date = new Date(item.day).toLocaleDateString();
              if (!acc[date]) acc[date] = {};
              acc[date][item.plantId] = item.gas;
              return acc;
            }, {});
  
            const labels = Object.keys(groupedData);
            const plantIds = [...new Set(data.content.map(item => item.plantId))];
  
            const datasets = plantIds.map(plantId => {
              return {
                label: plantId,
                data: labels.map(date => groupedData[date][plantId] || 0),
                backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`,
                borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
                borderWidth: 1,
                fill: true,
                pointRadius: 5,
                pointHoverRadius: 7,
              };
            });
  
            // Calculate total values for each date
            const totalData = labels.map(date => {
              return plantIds.reduce((total, plantId) => {
                return total + (groupedData[date][plantId] || 0);
              }, 0);
            });
  
            // Add the total dataset
            datasets.push({
              label: 'Total',
              data: totalData,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 1,
              fill: true,
              pointRadius: 5,
              pointHoverRadius: 7,
            });
  
            setChartData({
              labels: labels,
              datasets: datasets,
            });
          } else {
            setError('Data format is incorrect');
          }
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
          setError('Error fetching data');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('Please select valid options.');
    }
  };

  const options = {
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Gas Flaring (in units)'
        },
        stacked: true,
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: '#000',
        bodyColor: '#000',
        borderColor: '#000',
        borderWidth: 1,
      },
      legend: {
        display: true,
        position: 'top',
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };



  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Plant Flaring</h1>
        <ul>
          <li>Reports</li>
          <li>Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className='row'>
          <FacilitySelector
            selectedFacilityId={selectedFacilityId}
            onChange={setSelectedFacilityId}
          />

          <PlantSelector
            selectedFacilityId={selectedFacilityId}
            selectedPlantId={selectedPlantId}
            onChange={setSelectedPlantId}
          />

          <div className='col-md-2'>
            <label>Select Material State</label>
            <select
              name='materialState'
              className='form-control'
              value={materialState}
              onChange={handleMaterialStateChange}
            >
              <option value={'GAS'}>Gas</option>
              <option value={'LIQUID'}>Liquid</option>
            </select>
          </div>
          <div className='col-md-2'>
            <label>Start Date</label>
            <DatePicker
              className='form-control'
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className='col-md-2'>
            <label>End Date</label>
            <DatePicker
              className='form-control'
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className='col-md-2 generate_report_button'>
            <button className='btn btn btn-primary' onClick={handlePlantWiseFlaringButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
          </div>
        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">Plant Flaring</h4> */}
              <div className='row'>
                <div className='col-md-10'></div>
                <div className='col-md-2'>
                  {/* <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  /> */}
                </div>
              </div>
              {loading && <div>Loading...</div>}
              {error && <div>{error}</div>}
              {!loading && !error && Object.keys(chartData).length > 0 && (
                <div>
                  {/* <h2>Plant Flaring</h2> */}
                  <div style={{ height: '450px' }}>
                    <Line data={chartData} options={options} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end of col*/}
      </div>
    </div>
  );
}
