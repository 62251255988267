import axios from 'axios';
import { API_BASE_URL } from '../../../../config';

axios.defaults.withCredentials = true;

const getFacilities = async () => {
  const response = await axios.get(`${API_BASE_URL}/home/facilities`);
  return response.data;
};

const getCategory = async () => {
  const response = await axios.get(`${API_BASE_URL}/projectcategory/all`);
  return response.data;
};

const getType = async () => {
  const response = await axios.get(`${API_BASE_URL}/project/project-types`);
  return response.data;
};

const getStatus = async () => {
  const response = await axios.get(`${API_BASE_URL}/project/project-statuses`);
  return response.data;
};

const getPollutant = async () => {
  const response = await axios.get(`${API_BASE_URL}/master/pollutants`);
  return response.data;
};

const getComType = async () => {
  const response = await axios.get(`${API_BASE_URL}/master/comptyes`);
  return response.data;
};

const getAllUoms = async () => {
  const response = await axios.get(`${API_BASE_URL}/device/uoms`);
  return response.data;
};

const getComponent = async () => {
  const response = await axios.get(`${API_BASE_URL}/composition/physical-properties/components-all`);
  return response.data;
};

// Project Master
const getProjectMaster = async () => {
  const response = await axios.get(`${API_BASE_URL}/project/project-master`);
  return response.data;
};

const getProjectMasterById = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/project/project-master/${id}`);
  return response.data;
};

const saveProjectMaster = async (projectMaster, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/project/project-update` : `${API_BASE_URL}/project/project-save`;
  const response = await axios.post(url, projectMaster);
  return response.data;
};

const deleteProjectMaster = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/project/project-delete/${id}`);
  return response.data;
};

const getActiveProject = async () => {
  const response = await axios.get(`${API_BASE_URL}/project/project-active-master`);
  return response.data;
};

// Project Transaction
const getProjectTxById = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/project/project-tx/${id}`);
  return response.data;
};

const getProjectTx = async () => {
  const response = await axios.get(`${API_BASE_URL}/project/project-tx`);
  return response.data;
};

const saveProjectTx = async (projectTx, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/project/project-tx-update` : `${API_BASE_URL}/project/project-tx-save`;
  const response = await axios.post(url, projectTx);
  return response.data;
};

const deleteProjectTx = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/project/project-tx/${id}`);
  return response.data;
};

// Project Category
const getProjectCategory = async () => {
  const response = await axios.get(`${API_BASE_URL}/projectcategory/all`);
  return response.data;
};

const deleteProjectCategory = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/projectcategory/project-category/${id}`);
  return response.data;
};

const getProjectCategoryById = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/projectcategory/project-category/${id}`);
  return response.data;
};

const saveProjectCategory = async (projectCategory, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/projectcategory/project-category-update` : `${API_BASE_URL}/projectcategory/project-category-save`;
  const response = await axios.put(url, projectCategory);
  return response.data;
};

// Project Type
const getProjectTypeById = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/project/project-types/${id}`);
  return response.data;
};

const getProjectType = async () => {
  const response = await axios.get(`${API_BASE_URL}/project/project-types`);
  return response.data;
};

const saveProjectType = async (projectType, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/project/update-project-type` : `${API_BASE_URL}/project/save-project-type`;
  const response = await axios.put(url, projectType);
  return response.data;
};

const deleteProjectType = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/project/delete-project-type/${id}`);
  return response.data;
};

// Project Status
const getProjectStatusById = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/project/project-status/${id}`);
  return response.data;
};

const getProjectStatus = async () => {
  const response = await axios.get(`${API_BASE_URL}/project/project-statuses`);
  return response.data;
};

const saveProjectStatus = async (projectStatus, isEdit) => {
  const url = isEdit ? `${API_BASE_URL}/project/update-project-status` : `${API_BASE_URL}/project/save-project-status`;
  const response = await axios.put(url, projectStatus);
  return response.data;
};

const deleteProjectStatus = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/project/delete-project-status/${id}`);
  return response.data;
};

export {
  getFacilities,
  getCategory,
  getType,
  getStatus,
  getPollutant,
  getComType,
  getAllUoms,
  getComponent,
  getProjectMaster,
  getProjectMasterById,
  saveProjectMaster,
  deleteProjectMaster,
  getActiveProject,
  getProjectTxById,
  getProjectTx,
  saveProjectTx,
  deleteProjectTx,
  getProjectCategory,
  deleteProjectCategory,
  getProjectCategoryById,
  saveProjectCategory,
  getProjectTypeById,
  getProjectType,
  saveProjectType,
  deleteProjectType,
  getProjectStatusById,
  getProjectStatus,
  saveProjectStatus,
  deleteProjectStatus,
};
