import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TagsMasterService } from './services/TagsMasterService';

const AddTagsMasterVOne = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formGroup, setFormGroup] = useState({
    deviceId: '',
    tagName: '',
    description: '',
    deviceType: '',
    readingType: '',
    limsComposition: '',
    limsProperty: '',
    manualY: '',
    min: '',
    max: '',
    defaultValue: '',
    uom: '',
    valveOpenState: '',
    scheduleHours: '',
    status: 'A'
  });
  const [isEdit, setIsEdit] = useState(false);
  const [lstDevice, setLstDevice] = useState([]);
  const [lstHeader, setLstHeader] = useState([]);
  const [lstDeviceType, setLstDeviceType] = useState([]);
  const [lstReadingType, setLstReadingType] = useState([]);
  const [lstFilteredReadingType, setLstFilteredReadingType] = useState([]);
  const [lstComposition, setLstComposition] = useState([]);
  const [lstLiquidComposition, setLstLiquidComposition] = useState([]);
  const [lstUom, setLstUom] = useState([]);
  const [lstFacilityFeeds, setLstFacilityFeeds] = useState([]);
  const [lstAllCodeMasters, setLstAllCodeMasters] = useState([]);
  const [dataCollectionMode, setDataCollectionMode] = useState('');
  const [statusOptions] = useState(['A', 'D']);
  const [lstManualY] = useState(['Y', 'N']);
  const [displayHelpMessage, setDisplayHelpMessage] = useState(false);
  const [helpMessage, setHelpMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
    buildAddTagsMasterForm();
    loadInitialData(id);
  }, [id]);

  const buildAddTagsMasterForm = () => {
    setFormGroup({
      deviceId: '',
      tagName: '',
      description: '',
      deviceType: '',
      readingType: '',
      limsComposition: '',
      limsProperty: '',
      manualY: '',
      min: '',
      max: '',
      defaultValue: '',
      uom: '',
      valveOpenState: '',
      scheduleHours: '',
      status: 'A'
    });
  };

  const loadInitialData = async (id) => {
    try {
      const [
        codeMasters,
        headers,
        devices,
        compositions,
        liquidCompositions,
        readingTypes,
        uoms,
        facilityFeeds
      ] = await Promise.all([
        TagsMasterService.getAllCodeMaster(),
        TagsMasterService.getAllHeaders(),
        TagsMasterService.getAllDevices(),
        TagsMasterService.getAllCompositions(),
        TagsMasterService.getAllLiquidCompositions(),
        TagsMasterService.getReadingTypesForDataCollectionMode(),
        TagsMasterService.getAllUOMs(),
        TagsMasterService.getAllFacilityFeeds()
      ]);

      setLstHeader(headers);
      setLstDevice(devices);
      setLstComposition(compositions);
      setLstLiquidComposition(liquidCompositions);
      setLstReadingType(readingTypes);
      setLstUom(uoms);
      setLstFacilityFeeds(facilityFeeds);
      setLstAllCodeMasters(codeMasters);

      setLstDeviceType(TagsMasterService.filterDeviceTypesFromCodeMaster(codeMasters));

      if (id) {
        loadTagsMasterToUpdate(id);
      }
    } catch (error) {
      console.error('Failed to load initial data:', error);
    }
  };

  const loadTagsMasterToUpdate = async (id) => {
    try {
      const tagsMaster = await TagsMasterService.getTagsMasterById(id);
      setFormGroup(tagsMaster);
      applyTagType(tagsMaster.deviceId);

      if (tagsMaster.deviceType === 'EMSDEVICE' || tagsMaster.deviceType === 'GHGDEVICE') {
        setLstFilteredReadingType(lstReadingType.filter(t => t.value === 'LIMS'));
        setLstComposition(lstComposition.filter(x => x.componentId.charAt(0) === 'F'));
        setLstLiquidComposition([]);
      }

      setDisplayHelpMessage(false);
      setHelpMessage('');
    } catch (error) {
      console.error('Failed to load tags master data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));

    if (name === 'deviceId') {
      applyTagType(value);
    } else if (name === 'readingType') {
      onReadingTypeChange(value);
    } else if (name === 'limsComposition') {
      onLimsCompositionChange(value);
    } else if (name === 'limsProperty') {
      onLimsPropertyChange(value);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.deviceId) newErrors.deviceId = 'Source ID is required.';
    if (!formGroup.tagName) newErrors.tagName = 'Tag Name is required.';
    if (!formGroup.readingType) newErrors.readingType = 'Reading Type is required.';
    if (!formGroup.status) newErrors.status = 'Status is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await TagsMasterService.saveTagsMaster(formGroup, isEdit);
      toast.success(`Tags Master ${isEdit ? 'updated' : 'added'} successfully.`);
      setTimeout(() => {
        navigate('/admin/configurations/master/list-tags-master');
      }, 1000);
    } catch (error) {
      console.error('Failed to save Tags Master:', error);
      toast.error('Error saving Tags Master');
    }
  };

  const applyTagType = (selectedId) => {
    const headerFound = lstHeader.find(x => x.headerId === selectedId);
    if (headerFound) {
      setDataCollectionMode('HEADER');
      setLstFilteredReadingType(lstReadingType.filter(t => t.key === 'HEADERID'));
    } else {
      const device = lstDevice.find(x => x.deviceId === selectedId);
      if (device) {
        setDataCollectionMode(device.dataCollectionMode);
        setLstFilteredReadingType(lstReadingType.filter(t => t.key === device.dataCollectionMode));
      } else {
        setDataCollectionMode('');
        setLstFilteredReadingType([]);
      }
    }
  };

  const onReadingTypeChange = (selectedReadingType) => {
    if (selectedReadingType === 'LIMS') {
      setFormGroup(prevState => ({
        ...prevState,
        limsProperty: '',
        limsComposition: ''
      }));
    }
  };

  const onLimsCompositionChange = (value) => {
    if (value) {
      setFormGroup(prevState => ({
        ...prevState,
        limsProperty: ''
      }));
    }
  };

  const onLimsPropertyChange = (value) => {
    if (value) {
      setFormGroup(prevState => ({
        ...prevState,
        limsComposition: ''
      }));
    }
  };

  return (
    <div>
      {/* <h4>{isEdit ? 'Edit Tag Master' : 'Add Tag Master'}</h4> */}
      <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit Tag Master' : 'Add Tag Master'}</h1>
                <ul>
                    <li>Master Data Management</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="deviceId">Source/Header ID</label>
                <select
                  className="form-control"
                  id="deviceId"
                  name="deviceId"
                  value={formGroup.deviceId}
                  onChange={handleChange}
                >
                  <option value="">Select Source/Header ID</option>
                  {lstDevice.map(device => (
                    <option key={device.deviceId} value={device.deviceId}>Source - {device.deviceId}</option>
                  ))}
                  {lstHeader.map(header => (
                    <option key={header.headerId} value={header.headerId}>Header - {header.headerId}</option>
                  ))}
                </select>
                {errors.deviceId && <div className="invalid-feedback d-block">{errors.deviceId}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="tagName">Tag Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="tagName"
                  name="tagName"
                  value={formGroup.tagName}
                  onChange={handleChange}
                />
                {errors.tagName && <div className="invalid-feedback d-block">{errors.tagName}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description"
                  value={formGroup.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="deviceType">Source Type</label>
                <select
                  className="form-control"
                  id="deviceType"
                  name="deviceType"
                  value={formGroup.deviceType}
                  onChange={handleChange}
                >
                  <option value="">Select Source Type</option>
                  {lstDeviceType.map(type => (
                    <option key={type.code} value={type.code}>{type.description}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="readingType">Reading Type</label>
                <select
                  className="form-control"
                  id="readingType"
                  name="readingType"
                  value={formGroup.readingType}
                  onChange={handleChange}
                >
                  <option value="">Select Reading Type</option>
                  {lstFilteredReadingType.map(type => (
                    <option key={type.value} value={type.value}>{type.value}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="limsComposition">LIMS Composition</label>
                <select
                  className="form-control"
                  id="limsComposition"
                  name="limsComposition"
                  value={formGroup.limsComposition}
                  onChange={handleChange}
                >
                  <option value="">Select LIMS Composition</option>
                  <optgroup label="Gas Compositions">
                    {lstComposition.map(composition => (
                      <option key={composition.componentId} value={composition.componentId}>
                        {composition.componentId} - {composition.iupacName}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Liquid Compositions">
                    {lstLiquidComposition.map(composition => (
                      <option key={composition.compositionId} value={composition.compositionId}>
                        {composition.compositionId} - {composition.fuel}
                      </option>
                    ))}
                  </optgroup>
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="limsProperty">LIMS Property</label>
                <select
                  className="form-control"
                  id="limsProperty"
                  name="limsProperty"
                  value={formGroup.limsProperty}
                  onChange={handleChange}
                >
                  <option value="">Select LIMS Property</option>
                  {lstAllCodeMasters.filter(cm => cm.type === 'LIMSproperty').map(limsProp => (
                    <option key={limsProp.code} value={limsProp.code}>{limsProp.description}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="manualY">Manual Y</label>
                <select
                  className="form-control"
                  id="manualY"
                  name="manualY"
                  value={formGroup.manualY}
                  onChange={handleChange}
                >
                  {lstManualY.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="min">Tag Minimum</label>
                <input
                  type="number"
                  className="form-control"
                  id="min"
                  name="min"
                  value={formGroup.min}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="max">Tag Maximum</label>
                <input
                  type="number"
                  className="form-control"
                  id="max"
                  name="max"
                  value={formGroup.max}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="defaultValue">Tag Default Value</label>
                <input
                  type="number"
                  className="form-control"
                  id="defaultValue"
                  name="defaultValue"
                  value={formGroup.defaultValue}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="uom">Select UoM</label>
                <select
                  className="form-control"
                  id="uom"
                  name="uom"
                  value={formGroup.uom}
                  onChange={handleChange}
                >
                  <option value="">Select UoM</option>
                  {lstUom.map(uom => (
                    <option key={uom.uomId} value={uom.uomId}>{uom.description}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="valveOpenState">Valve Open State (Digital Tags)</label>
                <input
                  type="text"
                  className="form-control"
                  id="valveOpenState"
                  name="valveOpenState"
                  value={formGroup.valveOpenState}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="scheduleHours">Schedule Hours</label>
                <input
                  type="number"
                  className="form-control"
                  id="scheduleHours"
                  name="scheduleHours"
                  value={formGroup.scheduleHours}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  value={formGroup.status}
                  onChange={handleChange}
                >
                  {statusOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
              </div>
            </div>
            {displayHelpMessage && (
              <div className="row">
                <div className="col-sm-12">
                  <p className="text-danger">{helpMessage}</p>
                </div>
              </div>
            )}
            <div className="form-group row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/master/list-tags-master')}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddTagsMasterVOne;
