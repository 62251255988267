import React, { useState, useEffect, useContext } from 'react';
import fmsDashboardService from '../services/fmsDashboardService';
import DailyFlaring from '../daily-flaring/DailyFlaring';
import FacilitiesFlaringSummary from '../facilities-flaring-summary/FacilitiesFlaringSummary';
import FlaringKpiPredictions from '../flaring-kpi-predictions/FlaringKpiPredictions';
import FlaringReasons from '../flaring-reasons/FlaringReasonsApexChart';
import FlaringReasonsAnychart from '../flaring-reasons/FlaringReasonsAnychart';
import { DateContext } from '../../../../../contexts/DateContext';
import './FmsDashboard.css';
import { formatNumberDashboard } from '../../../../common/FormatNumber';

const FmsDashboard = () => {
  const { selectedDate } = useContext(DateContext);
  const [dashboardStats, setDashboardStats] = useState(null);
  const appSession = { gasUnits: 'MMSCF' }; // Replace with actual app session data
  const [fmsDashboardFilter, setFmsDashboardFilter] = useState({
    type: 1,
    facilityId: 'REF01'
  });

  useEffect(() => {
    fetchDashboardStats();
  }, [selectedDate, fmsDashboardFilter]);

  const fetchDashboardStats = async () => {
    try {
      const response = await fmsDashboardService.getFMSDashboardStats(
        selectedDate,
        fmsDashboardFilter.type,
        fmsDashboardFilter.facilityId
      );
      setDashboardStats(response.data);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }
  };

  const realFlaring = dashboardStats?.actualFlaring.realFlaring ?? 0;
  const variance = dashboardStats?.actualFlaring.variance ?? 0;
  const actualKPI = dashboardStats?.kpi.actualKPI ?? 0;
  const kpiVariance = dashboardStats?.kpi.variance ?? 0;
  const ytodRealFlaring = dashboardStats?.ytod.realFlaring ?? 0;
  const ytodVariance = dashboardStats?.ytod.variance ?? 0;

  return (
    <div className="dashboard mt-1">
      <div className="row">
        <div className="col-sm-4">
          <div className="gradient-card card text-light bg-flaring-gradient">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <span className="font-weight-bold">{formatNumberDashboard(realFlaring.toFixed(0))}</span><br />
                  <span>Flaring</span>
                </div>
                <div className="col-sm-6 text-right">
                  <span className="font-weight-bold">{formatNumberDashboard(variance.toFixed(2))}</span><br />
                  <span>Variance(%)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="gradient-card card text-light bg-kpi-gradient">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <span className="font-weight-bold">{formatNumberDashboard(actualKPI.toFixed(0))}</span><br />
                  <span>KPI</span>
                </div>
                <div className="col-sm-6 text-right">
                  <span className="font-weight-bold">{formatNumberDashboard(kpiVariance.toFixed(2))}</span><br />
                  <span>Variance(%)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="gradient-card card text-light bg-ytd-gradient">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <span className="font-weight-bold">{formatNumberDashboard(ytodRealFlaring.toFixed(0))}</span><br />
                  <span>YTD</span>
                </div>
                <div className="col-sm-6 text-right">
                  <span className="font-weight-bold">{formatNumberDashboard(ytodVariance.toFixed(2))}</span><br />
                  <span>Variance(%)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8">
          <div className="row ">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <span className="lnr lnr-chart-bars mr-1"></span>Daily Flaring
                </div>
                <div className="card-body">
                  <DailyFlaring
                    selectedDate={selectedDate}
                    fmsDashboardFilter={fmsDashboardFilter}
                    appSession={appSession}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row ">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <span className="lnr lnr-chart-bars mr-1"></span>Flaring Reasons
                </div>
                <div className="card-body m-auto">
                  <FlaringReasonsAnychart
                    selectedDate={selectedDate}
                    fmsDashboardFilter={fmsDashboardFilter}
                    realFlaring={formatNumberDashboard(realFlaring.toFixed(0))}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-6">
          <div className="card">
            <div className="card-header">
              <span className="lnr lnr-chart-bars mr-1"></span>Facilities Flaring
            </div>
            <div className="card-body">
              <FacilitiesFlaringSummary
                selectedDate={selectedDate}
                fmsDashboardFilter={fmsDashboardFilter}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <div className="card-header">
              <span className="lnr lnr-chart-bars mr-1"></span>Flaring Predictions
            </div>
            <div className="card-body">
              <FlaringKpiPredictions
                selectedDate={selectedDate}
                fmsDashboardFilter={fmsDashboardFilter}
                appSession={appSession}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FmsDashboard;
