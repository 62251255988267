import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import ExportComponent from '../../../common/ExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../common/FormatNumber';
import formatDate from '../../../common/formatDate ';

export default function ListProjectMaster() {
    const [projectData, setProjectData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();
    const agGridInstance = useRef(null);

    useEffect(() => {
        fetchProjectData();
    }, []);

    const fetchProjectData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/project/project-master`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setProjectData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch project data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (projectID) => {
        navigate(`/admin/project/add-project-master/${projectID}`);
    };

    const handleDelete = async (projectID) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete project: ${projectID}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/project/project-delete/${projectID}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Project Master deleted successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchProjectData();
            } catch (error) {
                console.error('Failed to delete project:', error);
            }
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = projectData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);


        const exportData = filtered.map(item => ({
            'Project ID': item.projectID,
            'Project Name': item.projectName,
            'Start Date': formatDate(item.projectStartDate),
            'Facility ID': item.facilityID,
            'Type': item.type,
            'Pollutant': item.pollutant,
            'Comp Type': item.compType,
            'Component ID': item.componentID,
            'Reduction Expected': formatWithIndianThousandsSeparatorForExportData(item.reductionExpected ? item.reductionExpected.toFixed(0) : item.reductionExpected),
            'Units': item.units,
            'Project Category ID': item.projectCategoryID,
            'Project Type ID': item.projectTypeID,
            'Project Status ID': item.projectStatusID,
            'Govt. Funded (Y/N)': item.projectGovtFundedYN,
            'Cost (USD)': formatWithIndianThousandsSeparatorForExportData(item.projectCost ? item.projectCost.toFixed(0) : item.projectCost),
        }));
        setExportFilteredData(exportData);

    }, [search, projectData]);

    const columns = [
        { name: 'Project ID', selector: row => row.projectID, sortable: true, resizable: true, pinned: 'left' },
        {
            name: 'Project Name',
            selector: row => row.projectName,
            width: '250px',
            sortable: true,
            resizable: true
        },
        {
            name: 'Start Date', selector: row => row.projectStartDate, sortable: true, resizable: true,
            cell: row => formatDate(row.projectStartDate)
        },
        { name: 'Facility ID', selector: row => row.facilityID, sortable: true, resizable: true },
        { name: 'Type', selector: row => row.type, sortable: true, resizable: true },
        { name: 'Pollutant', selector: row => row.pollutant, sortable: true, resizable: true },
        { name: 'Comp Type', selector: row => row.compType, sortable: true, resizable: true },
        {
            name: 'Component ID',
            selector: row => row.componentID,
            width: '150px',
            sortable: true,
            resizable: true
        },
        {
            name: 'Reduction Expected',
            selector: row => row.reductionExpected,
            cell: row => formatWithIndianThousandsSeparator(row.reductionExpected ? row.reductionExpected.toFixed(0) : row.reductionExpected),
            sortable: true,
        },
        { name: 'Units', selector: row => row.units, sortable: true, resizable: true },
        { name: 'Project Category ID', selector: row => row.projectCategoryID, sortable: true, resizable: true },
        { name: 'Project Type ID', selector: row => row.projectTypeID, sortable: true, resizable: true },
        { name: 'Project Status ID', selector: row => row.projectStatusID, sortable: true, resizable: true },
        { name: 'Govt. Funded (Y/N)', selector: row => row.projectGovtFundedYN, sortable: true, resizable: true },
        {
            name: 'Cost (USD)',
            selector: row => row.projectCost,
            cell: row => formatWithIndianThousandsSeparator(row.projectCost ? row.projectCost.toFixed(0) : row.projectCost),
            sortable: true,
        },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.projectID)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.projectID)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Project_Master_${new Date().toLocaleDateString()}`;

    const title = "Project Master Report";

    return (
        <div className="main-content">
            <div className="breadcrumb mt-3">
                <h1>Project Master</h1>
                <ul>
                    <li>Projects</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>

            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/project/add-project-master" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
