import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import 'react-datepicker/dist/react-datepicker.css';
import { formatNumber, formatWithIndianThousandsSeparator , formatWithIndianThousandsSeparatorForExportData} from '../../common/FormatNumber';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { format } from 'date-fns';

export default function SourceEmissions() {
    const [facilityData, setFacilityData] = useState([]);
    const [plantData, setPlantData] = useState([]);
    const [compTypeData, setCompTypeData] = useState([]);
    const [pollutantData, setPollutantData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [sourceEmissionData, setSourceEmissionsData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
    const [selectedPlantId, setSelectedPlantId] = useState(-1);
    const [selectedCompType, setSelectedCompType] = useState('');
    const [selectedPollutant, setSelectedPollutant] = useState(-1);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredPlantData, setFilteredPlantData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    const [startDatePickerClass, setStartDatePickerClass] = useState('');
    const [endDatePickerClass, setEndDatePickerClass] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]);


    useEffect(() => {
        fetchFacilityData();
        fetchCompTypeData();
        fetchPollutantData();
        fetchCategoryData();
        fetchPlantData();
    }, []);

    useEffect(() => {
        if (selectedFacilityId !== -1) {
            const filteredPlants = plantData.filter(plant => plant.facilityId == selectedFacilityId);
            setFilteredPlantData(filteredPlants);
        } else {
            setPlantData([]);
            setSelectedPlantId(-1);
        }
    }, [selectedFacilityId]);

    const fetchFacilityData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilityData(data.filter(t => t.facilityCategory != "AMMNET"));
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        }
    };

    const fetchPlantData = async (facilityId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/plants?facilityId=${facilityId}`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPlantData(data);
        } catch (error) {
            console.error('Failed to fetch plant data:', error);
        }
    };

    const fetchCompTypeData = async () => {
        try {
            const data = ['PP', 'HAPS', 'HC', 'NONHC'];
            setCompTypeData(data);
        } catch (error) {
            console.error('Failed to fetch comp type data:', error);
        }
    };
    // const fetchCompTypeData = async () => {
    //     try {
    //         const response = await fetch(`${API_BASE_URL}/home/comptypes`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             credentials: 'include'
    //         });

    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         const data = await response.json();
    //         setCompTypeData(data);
    //     } catch (error) {
    //         console.error('Failed to fetch comp type data:', error);
    //     }
    // };

    const fetchPollutantData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/master/pollutants`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPollutantData(data);
        } catch (error) {
            console.error('Failed to fetch pollutant data:', error);
        }
    };

    const fetchCategoryData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/master/code-master/categoryID`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setCategoryData(data);
        } catch (error) {
            console.error('Failed to fetch category data:', error);
        }
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFacilityChange = (e) => {
        setSelectedFacilityId(e.target.value);
    };

    const handlePlantChange = (e) => {
        setSelectedPlantId(e.target.value);
    };

    const handleCompTypeChange = (e) => {
        setSelectedCompType(e.target.value);
    };

    const handlePollutantChange = (e) => {
        setSelectedPollutant(e.target.value);
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const formatDate = (date) => {
        const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1
        const day = date.getDate();
        const year = date.getFullYear();

        // Format the date components without leading zeros
        return `${month}-${day}-${year}`;
    };

    const handleSourceEmissionButtonClick = () => {
        if (startDate && endDate && selectedFacilityId !== '' && selectedPlantId !== '') {
            setIsLoading(true); // Set loading state to true before fetching data

            const apiUrl = `${API_BASE_URL}/reports/EMSSourceEmissions?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&plantId=${selectedPlantId}&pollutantId=${selectedPollutant}&categoryId=${selectedCategory}`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    setSourceEmissionsData(data);
                    setFilteredData(data);
                    setIsLoading(false); // Set loading state to false after data is fetched
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false); // Set loading state to false if there's an error
                });
        } else {
            console.error('Please select valid options.');
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        let filtered = sourceEmissionData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );

        // If filtered data is empty, add a placeholder row to ensure headers are displayed
        if (filtered.length === 0) {
            filtered = [{
                facilityId: '',
                deviceId: '',
                plantName: '',
                headerId: '',
                materialState: '',
                emissionType: '',
                pollutant: '',
                categoryId: '',
                emissions: '',
                uomId: ''
            }];
        }

        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Facility ID': item.facilityId,
            'Source': item.deviceId,
            'Plant': item.plantName,
            'Header ID': item.headerId,
            'Material State': item.materialState,
            'Emission Type': item.emissionType,
            'Pollutant': item.pollutant,
            'Category': item.categoryId,
            'Adj Emission': formatWithIndianThousandsSeparatorForExportData(item.emissions ? item.emissions.toFixed(0) : item.emissions),
            'UoM': item.uomId
          }));
          setExportFilteredData(exportData);

    }, [search, sourceEmissionData]);



    const columns = [
        {
            name: 'Facility ID',
            selector: row => row.facilityId,
            sortable: true
        },
        {
            name: 'Source',
            selector: row => row.deviceId,
            sortable: true
        },
        {
            name: 'Plant',
            selector: row => row.plantName,
            sortable: true
        },
        {
            name: 'Header ID',
            selector: row => row.headerId,
            sortable: true
        },
        {
            name: 'Material State',
            selector: row => row.materialState,
            sortable: true
        },
        {
            name: 'Emission Type',
            selector: row => row.emissionType,
            sortable: true
        },
        {
            name: 'Pollutant',
            selector: row => row.pollutant,
            sortable: true
        },
        {
            name: 'Category',
            selector: row => row.categoryId,
            sortable: true
        },
        {
            name: 'Adj Emission',
            selector: row => row.emissions,
            sortable: true,
            cell: row => formatWithIndianThousandsSeparator(row.emissions ? row.emissions.toFixed(0) : row.emissions)
        },
        {
            name: 'UoM',
            selector: row => row.uomId.toLowerCase(),
            sortable: true
        }


    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `EMS_Source_Emissions${new Date().toLocaleDateString()}`;

    const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
    const handleStartDateBlur = () => setStartDatePickerClass('');
    const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
    const handleEndDateBlur = () => setEndDatePickerClass('');

    const headerData = [
        { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
        { label: 'Plant Name', value: selectedPlantId != -1 && selectedFacilityId != -1 ? filteredPlantData.find(plant => plant.plantId == selectedPlantId)?.plantName : 'All Plants' },
        { label: 'Comp Type Name', value: selectedCompType || 'PP' },
        { label: 'Pollutant Name', value: selectedPollutant != -1 ? pollutantData.find(pollutant => pollutant.pollutantid == selectedPollutant)?.pollutantName : 'All Pollutants' },
        { label: 'Category Name', value: selectedCategory != -1 ? categoryData.find(category => category.code == selectedCategory)?.code : 'All Categories' },
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];
    
    const title = "Source Emission Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Source Emissions</h1>
                <ul>
                    <li>Reports</li>
                    <li>Flaring</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Select Facility</label>
                        <select
                            name='facility'
                            className='form-control'
                            value={selectedFacilityId}
                            onChange={handleFacilityChange}
                        >
                            <option value={-1}>All Facilities</option>
                            {facilityData.map((facility) => (
                                <option key={facility.facilityId} value={facility.facilityId}>
                                    {facility.facilityName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-1'>
                        <label>Select Plant</label>
                        <select
                            name='plant'
                            className='form-control'
                            value={selectedPlantId}
                            onChange={handlePlantChange}
                            disabled={selectedFacilityId === -1}
                        >
                            <option value={-1}>All Plants</option>
                            {filteredPlantData.map((plant) => (
                                <option key={plant.plantId} value={plant.plantId}>
                                    {plant.plantName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label>Select Comp Type</label>
                        <select
                            name='compType'
                            className='form-control'
                            value={selectedCompType}
                            onChange={handleCompTypeChange}
                        >
                            {compTypeData.map((compType) => (
                                <option value={compType}>
                                    {compType}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label>Select Pollutant</label>
                        <select
                            name='pollutant'
                            className='form-control'
                            value={selectedPollutant}
                            onChange={handlePollutantChange}
                        >
                            <option value={-1}>All Pollutants</option>
                            {pollutantData.map((pollutant) => (
                                <option value={pollutant.pollutantid}>
                                    {pollutant.pollutantName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-2'>
                        <label>Select Categories</label>
                        <select
                            name='category'
                            className='form-control'
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            <option value={-1}>All Categories</option>
                            {categoryData.map((category) => (
                                <option key={category.id} value={category.code}>
                                    {category.code}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-1'>
                        <label>Start Date</label>
                        <div className={startDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleStartDateFocus}
                                onBlur={handleStartDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-1'>
                        <label>End Date</label>
                        <div className={endDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleEndDateFocus}
                                onBlur={handleEndDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-1 generate_report_button'>
                        <button className='btn btn-primary' onClick={handleSourceEmissionButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>
                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3"> Source Emissions </h4> */}
                            <div className='row'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title}/>
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (

                                    <>
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col*/}
            </div>
        </div>
    )
}

