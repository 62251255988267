import React, { useState, useEffect, useRef } from 'react';
import DataCorrectionService from '../services/DataCorrectionService';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, Card } from 'react-bootstrap';
import EditFlowValidation from './EditFlowValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';
import ExportComponent from '../../../../common/ExportComponent';
import { format } from 'date-fns';
import formatDateTime from '../../../../common/formatDateTime';

const FlowValidationList = () => {
  const [deviceFlaringItems, setDeviceFlaringItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null); // New state for selected date
  const datepickerRef = useRef();
  const [reasons, setReasons] = useState([]);
  const [exportFilteredData, setExportFilteredData] = useState([]);



  useEffect(() => {
    fetchReasons();
  }, []);

  const fetchReasons = async () => {
    try {
      const response = await DataCorrectionService.getAllReasons();
      setReasons(response.data);
    } catch (error) {
      console.error('Failed to fetch reasons:', error);
    }
  };

  const reasonValueGetter = (valueGetterParams) => {
    const reasonItem = reasons.find(t => t.reasonId == valueGetterParams);
    return reasonItem?.reasonDescription;
  }

  const fetchDeviceFlaringData = async (date) => {
    setIsLoading(true);
    try {
      const response = await DataCorrectionService.getDeviceDailyTxForDate(date);
      setDeviceFlaringItems(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Failed to fetch device flaring data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };




  const handleEdit = (data) => {
    setSelectedData(data);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete item: ${id}?`);
    if (confirmDelete) {
      try {
        await DataCorrectionService.deleteManualValvesItem(id);
        if (selectedDate) {
          const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
          fetchDeviceFlaringData(formattedDate);
        }
      } catch (error) {
        console.error('Failed to delete item:', error);
      }
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    fetchDeviceFlaringData(formattedDate);
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = deviceFlaringItems.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );

    // If filtered data is empty, add a placeholder row to ensure headers are displayed
    if (filtered.length === 0) {
      filtered.push({
        txDateTime: '',
        deviceId: '',
        headerId: '',
        facilityId: '',
        plantId: '',
        flaring: '',
        adjustedFlaring: '',
        flaringOil: '',
        adjustedFlaringOil: '',
        uomId: '',
        adjustedReasonId: '',
        status: ''
      });
    }

    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Tx Date & Time': item.txDateTime ? formatDateTime(item.txDateTime) : '',
      'Source ID': item.deviceId,
      'Header ID': item.headerId,
      'Facility ID': item.facilityId,
      'Plant ID': item.plantId,
      'Flaring': formatWithIndianThousandsSeparatorForExportData(item.flaring ? item.flaring.toFixed(2) : ''),
      'Adj Flaring': formatWithIndianThousandsSeparatorForExportData(item.adjustedFlaring ? item.adjustedFlaring.toFixed(2) : ''),
      'Flaring Oil': formatWithIndianThousandsSeparatorForExportData(item.flaringOil ? item.flaringOil.toFixed(2) : ''),
      'Adj Flaring Oil': formatWithIndianThousandsSeparatorForExportData(item.adjustedFlaringOil ? item.adjustedFlaringOil.toFixed(2) : ''),
      'UoM': item.uomId,
      'Adj Reason': reasonValueGetter(item.adjustedReasonId),
      'Status': item.status
    }));
    setExportFilteredData(exportData);

  }, [search, deviceFlaringItems]);

  const columns = [
    { name: 'Tx Date & Time', selector: row => row.txDateTime ? new Date(row.txDateTime).toLocaleString() : '', sortable: true,width: '150px',
      cell: row => row.txDateTime ? formatDateTime(row.txDateTime) : ''
     },
    {
      name: 'Source ID',
      selector: row => row.deviceId,
      sortable: true,
      resizable: true,
      width: '120px'
    },
    { name: 'Header ID', selector: row => row.headerId, sortable: true, resizable: true },
    { name: 'Facility ID', selector: row => row.facilityId, sortable: true, resizable: true },
    { name: 'Plant ID', selector: row => row.plantId, sortable: true, resizable: true },
    {
      name: 'Flaring',
      selector: row => row.flaring,
      cell: row => formatWithIndianThousandsSeparator(row.flaring ? row.flaring.toFixed(0) : row.flaring),
      sortable: true,
      resizable: true
    },
    {
      name: 'Adj Flaring',
      selector: row => row.adjustedFlaring,
      cell: row => formatWithIndianThousandsSeparator(row.adjustedFlaring ? row.adjustedFlaring.toFixed(0) : row.adjustedFlaring),
      sortable: true,
      resizable: true
    },
    {
      name: 'Flaring Oil',
      selector: row => row.flaringOil,
      cell: row => formatWithIndianThousandsSeparator(row.flaringOil ? row.flaringOil.toFixed(0) : row.flaringOil),
      sortable: true,
      resizable: true
    },
    {
      name: 'Adj Flaring Oil',
      selector: row => row.adjustedFlaringOil,
      cell: row => formatWithIndianThousandsSeparator(row.adjustedFlaringOil ? row.adjustedFlaringOil.toFixed(0) : row.adjustedFlaringOil),
      sortable: true,
      width: '120px',
      resizable: true
    },
    { name: 'UoM', selector: row => row.uomId, sortable: true, resizable: true },
    {
      name: 'Adj Reason',
      selector: row => row.adjustedReasonId,
      cell: row => reasonValueGetter(row.adjustedReasonId),
      width: '200px',
      sortable: true,
      resizable: true
    },
    { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
    {
      name: 'Edit',
      width: '75px',
      cell: row => row.txDateTime ? (
        <FontAwesomeIcon
          icon={faPencilAlt}
          onClick={() => handleEdit(row)}
          style={{ cursor: 'pointer', color: 'blue' }}
        />
      ) : null,
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `Flow_Validation_${new Date().toLocaleDateString()}`;


  const headerData = [
    { label: 'Select Date Range', value: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

const title = "Flow Validations";

  return (
    <div className="main-content">
      <div className="row mb-4">
        <div className="col-sm-12">
          <div className='breadcrumb mt-3'>
            <h1>Flow Validations</h1>
            <ul>
              <li>Data Corrections</li>
              <li>FLOW</li>
            </ul>
            <div className="separator-breadcrumb border-top" />
          </div>
          {/* <div className="separator-breadcrumb border-top" /> */}
          <div className='row'>
            <div className="col-sm-3">
              <label htmlFor="dateRange" className="control-label">Select Date Range</label>
              <div className={'datepicker-parent'}>
                <DatePicker
                  ref={datepickerRef}
                  className="form-control"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select a date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <Card.Body>

          <div className="row">
            <div className="col-sm-12">
              {isLoading && <div>Loading...</div>}
              {!isLoading && (
                <>
                  <div className='row mt-3'>
                    <div className='col-md-10'>
                      {exportFilteredData.length > 0 && (
                        <ExportComponent data={exportFilteredData} columns={columns} headerData={headerData} fileName={fileName} title={title} />
                      )}
                    </div>
                    <div className='col-md-2'>
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control mb-3"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    customStyles={customStyles}
                    pagination
                    highlightOnHover
                    striped
                  />
                </>

              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Flow Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditFlowValidation data={selectedData} onClose={() => setShowModal(false)} onSave={() => {
            if (selectedDate) {
              const formattedDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`;
              fetchDeviceFlaringData(formattedDate);
            }
          }} />
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default FlowValidationList;
