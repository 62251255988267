import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AnnualPriceService from '../services/AnnualPriceService';
import { Form, Button, Modal } from 'react-bootstrap';

const AddAnnualPrice = ({ showModal, handleClose, fetchAnnualPrices, annualPrice }) => {
    const [formGroup, setFormGroup] = useState({
        year: '',
        costPerMMBtu: '',
        costPerBBL: '',
    });
    const [lstYear, setLstYear] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        loadYears();
        if (annualPrice) {
            setFormGroup(annualPrice);
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [annualPrice]);

    const loadYears = async () => {
        try {
            const years = await AnnualPriceService.getYears();
            setLstYear(years);
        } catch (error) {
            console.error('Failed to load years:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormGroup(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validate = () => {
        const errors = {};
        if (!formGroup.year) errors.year = 'Year is required.';
        return errors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        try {
            await AnnualPriceService.saveAnnualPrice(formGroup, isEdit);
            toast.success('Annual Price saved successfully');
            fetchAnnualPrices();
            handleClose();
        } catch (error) {
            console.error('Error saving Annual Price:', error);
            toast.error('Error saving Annual Price');
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Annual Price' : 'Add Annual Price'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="year">
                        <Form.Label>Year</Form.Label>
                        <Form.Control
                            as="select"
                            name="year"
                            value={formGroup.year}
                            onChange={handleChange}
                            disabled={isEdit}
                            required
                        >
                            <option value="">Select Year</option>
                            {lstYear.map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="costPerMMBtu">
                        <Form.Label>Cost Per MMBTU</Form.Label>
                        <Form.Control
                            type="number"
                            name="costPerMMBtu"
                            placeholder="Enter Cost Per MMBTU"
                            value={formGroup.costPerMMBtu}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="costPerBBL">
                        <Form.Label>Cost Per BBL</Form.Label>
                        <Form.Control
                            type="number"
                            name="costPerBBL"
                            placeholder="Enter Cost Per BBL"
                            value={formGroup.costPerBBL}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <div className='row mt-3 float-end'>
                        <div className='col-md-6'>
                            <Button variant="secondary" onClick={handleClose} className="mr-2">
                                Cancel
                            </Button>
                        </div>
                        <div className='col-md-6'>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddAnnualPrice;
