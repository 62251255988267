import React, { useEffect, useState } from 'react';
import { Popover, Badge } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';

const ConnectionStatusPopover = () => {
  const [serverStatuses, setServerStatuses] = useState([]);

  useEffect(() => {
    const fetchServerStatuses = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/fmsdashboard/serverstatuses`);
        setServerStatuses(response.data);
      } catch (error) {
        console.error('Failed to fetch server statuses:', error);
      }
    };

    fetchServerStatuses();
  }, []);

  return (
    <Popover id="connection-status-popover" style={{ position:'fixed',top:'60%',left:'18%' }}>
      <Popover.Header as="h3">Connection Status</Popover.Header>
      <Popover.Body>
        <ul className="list-group">
          {serverStatuses.map((item) => (
            <li key={item.serverName} className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              {item.serverName}
              <Badge pill bg={item.connectivityStatus === 'SUCCESS' ? 'success' : 'danger'}>
                <span className={item.connectivityStatus === 'SUCCESS' ? 'i-Triangle-Arrow-Up' : 'i-Triangle-Arrow-Down'}></span>
              </Badge>
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );
};

export default ConnectionStatusPopover;
