import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FacilitySelector from '../../common/FacilitySelector';
import HeaderSelector from '../../common/HeaderSelector';
import { API_BASE_URL } from '../../../config';
import { BUTTON_LABELS } from '../../../constants';
// Register the components
Chart.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function UnaccountedFlaring() {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [selectedHeaderId, setSelectedHeaderId] = useState(-1);
  const [allHeaderData, setAllHeaderData] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const handleHeaderChange = (e) => {
    setSelectedHeaderId(e.target.value);
  };

  useEffect(() => {
    fetchAllHeaderData();
}, []);

const fetchAllHeaderData = async () => {
  try {
      const response = await fetch(`${API_BASE_URL}/home/headers`, {
          method: 'GET',
          credentials: 'include'
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setAllHeaderData(data);
  } catch (error) {
      console.error('Failed to fetch plant data:', error);
  }
};

  const handleButtonClick = () => {
    if (startDate && endDate) {
      const apiUrl = `${API_BASE_URL}/reports/unaccounted-flaring?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facility=${selectedFacilityId}&header=${selectedHeaderId}`;
      
      setLoading(true);
      fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data && data.content) {
            const labels = data.content.map(item => new Date(item.date));
            const totalFMSData = data.content.map(item => item.totalFMS);
            const meterData = data.content.map(item => item.meter);
            const unaccountedData = data.content.map(item => item.unAccounted);

            setChartData({
              labels: labels,
              datasets: [
                {
                  label: 'Total FMS',
                  data: totalFMSData,
                  backgroundColor: 'rgba(54, 162, 235, 0.2)',
                  borderColor: 'rgba(54, 162, 235, 1)',
                  borderWidth: 1,
                  fill: true,
                },
                {
                  label: 'Meter',
                  data: meterData,
                  backgroundColor: 'rgba(75, 192, 192, 0.2)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                  fill: true,
                },
                {
                  label: 'Unaccounted',
                  data: unaccountedData,
                  backgroundColor: 'rgba(255, 206, 86, 0.2)',
                  borderColor: 'rgba(255, 206, 86, 1)',
                  borderWidth: 1,
                  fill: true,
                }
              ]
            });
          } else {
            setError('Data format is incorrect');
          }
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
          setError('Error fetching data');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('Please select valid options.');
    }
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'yyyy-mm-dd',
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Value',
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
          },
        },
      },
    },
  };
  

  return (
    <div className="main-content">
       <div className='breadcrumb mt-3'>
        <h1>Unaccounted Flaring</h1>
        <ul>
          <li>Reports</li>
          <li>Unaccounted Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className='row'>
          <FacilitySelector
            selectedFacilityId={selectedFacilityId}
            onChange={setSelectedFacilityId}
          />

          {/* <HeaderSelector
            selectedFacilityId={selectedFacilityId}
            selectedHeaderId={selectedHeaderId}
            onChange={setSelectedHeaderId}
          /> */}
           <div className='col-md-2'>
            <label>Select Header</label>
            <select
                name='header'
                className='form-control'
                value={selectedHeaderId}
                onChange={setSelectedHeaderId}
                disabled={selectedFacilityId === -1}
            >
                <option value={-1}>All Headers</option>
                {allHeaderData.map((header) => (
                    <option key={header.headerId} value={header.headerId}>
                        {header.headerName}
                    </option>
                ))}
            </select>
        </div>

          <div className='col-md-2'>
            <label>Start Date</label>
            <DatePicker
              className='form-control'
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className='col-md-2'>
            <label>End Date</label>
            <DatePicker
              className='form-control'
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <div className='col-md-2 generate_report_button'>
            <button className='btn btn-primary' onClick={handleButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
          </div>
        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">Unaccounted Flaring</h4> */}
              {loading && <div>Loading...</div>}
              {error && <div>{error}</div>}
              {!loading && !error && Object.keys(chartData).length > 0 && (
                <div>
                  {/* <h2>Unaccounted Flaring</h2> */}
                  <Line data={chartData} options={options} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
