import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationService from '../services/NotificationService';

const AddRuleConfiguration = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formGroup, setFormGroup] = useState({
    notificationID: '',
    emissionType: '',
    facilityID: '',
    period: '',
    subject: '',
    status: '',
    message: '',
    srcTable: '',
    source: '',
    dataCollectionMode: '',
    condition: '',
    setDestination: false,
    dstTable: '',
    destination: '',
    destinationValue: '',
    destinationUom: ''
  });
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [facilityData, setFacilityData] = useState([]);
  const [sourceCategory, setSourceCategory] = useState([]);
  const [sourceColumn, setSourceColumn] = useState([]);
  const [sourceMainColumn, setSourceMainColumn] = useState([]);
  const [destinationCategory, setDestinationCategory] = useState([]);
  const [destinationMainColumn, setDestinationMainColumn] = useState([]);
  const [destinationColumn, setDestinationColumn] = useState([]);
  const [dataCollectionModes, setDataCollectionModes] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [uom, setUom] = useState([]);
  const [destinationhide, setDestinationhide] = useState(false);
  const [conditionvalue, setConditionvalue] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [
          facilityData,
          sourceCategory,
          sourceColumn,
          destinationCategory,
          destinationColumn,
          dataCollectionModes,
          conditions,
          uom
        ] = await Promise.all([
          NotificationService.getFacilities(),
          NotificationService.getSourceCategory(),
          NotificationService.getSourceColumn(),
          NotificationService.getDestinationCategory(),
          NotificationService.getDestinationColumn(),
          NotificationService.getDataCollectionMode(),
          NotificationService.getCondition(),
          NotificationService.getAllUoms()
        ]);

        setFacilityData(facilityData);
        setSourceCategory(sourceCategory);
        setSourceMainColumn(sourceColumn);
        setDestinationCategory(destinationCategory);
        setDestinationMainColumn(destinationColumn);
        setDataCollectionModes(dataCollectionModes);
        setConditions(conditions);
        setUom(uom);

        if (id) {
          setIsEdit(true);
          const notificationRule = await NotificationService.getNotificationRulesById(id);
          setFormGroup({
            ...notificationRule,
            setDestination: notificationRule.dstTable === 'CONSTANT',
            notificationID: notificationRule.notificationID.replace(/^N/, ''),
          });
          setSourceColumn(sourceMainColumn.filter(x => x.entity === notificationRule.srcTable));
          if (notificationRule.dstTable === 'CONSTANT') {
            setDestinationhide(true);
          } else {
            setDestinationColumn(destinationMainColumn.filter(x => x.entity === notificationRule.dstTable));
          }
        }
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
      }
    };

    fetchInitialData();
  }, [id]);

  const isInputInvalid = (name) => {
    return !formGroup[name] && errors[name];
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormGroup((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null,
    }));
  };

  const validate = () => {
    const newErrors = {};
    const requiredFields = ['notificationID', 'emissionType', 'facilityID', 'period', 'subject', 'status', 'message', 'srcTable', 'source', 'dataCollectionMode', 'condition'];
    requiredFields.forEach((field) => {
      if (!formGroup[field]) newErrors[field] = `${field} is required.`;
    });
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const modifiedFormGroup = {
      ...formGroup,
      notificationID: isEdit ? formGroup.notificationID : `N${formGroup.notificationID}`,
      dstTable: formGroup.setDestination ? 'CONSTANT' : formGroup.dstTable,
      destination: formGroup.setDestination ? '' : formGroup.destination,
      destinationValue: formGroup.setDestination ? formGroup.destinationValue : null,
      destinationUom: formGroup.setDestination ? formGroup.destinationUom : '',
    };

    try {
      const response = await NotificationService.saveNotificationRules(modifiedFormGroup, isEdit);
      if (response.isSuccess) {
        toast.success('Notification Rule saved successfully');
        setTimeout(() => {
          navigate('/admin/configurations/notification/list-rule-configuration');
        }, 1000);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error('Error saving Notification Rule');
    }
  };

  const handleCancel = () => {
    navigate('/admin/configurations/notification/list-rule-configuration');
  };

  const handleSrcCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setFormGroup((prevState) => ({
      ...prevState,
      srcTable: selectedValue,
      source: '',
    }));
    setSourceColumn(sourceMainColumn.filter(x => x.entity === selectedValue));
  };

  const handleDstCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setFormGroup((prevState) => ({
      ...prevState,
      dstTable: selectedValue,
      destination: '',
    }));
    setDestinationColumn(destinationMainColumn.filter(x => x.entity === selectedValue));
  };

  const handleConditionChange = (event) => {
    const selectedValue = event.target.value;
    setFormGroup((prevState) => ({
      ...prevState,
      condition: selectedValue,
    }));
    setConditionvalue(`source${selectedValue}destination`);
  };

  const handleConstantChange = (event) => {
    setFormGroup((prevState) => ({
      ...prevState,
      setDestination: event.target.checked,
    }));
    setDestinationhide(event.target.checked);
  };

  return (
    <div>
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Rule Configuration' : 'Add Rule Configuration'}</h1>
        <ul>
          <li>System Configuration</li>
          <li>Notification</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3">
                <h3>Notification Rule</h3>
                <div className="input-group mb-3" style={{ height: '3.125rem' }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="notificationId">N</span>
                  </div>
                  <input
                    style={{ height: '3.125rem' }}
                    className={`form-control ${isInputInvalid('notificationID') ? 'is-invalid' : ''}`}
                    id="notificationId"
                    name="notificationID"
                    placeholder="Notification ID"
                    value={formGroup.notificationID}
                    onChange={handleChange}
                    aria-label="Notification Id"
                    aria-describedby="notificationId"
                    disabled={isEdit}
                  />
                  {isInputInvalid('notificationID') && <div className="invalid-feedback">{errors.notificationID}</div>}
                </div>
              </div>
              <div className="col-sm-3 mt-3">
                <div className="form-label-group select-group">
                  <label htmlFor="emissionType">Emission Type</label>
                  <select
                    className={`form-control ${isInputInvalid('emissionType') ? 'is-invalid' : ''}`}
                    id="emissionType"
                    name="emissionType"
                    value={formGroup.emissionType}
                    onChange={handleChange}
                  >
                    <option value="">Select Emission Type</option>
                    <option value="FMS">FMS</option>
                    <option value="EMS">EMS</option>
                    <option value="GHG">GHG</option>
                    <option value="AMT">AMT</option>
                  </select>
                  {isInputInvalid('emissionType') && <div className="invalid-feedback">{errors.emissionType}</div>}
                </div>
              </div>
              <div className="col-sm-3 mt-3">
                <div className="form-label-group select-group">
                  <label htmlFor="facilityId">Facility ID</label>
                  <select
                    className={`form-control ${isInputInvalid('facilityID') ? 'is-invalid' : ''}`}
                    id="facilityId"
                    name="facilityID"
                    value={formGroup.facilityID}
                    onChange={handleChange}
                  >
                    <option value="">Select Facility</option>
                    <option value="all">All Facilities</option>
                    {facilityData.map((type) => (
                      <option key={type.facilityId} value={type.facilityId}>
                        {type.facilityName}
                      </option>
                    ))}
                  </select>
                  {isInputInvalid('facilityID') && <div className="invalid-feedback">{errors.facilityID}</div>}
                </div>
              </div>
              <div className="col-sm-3 mt-3">
                <div className="form-label-group select-group">
                  <label htmlFor="period">Period</label>
                  <select
                    className={`form-control ${isInputInvalid('period') ? 'is-invalid' : ''}`}
                    id="period"
                    name="period"
                    value={formGroup.period}
                    onChange={handleChange}
                  >
                    <option value="">Select Period</option>
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                  {isInputInvalid('period') && <div className="invalid-feedback">{errors.period}</div>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-9">
                <div className="form-label-group">
                  <label htmlFor="emailSubject">Email Subject</label>
                  <input
                    className={`form-control ${isInputInvalid('subject') ? 'is-invalid' : ''}`}
                    id="emailSubject"
                    name="subject"
                    placeholder="Email Subject"
                    value={formGroup.subject}
                    onChange={handleChange}
                  />
                  {isInputInvalid('subject') && <div className="invalid-feedback">{errors.subject}</div>}
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-label-group select-group">
                  <label htmlFor="status">Status</label>
                  <select
                    className={`form-control ${isInputInvalid('status') ? 'is-invalid' : ''}`}
                    id="status"
                    name="status"
                    value={formGroup.status}
                    onChange={handleChange}
                  >
                    <option value="">Select Status</option>
                    <option value="A">A</option>
                    <option value="D">D</option>
                  </select>
                  {isInputInvalid('status') && <div className="invalid-feedback">{errors.status}</div>}
                </div>
              </div>
            </div>
            <span className="font-weight-bold sub-header">Set Source</span>
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <textarea
                    className={`form-control ${isInputInvalid('message') ? 'is-invalid' : ''}`}
                    id="emailContent"
                    name="message"
                    placeholder="Email Content"
                    value={formGroup.message}
                    onChange={handleChange}
                    rows="2"
                  />
                  {isInputInvalid('message') && <div className="invalid-feedback">{errors.message}</div>}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4">
                <div className="form-label-group select-group">
                  <label htmlFor="srcCategory">Source Category</label>
                  <select
                    className={`form-control ${isInputInvalid('srcTable') ? 'is-invalid' : ''}`}
                    id="srcCategory"
                    name="srcTable"
                    value={formGroup.srcTable}
                    onChange={handleSrcCategoryChange}
                  >
                    <option value="">Select Source Category</option>
                    {sourceCategory.map((type) => (
                      <option key={type.entity} value={type.entity}>
                        {type.entityDisplayName}
                      </option>
                    ))}
                  </select>
                  {isInputInvalid('srcTable') && <div className="invalid-feedback">{errors.srcTable}</div>}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group select-group">
                  <label htmlFor="source">Source Column</label>
                  <select
                    className={`form-control ${isInputInvalid('source') ? 'is-invalid' : ''}`}
                    id="source"
                    name="source"
                    value={formGroup.source}
                    onChange={handleChange}
                  >
                    <option value="">Select Source</option>
                    {sourceColumn.map((type) => (
                      <option key={type.propertyName} value={type.propertyName}>
                        {type.propertyDisplayName}
                      </option>
                    ))}
                  </select>
                  {isInputInvalid('source') && <div className="invalid-feedback">{errors.source}</div>}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-label-group select-group">
                  <label htmlFor="dataCollectionMode">Data Collection Mode</label>
                  <select
                    className={`form-control ${isInputInvalid('dataCollectionMode') ? 'is-invalid' : ''}`}
                    id="dataCollectionMode"
                    name="dataCollectionMode"
                    value={formGroup.dataCollectionMode}
                    onChange={handleChange}
                  >
                    <option value="">Select Data Collection Mode</option>
                    {dataCollectionModes.map((type) => (
                      <option key={type.code} value={type.code}>
                        {type.description}
                      </option>
                    ))}
                  </select>
                  {isInputInvalid('dataCollectionMode') && <div className="invalid-feedback">{errors.dataCollectionMode}</div>}
                </div>
              </div>
            </div>
            <span className="font-weight-bold sub-header">Action</span>
            <div className="row">
              <div className="col-sm-5">
                <div className="form-label-group select-group col-sm-12">
                  <label htmlFor="condition">Condition</label>
                  <select
                    className={`form-control ${isInputInvalid('condition') ? 'is-invalid' : ''}`}
                    id="condition"
                    name="condition"
                    value={formGroup.condition}
                    onChange={handleConditionChange}
                  >
                    <option value="">Select Condition</option>
                    {conditions.map((type) => (
                      <option key={type.code} value={type.code}>
                        {type.description}
                      </option>
                    ))}
                  </select>
                  {isInputInvalid('condition') && <div className="invalid-feedback">{errors.condition}</div>}
                </div>
              </div>
              <div className="col-sm-3">
                <label style={{ color: 'red' }}>{conditionvalue}</label>
              </div>
            </div>
            <div className="row"><span className="font-weight-bold sub-header">Set Destination</span></div>
            <div className="row">
              <div className="form-group">
                <div className="form-check" style={{ paddingLeft: '0.25rem' }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    name="setDestination"
                    checked={formGroup.setDestination}
                    onChange={handleConstantChange}
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    Set Constant Value
                  </label>
                </div>
              </div>
            </div>
            {!destinationhide && (
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-label-group select-group">
                    <label htmlFor="dstCategory">Destination Category</label>
                    <select
                      className={`form-control ${isInputInvalid('dstTable') ? 'is-invalid' : ''}`}
                      id="dstCategory"
                      name="dstTable"
                      value={formGroup.dstTable}
                      onChange={handleDstCategoryChange}
                    >
                      <option value="">Select Destination Category</option>
                      {destinationCategory.map((type) => (
                        <option key={type.entity} value={type.entity}>
                          {type.entityDisplayName}
                        </option>
                      ))}
                    </select>
                    {isInputInvalid('dstTable') && <div className="invalid-feedback">{errors.dstTable}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-label-group select-group">
                    <label htmlFor="destination">Destination</label>
                    <select
                      className={`form-control ${isInputInvalid('destination') ? 'is-invalid' : ''}`}
                      id="destination"
                      name="destination"
                      value={formGroup.destination}
                      onChange={handleChange}
                    >
                      <option value="">Select Destination</option>
                      {destinationColumn.map((type) => (
                        <option key={type.propertyName} value={type.propertyName}>
                          {type.propertyDisplayName}
                        </option>
                      ))}
                    </select>
                    {isInputInvalid('destination') && <div className="invalid-feedback">{errors.destination}</div>}
                  </div>
                </div>
              </div>
            )}
            {destinationhide && (
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-label-group">
                    <label htmlFor="destinationValue">Destination Value</label>
                    <input
                      className={`form-control ${isInputInvalid('destinationValue') ? 'is-invalid' : ''}`}
                      id="destinationValue"
                      name="destinationValue"
                      placeholder="Destination Value"
                      value={formGroup.destinationValue}
                      onChange={handleChange}
                    />
                    {isInputInvalid('destinationValue') && <div className="invalid-feedback">{errors.destinationValue}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-label-group select-group">
                    <label htmlFor="uom">Uom</label>
                    <select
                      className={`form-control ${isInputInvalid('destinationUom') ? 'is-invalid' : ''}`}
                      id="uom"
                      name="destinationUom"
                      value={formGroup.destinationUom}
                      onChange={handleChange}
                    >
                      <option value="">Select Uom</option>
                      {uom.map((type) => (
                        <option key={type.uomId} value={type.uomId}>
                          {type.description}
                        </option>
                      ))}
                    </select>
                    {isInputInvalid('destinationUom') && <div className="invalid-feedback">{errors.destinationUom}</div>}
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddRuleConfiguration;
