import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../common/FormatNumber';
import ExportComponent from '../../../common/ExportComponent';
import HeaderWiseFlaringExport from '../../../common/exports/HeaderWiseFlaringExport';
import { BUTTON_LABELS } from '../../../../constants';
import './HeaderWiseFlaring.css';
import { format } from 'date-fns';


export default function HeaderWiseFlaring() {
    const [facilityData, setFacilityData] = useState([]);
    const [headerWiseFlaringData, setHeaderWiseFlaringData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
    const [materialState, setMaterialState] = useState("GAS");
    const [dailyReport, setDailyReport] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]); // Add this state

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleFacilityChange = (e) => {
        setSelectedFacilityId(e.target.value);
    };

    const handleMaterialStateChange = (e) => {
        setMaterialState(e.target.value);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchFacilityData();
    }, []);

    const fetchFacilityData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/home/facilities`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFacilityData(data);
        } catch (error) {
            console.error('Failed to fetch tracking data:', error);
        }
    };

    const handleReportsButtonClick = () => {
        if (startDate && endDate && selectedFacilityId !== '') {
            const apiUrl = `${API_BASE_URL}/reports/get-daily-header?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&materialState=${materialState}`;

            setIsLoading(true);

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    const reportData = data.content;
                    const totalRow = calculateTotalRow(reportData);
                    setDailyReport([...reportData, totalRow]);
                    setFilteredData([...reportData, totalRow]); // Initialize filtered data
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                });
        } else {
            console.error('Please select valid options.');
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = dailyReport.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
    
        // If filtered data is empty, add a placeholder row to ensure headers are displayed
        if (filtered.length === 0) {
            filtered.push({
                facilityId: '',
                headerId: '',
                headerName: '',
                valveFlaring: '',
                purge: '',
                pilot: '',
                leakage: '',
                totalFMSGas: '',
                unAccountedGas: '',
                meterOil: '',
                stackPurge: '',
                recoveryOil: '',
                totalMass: '',
                totalFlaring: '',
                totalFlaringInUSD: ''
            });
        }
    
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Facility ID': item.facilityId,
            'Header ID': item.headerId,
            'Header Name': item.headerName,
            'Valve Flaring': formatWithIndianThousandsSeparatorForExportData(item.valveFlaring ? (item.valveFlaring.toFixed(0)) : item.valveFlaring),
            'Purge': formatWithIndianThousandsSeparatorForExportData(item.purge ? (item.purge.toFixed(0)) : item.purge),
            'Pilot': formatWithIndianThousandsSeparatorForExportData(item.pilot ? (item.pilot.toFixed(0)) : item.pilot),
            'Design Leakage': formatWithIndianThousandsSeparatorForExportData(item.leakage ? (item.leakage.toFixed(0)) : item.leakage),
            'Total FMS': formatWithIndianThousandsSeparatorForExportData(item.totalFMSGas  ? (item.totalFMSGas.toFixed(0)) : item.totalFMSGas),
            'Unaccounted': formatWithIndianThousandsSeparatorForExportData(item.unAccountedGas ? (item.unAccountedGas.toFixed(0)) : item.unAccountedGas),
            'Meter': formatWithIndianThousandsSeparatorForExportData(item.meterOil ? (item.meterOil.toFixed(0)) : item.meterOil),
            'Stack Purge': formatWithIndianThousandsSeparatorForExportData(item.stackPurge ? (item.stackPurge.toFixed(0)) : item.stackPurge),
            'Recovery': formatWithIndianThousandsSeparatorForExportData(item.recoveryOil ? (item.recoveryOil.toFixed(0)) : item.recoveryOil),
            'Total Mass': formatWithIndianThousandsSeparatorForExportData(item.totalMass ? (item.totalMass.toFixed(0)) : item.totalMass),
            'Total Flaring': formatWithIndianThousandsSeparatorForExportData(item.totalFlaring ? (item.totalFlaring.toFixed(0)) : item.totalFlaring),
            'Total Flaring $': formatWithIndianThousandsSeparatorForExportData(item.totalFlaringInUSD ? (item.totalFlaringInUSD.toFixed(0)) : item.totalFlaringInUSD),  
        }));
        setExportFilteredData(exportData);

    }, [search, dailyReport]);

    const calculateTotalRow = (data) => {
        const totalRow = {
            facilityId: 'Total',
            headerId: '',
            headerName: '',
            valveFlaring: 0,
            purge: 0,
            pilot: 0,
            leakage: 0,
            totalFMSGas: 0,
            unAccountedGas: 0,
            meterOil: 0,
            stackPurge: 0,
            recoveryOil: 0,
            totalMass: 0,
            totalFlaring: 0,
            totalFlaringInUSD: 0
        };

        data.forEach(row => {
            totalRow.valveFlaring += row.valveFlaring;
            totalRow.purge += row.purge;
            totalRow.pilot += row.pilot;
            totalRow.leakage += row.leakage;
            totalRow.totalFMSGas += row.totalFMSGas;
            totalRow.unAccountedGas += row.unAccountedGas;
            totalRow.meterOil += row.meterOil;
            totalRow.stackPurge += row.stackPurge;
            totalRow.recoveryOil += row.recoveryOil;
            totalRow.totalMass += row.totalMass;
            totalRow.totalFlaring += row.totalFlaring;
            totalRow.totalFlaringInUSD += row.totalFlaringInUSD;
        });

        return totalRow;
    };

    const columns = [
        {
            name: 'Facility ID',
            selector: row => row.facilityId,
            cell: row => (row.facilityId == 'Total' ? 'Total' : row.facilityId),
            resizable: true,
            sortable: true,
            width: '125px',
        },
        {
            name: 'Header ID',
            selector: row => row.headerId,
            sortable: true,
            resizable: true,
            width: '118px',
        },
        {
            name: 'Header Name',
            selector: row => row.headerName,
            sortable: true,
            width: '145px',
        },
        {
            name: 'Valve Flaring',
            selector: row => row.valveFlaring,
            cell: row => formatWithIndianThousandsSeparator(row.valveFlaring ? row.valveFlaring.toFixed(0) : row.valveFlaring),
            sortable: true,
            width: '120px',
        },
        {
            name: 'Purge',
            selector: row => row.purge,
            cell: row => formatWithIndianThousandsSeparator(row.purge ? row.purge.toFixed(0) : row.purge),
            sortable: true,
            width: '95px',
        },
        {
            name: 'Pilot',
            selector: row => row.pilot,
            cell: row => formatWithIndianThousandsSeparator(row.pilot ? row.pilot.toFixed(0) : row.pilot),
            sortable: true,
            width: '95px',
        },
        {
            name: 'Design Leakage',
            selector: row => row.leakage,
            cell: row => formatWithIndianThousandsSeparator(row.leakage ? row.leakage.toFixed(0) : row.leakage),
            sortable: true,
            width: '145px',
        },
        {
            name: 'Total FMS',
            selector: row => row.totalFMSGas,
            cell: row => formatWithIndianThousandsSeparator(row.totalFMSGas ? row.totalFMSGas.toFixed(0) : row.totalFMSGas),
            sortable: true,
            width: '125px',
        },
        {
            name: 'Unaccounted',
            selector: row => row.unAccountedGas,
            cell: row => formatWithIndianThousandsSeparator(row.unAccountedGas ? row.unAccountedGas.toFixed(0) : row.unAccountedGas),
            sortable: true,
            width: '138px',
        },
        {
            name: 'Meter',
            selector: row => row.meterOil,
            cell: row => formatWithIndianThousandsSeparator(row.meterOil ? row.meterOil.toFixed(0) : row.meterOil),
            sortable: true,
            width: '100px',
        },
        {
            name: 'Stack Purge',
            selector: row => row.stackPurge,
            cell: row => formatWithIndianThousandsSeparator(row.stackPurge ? row.stackPurge.toFixed(0) : row.stackPurge),
            sortable: true,
            width: '118px',
        },
        {
            name: 'Recovery',
            selector: row => row.recoveryOil,
            cell: row => formatWithIndianThousandsSeparator(row.recoveryOil ? row.recoveryOil.toFixed(0) : row.recoveryOil),
            sortable: true,
            width: '108px',
        },
        {
            name: 'Total Mass',
            selector: row => row.totalMass,
            cell: row => formatWithIndianThousandsSeparator(row.totalMass ? row.totalMass.toFixed(0) : row.totalMass),
            sortable: true,
            width: '108px',
        },
        {
            name: 'Total Flaring',
            selector: row => row.totalFlaring,
            cell: row => formatWithIndianThousandsSeparator(row.totalFlaring ? row.totalFlaring.toFixed(0) : row.totalFlaring),
            sortable: true,
            width: '118px',
        },
        {
            name: 'Total Flaring $',
            selector: row => row.totalFlaringInUSD,
            cell: row => formatWithIndianThousandsSeparator(row.totalFlaringInUSD ? row.totalFlaringInUSD.toFixed(0) : row.totalFlaringInUSD),
            sortable: true,
            // width: '120px',
        }
    ];
    

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };
    // const customStyles = {
    //     rows: {
    //         style: {
    //             minHeight: '30px', // override the row height
    //         },
    //     },
    //     headCells: {
    //         style: {
    //             paddingLeft: '8px', // override the cell padding for head cells
    //             paddingRight: '8px',
    //             position: 'sticky',
    //             top: 0,
    //             zIndex: 1,
    //         },
    //     },
    //     cells: {
    //         style: {
    //             paddingLeft: '8px', // override the cell padding for data cells
    //             paddingRight: '8px',
    //             textAlign: 'right', // ensure alignment
    //             justifyContent: 'right', // ensures content aligns right
    //         },
    //     },
    // };

    const fileName = `FMS_Header_Wise_Flaring_${new Date().toLocaleDateString()}`;

    const headerData = [
        { label: 'Facility Name', value: selectedFacilityId != -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facilities' },
        { label: 'Material State', value: materialState || 'Gas' },
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];

    const title = "Header Flaring Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Header Flaring</h1>
                <ul>
                    <li>Reports</li>
                    <li>Flaring</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Select Facility</label>
                        <select
                            name='facility'
                            className='form-control'
                            // style={{ width: '20vw' }}
                            value={selectedFacilityId}
                            onChange={handleFacilityChange}
                        >
                            <option value={-1}>All Facilities</option>
                            {facilityData.map((facility) => (
                                <option key={facility.facilityId} value={facility.facilityId}>
                                    {facility.facilityName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='col-md-2'>
                        <label>Select Material State</label>
                        <select
                            name='materialState'
                            className='form-control'
                            // style={{ width: '20vw' }}
                            value={materialState}
                            onChange={handleMaterialStateChange}
                        >
                            <option value={'GAS'}>Gas</option>
                            <option value={'LIQUID'}>Liquid</option>
                        </select>
                    </div>
                    <div className='col-md-2'>
                                <label>Start Date</label>
                                <div className="datepicker-parent">
                                <DatePicker
                                    className='form-control'
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Start Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <label>End Date</label>
                                <div className="datepicker-parent">
                                <DatePicker
                                    className='form-control'
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText="End Date"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                </div>
                            </div>
                    <div className='col-md-2 generate_report_button'>
                        <button className='btn  btn-primary' onClick={handleReportsButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>
                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}

            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-md-10'>
                                    {exportFilteredData.length > 0 && (
                                        <HeaderWiseFlaringExport data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        customStyles={customStyles}
                                        pagination
                                        highlightOnHover
                                        striped
                                        fixedHeader
                                        fixedHeaderScrollHeight="400px"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
