import React, { useState, useEffect } from 'react';
import { useNavigate, useParams ,useSearchParams} from 'react-router-dom';
import { API_BASE_URL } from '../../../../../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddArea = () => {
    // const { areaId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("areaId")
    const navigate = useNavigate();
    const [area, setArea] = useState({
        areaKey: '',
        areaName: '',
        status: 'A'
    });
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (searchParams.get("areaId")) {
            fetchAreaData(searchParams.get("areaId"));
            setIsEdit(true);
        }
    }, [searchParams.get("areaId")]);

    const fetchAreaData = async (key) => {
        try {
            const response = await fetch(`${API_BASE_URL}/area/${key}`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setArea({
                areaKey: data.areaKey,
                areaName: data.areaName,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch area data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setArea(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEdit ? `${API_BASE_URL}/area/update` : `${API_BASE_URL}/area/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
               
                credentials: 'include',
                body: JSON.stringify({
                    areaKey: area.areaKey,
                    areaName: area.areaName,
                    status: area.status
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`Area ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            navigate('/admin/configurations/area');
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} area:`, error);
        }
    };

    return (
        <div className="main-content">
            <div className="breadcrumb"></div>
            <div className="separator-breadcrumb border-top" />
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            <h4 className="card-title mb-3">{isEdit ? 'Edit Area' : 'Add Area'}</h4>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Area ID</label>
                                    <input
                                        type="text"
                                        name="areaKey"
                                        className="form-control"
                                        value={area.areaKey}
                                        onChange={handleChange}
                                        disabled={isEdit}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Area Name</label>
                                    <input
                                        type="text"
                                        name="areaName"
                                        className="form-control"
                                        value={area.areaName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <input
                                        type="text"
                                        name="status"
                                        className="form-control"
                                        value={area.status}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    {isEdit ? 'Update' : 'Save'}
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={() => navigate('/admin/configurations/area')}>
                                    Cancel
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddArea;
