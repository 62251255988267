import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';

class DeviceService {
  getAllFacilities() {
    const url = `${API_BASE_URL}/home/facilities`;
    return axios.get(url);
  }

  getAllHeaders() {
    const url = `${API_BASE_URL}/device/headers`;
    return axios.get(url);
  }

  getAllPlants() {
    const url = `${API_BASE_URL}/device/plants`;
    return axios.get(url);
  }

  getAllEquipments() {
    const url = `${API_BASE_URL}/device/equipments`;
    return axios.get(url);
  }

  getAllReasons() {
    const url = `${API_BASE_URL}/device/reasons`;
    return axios.get(url);
  }

  getAllDeviceTypes() {
    const url = `${API_BASE_URL}/device/devicetypes`;
    return axios.get(url);
  }

  getDeviceClassifications() {
    const url = `${API_BASE_URL}/device/deviceclassifications`;
    return axios.get(url);
  }

  getAllCodeMasters() {
    const url = `${API_BASE_URL}/device/codemasters`;
    return axios.get(url);
  }

  getAllUOMs() {
    const url = `${API_BASE_URL}/device/uoms`;
    return axios.get(url);
  }

  getAllCoefficients() {
    const url = `${API_BASE_URL}/device/coefficients`;
    return axios.get(url);
  }

  getAllPipeFittings() {
    const url = `${API_BASE_URL}/device/pipefittings`;
    return axios.get(url);
  }

  getAllPipeFrictionFactors() {
    const url = `${API_BASE_URL}/device/pipefrictionfactors`;
    return axios.get(url);
  }

  saveDevice(device) {
    const url = `${API_BASE_URL}/device/savedevice`;
    return axios.post(url, device);
  }

  updateDevice(deviceId, device) {
    const url = `${API_BASE_URL}/device/update-device/${deviceId}`;
    return axios.put(url, device);
  }

  getAllDevices() {
    const url = `${API_BASE_URL}/device/all`;
    return axios.get(url);
  }

  canDeleteDevice(deviceId) {
    const url = `${API_BASE_URL}/device/can-delete/${deviceId}`;
    return axios.get(url);
  }

  deleteDevice(deviceId) {
    const url = `${API_BASE_URL}/device/delete/${deviceId}`;
    return axios.delete(url);
  }

  filterDataCollectionTypesFromCodeMaster(arrCodeMasters) {
    return arrCodeMasters.filter(x => x.type === CodeMasterType.DataCollectionMode);
  }

  filterDeviceCategoryFromCodeMaster(arrCodeMasters) {
    return arrCodeMasters.filter(x => x.type === CodeMasterType.CategoryID);
  }

  filterFlowTypesFromCodeMaster(arrCodeMasters) {
    return arrCodeMasters.filter(x => x.type === CodeMasterType.FlowType);
  }

  filterDeviceModeFromCodeMaster(arrCodeMasters) {
    return arrCodeMasters.filter(x => x.type === CodeMasterType.DeviceMode);
  }

  filterMaterialStateFromCodeMaster(arrCodeMasters) {
    return arrCodeMasters.filter(x => x.type === CodeMasterType.MaterialState);
  }

  filterValveTypesFromCodeMaster(arrCodeMasters) {
    return arrCodeMasters.filter(x => x.type === CodeMasterType.ValveType);
  }

  filtercvToFlowMethodsFromCodeMaster(arrCodeMasters) {
    return arrCodeMasters.filter(x => x.type === CodeMasterType.CVtoFlowMethod);
  }

  getDeviceById(deviceId) {
    const url = `${API_BASE_URL}/device/device-by-id/${deviceId}`;
    return axios.get(url);
  }

  async fetchDropdownData() {
    const [
      codemasters,
      devicetypes,
      deviceclassifications,
      reasons,
      uoms,
      pipefittings,
      coefficients,
      pipefrictionfactors,
      facilities,
      headers,
      plants,
      equipments
    ] = await Promise.all([
      this.getAllCodeMasters(),
      this.getAllDeviceTypes(),
      this.getDeviceClassifications(),
      this.getAllReasons(),
      this.getAllUOMs(),
      this.getAllPipeFittings(),
      this.getAllCoefficients(),
      this.getAllPipeFrictionFactors(),
      this.getAllFacilities(),
      this.getAllHeaders(),
      this.getAllPlants(),
      this.getAllEquipments()
    ]);

    return {
      allFacilities: facilities.data.filter(x => x.status === 'A'),
      deviceClassifications: deviceclassifications.data.filter(x => x.status === 'A'),
      calcMethodTypes: codemasters.data.filter(t => t.type === 'CalcMethod'),
      dataCollectionTypes: this.filterDataCollectionTypesFromCodeMaster(codemasters.data),
      deviceCategories: this.filterDeviceCategoryFromCodeMaster(codemasters.data),
      flowTypes: this.filterFlowTypesFromCodeMaster(codemasters.data),
      deviceModes: this.filterDeviceModeFromCodeMaster(codemasters.data),
      materialStates: this.filterMaterialStateFromCodeMaster(codemasters.data),
      valveTypes: this.filterValveTypesFromCodeMaster(codemasters.data),
      cvToFlowMethods: this.filtercvToFlowMethodsFromCodeMaster(codemasters.data),
      allReasons: reasons.data,
      uomList: uoms.data,
      ratedFlowUomList: uoms.data.filter(x => x.uomType === 'VOLFLOW'),
      lengthUomList: uoms.data.filter(x => x.uomType === 'LENGTH'),
      coefficients: coefficients.data
    };
  }

  async getPlantsAndHeaders() {
    const [plants, headers] = await Promise.all([
      this.getAllPlants(),
      this.getAllHeaders()
    ]);
    return { plants: plants.data, headers: headers.data };
  }
}

export default new DeviceService();
