import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddReason from './AddReason'; // Adjust the path as necessary
import ExportComponent from '../../../../common/ExportComponent';

export default function ReasonList() {
    const [reasonData, setReasonData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedReasonId, setSelectedReasonId] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);
    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchReasonData();
    }, []);

    const fetchReasonData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/ems-master/reason/all`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setReasonData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch facility data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/ems-master/add-reason?reasonId=${id}`);
    };

    const handleDelete = async (reasonId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete reason: ${reasonId}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/ems-master/reason/delete/${reasonId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchReasonData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedReasonId(null);
        fetchReasonData(); // Refresh data after adding or editing
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = reasonData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Reason ID': item.reasonId,
            'Reason Description': item.reasonDescription,
            'Reason Category': item.reasonCategory,
            'Reason Type': item.reasonType,
            'User Selectable Y/N': item.userSelectableYN,
            'Controllable Y/N': item.controlableYN,
            'Scenario Description': item.scenarioDescription,
            'Status': item.status
        }));

        setExportFilteredData(exportData);

    }, [search, reasonData]);

    const columns = [
        { name: 'Reason ID', selector: row => row.reasonId, sortable: true },
        { name: 'Reason Description', selector: row => row.reasonDescription, sortable: true },
        { name: 'Reason Category', selector: row => row.reasonCategory, sortable: true },
        { name: 'Reason Type', selector: row => row.reasonType, sortable: true },
        { name: 'User Selectable Y/N', selector: row => row.userSelectableYN, sortable: true },
        { name: 'Controllable Y/N', selector: row => row.controlableYN, sortable: true },
        { name: 'Scenario Description', selector: row => row.scenarioDescription, sortable: true },
        { name: 'Status', selector: row => row.status, sortable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.reasonId)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.reasonId)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Reason_${new Date().toLocaleDateString()}`;

    const title = "Reasons Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Reasons</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Flare Master</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Reasons</h4> */}
                            <div className='row pull-right'>
                                <div className='col-md-10'>
                                    <Link to="/admin/configurations/ems-master/add-reason" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}

