import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPollutantLimit = ({ show, handleClose, fetchPollutantLimitData, pollutantId, year }) => {
    const [form, setForm] = useState({
        pollutantId: '',
        limit: '',
        year: '',
        uom: '',
        status: 'A'
    });
    const [lstPollutant, setLstPollutant] = useState([]);
    const [lstYear, setLstYear] = useState([]);
    const [lstUom, setLstUom] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (pollutantId && year) {
            setIsEdit(true);
            loadPollutantLimit(pollutantId, year);
        } else {
            setIsEdit(false);
            resetForm();
        }
        fetchInitialData();
    }, [pollutantId, year]);

    const fetchInitialData = async () => {
        try {
            const [years, uoms, codeMasters] = await Promise.all([
                fetchYears(),
                fetchUOMs(),
                fetchCodeMasters()
            ]);
            setLstYear(years);
            setLstUom(uoms);
            setLstPollutant(filterPollutantLimitsFromCodeMasters(codeMasters));
        } catch (error) {
            console.error('Failed to fetch initial data:', error);
        }
    };

    const fetchYears = async () => {
        let years = [];
        for (let i = 2000; i <= 2050; i++) {
            years.push(i);
        }
        return years;
    };

    const fetchUOMs = async () => {
        const response = await fetch(`${API_BASE_URL}/device/uoms`);
        if (!response.ok) throw new Error('Failed to fetch UOMs');
        return await response.json();
    };

    const fetchCodeMasters = async () => {
        const response = await fetch(`${API_BASE_URL}/device/codemasters`);
        if (!response.ok) throw new Error('Failed to fetch code masters');
        return await response.json();
    };

    const filterPollutantLimitsFromCodeMasters = (codeMasters) => {
        return codeMasters.filter(x => x.type === CodeMasterType.PollutantID);
    };

    const loadPollutantLimit = async (pollutantId, year) => {
        try {
            const response = await fetch(`${API_BASE_URL}/composition/pollutant-limit/pollutant/${pollutantId}/year/${year}`);
            if (!response.ok) throw new Error('Failed to fetch pollutant limit');
            const data = await response.json();
            setForm({
                pollutantId: data.pollutantId,
                limit: data.limit,
                id: data.id,
                year: data.year,
                uom: data.uom,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch pollutant limit:', error);
        }
    };

    const resetForm = () => {
        setForm({
            pollutantId: '',
            limit: '',
            year: '',
            uom: '',
            status: 'A'
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = isEdit ? `${API_BASE_URL}/composition/pollutant-limit/update` : `${API_BASE_URL}/composition/pollutant-limit/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiUrl, {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(form)
            });
            if (!response.ok) throw new Error('Failed to save pollutant limit');

            toast.success('Pollutant Limit saved successfully');
            handleClose();
            fetchPollutantLimitData();
        } catch (error) {
            console.error('Failed to save pollutant limit:', error);
            toast.error('Error saving pollutant limit');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Pollutant Limits' : 'Add Pollutant Limits'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Pollutant ID</Form.Label>
                        <Form.Control as="select" name="pollutantId" value={form.pollutantId} onChange={handleChange} required>
                            <option value="">Select Pollutant ID</option>
                            {lstPollutant.map(pollutant => (
                                <option key={pollutant.code} value={pollutant.code}>
                                    {pollutant.description}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Year</Form.Label>
                        <Form.Control as="select" name="year" value={form.year} onChange={handleChange} required>
                            <option value="">Select Year</option>
                            {lstYear.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Limit</Form.Label>
                        <Form.Control type="number" name="limit" value={form.limit} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>UoM</Form.Label>
                        <Form.Control as="select" name="uom" value={form.uom} onChange={handleChange}>
                            <option value="">Select UoM</option>
                            {lstUom.map(uom => (
                                <option key={uom.uomId} value={uom.uomId}>
                                    {uom.description}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" name="status" value={form.status} onChange={handleChange} required>
                            <option value="">Select Status</option>
                            <option value="A">A</option>
                            <option value="D">D</option>
                        </Form.Control>
                    </Form.Group>
                   <div className='row mt-3 float-end'>
                    <div className='col-md-6'>
                    <Button variant="secondary" onClick={handleClose} className="mr-2">
                        Cancel
                    </Button>
                    </div>
                    <div className='col-md-6'>
                    <Button type="submit" variant="primary">
                        Save
                    </Button>
                    </div>
                   </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddPollutantLimit;
