import React from 'react'
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../config';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import 'react-datepicker/dist/react-datepicker.css';
import ExportComponent from '../../common/ExportComponent';
import { BUTTON_LABELS } from '../../../constants';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../common/FormatNumber';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { format } from 'date-fns';
// import { DateRangePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function FacilityWiseFlaring() {
  const [facilityData, setFacilityData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const [materialState, setMaterialState] = useState("GAS");
  const [facilityWiseFlaringData, setFacilityWiseFlaringData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [chartData, setChartData] = useState({});
  const [error, setError] = useState(null);
  const [startDatePickerClass, setStartDatePickerClass] = useState('');
  const [endDatePickerClass, setEndDatePickerClass] = useState('');
  const [exportFilteredData, setExportFilteredData] = useState([]);


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFacilityChange = (e) => {
    setSelectedFacilityId(e.target.value);
  };

  const handleMaterialStateChange = (e) => {
    setMaterialState(e.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleFlaringReasonButtonClick = () => {
    if (startDate && endDate && selectedFacilityId !== '') {
      const apiUrl = `${API_BASE_URL}/reports/flaring-by-facility?fromDate=${startDate.toLocaleDateString()}&toDate=${endDate.toLocaleDateString()}&facilityId=${selectedFacilityId}&materialState=${materialState}`;
      setIsLoading(true);
      // Make API call using fetch or any other HTTP client library
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // Process the data returned by the API
          setFacilityWiseFlaringData(data.content);
          setFilteredData(data.content); // Initialize filtered data

          // Prepare chart data
          const labels = data.content.map(item => item.facilityName);
          const gasData = data.content.map(item => item.gas);

          setChartData({
            labels: labels,
            datasets: [
              {
                label: 'Gas Flaring (in units)',
                data: gasData,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
              }
            ]
          });

          setIsLoading(false);
        })
        .catch(error => {
          // Handle errors
          console.error('Error fetching data:', error);
          setError('Error fetching data');
          setIsLoading(false);
        });
    } else {
      // Handle invalid selection or missing data
      console.error('Please select valid options.');
    }
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = facilityWiseFlaringData.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(lowercasedSearch)
      )
    );

    // If filtered data is empty, add a placeholder row to ensure headers are displayed
    if (filtered.length === 0) {
      filtered.push({
        facilityName: '',
        materialState: '',
        gas: '',
        purge: '',
        pilot: '',
        leakage: '',
        fmsgas: '',
        unAccountedGas: '',
        meter: '',
        stackPurge: '',
        recovery: '',
        totalFlaring: '',
        mass: ''
      });
    }

    setFilteredData(filtered);

    const exportData = filtered.map(item => ({
      'Facility ID': item.facilityName,
      'Material State': item.materialState,
      'Flaring': formatWithIndianThousandsSeparatorForExportData(item.gas ? item.gas.toFixed(0) : item.gas),
      'Purge': formatWithIndianThousandsSeparatorForExportData(item.purge ? item.purge.toFixed(0) : item.purge),
      'Pilot': formatWithIndianThousandsSeparatorForExportData(item.pilot ? item.pilot.toFixed(0) : item.pilot),
      'Leakage': formatWithIndianThousandsSeparatorForExportData(item.leakage ? item.leakage.toFixed(0) : item.leakage),
      'FMS Flaring': formatWithIndianThousandsSeparatorForExportData(item.fmsgas ? item.fmsgas.toFixed(0) : item.fmsgas),
      'Unaccounted Flaring': formatWithIndianThousandsSeparatorForExportData(item.unAccountedGas ? item.unAccountedGas.toFixed(0) : item.unAccountedGas),
      'Meter': formatWithIndianThousandsSeparatorForExportData(item.meter ? item.meter.toFixed(0) : item.meter),
      'Stack Purge': formatWithIndianThousandsSeparatorForExportData(item.stackPurge ? item.stackPurge.toFixed(0) : item.stackPurge),
      'Recovery': formatWithIndianThousandsSeparatorForExportData(item.recovery ? item.recovery.toFixed(0) : item.recovery),
      'Total Flaring': formatWithIndianThousandsSeparatorForExportData(item.totalFlaring ? item.totalFlaring.toFixed(0) : item.totalFlaring),
      'Mass': formatWithIndianThousandsSeparatorForExportData(item.mass ? item.mass.toFixed(0) : item.mass),
    }));
    setExportFilteredData(exportData);

  }, [search, facilityWiseFlaringData]);

  const columns = [
    {
      name: 'Facility ID',
      selector: row => row.facilityName,
      sortable: true
    },
    {
      name: 'Material State',
      selector: row => row.materialState,
      sortable: true
    },
    {
      name: 'Flaring',
      selector: row => row.gas,
      cell: row => formatWithIndianThousandsSeparator(row.gas ? row.gas.toFixed(0) : row.gas),
      sortable: true
    },
    {
      name: 'Purge',
      selector: row => row.purge,
      cell: row => formatWithIndianThousandsSeparator(row.purge ? row.purge.toFixed(0) :  row.purge),
      sortable: true
    },
    {
      name: 'Pilot',
      selector: row => row.pilot,
      cell: row => formatWithIndianThousandsSeparator(row.pilot ? row.pilot.toFixed(0) : row.pilot),
      sortable: true
    },
    {
      name: 'Leakage',
      selector: row => row.leakage,
      cell: row => formatWithIndianThousandsSeparator(row.leakage ? row.leakage.toFixed(0) : row.leakage),
      sortable: true
    },
    {
      name: 'FMS Flaring',
      selector: row => row.fmsgas,
      cell: row => formatWithIndianThousandsSeparator(row.fmsgas ? row.fmsgas.toFixed(0) : row.fmsgas),
      sortable: true
    },
    {
      name: 'Unaccounted Flaring',
      selector: row => row.unAccountedGas,
      cell: row => formatWithIndianThousandsSeparator(row.unAccountedGas  ? row.unAccountedGas.toFixed(0) : row.unAccountedGas),
      sortable: true
    },
    {
      name: 'Meter',
      selector: row => row.meter,
      cell: row => formatWithIndianThousandsSeparator(row.meter ? row.meter.toFixed(0) : row.meter),
      sortable: true
    },
    {
      name: 'Stack Purge',
      selector: row => row.stackPurge,
      cell: row => formatWithIndianThousandsSeparator(row.stackPurge ? row.stackPurge.toFixed(0) : row.stackPurge),  
      sortable: true
    },
    {
      name: 'Recovery',
      selector: row => row.recovery,
      cell: row => formatWithIndianThousandsSeparator(row.recovery ? row.recovery.toFixed(0) : row.recovery),
      sortable: true
    },
    {
      name: 'Total Flaring',
      selector: row => row.totalFlaring,
      cell: row => formatWithIndianThousandsSeparator(row.totalFlaring ? row.totalFlaring.toFixed(0) : row.totalFlaring),
      sortable: true
    },
    {
      name: 'Mass',
      selector: row => row.mass,
      cell: row => formatWithIndianThousandsSeparator(row.mass ? row.mass.toFixed(0) : row.mass),
      sortable: true
    },
  ];

  const customStyles = {
    rows: {
        style: {
            minHeight: '30px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};

  const fileName = `FMS_Facility_Wise_Flaring${new Date().toLocaleDateString()}`;

  const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
  const handleStartDateBlur = () => setStartDatePickerClass('');
  const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
  const handleEndDateBlur = () => setEndDatePickerClass('');

  const headerData = [
    { label: 'Material State', value: materialState || 'Gas' },
    { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
    { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
  ];

  const title = "Facility Flaring Report";

  return (
    <div className="main-content">
      <div className='breadcrumb mt-3'>
        <h1>Facility Flaring</h1>
        <ul>
          <li>Reports</li>
          <li>Flaring</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      <div className="breadcrumb mt-3">
        <div className='row'>
          <div className='col-md-3'>
            <label>Select Material State</label>
            <select
              name='materialState'
              className='form-control'
              value={materialState}
              onChange={handleMaterialStateChange}
            >
              <option value={'GAS'}>Gas</option>
              <option value={'LIQUID'}>Liquid</option>
            </select>
          </div>
          <div className='col-md-3'>
            <label>Start Date</label>
            <div className={startDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleStartDateFocus}
                onBlur={handleStartDateBlur}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <label>End Date</label>
            <div className={endDatePickerClass}>
              <DatePicker
                className='form-control'
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={handleEndDateFocus}
                onBlur={handleEndDateBlur}
              />
            </div>
          </div>
          <div className='col-md-3 generate_report_button'>
            <button className='btn btn-primary' onClick={handleFlaringReasonButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
          </div>
        </div>
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}

      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3"> Facilitywise Flaring  </h4> */}
              <div className='row'>
                <div className='col-md-10'>
                {exportFilteredData.length > 0 && (
                      <ExportComponent data={exportFilteredData} columns={columns} fileName={fileName} headerData={headerData} title={title}/>
                    )}
                </div>
                <div className='col-md-2'>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control mb-3"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                  <>
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      customStyles={customStyles}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </>

                )}
              </div>
              <div className='col-md-12 mt-4'>
                {!isLoading && !error && Object.keys(chartData).length > 0 && (
                  <div>
                    <Bar data={chartData} />
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
