import React, { useState, useEffect } from 'react';
import jsonData from '../../assets/json/import-property-map.json';

const EntitySelector = ({ selectedEntity, onEntityChange }) => {
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    // Set entities from the imported JSON data
    setEntities(Object.keys(jsonData));
  }, []);

  const handleEntityChange = (event) => {
    onEntityChange(event.target.value);
  };

  return (
    <div className="form-group">
      <label>Select Source Table to Import Data</label>
      <select className="form-control" value={selectedEntity || ''} onChange={handleEntityChange}>
        <option value="">Select source</option>
        {entities.map(entity => (
          <option key={entity} value={entity}>{entity}</option>
        ))}
      </select>
    </div>
  );
};

export default EntitySelector;
