import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, FormFeedback, Button, Alert } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getOnboardings, saveOnboarding } from './services/EmsDefaultsService'; // Importing service methods

const SystemDefaults = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    systemUnitsId: '',
    gasUnitsId: '',
    liquidUnitsId: '',
    massUnitsId: '',
    smtpServerName: '',
    rerunInterface: '',
    rerun: '',
    rerunDate: null // Using Date object
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [model, setModel] = useState(null);
  const [lists, setLists] = useState({
    gasUOMList: [],
    systemUnitsList: [],
    liquidUOMList: [],
    massUOMList: [],
    reRunInterfaceList: []
  });

  const rerunOptions = [
    { name: 'TRUE', value: true },
    { name: 'FALSE', value: false }
  ];

  useEffect(() => {
    getOnboardingData();
  }, []);

  const getOnboardingData = async () => {
    try {
      const res = await getOnboardings();
      setLists({
        gasUOMList: res.gasUOMList,
        systemUnitsList: res.systemUnitsList,
        liquidUOMList: res.liquidUOMList,
        massUOMList: res.massUOMList,
        reRunInterfaceList: res.dahsServerList
      });
      const onboarding = res.onboarding;
      setFormData({
        ...onboarding,
        rerunDate: new Date(onboarding.rerunDate)
      });
      setModel(onboarding);
    } catch (error) {
      console.error('Error fetching onboarding data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, rerunDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (doesSaveIncludeRun()) {
        const confirmed = window.confirm('Saving with Run & Run Date will run calculations again! Are you sure you want to proceed?');
        if (confirmed) {
          await proceedAndSave();
        }
      } else {
        await proceedAndSave();
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = true;
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const proceedAndSave = async () => {
    try {
      const res = await saveOnboarding(formData);
      setModel(res);
    } catch (error) {
      console.error('Error saving onboarding data:', error);
    }
  };

  const doesSaveIncludeRun = () => {
    return model?.rerun && model?.rerunDate;
  };

  return (
    <div className='main-content'>
      <div className='breadcrumb mt-2'>
        <h1>Company Settings</h1>
        <ul>
          <li>System Configuration</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="card">
          <div className="card-header">
            {/* <h4>Company Settings</h4> */}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="companyName">Company Name</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    invalid={!!validationErrors.companyName}
                    required
                  />
                  <FormFeedback>This field is required.</FormFeedback>
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="systemUnitsId">System Units</Label>
                  <Input
                    type="select"
                    name="systemUnitsId"
                    id="systemUnitsId"
                    value={formData.systemUnitsId}
                    onChange={handleChange}
                    invalid={!!validationErrors.systemUnitsId}
                    required
                  >
                    <option value="">Select System Unit</option>
                    {lists.systemUnitsList.map((unit) => (
                      <option key={unit.key} value={unit.key}>
                        {unit.value}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>This field is required.</FormFeedback>
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="gasUnitsId">Gas Default Units</Label>
                  <Input
                    type="select"
                    name="gasUnitsId"
                    id="gasUnitsId"
                    value={formData.gasUnitsId}
                    onChange={handleChange}
                    invalid={!!validationErrors.gasUnitsId}
                    required
                  >
                    <option value="">Select Gas Default Unit</option>
                    {lists.gasUOMList.map((unit) => (
                      <option key={unit.uomId} value={unit.uomId}>
                        {unit.description}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>This field is required.</FormFeedback>
                </FormGroup>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="liquidUnitsId">Liquid Default Units</Label>
                  <Input
                    type="select"
                    name="liquidUnitsId"
                    id="liquidUnitsId"
                    value={formData.liquidUnitsId}
                    onChange={handleChange}
                    invalid={!!validationErrors.liquidUnitsId}
                    required
                  >
                    <option value="">Select Liquid Default Unit</option>
                    {lists.liquidUOMList.map((unit) => (
                      <option key={unit.uomId} value={unit.uomId}>
                        {unit.description}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>This field is required.</FormFeedback>
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="massUnitsId">Mass Default Units</Label>
                  <Input
                    type="select"
                    name="massUnitsId"
                    id="massUnitsId"
                    value={formData.massUnitsId}
                    onChange={handleChange}
                    invalid={!!validationErrors.massUnitsId}
                    required
                  >
                    <option value="">Select Mass Default Unit</option>
                    {lists.massUOMList.map((unit) => (
                      <option key={unit.uomId} value={unit.uomId}>
                        {unit.description}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>This field is required.</FormFeedback>
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="smtpServerName">SMTP Server Name</Label>
                  <Input
                    type="text"
                    name="smtpServerName"
                    id="smtpServerName"
                    value={formData.smtpServerName}
                    onChange={handleChange}
                    invalid={!!validationErrors.smtpServerName}
                    required
                  />
                  <FormFeedback>This field is required.</FormFeedback>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-header">
            <h4>Re-run Settings</h4>
          </div>
          <div className="card-body">
            {model?.rerunStatus === 'Completed' ? (
              <>
                <div className="row">
                  <div className="col-sm-4">
                    <FormGroup>
                      <Label for="rerunInterface">Re-run Interface</Label>
                      <Input
                        type="select"
                        name="rerunInterface"
                        id="rerunInterface"
                        value={formData.rerunInterface}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Select Rerun Interface</option>
                        {lists.reRunInterfaceList.map((interfaceName, index) => (
                          <option key={index} value={interfaceName}>
                            {interfaceName}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup>
                      <Label for="rerunDate">Re-run Date</Label>
                      <div className="input-group">
                        <DatePicker
                          selected={formData.rerunDate}
                          onChange={handleDateChange}
                          className="form-control"
                          id="rerunDate"
                          dateFormat="MM/dd/yyyy"
                        />
                        <div className="input-group-append">
                          <Button
                            outline
                            color="secondary"
                            onClick={() => document.getElementById('rerunDate').focus()}
                          >
                            <i className="lnr lnr-calendar-full"></i>
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup>
                      <Label for="rerun">Re-run Status</Label>
                      <Input
                        type="select"
                        name="rerun"
                        id="rerun"
                        value={formData.rerun}
                        onChange={handleChange}
                      >
                        {rerunOptions.map((opt) => (
                          <option key={opt.name} value={opt.name}>
                            {opt.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                </div>
              </>
            ) : (
              <Alert color="warning">
                Your Re-run in progress for {new Date(model?.rerunDate).toLocaleDateString()}
              </Alert>
            )}
          </div>
        </div>

        <div className="mt-4">
          <Button type="submit" color="primary">Save</Button>
          <Button type="button" color="secondary" className="ml-3">Cancel</Button>
        </div>
      </form>
    </div>
  );
};

export default SystemDefaults;
