import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getCoefficientById,
  saveCoefficient,
} from '../services/PipeService';
import { API_BASE_URL } from '../../../../../config';

const AddFlowCoefficient = () => {
  const [formGroup, setFormGroup] = useState({
    manApiType: '',
    manValveClass: '',
    manValveType: '',
    manPort: '',
    manSizeUom: '',
    manValveSize: '',
    manCvValue: '',
    status: 'A',
  });
  const [statusOptions] = useState(['A', 'D']);
  const [errors, setErrors] = useState({});
  const [searchParams] = useSearchParams();
  const isEdit = !!searchParams.get('coefficientId');
  const navigate = useNavigate();

  useEffect(() => {
    const coefficientId = searchParams.get('coefficientId');
    if (coefficientId) {
      getCoefficientById(coefficientId).then((data) => {
        setFormGroup(data);
      }).catch(error => {
        console.error('Error fetching coefficient:', error);
        toast.error('Error fetching coefficient');
      });
    }
  }, [searchParams]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.manApiType) {
      newErrors.manApiType = 'Man API Type is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await saveCoefficient(formGroup, isEdit);

      if (!response.isSuccess) {
        throw new Error(response.message || 'Error saving Pipe Fitting');
      }

      toast.success('Coefficient saved successfully.');
      setTimeout(() => {
        navigate('/admin/configurations/pipe/list-coefficient');
      }, 1000);
    } catch (error) {
      console.error('Failed to save pipe fitting:', error);
      toast.error(error.message || 'Error saving Pipe Fitting');
    }
  };

  return (
    <div>
      {/* <h4>{isEdit ? 'EDIT COEFFICIENT' : 'ADD COEFFICIENT'}</h4> */}
      <div className='breadcrumb mt-2'>
                <h1>{isEdit ? 'Edit Coefficient' : 'Add Coefficient'}</h1>
                <ul>
                    <li>Master Data Management</li>
                    <li>Pipes</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Coefficient Info</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="manApiType">Man API Type</label>
                <input
                  type="text"
                  className="form-control"
                  id="manApiType"
                  name="manApiType"
                  value={formGroup.manApiType}
                  onChange={handleChange}
                  required
                />
                {errors.manApiType && (
                  <div className="invalid-feedback d-block">
                    {errors.manApiType}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="manValveClass">Man Valve Class</label>
                <input
                  type="text"
                  className="form-control"
                  id="manValveClass"
                  name="manValveClass"
                  value={formGroup.manValveClass}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="manValveType">Man Valve Type</label>
                <input
                  type="text"
                  className="form-control"
                  id="manValveType"
                  name="manValveType"
                  value={formGroup.manValveType}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="manPort">Man Port</label>
                <input
                  type="text"
                  className="form-control"
                  id="manPort"
                  name="manPort"
                  value={formGroup.manPort}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="manValveSize">Man Valve Size</label>
                <input
                  type="number"
                  className="form-control"
                  id="manValveSize"
                  name="manValveSize"
                  value={formGroup.manValveSize}
                  onChange={handleChange}
                />
                {errors.manValveSize && (
                  <div className="invalid-feedback d-block">
                    {errors.manValveSize}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="manSizeUom">Man Size UoM</label>
                <input
                  type="text"
                  className="form-control"
                  id="manSizeUom"
                  name="manSizeUom"
                  value={formGroup.manSizeUom}
                  onChange={handleChange}
                />
                {errors.manSizeUom && (
                  <div className="invalid-feedback d-block">
                    {errors.manSizeUom}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="manCvValue">Man CV Value</label>
                <input
                  type="number"
                  className="form-control"
                  id="manCvValue"
                  name="manCvValue"
                  value={formGroup.manCvValue}
                  onChange={handleChange}
                />
                {errors.manCvValue && (
                  <div className="invalid-feedback d-block">
                    {errors.manCvValue}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="status">Operational Status</label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  value={formGroup.status}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Operational Status</option>
                  {statusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                {errors.status && (
                  <div className="invalid-feedback d-block">
                    {errors.status}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary float-right">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary float-right mr-3"
              onClick={() => navigate('/admin/configurations/pipe/list-coefficient')}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddFlowCoefficient;
