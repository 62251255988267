import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import EmsService from '../services/EmsService';
import { DateContext } from '../../../../../contexts/DateContext';
import moment from 'moment';
import './EmissionsSummary.css';

const EmissionsSummary = ({ areaId, facilityId }) => {
  const { selectedDate } = useContext(DateContext);
  const [pollutantEmissions, setPollutantEmissions] = useState([]);
  const [activePollutants, setActivePollutants] = useState([]);
  const [lineChartData, setLineChartData] = useState([
    {
      label: 'Co',
      fill: true,
      lineTension: 0.0,
      pointRadius: 0,
      data: [],
      backgroundColor: '#fd49a0', // Pink
    },
    {
      label: 'Nox',
      fill: true,
      lineTension: 0.0,
      pointRadius: 0,
      data: [],
      backgroundColor: 'rgba(26, 179, 148)',
    },
    {
      label: 'PM',
      fill: true,
      lineTension: 0.0,
      pointRadius: 0,
      data: [],
      backgroundColor: 'rgba(212, 239, 107)',
    },
    {
      label: 'Sox',
      fill: true,
      lineTension: 0.0,
      pointRadius: 0,
      data: [],
      backgroundColor: '#5c038c',
    },
    {
      label: 'Voc',
      fill: true,
      lineTension: 0.0,
      pointRadius: 0,
      data: [],
      backgroundColor: 'rgba(116, 168, 252)',
    },
  ]);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [showNoDataText, setShowNoDataText] = useState(false);

  useEffect(() => {
    if (selectedDate) {
      const fromDate = moment(selectedDate).format('YYYY-MM-DD');
      const toDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
      getChartData(fromDate, toDate);
    }
  }, [selectedDate]);

  const getChartData = async (fromDate, toDate) => {
    try {
      const [pollutantsResp, emissionsResp] = await Promise.all([
        EmsService.getActivePollutants(),
        EmsService.getActualEmissions(areaId, facilityId, fromDate, toDate),
      ]);
      setActivePollutants(pollutantsResp.data);
      setPollutantEmissions(emissionsResp.data);
      setChartData(emissionsResp.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const setChartData = (emissions) => {
    if (!emissions || emissions.length === 0) {
      setShowNoDataText(true);
      return;
    }

    const newLineChartData = lineChartData.map((dataset) => ({ ...dataset, data: [] }));
    const newLineChartLabels = [];

    emissions.forEach((e) => {
      newLineChartLabels.push(moment(e.day).format('DD'));
      newLineChartData[0].data.push(e.Co.toFixed(2));
      newLineChartData[1].data.push(e.Nox.toFixed(2));
      newLineChartData[2].data.push(e.PM.toFixed(2));
      newLineChartData[3].data.push(e.Sox.toFixed(2));
      newLineChartData[4].data.push(e.VOC.toFixed(2));
    });

    setLineChartData(newLineChartData);
    setLineChartLabels(newLineChartLabels);
    setShowNoDataText(false);
  };

  const lineChartOptions = {
    legend: {
      display: true,
      labels: {
        fontColor: 'red',
      },
    },
    scales: {
      x: {
        scaleLabel: {
          fontColor: 'blue',
        },
        grid: {
          display: false,
        },
        ticks: {
          fontColor: 'yellow',
          display: true,
          autoSkip: true,
          maxTicksLimit: 8,
          maxRotation: 0,
        },
      },
      y: {
        ticks: {
          fontColor: 'red',
          callback: function(value) {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(0) + 'B'; // Show in Billions
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(0) + 'M'; // Show in Millions
            } else if (value >= 1000) {
              return (value / 1000).toFixed(0) + 'K'; // Show in Thousands
            } else {
              return value; // Show as is
            }
          }
        },
        grid: {
          display: false,
        },
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value + ' kg'; // Replace 'kg' with your units
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  

  return (
    <div className="emission-summary">
      <div className='card'>
        <div className='card-header'>
        Hourly Emission Trends
        </div>
      <div className='card-body'>
      {showNoDataText ? (
        <div className="alert alert-info">No data for emissions summary for selected date!</div>
      ) : (
        <div className="emission-summary-chart">
          <Line data={{ labels: lineChartLabels, datasets: lineChartData }} options={lineChartOptions} height={400} />
        </div>
      )}
    </div>
    </div>
    </div>
  );
};

export default EmissionsSummary;