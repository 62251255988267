import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';

const AddLiquidComposition = () => {
  const [formGroup, setFormGroup] = useState({
    compositionId: '',
    fuel: '',
    density: '',
    densityUom: '',
    hhv: '',
    hhvUom: '',
    lhv: '',
    lhvUom: '',
    carbonWt: '',
    status: 'A'
  });
  const [statusOptions] = useState(['A', 'D']);
  const [lstUom, setLstUom] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  // const { compositionId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = !!searchParams.get("compositionId");

  useEffect(() => {
    fetch(`${API_BASE_URL}/device/uoms`)
      .then(response => response.json())
      .then(data => setLstUom(data))
      .catch(error => console.error('Error fetching UOMs:', error));

    if (isEdit) {
      fetch(`${API_BASE_URL}/composition/liquid-composition/${searchParams.get("compositionId")}`)
        .then(response => response.json())
        .then(data => setFormGroup(data))
        .catch(error => console.error('Error fetching liquid composition:', error));
    }
  }, [searchParams.get("compositionId"), isEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.compositionId) {
      newErrors.compositionId = 'Composition Id is required.';
    }
    if (!formGroup.status) {
      newErrors.status = 'Status is required.';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/${isEdit ? 'composition/liquid-composition/update' : 'composition/liquid-composition/save'}`, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formGroup)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Liquid Composition ${isEdit ? 'updated' : 'added'} successfully.`);
      setTimeout(() => {
        navigate('/admin/configurations/composition/list-liquid-composition');
      }, 1000);
    } catch (error) {
      console.error('Failed to save liquid composition:', error);
      toast.error('Error saving liquid composition');
    }
  };

  return (
    <div>
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Properties-Solids & Liquids' : 'Add Properties-Solids & Liquids'}</h1>
        <ul>
          <li>System Configuration</li>
          <li>Composition</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Liquid Composition Details</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="compositionId">Composition ID</label>
                <input type="text" className={`form-control ${errors.compositionId ? 'is-invalid' : ''}`} id="compositionId" name="compositionId" value={formGroup.compositionId} onChange={handleChange} readOnly={isEdit} />
                {errors.compositionId && <div className="invalid-feedback d-block">{errors.compositionId}</div>}
              </div>
              <div className="col-sm-4">
                <label htmlFor="fuel">Fuel</label>
                <input type="text" className="form-control" id="fuel" name="fuel" value={formGroup.fuel} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="density">Density</label>
                <input type="number" className="form-control" id="density" name="density" value={formGroup.density} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="densityUom">Density UoM</label>
                <input type="text" className="form-control" id="densityUom" name="densityUom" value={formGroup.densityUom} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="hhv">High Heating Value</label>
                <input type="number" className="form-control" id="hhv" name="hhv" value={formGroup.hhv} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="hhvUom">HHV UoM</label>
                <select className="form-control" id="hhvUom" name="hhvUom" value={formGroup.hhvUom} onChange={handleChange}>
                  <option value="">Select HHV UoM</option>
                  {lstUom.map(uom => (
                    <option key={uom.uomId} value={uom.uomId}>{uom.description}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-header">Units</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="lhv">Low Heating Value</label>
                <input type="number" className="form-control" id="lhv" name="lhv" value={formGroup.lhv} onChange={handleChange} />
              </div>
              <div className="col-sm-4">
                <label htmlFor="lhvUom">LHV UoM</label>
                <select className="form-control" id="lhvUom" name="lhvUom" value={formGroup.lhvUom} onChange={handleChange}>
                  <option value="">Select LHV UoM</option>
                  {lstUom.map(uom => (
                    <option key={uom.uomId} value={uom.uomId}>{uom.description}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4">
                <label htmlFor="carbonWt">Carbon Wt</label>
                <input type="number" className="form-control" id="carbonWt" name="carbonWt" value={formGroup.carbonWt} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group row mt-2">
              <div className="col-sm-4">
                <label htmlFor="status">Operational Status</label>
                <select className={`form-control ${errors.status ? 'is-invalid' : ''}`} id="status" name="status" value={formGroup.status} onChange={handleChange}>
                  <option value="">Select Operational Status</option>
                  {statusOptions.map(status => (
                    <option key={status} value={status}>{status}</option>
                  ))}
                </select>
                {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mt-4">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary float-right">Save</button>
            <button type="button" className="btn btn-secondary float-right mr-3" onClick={() => navigate('/admin/configurations/composition/list-liquid-composition')}>Cancel</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddLiquidComposition;
