import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API_BASE_URL } from '../../../../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddGwpPotential = ({ id, closeModal, showModal }) => {
    const [gwpPotential, setGwpPotential] = useState({
        gwpCompound: '',
        year: '',
        ipccValue: '',
        standard: '',
        status: 'A'
    });
    const [ghgPollutants, setGhgPollutants] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        fetchGhgPollutants();
        if (id) {
            fetchGwpPotentialData(id);
            setIsEdit(true);
        } else {
            resetForm();
        }
    }, [id]);

    const fetchGhgPollutants = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/composition/comp-details/GHG`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setGhgPollutants(data);
        } catch (error) {
            console.error('Failed to fetch GHG pollutants:', error);
        }
    };

    const fetchGwpPotentialData = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/ghg-master/gwp-potential/${id}`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setGwpPotential({
                id: data.id,
                gwpCompound: data.gwpCompound,
                year: data.year,
                ipccValue: data.ipccValue,
                standard: data.standard,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch GWP potential data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGwpPotential(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEdit ? `${API_BASE_URL}/ghg-master/gwp-potential/update` : `${API_BASE_URL}/ghg-master/gwp-potential/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(gwpPotential)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`GWP Potential ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            closeModal();
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} GWP potential:`, error);
        }
    };

    const resetForm = () => {
        setGwpPotential({
            gwpCompound: '',
            year: '',
            ipccValue: '',
            standard: '',
            status: 'A'
        });
        setIsEdit(false);
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit GWP Potential' : 'Add GWP Potential'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="gwpCompound">
                        <Form.Label>GWP Compound</Form.Label>
                        <Form.Control
                            as="select"
                            name="gwpCompound"
                            value={gwpPotential.gwpCompound}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select GWP Compound</option>
                            {ghgPollutants.map((pollutant) => (
                                <option key={pollutant.eqptCode} value={pollutant.eqptCode}>
                                    {pollutant.eqptCode}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="year">
                        <Form.Label>Year</Form.Label>
                        <Form.Control
                            type="number"
                            name="year"
                            value={gwpPotential.year}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="ipccValue">
                        <Form.Label>IPCC Value</Form.Label>
                        <Form.Control
                            type="number"
                            name="ipccValue"
                            value={gwpPotential.ipccValue}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="standard">
                        <Form.Label>Standard</Form.Label>
                        <Form.Control
                            type="text"
                            name="standard"
                            value={gwpPotential.standard}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="status"
                            value={gwpPotential.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="A">A</option>
                            <option value="D">D</option>
                        </Form.Control>
                    </Form.Group>
                   <div className='mt-2 float-right'>
                   <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="ml-2">
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                   </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddGwpPotential;
