import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../config';
import axios from 'axios';
import TransactionService from '../services/TransactionService';
import { Button } from 'react-bootstrap';

const AddManualValveForm = ({ setShowModal, fetchManualValvesData }) => {
  const [formData, setFormData] = useState({
    facilityId: '',
    deviceId: '',
    transactionDateTime: '',
    valveOpenedOn: '',
    valveClosedOn: '',
    valveOpenPercentage: '',
    lockedOn: '',
    reason: '',
    status: '',
    userComments: ''
  });

  const [allFacilities, setAllFacilities] = useState([]);
  const [allDeviceTypes, setAllDeviceTypes] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchFacilityDetails();
    fetchAllReasons();
    fetchAllStatus();
  }, []);

  const fetchFacilityDetails = async () => {
    try {
      const response = await TransactionService.getFaclityDetails('AMMNET');
      setAllFacilities(response.data);
    } catch (error) {
      console.error('Failed to fetch facility details:', error);
    }
  };

  const handleFacilityChange = async (e) => {
    const facilityId = e.target.value;
    setFormData({ ...formData, facilityId });
    try {
      const response = await TransactionService.getDeviceDetailsForManualValves(facilityId, 'MOVMANUAL');
      setAllDeviceTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch device details:', error);
    }
  };

  const fetchAllReasons = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/device/reasons`);
      setReasons(response.data);
    } catch (error) {
      console.error('Failed to fetch reasons:', error);
    }
  };

  const fetchAllStatus = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/master/status`);
      setAllStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch status:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.facilityId) newErrors.facilityId = 'Facility is required';
    if (!formData.deviceId) newErrors.deviceId = 'Device is required';
    if (!formData.transactionDateTime) newErrors.transactionDateTime = 'Transaction Date is required';
    if (!formData.valveOpenedOn) newErrors.valveOpenedOn = 'Valve Opens On Date is required';
    if (!formData.valveClosedOn) newErrors.valveClosedOn = 'Valve Closes On Date is required';
    if (!formData.valveOpenPercentage) newErrors.valveOpenPercentage = 'Percentage is required';
    if (!formData.lockedOn) newErrors.lockedOn = 'Locked On Date is required';
    if (!formData.reason) newErrors.reason = 'Reason is required';
    if (!formData.status) newErrors.status = 'Status is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const result = {
      SiteId: formData.facilityId,
      DeviceId: formData.deviceId,
      TransactionDateTime: new Date(formData.transactionDateTime).toISOString(),
      ValveOpenedOn: new Date(formData.valveOpenedOn).toISOString(),
      ValveClosedOn: new Date(formData.valveClosedOn).toISOString(),
      ValveOpenPercentage: formData.valveOpenPercentage,
      LockedOn: new Date(formData.lockedOn).toISOString(),
      ReasonId: formData.reason,
      Status: formData.status,
      UserComments: formData.userComments
    };

    try {
      await TransactionService.addManualValvesData(result);
      fetchManualValvesData();
      setShowModal(false);
    } catch (error) {
      console.error('Failed to add manual valve:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Facility</label>
            <select
              className={`form-control ${errors.facilityId ? 'is-invalid' : ''}`}
              name="facilityId"
              value={formData.facilityId}
              onChange={handleFacilityChange}
            >
              <option value="">Select Facility</option>
              {allFacilities.map(facility => (
                <option key={facility.facilityId} value={facility.facilityId}>
                  {facility.facilityName}
                </option>
              ))}
            </select>
            {errors.facilityId && <div className="invalid-feedback">{errors.facilityId}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Device</label>
            <select
              className={`form-control ${errors.deviceId ? 'is-invalid' : ''}`}
              name="deviceId"
              value={formData.deviceId}
              onChange={handleInputChange}
              disabled={!formData.facilityId}
            >
              <option value="">Select Source</option>
              {allDeviceTypes.map(device => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.deviceId} ({device.deviceName})
                </option>
              ))}
            </select>
            {errors.deviceId && <div className="invalid-feedback">{errors.deviceId}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Transaction Date</label>
            <input
              type="datetime-local"
              className={`form-control ${errors.transactionDateTime ? 'is-invalid' : ''}`}
              name="transactionDateTime"
              value={formData.transactionDateTime}
              onChange={(e) => handleDateChange('transactionDateTime', e.target.value)}
            />
            {errors.transactionDateTime && <div className="invalid-feedback">{errors.transactionDateTime}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Valve Opens On</label>
            <input
              type="datetime-local"
              className={`form-control ${errors.valveOpenedOn ? 'is-invalid' : ''}`}
              name="valveOpenedOn"
              value={formData.valveOpenedOn}
              onChange={(e) => handleDateChange('valveOpenedOn', e.target.value)}
            />
            {errors.valveOpenedOn && <div className="invalid-feedback">{errors.valveOpenedOn}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Valve Closes On</label>
            <input
              type="datetime-local"
              className={`form-control ${errors.valveClosedOn ? 'is-invalid' : ''}`}
              name="valveClosedOn"
              value={formData.valveClosedOn}
              onChange={(e) => handleDateChange('valveClosedOn', e.target.value)}
            />
            {errors.valveClosedOn && <div className="invalid-feedback">{errors.valveClosedOn}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Percentage</label>
            <input
              type="number"
              className={`form-control ${errors.valveOpenPercentage ? 'is-invalid' : ''}`}
              name="valveOpenPercentage"
              value={formData.valveOpenPercentage}
              onChange={handleInputChange}
              min="1"
              max="100"
            />
            {errors.valveOpenPercentage && <div className="invalid-feedback">{errors.valveOpenPercentage}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Locked On</label>
            <input
              type="datetime-local"
              className={`form-control ${errors.lockedOn ? 'is-invalid' : ''}`}
              name="lockedOn"
              value={formData.lockedOn}
              onChange={(e) => handleDateChange('lockedOn', e.target.value)}
            />
            {errors.lockedOn && <div className="invalid-feedback">{errors.lockedOn}</div>}
          </div>
        </div>
        <div className='col-md-6'>
          <div className="form-group">
            <label>Reason</label>
            <select
              className={`form-control ${errors.reason ? 'is-invalid' : ''}`}
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
            >
              <option value="">Select Reason</option>
              {reasons.map(reason => (
                <option key={reason.reasonId} value={reason.reasonId}>
                  {reason.reasonDescription}
                </option>
              ))}
            </select>
            {errors.reason && <div className="invalid-feedback">{errors.reason}</div>}
          </div>
        </div>
        <div className='col-md-12'>
          <div className="form-group">
            <label>Status</label>
            <select
              className={`form-control ${errors.status ? 'is-invalid' : ''}`}
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value="">Select Status</option>
              {allStatus.map(status => (
                <option key={status.statusId} value={status.statusId}>
                  {status.statusDescription}
                </option>
              ))}
            </select>
            {errors.status && <div className="invalid-feedback">{errors.status}</div>}
          </div>
        </div>
        <div className='col-md-12'>
          <div className="form-group">
            <label>Comments</label>
            <textarea
              className="form-control"
              name="userComments"
              rows="2"
              value={formData.userComments}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <Button type="submit" className="btn btn-primary float-right">Save</Button>
      <Button type="button" className="btn btn-secondary float-right mr-3" onClick={() => setShowModal(false)}>Cancel</Button>
    </form>
  );
};

export default AddManualValveForm;
