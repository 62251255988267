// src/services/fmsDashboardService.js

import axios from 'axios';
import moment from 'moment';
import { API_BASE_URL } from '../../../../../config';

const baseURL = `${API_BASE_URL}/fmsdashboard`;

const fmsDashboardService = {
  getKPIPredictionByLayerAndTargetType: (year, layer, targetType) => {
    const url = `${baseURL}/predictions/year/${year}/layer/${layer}/layerCode/${targetType}`;
    console.log("url calling",url)
    return axios.get(url);
  },
  getFacilityFlaringSummaries: (fromDate, facilityType, facilityName) => {
    const url = `${baseURL}/flaringsummaries/fromDate/${moment(fromDate).format('MM-DD-YYYY')}/facilityTypeId/${facilityType}/facilityName/${facilityName}`;
    console.log('url',url)
    return axios.get(url);
  },
  getDailyFlarings: (fromDate, facilityType, facilityName) => {
    const url = `${baseURL}/dailyflarings/fromDate/${moment(fromDate).format('MM-DD-YYYY')}/facilityTypeId/${facilityType}/facilityName/${facilityName}`;
    return axios.get(url);
  },
  getFlaringReasons: (fromDate, facilityType, facilityName) => {
    const url = `${baseURL}/flaringreasons/fromDate/${moment(fromDate).format('MM-DD-YYYY')}/facilityTypeId/${facilityType}/facilityName/${facilityName}`;
    return axios.get(url);
  },
  getFMSDashboardStats: (fromDate, facilityType, facilityName) => {
    const url = `${baseURL}/fmsdashboardstats/fromDate/${moment(fromDate).format('MM-DD-YYYY')}/facilityTypeId/${facilityType}/facilityName/${facilityName}`;
    return axios.get(url);
  }
};

export default fmsDashboardService;
