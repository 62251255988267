import React, { useEffect, useRef } from 'react';
import anychart from 'anychart';
import { formatNumberDashboard } from '../../../../common/FormatNumber';
const GhgPollutantSunburstAnyChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && data.length > 0) {
      renderSunburstChart(data);
    }
  }, [data]);

  const renderSunburstChart = (data) => {
    // Clear any previous chart
    if (chartRef.current) {
      chartRef.current.innerHTML = '';
    }

    // Convert data to AnyChart format
    const chartData = convertToSunburstData(data);

    console.log("chartdata",chartData);

    // Create the sunburst chart
    const chart = anychart.sunburst(chartData, "as-tree");

    // Set the container for the chart
    chart.container(chartRef.current);

    // Set the inner radius to create the inner circle
    chart.innerRadius("50%");

    // Set up colors for the segments
    chart.palette(["#2af598", "#7F00FF", "#FFA500", "green", "yellow"]);

    // Configure the labels
    chart.labels().useHtml(true);
    chart.labels().fontSize(14);
    chart.labels().format("<b>{%name}</b>");

    // Configure the tooltip
    chart.tooltip().useHtml(true);
    chart.tooltip().fontSize(14);
    chart.tooltip().format(function() {
      const context = this;
      const { name, value } = context;
    
      if (name === 'Co2e') {
        const totalCo2e = data.reduce((acc, curr) => acc + (curr.pollutants.find(p => p.key === 'Co2e')?.value || 0), 0);
        return `
          <div>Total Co2e: <b>${formatNumberDashboard(totalCo2e.toFixed(0))}</b></div>
        `;
      } else if (name.includes('Scope 1')) {
        const scop1Co2e = data.reduce((acc, curr) => acc + (curr.pollutants.find(p => p.key === 'Co2e')?.value || 0), 0);
        return `
          <div>Scope 1 Co2e: <b>${formatNumberDashboard(scop1Co2e.toFixed(0))}</b></div>
        `;
      } else {
        return `Reason: <b>${name}</b><br>Value: <b>${formatNumberDashboard(value)}</b>`;
      }
    });

    // Draw the chart
    chart.draw();

    // Add a central label manually
    const centerLabel = chart.label();
    centerLabel
      .text(`${formatNumberDashboard(data.reduce((acc, curr) => acc + curr.pollutants.find(p => p.key === 'Co2e')?.value || 0, 0).toFixed(0))}`)
      .fontColor("#000000")
      .fontSize(10)
      .fontWeight(800)
      .hAlign("center")
      .vAlign("middle")
      .anchor("center")
      .position("center")
      .useHtml(true);
  };

  // const convertToSunburstData = (data) => {
  //   // Calculate total Co2e and structure the data
  //   const groupedByScope = data.reduce((acc, item) => {
  //     const { scope, ghgMethodId } = item;
  
  //     if (!acc[scope]) {
  //       acc[scope] = [];
  //     }
  
  //     acc[scope].push({
  //       name: ` ${ghgMethodId || 'No Method'}`,
  //       value: item.pollutants.find(p => p.key === 'Co2e')?.value || 0
  //     });
  
  //     return acc;
  //   }, {});
  
  //   // Structure data for AnyChart (removing the 'Co2e' parent)
  //   const chartData = Object.keys(groupedByScope).map(scope => ({
  //     name: `Scope ${scope}`,
  //     children: groupedByScope[scope]
  //   }));
  
  //   return chartData;
  // };

  const convertToSunburstData = (data) => {
    // Calculate total Co2e and structure the data
    const groupedByScope = data.reduce((acc, item) => {
      const { scope, ghgMethodId } = item;
  
      if (!acc[scope]) {
        acc[scope] = [];
      }
  
      acc[scope].push({
        name: ` ${ghgMethodId || 'No Method'}`,
        value: item.pollutants.find(p => p.key === 'Co2e')?.value || 0
      });
  
      return acc;
    }, {});
  
    // Structure data for AnyChart (removing the 'Co2e' parent)
    const chartData = Object.keys(groupedByScope).map(scope => ({
      name: `Scope ${scope}`,
      children: groupedByScope[scope]
    }));
  
    return chartData;
  };
  

  return (
    <div className="ghg-pollutant-sunburst">
      {data.length === 0 ? (
        <div className="alert alert-info">No data available for the selected scope!</div>
      ) : (
        <div ref={chartRef} style={{ width: '100%', height: '300px' }}></div>
      )}
    </div>
  );
};

export default GhgPollutantSunburstAnyChart;

// import React, { useEffect, useRef } from 'react';
// import * as am5 from "@amcharts/amcharts5";
// import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// const GhgPollutantSunburst = ({ data }) => {
//   const chartRef = useRef(null);
//   const rootRef = useRef(null);

//   useEffect(() => {
//     if (data && data.length > 0) {
//       renderSunburstChart(data);
//     }
//   }, [data]);

//   useEffect(() => {
//     return () => {
//       if (rootRef.current) {
//         rootRef.current.dispose();
//       }
//     };
//   }, []);

//   const renderSunburstChart = (data) => {
//     if (rootRef.current) {
//       rootRef.current.dispose();
//     }

//     const root = am5.Root.new(chartRef.current);
//     rootRef.current = root;
//     root.setThemes([am5themes_Animated.new(root)]);

//     // Remove the watermark
//     root._logo.dispose();

//     const series = root.container.children.push(
//       am5hierarchy.Sunburst.new(root, {
//         singleBranchOnly: false,
//         downDepth: 3,
//         initialDepth: 1,
//         topDepth: 0,
//         innerRadius: am5.percent(10),
//         valueField: "value",
//         categoryField: "name",
//         childDataField: "children",
//         colors: am5.ColorSet.new(root, {
//           colors: ['#0A7029', '#FEDE00', '#C8DF52', '#b57bee', '#116530', '#21B6A8', '#A3EBB1', '#18A558', '#145DA0', '#0C2D48', '#2E8BC0', '#B1D4E0', '#B1D4E0'],
//           passOptions: {
//             lightness: 0.1,
//           },
//         }),
//       })
//     );

//     const chartData = convertToSunburstData(data);
//     series.data.setAll(chartData);

//     // Add labels to the segments
//     series.slices.template.set("tooltipText", "{name}: {value}");
//     series.labels.template.setAll({
//       text: "{name}",
//       fill: am5.color(0x000000),
//       centerX: am5.percent(50),
//       centerY: am5.percent(50),
//     });

//     series.appear(1000, 100);
//   };

//   const convertToSunburstData = (data) => {
//     // Calculate total Co2e
//     const totalCo2e = data.reduce((total, item) => {
//       const co2eValue = item.pollutants.find(p => p.key === 'Co2e')?.value || 0;
//       return total + co2eValue;
//     }, 0);

//     // Group data by scope and methods
//     const groupedByScope = data.reduce((acc, item) => {
//       const { scope, ghgMethodId } = item;

//       if (!acc[scope]) {
//         acc[scope] = [];
//       }

//       acc[scope].push({
//         name: `Method ${ghgMethodId || 'No Method'}`,
//         value: item.pollutants.find(p => p.key === 'Co2e')?.value || 0
//       });

//       return acc;
//     }, {});

//     // Convert the grouped data into the structure needed for the sunburst chart
//     const chartData = [{
//       name: 'Total Co2e',
//       value: totalCo2e,
//       children: Object.keys(groupedByScope).map(scope => ({
//         name: `Scope ${scope}`,
//         children: groupedByScope[scope]
//       }))
//     }];

//     return chartData;
//   };

//   return (
//     <div className="ghg-pollutant-sunburst">
//       {data.length === 0 ? (
//         <div className="alert alert-info">No data available for the selected scope!</div>
//       ) : (
//         <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>
//       )}
//     </div>
//   );
// };

// export default GhgPollutantSunburst;
