import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_BASE_URL } from '../../../../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddArea = ({ areaId, closeModal }) => {
    const [area, setArea] = useState({
        areaKey: '',
        areaName: '',
        status: 'A'
    });
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (areaId) {
            fetchAreaData(areaId);
            setIsEdit(true);
        }
    }, [areaId]);

    const fetchAreaData = async (key) => {
        try {
            const response = await fetch(`${API_BASE_URL}/area/${key}`, {
                method: 'GET',
               
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setArea({
                areaKey: data.areaKey,
                areaName: data.areaName,
                status: data.status
            });
        } catch (error) {
            console.error('Failed to fetch area data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setArea(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiEndpoint = isEdit ? `${API_BASE_URL}/area/update` : `${API_BASE_URL}/area/save`;
        const method = isEdit ? 'PUT' : 'POST';

        try {
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                credentials: 'include',
                body: JSON.stringify(area)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success(`Area ${isEdit ? 'updated' : 'added'} successfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            closeModal();
        } catch (error) {
            console.error(`Failed to ${isEdit ? 'update' : 'add'} area:`, error);
        }
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Area ID</label>
                        <input
                            type="text"
                            name="areaKey"
                            className="form-control"
                            value={area.areaKey}
                            onChange={handleChange}
                            disabled={isEdit}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Area Name</label>
                        <input
                            type="text"
                            name="areaName"
                            className="form-control"
                            value={area.areaName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Status</label>
                        <input
                            type="text"
                            name="status"
                            className="form-control"
                            value={area.status}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="button" className="btn btn-secondary float-left" onClick={closeModal}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary float-right">
                        {isEdit ? 'Update' : 'Save'}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddArea;
