import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './PollutantSummary.css'; // Ensure to import your CSS file
import { formatNumberDashboard } from '../../../../common/FormatNumber';

const PollutantSummary = ({ pollutantStats }) => {
  const [orderedPollutantStats, setOrderedPollutantStats] = useState([]);
  const [hoveredPollutant, setHoveredPollutant] = useState(null);

  useEffect(() => {
    if (pollutantStats) {
      const orderedStats = [
        pollutantStats.find(t => t.pollutantId === 'CO2'),
        pollutantStats.find(t => t.pollutantId === 'CH4'),
        pollutantStats.find(t => t.pollutantId === 'N2O'),
        pollutantStats.find(t => t.pollutantId === 'CO2e')
      ].filter(Boolean);
      setOrderedPollutantStats(orderedStats);
    }
  }, [pollutantStats]);

  return (
    <div className="pollutant-summary">
      <div className="row">
        {orderedPollutantStats.map(item => (
          <div
            key={item.pollutantId}
            className="col-sm-3 col-md-3 pollutant-series"
            onMouseEnter={() => setHoveredPollutant(item.pollutantId)}
            onMouseLeave={() => setHoveredPollutant(null)}
          >
            <div className={`pollutant-tile ${item.pollutantId}`}>
              <div className='row'>
                <div className='col-md-4'>
                  <div className={`pollutant-name ${item.pollutantId}`}>
                    <h3>{item.pollutantId}</h3>
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className="monthly-emission">
                    {/* <h3>Monthly: {(item.monthlyGhg / 1000).toFixed(0)} Tons</h3> */}
                    <h3>{formatNumberDashboard((item.monthlyGhg / 1000).toFixed(0))}</h3>
                  </div>
                </div>
              </div>


              {hoveredPollutant === item.pollutantId && (
                <div className="pollutant-tooltip">
                  <div className="yesterday-emission">
                    <h3>Yesterday: {formatNumberDashboard((item.yesterdayGhg / 1000).toFixed(0))}</h3>
                  </div>
                  <div className="annual-emission">
                    <h3>Annual: {formatNumberDashboard((item.annualGhg / 1000).toFixed(0))}</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PollutantSummary.propTypes = {
  pollutantStats: PropTypes.arrayOf(
    PropTypes.shape({
      pollutantId: PropTypes.string.isRequired,
      yesterdayGhg: PropTypes.number.isRequired,
      monthlyGhg: PropTypes.number.isRequired,
      annualGhg: PropTypes.number.isRequired
    })
  ).isRequired
};

export default PollutantSummary;
