import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveProjectType } from '../services/projectService';

const AddProjectTypeModal = ({ show, onHide, reload }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await saveProjectType(data, false);
      if (response.isSuccess) {
        toast.success('Project Type saved successfully');
        onHide();
        reload();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error('Error saving Project Type');
    }
  };

  const handleClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Project Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="projectTypeID">Project Type ID</label>
            <input
              type="text"
              className={`form-control ${errors.projectTypeID ? 'is-invalid' : ''}`}
              id="projectTypeID"
              placeholder="Project Type ID"
              {...register('projectTypeID', { required: true })}
            />
            {errors.projectTypeID && <div className="invalid-feedback">Project Type ID is required.</div>}
          </div>
          <div className="form-group">
            <label htmlFor="projectType">Project Type</label>
            <input
              type="text"
              className={`form-control ${errors.projectType ? 'is-invalid' : ''}`}
              id="projectType"
              placeholder="Project Type"
              {...register('projectType', { required: true })}
            />
            {errors.projectType && <div className="invalid-feedback">Project Type is required.</div>}
          </div>
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              className={`form-control ${errors.status ? 'is-invalid' : ''}`}
              id="status"
              {...register('status', { required: true })}
            >
              <option value="">Status</option>
              <option value="A">Active</option>
              <option value="D">Deactivated</option>
            </select>
            {errors.status && <div className="invalid-feedback">Status is required.</div>}
          </div>
          <div className="form-group">
            <Button type="submit" className="btn btn-primary">Save</Button>
            <Button type="button" className="btn btn-secondary ml-3" onClick={handleClose}>Cancel</Button>
          </div>
        </form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};

export default AddProjectTypeModal;
