import React from 'react';

const CoordinateFormatter = ({ value }) => {
    if (!value) return '';

    const degrees = Math.floor(value);
    const minutes = Math.floor((value - degrees) * 60);
    const seconds = ((value - degrees - minutes / 60) * 3600).toFixed(2);

    return (
        <span>{`${degrees}°${minutes}'${seconds}"`}</span>
    );
};

export default CoordinateFormatter;
