import axios from 'axios';
import { API_BASE_URL } from '../../../../../config';
const API_PREFIX = `${API_BASE_URL}/dashboard/ems/pollutants`;

const EmsService = {
  getActivePollutants: () => {
    return axios.get(`${API_PREFIX}`);
  },

  getPollutantStats: (areaId, facilityId, fromDate) => {
    const urlPart = `stats/v2?fromDate=${fromDate}&areaId=${areaId}&facilityId=${facilityId}`;
    return axios.get(`${API_PREFIX}/${urlPart}`);
  },

  getPollutantStatsV2: (areaId, facilityId, fromDate) => {
    const urlPart = `stats/v2?fromDate=${fromDate}&areaId=${areaId}&facilityId=${facilityId}`;
    console.log('Weoclme')
    return axios.get(`${API_PREFIX}/${urlPart}`);
  },

  getActualEmissions: (areaId, facilityId, fromDate, toDate) => {
    const urlPart = `actual/emissions?fromDate=${fromDate}&toDate=${toDate}&areaId=${areaId}&facilityId=${facilityId}`;
    return axios.get(`${API_PREFIX}/${urlPart}`);
  },

  getEmissionsByReason: (areaId, facilityId, date) => {
    const urlPart = `reasons-by-type?date=${date}&areaId=${areaId}&facilityId=${facilityId}`;
    return axios.get(`${API_PREFIX}/${urlPart}`);
  }
};

export default EmsService;
