import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../config';
import DatePicker from 'react-datepicker';
import FacilitySelector from '../../../common/FacilitySelector';
import 'react-datepicker/dist/react-datepicker.css';
import KPIExportComponent from '../../../common/KPIExportComponent';
import './FlaringKPIReport.css';
import { BUTTON_LABELS } from '../../../../constants';
import { formatNumber, formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../common/FormatNumber';
import { format } from 'date-fns';

export default function FlaringKPIReport() {
    const [facilityData, setFacilityData] = useState([]);
    const [flaringKpiReportData, setFlaringKpiReportData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState('');
    const [startDatePickerClass, setStartDatePickerClass] = useState('');
    const [endDatePickerClass, setEndDatePickerClass] = useState('');
    const [exportFilteredData, setExportFilteredData] = useState([]);


    const handleStartDateChange = (date) => setStartDate(date);
    const handleEndDateChange = (date) => setEndDate(date);
    const handleFacilityChange = (e) => setSelectedFacilityId(e.target.value);
    const handleSearch = (event) => setSearch(event.target.value);

    const formatDate = (date) => {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const handlePlanVsActualButtonClick = () => {
        if (startDate && endDate && selectedFacilityId !== '') {
            const apiUrl = `${API_BASE_URL}/reports/kpi-audit-report?fromDate=${formatDate(startDate)}&toDate=${formatDate(endDate)}&facility=${selectedFacilityId}`;
            setIsLoading(true);

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    setFlaringKpiReportData(data.content);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                });
        } else {
            console.error('Please select valid options.');
        }
    };

    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = flaringKpiReportData.filter((item) =>
            Object.values(item).some((value) =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Facility ID': item.facilityId,
            'Actual Gas(mmSCF)': formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualGas ? item.feedItem.actualGas.toFixed(0) : item.feedItem.actualGas),
            'Actual Liquid(mBBL)': formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualLiquid ? item.feedItem.actualLiquid.toFixed(0) : item.feedItem.actualLiquid),
            'Actual Thousand BOE(MBOE)': formatWithIndianThousandsSeparatorForExportData(item.feedItem.actualThousandBOE ? item.feedItem.actualThousandBOE.toFixed(0) : item.feedItem.actualThousandBOE),
            'Plan Gas(mmSCF)': formatWithIndianThousandsSeparatorForExportData(item.feedItem.planGas ? item.feedItem.planGas.toFixed(0) : item.feedItem.planGas),
            'Plan Liquid(mBBL)': formatWithIndianThousandsSeparatorForExportData(item.feedItem.planLiquid ? item.feedItem.planLiquid.toFixed(0) : item.feedItem.planLiquid),
            'Plan Thousand BOE(MBOE)': formatWithIndianThousandsSeparatorForExportData(item.feedItem.planThousandBOE ? item.feedItem.planThousandBOE.toFixed(0) : item.feedItem.planThousandBOE),
            'Actual Gas(mmSCF)': formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualGas ? item.flaringItem.actualGas.toFixed(0) : item.flaringItem.actualGas),
            'Actual Liquid(mBBL)': formatWithIndianThousandsSeparatorForExportData(item.flaringItem.actualLiquid ? item.flaringItem.actualLiquid.toFixed(0) : item.flaringItem.actualLiquid),
            'Plan Gas(mmSCF)': formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planGas ? item.flaringItem.planGas.toFixed(0) : item.flaringItem.planGas),
            'Plan Liquid(mBBL)': formatWithIndianThousandsSeparatorForExportData(item.flaringItem.planLiquid ? item.flaringItem.planLiquid.toFixed(0) : item.flaringItem.planLiquid),
            'Actual Gas(scf/boe)': formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualGas ? item.varianceItem.actualGas.toFixed(0) : item.varianceItem.actualGas),
            'Plan Gas(scf/boe)': formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planGas ? item.varianceItem.planGas.toFixed(0) : item.varianceItem.planGas),
            'Variance Gas(%)': formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceGas ? item.varianceItem.varianceGas.toFixed(0) : item.varianceItem.varianceGas),
            'Actual Liquid(bbl/boe)': formatWithIndianThousandsSeparatorForExportData(item.varianceItem.actualLiquid ? item.varianceItem.actualLiquid.toFixed(0) : item.varianceItem.actualLiquid),
            'Plan Liquid(bbl/boe)': formatWithIndianThousandsSeparatorForExportData(item.varianceItem.planLiquid ? item.varianceItem.planLiquid.toFixed(0) : item.varianceItem.planLiquid),
            'Variance': formatWithIndianThousandsSeparatorForExportData(item.varianceItem.varianceLiquid ? item.varianceItem.varianceLiquid.toFixed(0) : item.varianceItem.varianceLiquid)
        }));
        setExportFilteredData(exportData);

    }, [search, flaringKpiReportData]);

    const handleStartDateFocus = () => setStartDatePickerClass('datepicker-parent');
    const handleStartDateBlur = () => setStartDatePickerClass('');
    const handleEndDateFocus = () => setEndDatePickerClass('datepicker-parent');
    const handleEndDateBlur = () => setEndDatePickerClass('');

    const headerData = [
        { label: 'Facility Name', value: selectedFacilityId !== -1 ? facilityData.find(facility => facility.facilityId == selectedFacilityId)?.facilityName : 'All Facility' },
        { label: 'Start Date', value: startDate ? format(startDate, 'yyyy-MM-dd') : 'no date selected' },
        { label: 'End Date', value: endDate ? format(endDate, 'yyyy-MM-dd') : 'no date selected' },
    ];

    const title = "Flaring KPI Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Flaring KPI Report</h1>
                <ul>
                    <li>Reports</li>
                    <li>Flaring</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            <div className="breadcrumb mt-3">
                <div className='row'>
                    <FacilitySelector
                        selectedFacilityId={selectedFacilityId}
                        onChange={setSelectedFacilityId}
                        facilityData={facilityData}
                        setFacilityData={setFacilityData}
                    />
                    <div className='col-md-2'>
                        <label>Start Date</label>
                        <div className={startDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleStartDateFocus}
                                onBlur={handleStartDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <label>End Date</label>
                        <div className={endDatePickerClass}>
                            <DatePicker
                                className='form-control'
                                selected={endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onFocus={handleEndDateFocus}
                                onBlur={handleEndDateBlur}
                            />
                        </div>
                    </div>
                    <div className='col-md-3 generate_report_button'>
                        <button className='btn btn-primary' onClick={handlePlanVsActualButtonClick}>{BUTTON_LABELS.REPORT_BUTTON}</button>
                    </div>
                </div>
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Flaring KPI Report</h4> */}
                            <div className='row'>
                                <div className='col-md-10'></div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>
                                        {filteredData.length > 0 && (
                                            <KPIExportComponent data={flaringKpiReportData} fileName={`FMS_Flaring_KPI_Report_${new Date().toLocaleDateString()}`} headerData={headerData} title={title} />
                                        )}
                                        <br></br>
                                        <table className="table table-striped table-bordered" style={{ border: '2px solid #dee2e6' }}>
                                            <thead>
                                                <tr>
                                                    <th rowSpan="1"></th>
                                                    <th colSpan="6">Facility Feed</th>
                                                    <th colSpan="5">Flaring</th>
                                                    <th colSpan="6">KPI</th>
                                                </tr>
                                                <tr>
                                                    <th className='h6'>Facility ID</th>
                                                    <th className='h6'>Actual Gas(mmSCF)</th>
                                                    <th className='h6'>Actual Liquid(mBBL)</th>
                                                    <th className='h6'>Actual Thousand BOE(MBOE)</th>
                                                    <th className='h6'>Plan Gas(mmSCF)</th>
                                                    <th className='h6'>Plan Liquid(mBBL)</th>
                                                    <th className='h6'>Plan Thousand BOE(MBOE)</th>
                                                    <th className='h6'>Actual Gas(mmSCF)</th>
                                                    <th className='h6'>Actual Liquid(mBBL)</th>
                                                    <th className='h6'>Plan Gas(mmSCF)</th>
                                                    <th className='h6'>Plan Liquid(mBBL)</th>
                                                    <th className='h6'>Actual Gas(scf/boe)</th>
                                                    <th className='h6'>Plan Gas(scf/boe)</th>
                                                    <th className='h6'>Variance Gas(%)</th>
                                                    <th className='h6'>Actual Liquid(bbl/boe)</th>
                                                    <th className='h6'>Plan Liquid(bbl/boe)</th>
                                                    <th className='h6'>Variance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='fw-light'>{item.facilityId}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.feedItem.actualGas ? item.feedItem.actualGas.toFixed(0) : item.feedItem.actualGas)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.feedItem.actualLiquid ? item.feedItem.actualLiquid.toFixed(0) : item.feedItem.actualLiquid)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.feedItem.actualThousandBOE ? item.feedItem.actualThousandBOE.toFixed(0) : item.feedItem.actualThousandBOE)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.feedItem.planGas ? item.feedItem.planGas.toFixed(0) : item.feedItem.planGas)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.feedItem.planLiquid ? item.feedItem.planLiquid.toFixed(0) : item.feedItem.planLiquid)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.feedItem.planThousandBOE ? item.feedItem.planThousandBOE.toFixed(0) : item.feedItem.planThousandBOE)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.flaringItem.actualGas ? item.flaringItem.actualGas.toFixed(0) : item.flaringItem.actualGas)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.flaringItem.actualLiquid ? item.flaringItem.actualLiquid.toFixed(0) : item.flaringItem.actualLiquid)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.flaringItem.planGas ? item.flaringItem.planGas.toFixed(0) : item.flaringItem.planGas)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.flaringItem.planLiquid ? item.flaringItem.planLiquid.toFixed(0) : item.flaringItem.planLiquid)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.varianceItem.actualGas ? item.varianceItem.actualGas.toFixed(0) : item.varianceItem.actualGas)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.varianceItem.planGas ? item.varianceItem.planGas.toFixed(0) : item.varianceItem.planGas)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.varianceItem.varianceGas ? item.varianceItem.varianceGas.toFixed(0) : item.varianceItem.varianceGas)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.varianceItem.actualLiquid ? item.varianceItem.actualLiquid.toFixed(0) : item.varianceItem.actualLiquid)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.varianceItem.planLiquid ? item.varianceItem.planLiquid.toFixed(0) : item.varianceItem.planLiquid)}</td>
                                                        <td className='fw-light'>{formatWithIndianThousandsSeparator(item.varianceItem.varianceLiquid ? item.varianceItem.varianceLiquid.toFixed(0) : item.varianceItem.varianceLiquid)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
