import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';
const AddFacility = () => {
  const navigate = useNavigate();
  // const { facilityId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [facility, setFacility] = useState({
    facilityId: '',
    facilityName: '',
    areaId: '',
    categoryId: '',
    zoneOrArea: '',
    flaringUOM: '',
    leakageCalcSwitch: '',
    shiftStartTime: '',
    noOfShifts: '',
    dahsServerName: '',
    flareScheduler: '',
    emsScheduler: '',
    piURL: '',
    status: 'A'
  });
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [areas, setAreas] = useState([]);
  const [facilityCategories, setFacilityCategories] = useState([]);
  const [leakageCalcSwitches, setLeakageCalcSwitches] = useState([]);
  const [zonesOrAreas, setZonesOrAreas] = useState([]);
  const [uoms, setUoms] = useState([]);

  useEffect(() => {
    if (searchParams.get("facilityId")) {
      fetchFacilityData(searchParams.get("facilityId"));
      setIsEdit(true);
    }
    fetchInitialData();
  }, [searchParams.get("facilityId")]);

  const fetchFacilityData = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/facility/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setFacility(data);
    } catch (error) {
      console.error('Failed to fetch facility data:', error);
    }
  };

  const fetchInitialData = async () => {
    try {
      const [areasResponse, uomsResponse, codemastersResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/facility/areas`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/uoms`, { credentials: 'include' }),
        fetch(`${API_BASE_URL}/device/codemasters`, { credentials: 'include' })
      ]);

      if (!areasResponse.ok || !uomsResponse.ok || !codemastersResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const [areasData, uomsData, codemastersData] = await Promise.all([
        areasResponse.json(),
        uomsResponse.json(),
        codemastersResponse.json()
      ]);

      setAreas(areasData);
      setUoms(uomsData.filter(uom => uom.uomType === 'VOLFLOW'));

      const filteredLeakageCalcSwitches = codemastersData.filter(item => item.type === CodeMasterType.LeakageCalcSwitch);
      setLeakageCalcSwitches(filteredLeakageCalcSwitches);

      const filteredZonesOrAreas = codemastersData.filter(item => item.type === CodeMasterType.ZoneOrArea);
      setZonesOrAreas(filteredZonesOrAreas);

      const filteredFacilityCategories = codemastersData.filter(item => item.type === CodeMasterType.FacilityCategory);
      setFacilityCategories(filteredFacilityCategories);
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!facility.facilityId) {
      newErrors.facilityId = 'Facility ID is required.';
    }
    if (!facility.areaId) {
      newErrors.areaId = 'Area is required.';
    }
    if (!facility.status) {
      newErrors.status = 'Status is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFacility(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: null
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const apiEndpoint = isEdit ? `${API_BASE_URL}/facility/update` : `${API_BASE_URL}/facility/save`;
    const method = isEdit ? 'PUT' : 'POST';

    try {
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(facility)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Facility ${isEdit ? 'updated' : 'added'} successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate('/admin/configurations/facility');
      }, 1000);
    } catch (error) {
      console.error(`Failed to ${isEdit ? 'update' : 'add'} facility:`, error);
      toast.error(`Failed to ${isEdit ? 'update' : 'add'} facility.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  console.log("facilitydata", facility)
  return (
    <div className="main-content">
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Facility' : 'Add Facility'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Organizational Structure</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <div className="row mb-4">
        <div className="col-md-12 mb-3">
          <div className="card text-left">
            <div className="card-body">
              {/* <h4 className="card-title mb-3">{isEdit ? 'Edit Facility' : 'Add Facility'}</h4> */}
              <hr></hr>
              <form onSubmit={handleSubmit} noValidate>
                <h4>Facility Info</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Facility ID</label>
                      <input
                        type="text"
                        name="facilityId"
                        className={`form-control ${errors.facilityId ? 'is-invalid' : ''}`}
                        value={facility.facilityId}
                        onChange={handleChange}
                        disabled={isEdit}
                        required
                      />
                      {errors.facilityId && <div className="invalid-feedback">{errors.facilityId}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Facility Name</label>
                      <input
                        type="text"
                        name="facilityName"
                        className={`form-control ${errors.facilityName ? 'is-invalid' : ''}`}
                        value={facility.facilityName}
                        onChange={handleChange}
                      />
                      {errors.facilityName && <div className="invalid-feedback">{errors.facilityName}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Facility Area</label>
                      <select
                        name="areaId"
                        className={`form-control ${errors.areaId ? 'is-invalid' : ''}`}
                        value={facility.areaId}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Facility Area</option>
                        {areas.map(area => (
                          <option key={area.areaKey} value={area.areaKey}>{area.areaName}</option>
                        ))}
                      </select>
                      {errors.areaId && <div className="invalid-feedback">{errors.areaId}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Facility Category</label>
                      <select
                        name="categoryId"
                        className={`form-control ${errors.categoryId ? 'is-invalid' : ''}`}
                        value={facility.categoryId}
                        onChange={handleChange}
                      >
                        <option value="">Select Facility Category</option>
                        {facilityCategories.map(category => (
                          <option key={category.code} value={category.code}>{category.description}</option>
                        ))}
                      </select>
                      {errors.categoryId && <div className="invalid-feedback">{errors.categoryId}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Zone or Area</label>
                      <select
                        name="zoneOrArea"
                        className={`form-control ${errors.zoneOrArea ? 'is-invalid' : ''}`}
                        value={facility.zoneOrArea}
                        onChange={handleChange}
                      >
                        <option value="">Select Zone or Area</option>
                        {zonesOrAreas.map(zone => (
                          <option key={zone.code} value={zone.code}>{zone.description}</option>
                        ))}
                      </select>
                      {errors.zoneOrArea && <div className="invalid-feedback">{errors.zoneOrArea}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Flaring UoM</label>
                      <select
                        name="flaringUOM"
                        className={`form-control ${errors.flaringUOM ? 'is-invalid' : ''}`}
                        value={facility.flaringUOM}
                        onChange={handleChange}
                      >
                        <option value="">Select Flaring UoM</option>
                        {uoms.map(uom => (
                          <option value={uom.uomId}>{uom.description}</option>
                        ))}
                      </select>
                      {errors.flaringUOM && <div className="invalid-feedback">{errors.flaringUOM}</div>}
                    </div>
                  </div>
                </div>
                <br></br>
                <h4>Facility Details</h4>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Leakage Calc Switch</label>
                      <select
                        name="leakageCalcSwitch"
                        className={`form-control ${errors.leakageCalcSwitch ? 'is-invalid' : ''}`}
                        value={facility.leakageCalcSwitch}
                        onChange={handleChange}
                      >
                        <option value="">Select Leakage Calc Switch</option>
                        {leakageCalcSwitches.map(switchItem => (
                          <option key={switchItem.code} value={switchItem.code}>{switchItem.description}</option>
                        ))}
                      </select>
                      {errors.leakageCalcSwitch && <div className="invalid-feedback">{errors.leakageCalcSwitch}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Shift Start Time</label>
                      <input
                        type="text"
                        name="shiftStartTime"
                        className={`form-control ${errors.shiftStartTime ? 'is-invalid' : ''}`}
                        value={facility.shiftStartTime}
                        onChange={handleChange}
                      />
                      {errors.shiftStartTime && <div className="invalid-feedback">{errors.shiftStartTime}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>No of Shifts</label>
                      <input
                        type="text"
                        name="noOfShifts"
                        className={`form-control ${errors.noOfShifts ? 'is-invalid' : ''}`}
                        value={facility.noOfShifts}
                        onChange={handleChange}
                      />
                      {errors.noOfShifts && <div className="invalid-feedback">{errors.noOfShifts}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>DAHS Server Name</label>
                      <input
                        type="text"
                        name="dahsServerName"
                        className={`form-control ${errors.dahsServerName ? 'is-invalid' : ''}`}
                        value={facility.dahsServerName}
                        onChange={handleChange}
                      />
                      {errors.dahsServerName && <div className="invalid-feedback">{errors.dahsServerName}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Flare Scheduler</label>
                      <input
                        type="text"
                        name="flareScheduler"
                        className={`form-control ${errors.flareScheduler ? 'is-invalid' : ''}`}
                        value={facility.flareScheduler}
                        onChange={handleChange}
                      />
                      {errors.flareScheduler && <div className="invalid-feedback">{errors.flareScheduler}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>EMS Scheduler</label>
                      <input
                        type="text"
                        name="emsScheduler"
                        className={`form-control ${errors.emsScheduler ? 'is-invalid' : ''}`}
                        value={facility.emsScheduler}
                        onChange={handleChange}
                      />
                      {errors.emsScheduler && <div className="invalid-feedback">{errors.emsScheduler}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>PI URL</label>
                      <input
                        type="text"
                        name="piURL"
                        className={`form-control ${errors.piURL ? 'is-invalid' : ''}`}
                        value={facility.piURL}
                        onChange={handleChange}
                      />
                      {errors.piURL && <div className="invalid-feedback">{errors.piURL}</div>}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        name="status"
                        className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                        value={facility.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="A">A</option>
                        <option value="D">D</option>
                      </select>
                      {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                    </div>
                  </div>
                </div>
                <button type="button" className="btn btn-secondary" onClick={() => navigate('/admin/configurations/facility')}>Cancel</button>
                <button type="submit" className="btn btn-primary float-right mr-3">
                  {isEdit ? 'Update' : 'Save'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddFacility;
