import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../../../config';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import AddTagMasterVOne from './AddTagMasterVOne'; // Adjust the path as necessary
import AddTagsMasterVTwo from './AddTagMasterVTwo';
import ExportComponent from '../../../../common/ExportComponent';
import TagsMasterExportComponent from '../../../../common/exports/TagsMasterExportComponent';
import { formatWithIndianThousandsSeparator, formatWithIndianThousandsSeparatorForExportData } from '../../../../common/FormatNumber';

export default function TagsMasterList() {
    const [tagsMasterData, setTagsMasterData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const [exportFilteredData, setExportFilteredData] = useState([]);

    const navigate = useNavigate();

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        fetchTagsMasterData();
    }, []);

    const fetchTagsMasterData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/master/tags-master`, {
                method: 'GET',

                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setTagsMasterData(data);
            setFilteredData(data);
        } catch (error) {
            console.error('Failed to fetch Device data:', error);
        } finally {
            setIsLoading(false);
        }
    };



    const handleEdit = (id) => {
        navigate(`/admin/configurations/master/add-tags-master/${id}`);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Tag Master ${id}?`);
        if (confirmDelete) {
            try {
                const response = await fetch(`${API_BASE_URL}/master/tags-master/delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                toast.success('Successfully removed!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                fetchTagsMasterData();
            } catch (error) {
                console.error('Failed to delete header:', error);
            }
        }
    };


    useEffect(() => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = tagsMasterData.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedSearch)
            )
        );
        setFilteredData(filtered);

        const exportData = filtered.map(item => ({
            'Source ID': item.deviceId,
            'Tag Name': item.tagName,
            'Description': item.description,
            'Reading Type': item.readingType,
            'LIMS Composition': item.limsComposition,
            'LIMS Property': item.limsProperty,
            'Source Type': item.deviceType,
            'Min': formatWithIndianThousandsSeparatorForExportData(item.min ? item.min.toFixed(0) : item.min),
            'Max': formatWithIndianThousandsSeparatorForExportData(item.max ? item.max.toFixed(0) : item.max),
            'Default Value': formatWithIndianThousandsSeparatorForExportData(item.defaultValue ? item.defaultValue.toFixed(0) : item.defaultValue),
            'Schedule Hours': formatWithIndianThousandsSeparatorForExportData(item.scheduleHours ? item.scheduleHours.toFixed(0) : item.scheduleHours),
            'Valve Open State': item.valveOpenState,
            'UoM': item.uom,
            'Status': item.status
        }));
        setExportFilteredData(exportData);


    }, [search, tagsMasterData]);

    const columns = [
        { name: 'Source ID', selector: row => row.deviceId, sortable: true, resizable: true, pinned: 'left' },
        { name: 'Tag Name', selector: row => row.tagName, sortable: true, resizable: true },
        { name: 'Description', selector: row => row.description, sortable: true, resizable: true },
        { name: 'Reading Type', selector: row => row.readingType, sortable: true, resizable: true },
        { name: 'LIMS Composition', selector: row => row.limsComposition, sortable: true, resizable: true },
        { name: 'LIMS Property', selector: row => row.limsProperty, sortable: true, resizable: true },
        { name: 'Source Type', selector: row => row.deviceType, sortable: true, resizable: true },
        {
            name: 'Min',
            selector: row => row.min,
            cell: row => formatWithIndianThousandsSeparator(row.min ? row.min.toFixed(0) : row.min),
            sortable: true,
            resizable: true
        },
        {
            name: 'Max',
            selector: row => row.max,
            cell: row => formatWithIndianThousandsSeparator(row.max ? row.max.toFixed(0) : row.max),
            sortable: true,
            resizable: true
        },
        {
            name: 'Default Value',
            selector: row => row.defaultValue,
            cell: row => formatWithIndianThousandsSeparator(row.defaultValue ? row.defaultValue.toFixed(0) : row.defaultValue),
            sortable: true,
            resizable: true
        },
        {
            name: 'Schedule Hours',
            selector: row => row.scheduleHours,
            cell: row => formatWithIndianThousandsSeparator(row.scheduleHours ? row.scheduleHours.toFixed(0) : row.scheduleHours),
            sortable: true,
            resizable: true
        },
        { name: 'Valve Open State', selector: row => row.valveOpenState, sortable: true, resizable: true },
        { name: 'UoM', selector: row => row.uom, sortable: true, resizable: true },
        { name: 'Status', selector: row => row.status, sortable: true, resizable: true },
        {
            name: 'Edit',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => handleEdit(row.id)}
                    style={{ cursor: 'pointer' }}
                />
            )
        },
        {
            name: 'Delete',
            width: '75px',
            cell: row => (
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => handleDelete(row.id)}
                    style={{ cursor: 'pointer', color: 'red' }}
                />
            )
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '30px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const fileName = `Tag_Master_${new Date().toLocaleDateString()}`;

    const title = "Tag Master Report";

    return (
        <div className="main-content">
            <div className='breadcrumb mt-3'>
                <h1>Tag Master</h1>
                <ul>
                    <li>Master Data Management</li>
                </ul>
                <div className="separator-breadcrumb border-top" />
            </div>
            {/* <div className="separator-breadcrumb border-top" /> */}
            <div className="row mb-4">
                <div className="col-md-12 mb-3">
                    <div className="card text-left">
                        <div className="card-body">
                            {/* <h4 className="card-title mb-3">Tags Master</h4> */}
                            <div className='row pull-right'>
                                {/* <div className='col-md-2'>
                                    <Link to="/admin/configurations/master/add-tags-master" className="btn btn-primary">
                                        Add New Record1
                                    </Link>
                                </div> */}
                                <div className='col-md-2'>
                                    <Link to="/admin/configurations/master/add-tags-master2" className="btn btn-primary">
                                        Add New Record
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-10'>
                                    {filteredData.length > 0 && (
                                        <TagsMasterExportComponent data={exportFilteredData} columns={columns} fileName={fileName} title={title} />
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="form-control mb-3"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {isLoading && <div>Loading...</div>}
                                {!isLoading && (
                                    <>

                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            customStyles={customStyles}
                                            pagination
                                            highlightOnHover
                                            striped
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end of col */}
            </div>
            <ToastContainer />
        </div>
    );
}
