// src/components/CustomDatePicker/CustomDatePicker.jsx

import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DateContext } from '../../contexts/DateContext';
import './CustomDatePicker.css'; // Create this file for custom styles

const CustomDatePicker = () => {
  const { selectedDate, setSelectedDate } = useContext(DateContext);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="MMMM yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      className="custom-datepicker-input"
      calendarClassName="custom-datepicker"
    />
  );
};

export default CustomDatePicker;
