import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CodeMasterType from '../../../../constants/CodeMasterType';
import {
  getAllCodeMasters,
  getEquipmentClassificationByTypeId,
  saveEquipmentClassification,
} from '../services/EquipmentClassificationService';

const AddEquipmentClassification = () => {
  const [formGroup, setFormGroup] = useState({
    equipmentTypeId: '',
    description: '',
    type: '',
    status: 'A',
  });
  const [statusOptions] = useState(['A', 'D']);
  const [lstEquipmentType, setLstEquipmentType] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { typeId } = useParams();
  const isEdit = !!typeId;

  useEffect(() => {
    fetchInitialData();
    if (isEdit) {
      fetchEquipmentClassificationData(typeId);
    }
  }, [typeId, isEdit]);

  const fetchInitialData = async () => {
    try {
      const codeMasters = await getAllCodeMasters();
      setLstEquipmentType(filterEqptTypeFromCodeMaster(codeMasters));
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const fetchEquipmentClassificationData = async (typeId) => {
    try {
      const data = await getEquipmentClassificationByTypeId(typeId);
      setFormGroup(data);
    } catch (error) {
      console.error('Error fetching equipment classification data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.equipmentTypeId) {
      newErrors.equipmentTypeId = 'Equipment Classification is required.';
    }
    // if (!formGroup.status) {
    //   newErrors.status = 'Status is required.';
    // }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await saveEquipmentClassification(isEdit, formGroup);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Equipment Classification ${isEdit ? 'updated' : 'added'} successfully.`);
      setTimeout(() => {
        navigate('/admin/configurations/equipment/list-equipment-classification');
      }, 1000);
    } catch (error) {
      console.error('Failed to save equipment classification:', error);
      toast.error('Error saving Equipment Classification');
    }
  };

  return (
    <div>
      
      <div className='breadcrumb mt-2'>
        <h1>{isEdit ? 'Edit Equipment Classification' : 'Add Equipment Classification'}</h1>
        <ul>
          <li>Master Data Management</li>
          <li>Equipment</li>
        </ul>
        <div className="separator-breadcrumb border-top" />
      </div>
      {/* <div className="separator-breadcrumb border-top" /> */}
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-header">Equipment Classification Data</div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="equipmentTypeId">Equipment Classification</label>
                <input
                  type="text"
                  className={`form-control ${errors.equipmentTypeId ? 'is-invalid' : 'is-valid'}`}
                  id="equipmentTypeId"
                  name="equipmentTypeId"
                  value={formGroup.equipmentTypeId}
                  onChange={handleChange}
                  readOnly={isEdit}
                  required
                />
                {errors.equipmentTypeId && (
                  <div className="invalid-feedback d-block">
                    {errors.equipmentTypeId}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className={`form-control ${errors.description ? 'is-invalid' : 'is-valid'}`}
                  id="description"
                  name="description"
                  value={formGroup.description}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="type">Equipment Type</label>
                <select
                  className={`form-control ${errors.type ? 'is-invalid' : 'is-valid'}`}
                  id="type"
                  name="type"
                  value={formGroup.type}
                  onChange={handleChange}
                >
                  <option value=""> Select Equipment Type</option>
                  {lstEquipmentType.map((status) => (
                    <option key={status.code} value={status.code}>
                      {status.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row mt-4">
              <div className="col-sm-4">
                <label htmlFor="status">Status</label>
                <select
                  className={`form-control ${errors.status ? 'is-invalid' : 'is-valid'}`}
                  id="status"
                  name="status"
                  value={formGroup.status}
                  onChange={handleChange}
                  required
                >
                  {statusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                {errors.status && (
                  <div className="invalid-feedback d-block">{errors.status}</div>
                )}
              </div>
            </div>
            <div className="form-group row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary float-right mr-3"
                  onClick={() =>
                    navigate('/admin/configurations/equipment/list-equipment-classification')
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

const filterEqptTypeFromCodeMaster = (codeMasters) =>
  codeMasters.filter((x) => x.type === CodeMasterType.EqptType);

export default AddEquipmentClassification;
