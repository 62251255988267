import { API_BASE_URL } from '../../../../../config';
import CodeMasterType from '../../../../constants/CodeMasterType';
const UomFormulaService = {
  getAllUomFormula: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/master/uom-formula`);
      if (!response.ok) {
        throw new Error('Failed to fetch UOM formulas');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching UOM formulas:', error);
      throw error;
    }
  },

  getUomFormulaById: async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/master/uom-formula/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch UOM formula by ID');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching UOM formula by ID:', error);
      throw error;
    }
  },

  getAllCodeMasters: async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/device/codemasters`);
      if (!response.ok) {
        throw new Error('Failed to fetch code masters');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching code masters:', error);
      throw error;
    }
  },

  saveUomFormula: async (uomFormula, isEdit) => {
    try {
      const response = await fetch(`${API_BASE_URL}/master/uom-formula/${isEdit ? 'update' : 'save'}`, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(uomFormula),
      });
      if (!response.ok) {
        throw new Error('Failed to save UOM formula');
      }
      return response.json();
    } catch (error) {
      console.error('Error saving UOM formula:', error);
      throw error;
    }
  },

  deleteUomFormula: async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/master/uom-formula/delete/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete UOM formula');
      }
      return response.json();
    } catch (error) {
      console.error('Error deleting UOM formula:', error);
      throw error;
    }
  },

  canDeleteUomFormula: async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/master/uom-formula/can-delete/${id}`);
      if (!response.ok) {
        throw new Error('Failed to check if UOM formula can be deleted');
      }
      return response.json();
    } catch (error) {
      console.error('Error checking if UOM formula can be deleted:', error);
      throw error;
    }
  },

  filterUomTypesFromCodeMaster: (allCodeMasters) => {
    return allCodeMasters.filter(x => x.type === CodeMasterType.UomType);
  },
};

export default UomFormulaService;
