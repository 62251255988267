import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationService from '../services/NotificationService';

const AddUsersConfiguration = ({ showModal, handleClose, selectedUserConfig }) => {
  const navigate = useNavigate();
  const [formGroup, setFormGroup] = useState({
    subscriberID: '',
    subscriber: '',
    activeDirectoryYN: '',
    emailID: '',
    status: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedUserConfig) {
      setFormGroup({
        subscriberID: selectedUserConfig.subscriberID.replace('SCB', ''),
        subscriber: selectedUserConfig.subscriber,
        activeDirectoryYN: selectedUserConfig.activeDirectoryYN,
        emailID: selectedUserConfig.emailID,
        status: selectedUserConfig.status
      });
    }
  }, [selectedUserConfig]);

  const isInputInValid = (name) => {
    return !formGroup[name] && formGroup[name] !== '' && errors[name];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formGroup.subscriberID) newErrors.subscriberID = 'Subscriber ID is required.';
    if (!formGroup.subscriber) newErrors.subscriber = 'Subscriber Name is required.';
    if (!formGroup.emailID) newErrors.emailID = 'Email ID is required.';
    if (!formGroup.status) newErrors.status = 'Status is required.';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const userConfig = {
      ...formGroup,
      subscriberID: `SCB${formGroup.subscriberID}`
    };

    try {
      const response = await NotificationService.saveUserConfig(userConfig);
      if (response.isSuccess) {
        toast.success('User Configurations saved successfully');
        handleClose();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error('Error saving User Configurations');
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <div>
      <h4>{selectedUserConfig ? 'Edit User Configuration' : 'Add User Configuration'}</h4>
      <form onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-sm-12 col-md-12">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="subscriberId">SCB</span>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${isInputInValid('subscriberID') ? 'is-invalid' : ''}`}
                    id="subscriberId"
                    name="subscriberID"
                    placeholder="Subscriber ID"
                    value={formGroup.subscriberID}
                    onChange={handleChange}
                    aria-label="Subscriber Id"
                    aria-describedby="subscriberId"
                    readOnly={!!selectedUserConfig} // Make readonly if editing
                  />
                  {isInputInValid('subscriberID') && <div className="invalid-feedback">{errors.subscriberID}</div>}
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-label-group">
                <label htmlFor="subscriberName">Subscriber Name</label>
                  <input
                    type="text"
                    className={`form-control ${isInputInValid('subscriber') ? 'is-invalid' : ''}`}
                    id="subscriberName"
                    name="subscriber"
                    placeholder="Subscriber Name"
                    value={formGroup.subscriber}
                    onChange={handleChange}
                  />
                  {isInputInValid('subscriber') && <div className="invalid-feedback">{errors.subscriber}</div>}
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-label-group select-group mb-0">
                  <label htmlFor="ActiveDirectoryYN">Active Directory Y/N</label>
                  <select
                    className={`form-control ${isInputInValid('activeDirectoryYN') ? 'is-invalid' : ''}`}
                    id="ActiveDirectoryYN"
                    name="activeDirectoryYN"
                    value={formGroup.activeDirectoryYN}
                    onChange={handleChange}
                  >
                    <option value="">Select Active Directory Y/N</option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                  {isInputInValid('activeDirectoryYN') && <div className="invalid-feedback">{errors.activeDirectoryYN}</div>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="form-label-group">
                <label htmlFor="emailId">Email ID</label>
                  <input
                    type="email"
                    className={`form-control ${isInputInValid('emailID') ? 'is-invalid' : ''}`}
                    id="emailId"
                    name="emailID"
                    placeholder="Email ID"
                    value={formGroup.emailID}
                    onChange={handleChange}
                  />
                  {isInputInValid('emailID') && <div className="invalid-feedback">{errors.emailID}</div>}
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-label-group select-group mb-0">
                  <label htmlFor="status">Status</label>
                  <select
                    className={`form-control ${isInputInValid('status') ? 'is-invalid' : ''}`}
                    id="status"
                    name="status"
                    value={formGroup.status}
                    onChange={handleChange}
                  >
                    <option value="">Select Status</option>
                    <option value="A">A</option>
                    <option value="D">D</option>
                  </select>
                  {isInputInValid('status') && <div className="invalid-feedback">{errors.status}</div>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary float-right">Save</button>
                <button type="button" className="btn btn-secondary float-right mr-3" onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddUsersConfiguration;
