export const ImportStatus = {
    Review: 0,
    Complete: 1,
    Partial: 2,
    Fail: 3,
  };
  
  // Optional: You can add a reverse mapping for easier use in displaying status names
  export const ImportStatusNames = {
    0: 'Review',
    1: 'Complete',
    2: 'Partial',
    3: 'Fail',
  };
  