import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config';

const FacilitySelector = ({ selectedFacilityId, onChange }) => {
  const [facilityData, setFacilityData] = useState([]);

  useEffect(() => {
    const fetchFacilityData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/home/facilities`, {
          method: 'GET',
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setFacilityData(data); // Set the facility data in state
      } catch (error) {
        console.error('Failed to fetch facility data:', error);
      }
    };

    fetchFacilityData();
  }, []); // Only run once on component mount

  return (
    <div className='col-md-2'>
      <label>Select Facility</label>
      <select
        name='facility'
        className='form-control'
        value={selectedFacilityId}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value={-1}>All Facilities</option>
        {facilityData.map((facility) => (
          <option key={facility.facilityId} value={facility.facilityId}>
            {facility.facilityName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FacilitySelector;
